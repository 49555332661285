<div class='consumer-flow mb-5'>
  <div
    class='warning mt-4'
    [innerHTML]="'authentication.register.errors.generic' | translate: lang"
    *ngIf='registerForm.errors?.generic'
  ></div>

  <form
    [formGroup]='registerForm'
    (ngSubmit)='register()'
    novalidate
    [ngClass]="registerForm.errors?.generic ? 'mt-4' : 'mt-5'"
  >
    <div class='mb-3'>
      <broker-label
        [for]="'firstName'"
        [label]="'authentication.register.form.first-name.label' | translate:lang">
      </broker-label>
      <mat-form-field [appearance]="'standard'">
        <input
          type='text'
          matInput
          id='firstName'
          required
          maxlength='255'
          [formControl]='firstNameControl'
          (blur)='trimValue(firstNameControl)'
          [placeholder]="'authentication.register.form.first-name.placeholder' | translate: lang"
          autocomplete='off'
        />
      </mat-form-field>
    </div>

    <div class='mb-3'>
      <broker-label
        [for]="'lastName'"
        [label]="'authentication.register.form.last-name.label' | translate:lang">
      </broker-label>
      <mat-form-field [appearance]="'standard'">
        <input
          type='text'
          matInput
          id='lastName'
          required
          maxlength='255'
          [formControl]='lastNameControl'
          (blur)='trimValue(lastNameControl)'
          [placeholder]="'authentication.register.form.last-name.placeholder' | translate: lang"
          autocomplete='off'
        />
      </mat-form-field>
    </div>

    <div class='mb-3' id='phoneNumber'>
      <broker-label
        [for]="'mobilePhoneNumber'"
        [label]="'authentication.register.form.phone-number.label' | translate:lang">
      </broker-label>
      <mat-form-field appearance='standard'>
        <ngx-mat-intl-tel-input
          id='mobilePhoneNumber'
          [preferredCountries]="['gb']"
          [enableSearch]='true'
          [formControl]='phoneNumberControl'
          [format]="'national'"
        ></ngx-mat-intl-tel-input>
      </mat-form-field>
      <mat-error *ngIf='phoneNumberControl.errors?.invalidPhone'>
        {{ 'authentication.register.form.phone-number.errors.invalid-phone' | translate: lang }}
      </mat-error>
    </div>

    <div class='mb-3'>
      <broker-label
        [for]="'email'"
        [label]="'authentication.register.form.email.label' | translate:lang">
      </broker-label>
      <mat-form-field [appearance]="'standard'">
        <input
          type='email'
          matInput
          id='email'
          required
          email
          [formControl]='emailControl'
          (blur)='trimValue(emailControl)'
          [placeholder]="'authentication.register.form.email.placeholder' | translate: lang"
          autocomplete='username'
        />
      </mat-form-field>
      <mat-error *ngIf='emailControl.errors?.email'>
        {{ 'authentication.register.form.email.errors.email' | translate: lang }}
      </mat-error>
      <mat-error *ngIf='emailControl.errors?.alreadyExists'>
        {{ 'authentication.register.form.email.errors.already-exists' | translate: lang }}
      </mat-error>
    </div>

    <div class='mb-3'>
      <broker-label
        [for]="'password'"
        [label]="'authentication.register.form.password.label' | translate:lang">
      </broker-label>
      <mat-form-field [appearance]="'standard'">
        <input
          type='password'
          matInput
          id='password'
          required
          maxlength='255'
          [formControl]='passwordControl'
          (blur)='trimValue(passwordControl)'
          [placeholder]="'authentication.register.form.password.placeholder' | translate: lang"
          autocomplete='new-password'
        />
      </mat-form-field>
      <mat-error *ngIf='passwordControl.errors?.complexity'>
        {{ 'authentication.register.form.password.errors.complexity' | translate: lang }}
      </mat-error>
    </div>

    <div class='mb-3'>
      <broker-label
        [for]="'confirmPassword'"
        [label]="'authentication.register.form.confirm-password.label' | translate:lang">
      </broker-label>
      <mat-form-field [appearance]="'standard'">
        <input
          type='password'
          matInput
          id='confirmPassword'
          required
          maxlength='255'
          [formControl]='confirmPasswordControl'
          (blur)='trimValue(confirmPasswordControl)'
          [placeholder]="'authentication.register.form.confirm-password.placeholder' | translate: lang"
          autocomplete='new-password'
        />
      </mat-form-field>
      <mat-error *ngIf='confirmPasswordControl.errors?.match'>
        {{ 'authentication.register.form.confirm-password.errors.match' | translate: lang }}
      </mat-error>
    </div>

    <div class='mb-3' id='termsAndConditions'>
      <mat-checkbox [formControl]='acceptedTermsAndConditionsControl' class='register'>
        <span [innerHTML]="'authentication.register.form.tc-and-pp' | translate: lang"></span>
      </mat-checkbox>
    </div>

    <broker-button [id]="'register'" [height]="'large'" [width]="'full-width'"
                   [disabled]='!registerForm.valid || !acceptedTermsAndConditionsControl.value || disabled'>
      {{ 'authentication.register.submit.create-account' | translate: lang }}</broker-button>
  </form>
</div>
