/**
 * The form control names for the Applicant form group.
 */
export enum ApplicantControlNamesEnum {
  TITLE = 'title',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  SURNAME = 'surname',
  USED_ANOTHER_NAME = 'applicantUsedAnotherName',

  PREVIOUS_NAME_ARRAY = 'previousName',
  PREVIOUS_TITLE = 'title',
  PREVIOUS_FIRST_NAME = 'firstName',
  PREVIOUS_MIDDLE_NAME = 'middleName',
  PREVIOUS_SURNAME = 'surname',

  RETIREMENT_AGE = 'estimatedRetirementAge',
  DATE_OF_BIRTH = 'dateOfBirth',
  COUNTRY_OF_CITIZENSHIP = 'nationality',
  MARITAL_STATUS = 'maritalStatus',

  ADDRESS_FORM_GROUP = 'address',
  LIVED_AT_ADDRESS_3_YEARS = 'livedInCurrentAddressFor3Years',
  APPLICANT_2_LIVES_WITH_APPLICANT_1 = 'applicant2LivesWithApplicant1',
  APPLICANT_2_LIVES_WITH_APPLICANT_1_FOR_3_YEARS = 'applicant2LivesWithApplicant1For3Years',
  CURRENT_ADDRESS_MOVED_IN_DATE = 'currentAddressMovedInDate',
  PREVIOUS_ADDRESS_ARRAY = 'previousAddresses',
  DATE_APPLICANT_MOVED_IN = 'from',
  DATE_APPLICANT_MOVED_OUT = 'to',

  // Employment
  EMPLOYMENT_STATUS = 'employmentStatus',
  EMPLOYMENT_FORM_GROUP = 'employment',
  EMPLOYMENT_TYPE = 'employmentType',
  CONTRACT_START_DATE = 'contractStartDate',
  IS_IN_PROBATION_PERIOD = 'isInProbationPeriod',
  FISCAL_YEAR_REPORTED = 'fiscalYear',
  OWNERSHIP_SHARE = 'ownershipShare',
  TIME_IN_SELF_EMPLOYMENT = 'timeInSelfEmployment',
  CONTRACT_END_DATE = 'contractEndDate',
  IS_FIRST_TIME_CONTRACTOR = 'isFirstTimeContractor',

  // Income
  INCOME_SOURCE_FORM_GROUP = 'incomeSourceFormGroup',
  ANY_FUTURE_DECREASE = 'expectsFutureIncomeDecrease',
  REASON_FOR_FUTURE_DECREASE = 'futureIncomeDecreaseReason'
}
