<div class='consumer-flow'>
  <div
    class='warning mt-4'
    [innerHTML]="'authentication.register.errors.generic' | translate: lang"
    *ngIf='fcaForm.errors?.generic'
  ></div>

  <form
    [formGroup]='fcaForm'
    (ngSubmit)='validateFcaNumber()'
    novalidate
    [ngClass]="fcaForm.errors?.generic ? 'mt-4' : 'mt-5'"
  >
    <div class='mb-3'>
      <broker-label
        [for]="'fcaNumber'"
        [label]="'authentication.register.form.fca-number.label' | translate:lang">
      </broker-label>
      <mat-form-field [appearance]="'standard'">
        <input
          type='text'
          matInput
          id='fcaNumber'
          required
          maxlength='10'
          [formControl]='fcaNumberControl'
          [placeholder]="'authentication.register.form.fca-number.placeholder' | translate: lang"
          (blur)='trimValue(fcaNumberControl)'
        />
      </mat-form-field>
    </div>

    <broker-button [id]="'register'" [height]="'large'" [width]="'full-width'"
                   [disabled]='!fcaForm.valid || loading' [loading]='loading'>
      {{ 'authentication.register.submit.fcs-check' | translate: lang }}</broker-button>
  </form>
</div>
