import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FeatureFlagsService } from '../../feature-flags/feature-flags.service';
import { FeatureFlagsEnum } from '../../../enums/feature-flags/feature-flags.enum';
import { ConsumerResponseService } from '../../../consumer/application/consumer-response.service';
import {
  AbstractFetchApplicationService,
  FetchNonPaginatedApplicationsService,
  FetchPaginatedApplicationsService
} from './abstract-fetch-application';

@Injectable({
  providedIn: 'root'
})
export class FetchApplicationsFactoryService {
  constructor(
    private readonly featureFlags: FeatureFlagsService,
    private readonly httpClient: HttpClient,
    private readonly response: ConsumerResponseService
  ) {}

  getService(): AbstractFetchApplicationService {
    let service: AbstractFetchApplicationService;
    if (this.featureFlags.isFeatureFlagEnabled(FeatureFlagsEnum.SERVER_SIDE_PAGINATION)) {
      service = new FetchPaginatedApplicationsService(this.httpClient, this.response);
    } else {
      service = new FetchNonPaginatedApplicationsService(this.httpClient, this.response);
    }

    return service;
  }
}
