<div class='login-wrapper d-flex flex-row'>
  <div class='d-flex flex-column left-column'>
    <a [routerLink]="['']">
      <img src='assets/images/selina-logo-green.svg' alt='Selina Logo' width='150' />
    </a>
    <div class='authentication'>
      <router-outlet></router-outlet>
    </div>
    <p class='font-small mt-5'>{{ 'authentication.footer-info' | translate: lang }}</p>
    <p class='font-small'>{{ 'authentication.copyright' | translate: lang:{ currentYear: currentYear } }}</p>
  </div>
  <div class='login-image'></div>
</div>
