/**
 * Income source type enum.
 */
// noinspection JSUnusedGlobalSymbols
export enum IncomeSourceTypeEnum {
  ARMED_FORCES_PENSION = 'Armed forces pension',
  BEREAVEMENT_ALLOWANCE = 'Bereavement allowance',
  BONUS = 'Bonus',
  CAR_ALLOWANCE = 'Car allowance',
  CARERS_ALLOWANCE = 'Carers allowance',
  CHILD_TAX_CREDITOR_UNIVERSAL_CREDIT_EQUIVALENT = 'Child tax credit or Universal credit equivalent',
  CITY_ALLOWANCE = 'City allowance',
  COMMISSION = 'Commission',
  DIRECTOR_GROSS_SALARY = 'Director gross salary',
  DISABILITY_LIVING_ALLOWANCE_DLA = 'Disability living allowance - DLA',
  DIVIDENDS = 'Dividends',
  DRAWINGS = 'Drawings',
  EMPLOYMENT_AND_SUPPORT_ALLOWANCE = 'Employment and support allowance',
  GUARDIANS_ALLOWANCE = 'Guardians allowance',
  INDUSTRIAL_INJURIES_DISABLEMENT_BENEFIT = 'Industrial injuries disablement benefit',
  MAINTENANCE_INCOME = 'Maintenance income',
  MOBILITY_ALLOWANCE = 'Mobility allowance',
  NET_PROFIT = 'Net profit',
  OVERTIME = 'Overtime',
  POLICE_AND_NHS_ALLOWANCE = 'Police and NHS allowance',
  PRIVATE_PENSION = 'Private pension',
  RENTAL_INCOME = 'Rental income',
  GROSS_SALARY = 'Gross salary',
  SECOND_JOB_INCOME = 'Second job income',
  SHIFT_ALLOWANCE = 'Shift allowance',
  STATE_PENSION = 'State pension',
  WORKING_TAX_CREDITOR_UNIVERSAL_CREDIT_EQUIVALENT = 'Working tax credit or Universal credit equivalent'
}
