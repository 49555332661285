<div *ngFor='let item of whatsNewItems; let i = index'>
  <div class='whats-new-container' [class.hidden]='i > currentModalIndex'>
    <ng-container [ngTemplateOutlet]='xButton'></ng-container>
    <p class='title mb-0'>{{'dialog.whats-new.title' | translate:lang}}</p>
    <img [src]='item.svg.src' [alt]='item.svg.alt' />
    <div class='info'>
      <p class='title'>{{item.title | translate:lang}}</p>
      <p class='mb-0' [innerHTML]='item.description | translate:lang'></p>
    </div>
    <div class='mt-3 d-flex' [class.justify-content-end]='i === 0' [class.justify-content-between]='i !== 0'>
      <ng-template [ngTemplateOutlet]='previousButton' [ngTemplateOutletContext]='{page: i}'></ng-template>
      <div class='align-items-center d-flex gap-3'>
        <p *ngIf='whatsNewItems.length > 1' class='pages'>
          {{'dialog.whats-new.pages'|translate:lang:{ index: i + 1, total: whatsNewItems.length } }}</p>
        <ng-template [ngTemplateOutlet]='nextButton' [ngTemplateOutletContext]='{page: i}'></ng-template>
      </div>
    </div>
  </div>
</div>
<!-- Previous button -->
<ng-template #previousButton let-page='page'>
  <broker-button *ngIf='page > 0' [id]="'previous'" [style]="'outline-dark'" [icon]="'arrow'" [iconPosition]="'left'"
    [width]="'compact'" (clickEvent)='showPreviousPage(page + 1)'>
    {{'dialog.whats-new.buttons.previous' | translate:lang}}
  </broker-button>
</ng-template>

<!-- Next button -->
<ng-template #nextButton let-page='page'>
  <broker-button *ngIf='(page + 1) < whatsNewItems.length' [id]="'next'" [style]="'primary'" [icon]="'arrow'"
    [iconPosition]="'right'" [width]="'compact'" (clickEvent)='showNextPage(page + 1)'>
    {{'dialog.whats-new.buttons.next' | translate:lang}}
  </broker-button>

  <broker-button *ngIf='(page + 1) >= whatsNewItems.length' [id]="'continue'" [style]="'primary'" [width]="'compact'"
    (clickEvent)='close()'>
    {{'dialog.whats-new.buttons.continue' | translate:lang}}
  </broker-button>
</ng-template>

<!-- X button -->
<ng-template #xButton>
  <div class='position-absolute top-0 end-0 cursor-pointer' (click)='close()'>
    <svg width='53' height='50' viewBox='0 0 53 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill-rule='evenodd' clip-rule='evenodd'
        d='M0.740226 0H46C49.866 0 53 3.13401 53 7V49.8829C51.8737 49.9605 50.7369 50 49.5909 50C22.6043 50 0.727273 28.123 0.727273 1.13636C0.727273 0.756548 0.731606 0.377745 0.740226 0Z'
        fill='white' />
      <path d='M24 14.0001L36.7742 26.7743' stroke='#3A4146' stroke-width='2.08428' stroke-linecap='round'
        stroke-linejoin='round' />
      <path d='M24 26.7742L36.7742 14' stroke='#3A4146' stroke-width='2.08428' stroke-linecap='round'
        stroke-linejoin='round' />
    </svg>
  </div>
</ng-template>