/**
 * The form control names for the Facility Details form group.
 */
export enum FacilityDetailsControlNamesEnum {
  NUMBER_OF_APPLICANTS = 'numberOfApplicants',
  LOAN_AMOUNT_REQUESTED = 'requestedLoanAmount',
  LOAN_TERM_REQUESTED = 'requestedLoanTerm',
  FACILITIES_ARRAY = 'facilities',
  FACILITY_ALLOCATION_PURPOSE = 'allocationPurpose',
  FACILITY_ALLOCATION_AMOUNT = 'allocationAmount',

  IS_THE_ADVISING_BROKER = 'isUserTheAdvisingBroker',
  INTERMEDIARY_DETAILS = 'intermediary',
  FIRM_NAME = 'firmName',
  FCA_NUMBER = 'fcaNumber',
  CONTACT_FIRST_NAME = 'contactFirstName',
  CONTACT_LAST_NAME = 'contactLastName'
}
