/**
 * Interface for items to be used in radio buttons.
 */
export interface SelectItemModel {
  /**
   * The item label.
   */
  label: string;

  /**
   * The item value.
   */
  value: any;

  /**
   * If item is disabled.
   */
  disabled: boolean;
}

/**
 * Creates a new Select Item.
 */
export const newSelectItem: (label: string, value: any) => SelectItemModel = (
  label: string,
  value: any
): SelectItemModel => ({
  label,
  value,
  disabled: false
});
