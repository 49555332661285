import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelComponent } from './label.component';
import { SvgModule } from '../svg/svg.module';
import { MaterialModule } from '../../material/material.module';

@NgModule({
  declarations: [LabelComponent],
  exports: [LabelComponent],
  imports: [CommonModule, SvgModule, MaterialModule]
})
export class LabelModule {}
