import { AddressModel } from '../../../services/address/model/address.model';
import { DecisionType } from '../../base-opportunity.model';
import { ApplicantNameModel, NameModel } from './applicant-name.model';
import { StageNameEnum } from '../../dip/enum/stage-name.enum';
import { SubStageNameEnum } from '../../dip/enum/sub-stage-name.enum';
import { PaginationMetadataModel, PaginationSummaryModel } from '../../model/dashboard.model';

export enum ApplicationsQueryParamsNames {
  ALL_APPLICATIONS = 'allApplications',
  BROKER_ACCOUNT_ID = 'brokerAccountId',
  INCLUDE_LIVE = 'includeLive',
  INCLUDE_CLOSED = 'includeClosed',
  INCLUDE_FUNDED = 'includeFunded',
  STAGE = 'stage',
  PAGE_NUMBER = 'pageNumber',
  PAGE_SIZE = 'pageSize',
  SORT_BY = 'sortBy',
  SEARCH_TERM = 'searchTerm'
}

export interface ApplicationModel {
  /**
   * The consumer opportunity ID.
   */
  id: string;

  /**
   * The Sales Force reference.
   */
  reference: string;

  /**
   * The consumer opportunity state.
   */
  stage: StageNameEnum;

  /**
   * The consumer opportunity sub stage.
   */
  subStage?: SubStageNameEnum;

  /**
   * The consumer opportunity created date.
   */
  created: Date;

  /**
   * The consumer requested amount.
   */
  requestedAmount: number;

  /**
   * The stage decision.
   */
  decision?: DecisionType;

  /**
   * The consumer opportunity created date.
   */
  lastModified?: Date;

  /**
   * The consumer opportunity applicant 1 data.
   */
  applicant1: ApplicantNameModel;

  /**
   * The consumer opportunity applicant 2 data.
   */
  applicant2?: ApplicantNameModel;

  /**
   * The security property models.
   */
  propertyAddress: AddressModel;

  /**
   * The opportunity submitting broker.
   */
  brokerSubmitter: NameModel;

  /**
   * The selina contact.
   */
  selinaContact: NameModel;

  /**
   * The credit file expiration date.
   */
  creditCheckExpiry?: Date;

  dashboardStage: string;
}

export interface NonPaginatedApplicationsResponseModel {
  summary: PaginationSummaryModel;
  liveApplications?: ApplicationModel[];
  closedApplications?: ApplicationModel[];
  fundedApplications?: ApplicationModel[];
}

export interface PaginatedApplicationsResponseModel {
  metadata?: PaginationMetadataModel;
  records: ApplicationModel[];
  summary: PaginationSummaryModel;
}
