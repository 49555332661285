import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { NgModule } from '@angular/core';

import { DateFnsDateAdapter, MAT_DATE_FNS_ADAPTER_OPTIONS, MAT_DATE_LOCALE } from './date-fns.date-adapter';
import { MAT_DATE_FNS_DATE_FORMATS } from './date-fns.date-formats';

/**
 * Material Date-fns date adapter module.
 */
@NgModule({
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FNS_ADAPTER_OPTIONS]
    }
  ]
})
export class DateFnsDateModule {}

/**
 * Material Date-fns date adapter module with pre-defined format.
 */
@NgModule({
  imports: [DateFnsDateModule],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS }]
})
export class MatDateFnsDateModule {}
