import { Inject, Injectable } from '@angular/core';

import {
  L10N_LOCALE,
  L10nDateTimeFormatOptions,
  L10nLocale,
  L10nNumberFormatOptions,
  L10nValidation,
  parseDigits
} from 'angular-l10n';

/**
 * Locale validation service.
 */
@Injectable()
export class LocaleValidation implements L10nValidation {
  constructor(@Inject(L10N_LOCALE) private readonly locale: L10nLocale) {}

  /**
   * Parses a number from a string.
   * @param value the number value to parse.
   * @param options the format options.
   * @param language the required language.
   * @return the parsed number, or null if it's not possible to parse the number.
   */
  parseNumber(
    value: string,
    options?: L10nNumberFormatOptions,
    language: string = this.locale.language
  ): number | null {
    if (value === '' || value == null) {
      return null;
    }

    let format: any = {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 0,
      maximumFractionDigits: 10
    };
    if (options?.digits) {
      format = { ...format, ...parseDigits(options.digits) };
    }

    let decimalSeparator: string;
    if (language === 'pt-PT') {
      decimalSeparator = ',';
    } else {
      decimalSeparator = '.';
    }

    const pattern = `^-?[\\d]{${format.minimumIntegerDigits},}(\\${decimalSeparator}[\\d]{${format.minimumFractionDigits},${format.maximumFractionDigits}})?$`;
    const regex = new RegExp(pattern);
    return regex.test(value) ? parseFloat(value.replace(decimalSeparator, '.')) : null;
  }

  /**
   * Parses a date from a string.
   * @param _value the number value to parse.
   * @param _options the format options.
   * @param _language the required language.
   * @return the parsed number, or null if it's not possible to parse the number.
   */
  parseDate(
    _value: string,
    _options?: L10nDateTimeFormatOptions,
    _language: string = this.locale.language
  ): Date | null {
    return null;
  }
}
