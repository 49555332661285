/**
 * The base route enums.
 */
export enum BaseRouteEnum {
  /**
   * The root route.
   */
  ROOT = '/',

  /**
   * The empty route.
   */
  EMPTY = '',

  /**
   * The wildcard route.
   */
  WILDCARD = '**',

  /**
   * The opportunity id route parameter.
   */
  OPPORTUNITY_ID = ':opportunityId'
}
