import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationComponent } from './authentication/authentication.component';
import { CallUsComponent } from './authentication/call-us/call-us.component';
import { ConfirmRegistrationComponent } from './authentication/confirm-registration/confirm-registration.component';
import { LoginComponent } from './authentication/login/login.component';
import { RecoverComponent } from './authentication/recover/recover.component';
import { RegisterComponent } from './authentication/register/register.component';
import { VerifyCodeComponent } from './authentication/verify-code/verify-code.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CanUserSubmitConsumerFlowGuard } from './shared/guards/consumer-flow/can-user-submit-consumer-flow-guard';
import { BaseRouteEnum } from './shared/enums/route/base-route.enum';
import { DipRouteEnum } from './shared/enums/route/dip-route.enum';
import { FullAppRouteEnum } from './shared/enums/route/full-app-route.enum';
import { IsUserActiveGuard } from './shared/guards/is-user-active-guard.service';

const routes: Routes = [
  {
    path: BaseRouteEnum.EMPTY,
    canActivate: [AuthGuard],
    component: DashboardComponent
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'confirm-registration',
        component: ConfirmRegistrationComponent
      },
      {
        path: 'call-us',
        component: CallUsComponent
      },
      {
        path: 'verify',
        component: VerifyCodeComponent
      }
    ]
  },
  {
    path: 'recover',
    component: RecoverComponent
  },
  {
    path: DipRouteEnum.DIP,
    canActivate: [AuthGuard, IsUserActiveGuard, CanUserSubmitConsumerFlowGuard],
    loadChildren: () => import('src/app/consumer/dip/consumer-dip.module').then(m => m.ConsumerDipModule)
  },
  {
    path: FullAppRouteEnum.FULL_APP,
    canActivate: [AuthGuard, IsUserActiveGuard],
    loadChildren: () => import('src/app/consumer/full-app/consumer-full-app.module').then(m => m.ConsumerFullAppModule)
  },
  {
    path: BaseRouteEnum.WILDCARD,
    redirectTo: BaseRouteEnum.EMPTY,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
