/**
 * Repayment vehicle enum.
 */
export enum RepaymentVehicleEnum {
  ENDOWMENT_ISA = 'Endowment/ISA',
  INVESTMENTS = 'Investments',
  PENSION = 'Pension',
  SALE_OF_BUY_TO_LET_PROPERTY = 'Sale of buy-to-let property',
  SALE_OF_SECURITY_PROPERTY = 'Sale of security property',
  SAVINGS = 'Savings'
}
