import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { ConsumerStepperContainerComponent } from './container/consumer-stepper-container.component';
import { MaterialModule } from '../../material/material.module';
import { SvgModule } from '../svg/svg.module';
import { ConsumerModule } from '../consumer/consumer.module';
import { NeedHelpModule } from '../need-help/need-help.module';
import { LeftPanelConsumerModule } from '../left-panel/consumer/left-panel-consumer.module';
import { ConsumerStepperModule } from './consumer-stepper.module';
import { StepModule } from './step/step.module';

@NgModule({
  declarations: [ConsumerStepperContainerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SvgModule,
    L10nTranslationModule,
    ConsumerModule,
    NeedHelpModule,
    LeftPanelConsumerModule,
    ConsumerStepperModule,
    StepModule
  ],
  exports: [ConsumerStepperContainerComponent]
})
export class StepperModule {}
