import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { StateIndicatorComponent } from './state-indicator.component';
import { SvgModule } from '../svg/svg.module';

@NgModule({
  declarations: [StateIndicatorComponent],
  exports: [StateIndicatorComponent],
  imports: [CommonModule, L10nTranslationModule, SvgModule]
})
export class StateIndicatorModule {}
