import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { FeatureFlagsEnum } from '../../enums/feature-flags/feature-flags.enum';
import { FeatureFlagsService } from '../feature-flags/feature-flags.service';
import { DialogService } from '../dialog/dialog.service';
import { StorageService } from '../storage/storage.service';
import { StorageKeyEnum } from '../storage/enum/storage-key.enum';
import { WhatsNewAnimationsService } from './whats-new-animations.service';

export interface WhatsNewItem {
  title: string;
  description: string;
  svg: {
    src: string;
    alt: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService {
  showWhatsNewBoxOnDashboard$: Subject<boolean> = new Subject<boolean>();

  get whatsNewItems(): WhatsNewItem[] {
    return this._whatsNewItems;
  }

  private _whatsNewItems: WhatsNewItem[] = [];

  constructor(
    private readonly featureFlags: FeatureFlagsService,
    private readonly dialog: DialogService,
    private readonly storage: StorageService,
    private readonly whatsNewAnimations: WhatsNewAnimationsService
  ) {
    this.buildWhatsNewItems();
  }

  showWhatsNewPopup(): void {
    this.dialog.openWhatsNewDialog().finally(() => {
      this.storage.saveValue(StorageKeyEnum.WAS_WHATS_NEW_POPUP_VIEWED, 'true');
      this.showWhatsNewBoxOnDashboard$.next(true);
    });
  }

  closeWhatsNewBoxOnDashboard() {
    this.whatsNewAnimations.animateOutWhatsNewBox().then(() => this.showWhatsNewBoxOnDashboard$.next(false));
  }

  showWhatsNewInformation() {
    const wasPopupViewed = !!this.storage.getValue(StorageKeyEnum.WAS_WHATS_NEW_POPUP_VIEWED);
    const isShowWhatsNewPopupFeatureFlagEnabled = this.featureFlags.isFeatureFlagEnabled(
      FeatureFlagsEnum.SHOW_WHATS_NEW_POPUP
    );

    if (isShowWhatsNewPopupFeatureFlagEnabled) {
      if (wasPopupViewed) {
        this.showWhatsNewBoxOnDashboard$.next(true);
      } else {
        this.showWhatsNewPopup();
      }
    }
  }

  private buildWhatsNewItems() {
    this.whatsNewItems.push(this.buildWhatsNewItem('up-to-ltv-products-launched'));
    this.whatsNewItems.push(this.buildWhatsNewItem('auto-consolidation-credit-items'));
  }

  private buildWhatsNewItem(feature: string): WhatsNewItem {
    return {
      title: `dialog.whats-new.${feature}.title`,
      description: `dialog.whats-new.${feature}.description`,
      svg: {
        src: `assets/images/whats-new/${feature}.svg`,
        alt: `dialog.whats-new.${feature}.title`
      }
    };
  }
}
