import { L10nConfig, L10nLoader } from 'angular-l10n';

import { environment } from '../../../environments/environment';
import { EN_GB_LOCALE } from './supported-languages';

const createConfiguration: (fallback: boolean, cache: boolean) => L10nConfig = (
  fallback: boolean,
  cache: boolean
): L10nConfig => ({
  format: 'language-region',
  providers: [
    {
      name: 'dashboard',
      asset: './assets/l10n/dashboard',
      options: {
        version: environment.applicationVersion
      }
    },
    {
      name: 'consumer',
      asset: './assets/l10n/consumer',
      options: {
        version: environment.applicationVersion
      }
    },
    {
      name: 'authentication',
      asset: './assets/l10n/authentication',
      options: {
        version: environment.applicationVersion
      }
    },
    {
      name: 'countries',
      asset: './assets/l10n/countries',
      options: {
        version: environment.applicationVersion
      }
    },
    {
      name: 'dialogs',
      asset: './assets/l10n/dialogs',
      options: {
        version: environment.applicationVersion
      }
    },
    {
      name: 'components',
      asset: './assets/l10n/components',
      options: {
        version: environment.applicationVersion
      }
    }
  ],
  fallback: fallback,
  cache: cache,
  keySeparator: '.',
  defaultLocale: EN_GB_LOCALE(),
  schema: [
    {
      locale: EN_GB_LOCALE(),
      dir: 'ltr',
      text: 'Great Britain'
    }
  ],
  defaultRouting: true
});

/**
 * Localization library object.
 */
export const l10nConfig: L10nConfig = createConfiguration(false, true);

/**
 * Test localization library object.
 */
export const l10nTestConfig: L10nConfig = createConfiguration(false, false);

/**
 * Initialization function for the localization library.
 * @param l10nLoader the translations loader service loader.
 * @return a function that will call the loader.
 */
export const initL10n: (l10nLoader: L10nLoader) => () => Promise<void> = (
  l10nLoader: L10nLoader
): (() => Promise<void>) => (): Promise<void> => l10nLoader.init();
