/**
 * The Storage key enums.
 */
export enum StorageKeyEnum {
  MIXPANEL_EMAIL = 'mixpanel_email',
  DASHBOARD_SELECTED_COMPANY = 'dashboard_select_company',
  CAN_SUBMIT_CONSUMER_FLOW = 'can_submit_consumer_flow',
  CAN_REQUEST_ADMIN_ACCESS = 'can_request_admin_access',
  CAN_REQUEST_ANY_ACCOUNT_ACCESS = 'can_request_any_account_access',
  IS_ADMIN_VIEW_ENABLED = 'is_admin_view_enabled',
  IS_ANY_ACCOUNT_VIEW_ENABLED = 'is_any_account_view_enabled',
  BROKER_DATA = 'broker_data',
  REGISTERED_USER_INFO = 'registered_user_info',
  SESSION_FCA_NUMBER = 'session_fca_number',
  SESSION_RECOVER_EMAIL = 'session_recover_email',
  DIP_DATA = 'DipData',
  DIP_OFFERS_DATA = 'DipOffersData',
  DIP_CREDIT_COMMITMENTS_DATA = 'DipCreditCommitmentsData',
  FULL_APP_DATA = 'FullAppData',
  USER_OPPORTUNITIES = 'UserOpportunities',
  IS_USER_ACTIVE = 'is_user_active',
  WAS_WHATS_NEW_POPUP_VIEWED = 'was_whats_new_popup_viewed'
}
