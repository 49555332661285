import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { L10nTranslationModule } from 'angular-l10n';
import { NgxMaskModule } from 'ngx-mask';

import { CcCalculatorComponent } from './cc-calculator/cc-calculator.component';
import { ConsumerModule } from './consumer/consumer.module';
import { InputComponent } from './input/input.component';
import { MaskedDatePickerComponent } from './masked-date-picker/masked-date-picker.component';
import { MonthYearPickerComponent } from './month-year-picker/month-year-picker.component';
import { RadioButtonGroupComponent } from './radio-button-group/radio-button-group.component';
import { SvgModule } from './svg/svg.module';
import { MaterialModule } from '../material/material.module';
import { StepperModule } from './stepper/stepper.module';
import { ReferInfoBoxModule } from './refer-info-box/refer-info-box.module';
import { LabelModule } from './label/label.module';
import { ButtonModule } from './button/button.module';

@NgModule({
  declarations: [
    CcCalculatorComponent,
    InputComponent,
    MaskedDatePickerComponent,
    MonthYearPickerComponent,
    RadioButtonGroupComponent
  ],
  imports: [
    CommonModule,
    L10nTranslationModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MaterialModule,
    ConsumerModule,
    SvgModule,
    StepperModule,
    ReferInfoBoxModule,
    LabelModule,
    ButtonModule
  ],
  exports: [
    CcCalculatorComponent,
    InputComponent,
    MaskedDatePickerComponent,
    MonthYearPickerComponent,
    RadioButtonGroupComponent,

    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    MaterialModule,
    ConsumerModule,
    SvgModule,
    StepperModule
  ]
})
export class ComponentsModule {}
