import { ValidatorFn, Validators } from '@angular/forms';

import { emailValidator, mobilePhoneValidator, yearDifference } from './input-validator';
import { ValidationModel } from '../validations/validation.model';

export function getValidations(validationData: ValidationModel, overrideRequired?: boolean): ValidatorFn[] {
  const validations: ValidatorFn[] = [];

  if (overrideRequired ?? validationData.required) {
    validations.push(Validators.required);
  }

  if (validationData.requiredTrue) {
    validations.push(Validators.requiredTrue);
  }

  if (validationData.min !== undefined) {
    validations.push(Validators.min(validationData.min));
  }

  if (validationData.max !== undefined) {
    validations.push(Validators.max(validationData.max));
  }

  if (validationData.minLength !== undefined) {
    validations.push(Validators.minLength(validationData.minLength));
  }

  if (validationData.maxLength !== undefined) {
    validations.push(Validators.maxLength(validationData.maxLength));
  }

  if (validationData.pattern !== undefined) {
    validations.push(Validators.pattern(validationData.pattern));
  }

  if (validationData.yearDifference !== undefined) {
    validations.push(yearDifference(validationData.yearDifference));
  }

  if (validationData.isEmail) {
    validations.push(emailValidator());
  }

  if (validationData.isPhoneNumber) {
    validations.push(mobilePhoneValidator());
  }

  return validations;
}
