/**
 * Defines the supported log levels.
 */
export enum LoggerLevel {
  /**
   * No output will be sent to the console.
   */
  OFF = 0,
  /**
   * Only error messages will be sent to the console.
   */
  ERROR = 1,
  /**
   * Only warning or higher messages will be sent to the console.
   */
  WARN = 2,
  /**
   * Only info or higher messages will be sent to the console.
   */
  INFO = 3,
  /**
   * Only debug or higher messages will be sent to the console.
   */
  DEBUG = 4,
  /**
   * Only trace or higher messages will be sent to the console.
   */
  TRACE = 5,
  /**
   * All messages will be sent to the console.
   */
  LOG = 6
}
