import { DocumentModel, IncomeDocumentModel, AllocationPurposeDocumentModel } from './document.model';

export interface ApplicantDocumentGroup {
  /**
   * All documents on the set are required.
   */
  all: IncomeDocumentModel[];

  /**
   * In each group one of the documents must be chosen.
   */
  oneOf: IncomeDocumentModel[][];
}

export interface LoanInformationDocumentGroup {
  /**
   * All documents on the set are required.
   */
  all: AllocationPurposeDocumentModel[];

  /**
   * In each group one of the documents must be chosen.
   */
  oneOf: AllocationPurposeDocumentModel[][];
}

export interface OtherDocumentsModel {
  sectionMessages: string[];
  documents: DocumentModel[];
}

/**
 * The application required documents.
 */
export interface FullApplicationDocumentsModel {
  applicant1Income?: ApplicantDocumentGroup;
  applicant2Income?: ApplicantDocumentGroup;
  valuation?: DocumentModel;
  otherV2: OtherDocumentsModel;
  loanInformation?: LoanInformationDocumentGroup;
  checklist?: DocumentModel;
}

export const emptyApplicantDocumentGroup: () => ApplicantDocumentGroup = (): ApplicantDocumentGroup => ({
  all: [],
  oneOf: []
});

export const emptyLoanInformationDocumentGroup: () => LoanInformationDocumentGroup = (): LoanInformationDocumentGroup => ({
  all: [],
  oneOf: []
});

export const emptyOtherDocumentsModel: () => OtherDocumentsModel = (): OtherDocumentsModel => ({
  sectionMessages: [],
  documents: []
});
