<ng-container *ngIf='!insideDialog; else dialogNavigation'>
  <div class='fixed-bottom details-footer px-3 px-md-4'>
    <div class='details-footer-container'>
      <div>
        <ng-container [ngTemplateOutlet]='backButton'></ng-container>
        <ng-container *ngIf='backButtonHasError' [ngTemplateOutlet]='buttonError'></ng-container>
      </div>

      <div class='text-end'>
        <ng-container [ngTemplateOutlet]='nextButton'></ng-container>
        <ng-container *ngIf='nextButtonHasError' [ngTemplateOutlet]='buttonError'></ng-container>
      </div>
    </div>
    <div
      #additionalContent
      [hidden]='!additionalContent.hasChildNodes()'
      class='pb-md-5 pb-4 navigation-bar-wrapper'
      [ngClass]='{ large: largeBar, relaxed: relaxedBar }'
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>

<ng-template #dialogNavigation>
  <div class='d-flex justify-content-between pt-4'>
    <ng-container [ngTemplateOutlet]='backButton'></ng-container>
    <ng-container [ngTemplateOutlet]='nextButton'></ng-container>
  </div>
  <ng-container *ngIf='backButtonHasError || nextButtonHasError' [ngTemplateOutlet]='buttonError'></ng-container>
</ng-template>

<ng-template #nextButton>
  <broker-button [id]="'next'" [height]="'large'" [icon]="!hideArrow ? 'arrow' : null" [iconPosition]="'right'"
                 [disabled]='nextButtonDisabled' [loading]='nextButtonLoading' (clickEvent)='next()'>
    <span *ngIf='nextButtonLoading'>{{ 'consumer.navigation-bar.button.loading' | translate: lang }}</span>
    <ng-container *ngIf='!nextButtonLoading'>
    <span *ngIf='!lastStep && !nextButtonText'>
      {{'consumer.navigation-bar.next.is-not-last-step' | translate:lang}}</span>
      <span *ngIf='lastStep'>{{ 'consumer.navigation-bar.next.is-last-step' | translate:lang }}</span>
      <span *ngIf='nextButtonText'>{{ nextButtonText }}</span>
    </ng-container>
  </broker-button>
</ng-template>

<ng-template #backButton>
  <broker-button [id]="'back'" [style]="'outline-dark'" [height]="'large'" [icon]="!hideArrow ? 'arrow' : null"
                 [iconPosition]="'left'" [disabled]='backButtonDisabled' [loading]='backButtonLoading'
                 (clickEvent)='back()'>
    <span *ngIf='backButtonLoading'>{{ 'consumer.navigation-bar.button.loading' | translate: lang }}</span>
    <ng-container *ngIf='!backButtonLoading'>
      <span *ngIf='!backButtonText'>{{ 'consumer.navigation-bar.back' | translate: lang }}</span>
      <span *ngIf='backButtonText'>{{ backButtonText }}</span>
    </ng-container>
  </broker-button>
</ng-template>

<ng-template #buttonError>
  <div class='mt-2 text-end'>
    <mat-error>
      <div>{{ 'consumer.navigation-bar.button.error.line-1' | translate: lang }}</div>
      <div>{{ 'consumer.navigation-bar.button.error.line-2' | translate: lang }}</div>
    </mat-error>
  </div>
</ng-template>
