<table class='table table-borderless mt-4 submission-details-container' *ngIf='data?.modalData'
       aria-label='Opportunity submission details'>
  <tr>
    <td>{{ data.modalData.webCopy.clientLabel }}</td>
    <td class='text-end'>{{ data.modalData.clientName }}</td>
  </tr>
  <tr>
    <td>{{ data.modalData.webCopy.loanAmountLabel }}</td>
    <td class='text-end'>{{ data.modalData.loanAmountRequested }}</td>
  </tr>
  <tr>
    <td>{{ data.modalData.webCopy.termDurationLabel }}</td>
    <td class='text-end'>{{ data.modalData.termDurationRequested }}</td>
  </tr>
  <tr>
    <td>{{ data.modalData.webCopy.securityPropertyLabel }}</td>
    <td class='text-end'>{{ data.modalData.securityPropertyDetails }}</td>
  </tr>
</table>
