/**
 * The Sign-Up user attributes enum.
 */
export enum AuthSignUpAttributesEnum {
  GIVEN_NAME = 'given_name',
  FAMILY_NAME = 'family_name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  FCA_NUMBER = 'custom:fca_number',
  AGREED_TO_TERMS_AND_CONDITIONS = 'custom:agreed_to_tc'
}
