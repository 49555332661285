/**
 * The form control names for the Property Characteristics form group.
 */
export enum PropertyCharacteristicsFormControlNamesEnum {
  PROPERTY_TYPE = 'propertyType',
  CONSTRUCTION_YEAR = 'constructionYear',
  TENURE = 'tenure',

  TENURE_PROPERTY_CHARACTERISTICS = 'tenurePropertyCharacteristics',
  YEARS_REMAINING_ON_LEASE = 'yearsRemainingOnLease',

  NEW_BUILD_PROPERTY_CHARACTERISTICS = 'newBuildPropertyCharacteristics',
  BUILDING_WARRANTY_CERTIFICATE = 'buildingWarrantyCertificate',

  IS_PROPERTY_AN_EX_LOCAL_AUTHORITY = 'isPropertyAnExLocalAuthority',

  FLAT_TYPE_PROPERTY_CHARACTERISTICS = 'flatTypePropertyCharacteristics',
  NUMBER_OF_FLOORS_IN_THE_BUILDING = 'numberOfFloorsInTheBuilding',
  FLAT_FLOOR_NUMBER = 'flatFloorNumber',
  IS_PROPERTY_ABOVE_COMMERCIAL_PROPERTY = 'isPropertyAboveCommercialProperty',
  COMMERCIAL_PREMISES = 'commercialPremises',
  TYPE = 'type',

  VALUATION = 'propertyValuation',
  HOMETRACK_VALUATION = 'hometrackValuation',
  HOMETRACK_CONFIDENCE_LEVEL = 'hometrackConfidenceLevel',
  RICS_VALUATION = 'ricsValuation',

  IS_LISTED_AS_GRADE_I_OR_II_OR_A = 'isListedAsGradeIOrIiOrA'
}
