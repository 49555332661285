import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

import {
  addDays,
  addMonths,
  addYears,
  format,
  formatISO,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  parse,
  setDay,
  setMonth,
  toDate
} from 'date-fns';
import { enGB } from 'date-fns/locale';

import { dateFnsAdapterDefaultOptions, MatDateFnsAdapterOptions } from './mat-date-fns-adapter.options';

/**
 * Date fns options.
 */
export const MAT_DATE_FNS_ADAPTER_OPTIONS: InjectionToken<MatDateFnsAdapterOptions> = new InjectionToken<
  MatDateFnsAdapterOptions
>('MAT_DATE_FNS_ADAPTER_OPTIONS', {
  providedIn: 'root',
  factory: (): MatDateFnsAdapterOptions => dateFnsAdapterDefaultOptions
});

/**
 * Default locale.
 */
export const MAT_DATE_LOCALE: InjectionToken<Locale> = new InjectionToken<Locale>('MAT_DATE_LOCALE', {
  providedIn: 'root',
  factory: (): Locale => enGB
});

/**
 * Date-fns date adapter for Angular Material.
 */
@Injectable()
export class DateFnsDateAdapter extends DateAdapter<Date> {
  constructor(
    @Inject(MAT_DATE_LOCALE) locale: Locale,
    @Inject(MAT_DATE_FNS_ADAPTER_OPTIONS) private readonly options: MatDateFnsAdapterOptions
  ) {
    super();
    this.setLocale(locale || enGB);
  }

  /**
   * Gets the year component of the given date.
   * @param date The date to extract the year from.
   * @returns The year component.
   */
  getYear(date: Date): number {
    return getYear(date);
  }

  /**
   * Gets the month component of the given date.
   * @param date The date to extract the month from.
   * @returns The month component (0-indexed, 0 = January).
   */
  getMonth(date: Date): number {
    return getMonth(date);
  }

  /**
   * Gets the date of the month component of the given date.
   * @param date The date to extract the date of the month from.
   * @returns The month component (1-indexed, 1 = first of month).
   */
  getDate(date: Date): number {
    return getDate(date);
  }

  /**
   * Gets the day of the week component of the given date.
   * @param date The date to extract the day of the week from.
   * @returns The month component (0-indexed, 0 = Sunday).
   */
  getDayOfWeek(date: Date): number {
    return parseInt(format(date, 'i'), 10);
  }

  /**
   * Gets a list of names for the months.
   * @param style The naming style (e.g. long = 'January', short = 'Jan', narrow = 'J').
   * @returns An ordered list of all month names, starting with January.
   */
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const map: { short: string; narrow: string; long: string } = {
      long: 'LLLL',
      short: 'LLL',
      narrow: 'LLLLL'
    };

    const formatStr: string = map[style];
    const date: Date = new Date();

    return DateFnsDateAdapter.range(0, 11).map((month: number) =>
      format(setMonth(date, month), formatStr, {
        locale: this.locale
      })
    );
  }

  /**
   * Gets a list of names for the dates of the month.
   * @returns An ordered list of all date of the month names, starting with '1'.
   */
  getDateNames(): string[] {
    return DateFnsDateAdapter.range(1, 31).map((day: number) => String(day));
  }

  /**
   * Gets a list of names for the days of the week.
   * @param style The naming style (e.g. long = 'Sunday', short = 'Sun', narrow = 'S').
   * @returns An ordered list of all weekday names, starting with Sunday.
   */
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const map: { short: string; narrow: string; long: string } = {
      long: 'EEEE',
      short: 'E..EEE',
      narrow: 'EEEEE'
    };

    const formatStr: string = map[style];
    const date: Date = new Date();

    return DateFnsDateAdapter.range(0, 6).map((month: number) =>
      format(setDay(date, month), formatStr, {
        locale: this.locale
      })
    );
  }

  /**
   * Gets the name for the year of the given date.
   * @param date The date to get the year name for.
   * @returns The name of the given year (e.g. '2017').
   */
  getYearName(date: Date): string {
    return format(date, 'yyyy', {
      locale: this.locale
    });
  }

  /**
   * Gets the first day of the week.
   * @returns The first day of the week (0-indexed, 0 = Sunday).
   */
  getFirstDayOfWeek(): number {
    return this.locale.options.weekStartsOn;
  }

  /**
   * Gets the number of days in the month of the given date.
   * @param date The date whose month should be checked.
   * @returns The number of days in the month of the given date.
   */
  getNumDaysInMonth(date: Date): number {
    return getDaysInMonth(date);
  }

  /**
   * Clones the given date.
   * @param date The date to clone
   * @returns A new date equal to the given date.
   */
  clone(date: Date): Date {
    return toDate(date);
  }

  /**
   * Creates a date with the given year, month, and date. Does not allow over/under-flow of the
   * month and date.
   * @param year The full year of the date. (e.g. 89 means the year 89, not the year 1989).
   * @param month The month of the date (0-indexed, 0 = January). Must be an integer 0 - 11.
   * @param date The date of month of the date. Must be an integer 1 - length of the given month.
   * @returns The new date, or null if invalid.
   */
  createDate(year: number, month: number, date: number): Date {
    return this.options?.useUtc ? new Date(Date.UTC(year, month, date)) : new Date(year, month, date);
  }

  /**
   * Gets today's date.
   * @returns Today's date.
   */
  today(): Date {
    return new Date();
  }

  /**
   * Parses a date from a user-provided value.
   * @param value The value to parse.
   * @param parseFormat The expected format of the value being parsed
   *     (type is implementation-dependent).
   * @returns The parsed date.
   */
  parse(value: any, parseFormat: string): Date | null {
    return parse(value, parseFormat, new Date(), {
      locale: this.locale
    });
  }

  /**
   * Formats a date as a string according to the given format.
   * @param date The value to format.
   * @param displayFormat The format to use to display the date as a string.
   * @returns The formatted date string.
   */
  format(date: Date, displayFormat: string): string {
    return format(date, displayFormat, {
      locale: this.locale
    });
  }

  /**
   * Adds the given number of years to the date. Years are counted as if flipping 12 pages on the
   * calendar for each year and then finding the closest date in the new month. For example when
   * adding 1 year to Feb 29, 2016, the resulting date will be Feb 28, 2017.
   * @param date The date to add years to.
   * @param years The number of years to add (may be negative).
   * @returns A new date equal to the given one with the specified number of years added.
   */
  addCalendarYears(date: Date, years: number): Date {
    return addYears(date, years);
  }

  /**
   * Adds the given number of months to the date. Months are counted as if flipping a page on the
   * calendar for each month and then finding the closest date in the new month. For example when
   * adding 1 month to Jan 31, 2017, the resulting date will be Feb 28, 2017.
   * @param date The date to add months to.
   * @param months The number of months to add (may be negative).
   * @returns A new date equal to the given one with the specified number of months added.
   */
  addCalendarMonths(date: Date, months: number): Date {
    return addMonths(date, months);
  }

  /**
   * Adds the given number of days to the date. Days are counted as if moving one cell on the
   * calendar for each day.
   * @param date The date to add days to.
   * @param days The number of days to add (may be negative).
   * @returns A new date equal to the given one with the specified number of days added.
   */
  addCalendarDays(date: Date, days: number): Date {
    return addDays(date, days);
  }

  /**
   * Gets the RFC 3339 compatible string (https://tools.ietf.org/html/rfc3339) for the given date.
   * This method is used to generate date strings that are compatible with native HTML attributes
   * such as the `min` or `max` attribute of an `<input>`.
   * @param date The date to get the ISO date string for.
   * @returns The ISO date string date string.
   */
  toIso8601(date: Date): string {
    return formatISO(date);
  }

  /**
   * Checks whether the given object is considered a date instance by this DateAdapter.
   * @param obj The object to check
   * @returns Whether the object is a date instance.
   */
  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }

  /**
   * Checks whether the given date is valid.
   * @param date The date to check.
   * @returns Whether the date is valid.
   */
  isValid(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  /**
   * Gets date instance that is not valid.
   * @returns An invalid date.
   */
  invalid(): Date {
    return new Date(NaN);
  }

  private static range(start: number, end: number): number[] {
    const arr: number[] = [];
    for (let i: number = start; i <= end; i++) {
      arr.push(i);
    }

    return arr;
  }
}
