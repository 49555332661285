import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { merge } from 'rxjs';
import { isNull } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { formDetailAnimation } from '../../shared/consumer/animations/form-detail-animation';
import { BaseOpportunityModel } from '../../shared/consumer/base-opportunity.model';
import { SecuredCreditCommitmentViewModel } from '../../shared/consumer/dip/model/view-model/credit-commitments-details.view-model';
import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { LoggerService } from '../../shared/services/logger/logger.service';
import { MixpanelEventsEnum } from '../../shared/services/mixpanel/enum/mixpanel-events.enum';
import { MixpanelService } from '../../shared/services/mixpanel/mixpanel.service';
import { TableItemModel } from '../../shared/models/table-item.model';
import { NavigatorService } from '../../shared/services/navigator/navigator.service';
import { StateType } from '../../shared/components/state-indicator/state-indicator.component';
import { FeatureFlagsService } from '../../shared/services/feature-flags/feature-flags.service';
import { FeatureFlagsEnum } from '../../shared/enums/feature-flags/feature-flags.enum';
import { PaginationMetadataModel } from '../../shared/consumer/model/dashboard.model';
import { ApplicationsPageSortSearchFilter } from '../../shared/services/dashboard/dashboard.service';

export const DEFAULT_SORT_CONFIG: MatSortable = { id: 'created', start: 'desc', disableClear: true };
export const DEFAULT_PAGINATOR_CONFIG = { page: 1, size: 10, sizeOptions: [5, 10, 20, 50], nextPreviousLabel: null };

/**
 * Application list table component.
 */
@Component({
  selector: 'broker-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
  animations: [formDetailAnimation]
})
export class DashboardTableComponent extends LocalizableComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() fetchApplications = new EventEmitter<ApplicationsPageSortSearchFilter>();

  @Input() set records(data: TableItemModel[]) {
    this.dataSource = new MatTableDataSource(data || []);
    this.dataSource.filterPredicate = (item: TableItemModel, filter: string): boolean => {
      return (
        item.applicant1Name?.toLowerCase().includes(filter.toLowerCase().trim()) ||
        item.applicant2Name?.toLowerCase().includes(filter.toLowerCase().trim()) ||
        item.brokerName?.toLowerCase().includes(filter.toLowerCase().trim()) ||
        item.selinaContact?.toLowerCase().includes(filter.toLowerCase().trim()) ||
        item.reference?.toLowerCase().includes(filter.toLowerCase().trim())
      );
    };
  }

  @Input() set metadata(data: PaginationMetadataModel) {
    this.resultsLength = data?.totalCount || 0;
    if (this.isServerSidePaginationEnabled) {
      this._metadata = data;

      if (isNull(data)) {
        this.resetUI();
      }
    }
  }

  get metadata(): PaginationMetadataModel {
    return this._metadata;
  }

  @Input() isLoadingResults = false;
  @Input() isAnyAccountViewEnabled = false;
  @Input() isCompanySelected = false;

  @Input() set showCreditCheckExpiryColumn(value: boolean) {
    this.setColumns(value);
    this._showCreditCheckExpiryColumn = value;
  }

  get showCreditCheckExpiryColumn(): boolean {
    return this._showCreditCheckExpiryColumn;
  }

  get resultsLength(): number {
    if (this.isServerSidePaginationEnabled) {
      return this._resultsLength;
    }
    return this.searchTerm ? this.dataSource?.filteredData.length : this.dataSource?.data.length;
  }

  set resultsLength(value: number) {
    this._resultsLength = value;
  }

  get showEmptyMessage(): boolean {
    return this.resultsLength <= 0;
  }

  get showSelectCompanyMessage(): boolean {
    return this.isAnyAccountViewEnabled && !this.isCompanySelected;
  }

  get isServerSidePaginationEnabled(): boolean {
    return this.featureFlags.isFeatureFlagEnabled(FeatureFlagsEnum.SERVER_SIDE_PAGINATION);
  }

  get hasReachedMaxSupportedPageNumber(): boolean {
    if (!this.metadata) return false;

    const isLastPage = this.metadata.maxSupportedPageNumber === this.metadata.pageCount;
    const isMaxSupportedPage = this.metadata.maxSupportedPageNumber === this.metadata.page;
    return isMaxSupportedPage && !isLastPage;
  }

  dataSource: MatTableDataSource<TableItemModel>;
  columns: string[];
  searchTerm = '';

  private _resultsLength = 0;
  private _showCreditCheckExpiryColumn = false;
  private _metadata: PaginationMetadataModel | null = null;
  private _pageChangeEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  private _searchTermEvent = new EventEmitter<string>();
  protected readonly DEFAULT_SORT_CONFIG = DEFAULT_SORT_CONFIG;
  protected readonly DEFAULT_PAGINATOR_CONFIG = DEFAULT_PAGINATOR_CONFIG;

  constructor(
    private readonly navigator: NavigatorService,
    private readonly logger: LoggerService,
    private readonly mixpanelService: MixpanelService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly changeDetector: ChangeDetectorRef,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
    this.setColumns(this._showCreditCheckExpiryColumn);
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.resetUI();

      if (!this.isServerSidePaginationEnabled) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

      if (this.isServerSidePaginationEnabled) {
        merge(this.sort.sortChange, this._searchTermEvent).subscribe(() => this.changePaginatorPage(0));

        merge(this.sort.sortChange, this._pageChangeEvent, this._searchTermEvent)
          .pipe(filter(() => !!this.metadata))
          .subscribe(() => {
            const pageSize = this.paginator ? this.paginator.pageSize : DEFAULT_PAGINATOR_CONFIG.size;
            const pageNumber = this.paginator ? this.paginator.pageIndex + 1 : DEFAULT_PAGINATOR_CONFIG.page;
            this.fetchApplications.emit({
              pageSize,
              pageNumber,
              sortBy: `${this.sort.active}.${this.sort.direction}`,
              searchTerm: this.searchTerm
            });
          });
      }
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.dataSource) {
      if (!this.isServerSidePaginationEnabled) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  asElement(value: any): TableItemModel {
    return value as TableItemModel;
  }

  asApplicantNames(value: TableItemModel): string {
    if (value?.applicant1Name && value?.applicant2Name) {
      return `${value.applicant1Name}<br />${value.applicant2Name}`;
    }
    if (value?.applicant1Name) {
      return value.applicant1Name;
    }
    return '-';
  }

  asDecisionElement(value: TableItemModel): StateType {
    if (!value) return null;
    return value.decision?.toLowerCase() as StateType;
  }

  isExpanded(value: TableItemModel): boolean {
    return value?.active;
  }

  toggleRow(value: SecuredCreditCommitmentViewModel): void {
    value.active = !value.active;
  }

  onRowClick(row: TableItemModel): void {
    if (!row?.id) {
      this.logger.error('Opportunity has no ID.');
      return;
    }

    this.mixpanelService.trackEventWithReference(MixpanelEventsEnum.CONTINUE_CONSUMER, row.reference);
    this.navigator.isFromTableClick = true;
    this.navigator
      .redirectToAppropriateRoute(row.id, {
        stage: row.stage,
        subStage: row.subStage,
        decision: row.decision
      } as BaseOpportunityModel)
      .catch((error) =>
        this.logger.error('Error navigation from the dashboard table to the appropriate route: ', error)
      );
  }

  filterList(searchTerm: string): void {
    this.searchTerm = searchTerm;

    if (this.isServerSidePaginationEnabled) {
      this._searchTermEvent.emit(searchTerm);
    }

    if (!this.isServerSidePaginationEnabled) {
      this.dataSource.filter = searchTerm;
    }
  }

  onPageChange(event: PageEvent) {
    if (this.isPageReachedMaxSupported(event.pageIndex)) {
      this.changePaginatorPage(this.metadata.maxSupportedPageNumber - 1);
    } else {
      this._pageChangeEvent.emit(event);
    }
  }

  private setColumns(addCreditCheckExpiry: boolean) {
    this.columns = [
      'reference',
      'name',
      'stage',
      'decision',
      'requestedAmount',
      'created',
      'lastModified',
      'selinaContact',
      'brokerSubmitter',
      'actions'
    ];

    if (addCreditCheckExpiry) {
      this.columns.splice(this.columns.length - 1, 0, 'creditCheckExpiry');
    }
  }

  private isNotDefaultSortConfig(): boolean {
    return this.sort.active !== DEFAULT_SORT_CONFIG.id || this.sort.direction !== DEFAULT_SORT_CONFIG.start;
  }

  private isPageReachedMaxSupported(page: number): boolean {
    return page === this.metadata.maxSupportedPageNumber;
  }

  private changePaginatorPage(value: number) {
    if (this.paginator) {
      this.paginator.pageIndex = value;
    }
  }

  private resetUI() {
    if (this.paginator) {
      this.changePaginatorPage(0);
      this.paginator.pageSize = DEFAULT_PAGINATOR_CONFIG.size;
      this.paginator._intl.nextPageLabel = DEFAULT_PAGINATOR_CONFIG.nextPreviousLabel;
      this.paginator._intl.previousPageLabel = DEFAULT_PAGINATOR_CONFIG.nextPreviousLabel;
    }
    this.searchTerm = '';

    if (this.isNotDefaultSortConfig()) {
      this.sort.sort(DEFAULT_SORT_CONFIG);
    }

    this.changeDetector.detectChanges();
  }
}
