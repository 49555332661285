/**
 * The form control names for the address section.
 */
export enum AddressControlNamesEnum {
  POSTCODE = 'postcode',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  CITY = 'city',
  SUB_BUILDING_NAME = 'subBuildingName',
  BUILDING_NAME = 'buildingName',
  BUILDING_NUMBER = 'buildingNumber',
  UDPRN = 'udprn',
  UPRN = 'uprn',
  PO_BOX = 'poBox',
  COUNTY = 'county',
  COUNTRY = 'country'
}
