import { Component, Inject, Input } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../../shared/localization/localizable.component';
import { CreditCheckExpiryService } from '../../../shared/services/credit-check-expiry/credit-check-expiry.service';
import { isNull } from 'lodash-es';

@Component({
  selector: 'broker-dashboard-credit-check-expiry-display',
  templateUrl: './dashboard-credit-check-expiry-display.component.html'
})
export class DashboardCreditCheckExpiryDisplayComponent extends LocalizableComponent {
  @Input() set expiryDate(value: Date) {
    this.numberOfDaysUntilExpires = this.creditCheckExpiry.getNumberOfDaysUntilExpires(value);
  }

  get isWarning(): boolean {
    return (
      !isNull(this.numberOfDaysUntilExpires) && this.numberOfDaysUntilExpires <= 5 && this.numberOfDaysUntilExpires >= 1
    );
  }

  get isExpired(): boolean {
    return !isNull(this.numberOfDaysUntilExpires) && this.numberOfDaysUntilExpires <= 0;
  }

  get creditCheckExpiryDateKey(): string {
    return this.creditCheckExpiry.getTranslationKeyFor('dashboard', this.numberOfDaysUntilExpires);
  }

  get days(): number {
    return this.numberOfDaysUntilExpires;
  }

  private numberOfDaysUntilExpires: number;

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale, private readonly creditCheckExpiry: CreditCheckExpiryService) {
    super(locale);
  }
}
