import { Component, Inject } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../shared/localization/localizable.component';

@Component({
  selector: 'broker-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent extends LocalizableComponent {
  currentYear: number = new Date().getFullYear();

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }
}
