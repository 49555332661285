<broker-search-field [searchTerm]='searchTerm' (searchChanged)='filterList($event)'></broker-search-field>

<div>
  <table
    mat-table
    [dataSource]='isLoadingResults ? [] : dataSource'
    multiTemplateDataRows
    matSort
    matSortActive='{{DEFAULT_SORT_CONFIG.id}}'
    matSortDirection='{{DEFAULT_SORT_CONFIG.start}}'
    matSortDisableClear='{{DEFAULT_SORT_CONFIG.disableClear}}'
    class='credit-commitments-table'
    aria-label='Applications'
  >
    <!-- Case ID -->
    <ng-container [matColumnDef]="'reference'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='w-10 pb-4'>
        {{ 'table.reference-number' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10 no-wrap'>
        <broker-arrow-svg [cssClasses]="'me-2'" [up]='isExpanded(element)'></broker-arrow-svg>
        {{ asElement(element).reference || '-' }}
      </td>
    </ng-container>

    <!-- Name -->
    <ng-container [matColumnDef]="'name'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          class='w-15 pb-4'>
        {{ 'table.applicant-name' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-15'>
        <span class='no-wrap' [innerHTML]='asApplicantNames(element)'></span>
      </td>
    </ng-container>

    <!-- Stage -->
    <ng-container [matColumnDef]="'stage'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          class='w-10 pb-4'>
        {{ 'table.stage' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        <span>{{asElement(element).dashboardStage}}</span>
      </td>
    </ng-container>

    <!-- Decision -->
    <ng-container [matColumnDef]="'decision'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='w-10 pb-4'>
        {{ 'table.decision' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        <broker-state-indicator *ngIf='asDecisionElement(element) as state' [state]='state'
                                [cssClasses]="'m-auto'"></broker-state-indicator>
        <span *ngIf='!asDecisionElement(element)'>{{'-'}}</span>
      </td>
    </ng-container>

    <!-- Loan Amount -->
    <ng-container [matColumnDef]="'requestedAmount'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='w-10 pb-4'>
        {{ 'table.loan-amount' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        {{ (asElement(element).requestedAmount | l10nNumber: lang:currencyFormat) || '-' }}
      </td>
    </ng-container>

    <!-- Date created -->
    <ng-container [matColumnDef]="'created'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='w-10 pb-4'>
        {{ 'table.create-date' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        {{ (asElement(element).created | l10nDate: lang:dateFormat) || '-' }}
      </td>
    </ng-container>

    <!-- Date last modified -->
    <ng-container [matColumnDef]="'lastModified'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='w-10 pb-4'>
        {{ 'table.last-modified-date' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        {{ (asElement(element).lastModified | l10nDate: lang:dateFormat) || '-' }}
      </td>
    </ng-container>

    <!-- Selina contact -->
    <ng-container [matColumnDef]="'selinaContact'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class='w-10 pb-4'>
        {{ 'table.contact-name' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        {{ asElement(element).selinaContact || '-' }}
      </td>
    </ng-container>

    <!-- Broker name -->
    <ng-container [matColumnDef]="'brokerSubmitter'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          class='w-10 pb-4'>
        {{ 'table.broker-name' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        {{ asElement(element).brokerName || '-' }}
      </td>
    </ng-container>

    <!-- Credit check expiry -->
    <ng-container [matColumnDef]="'creditCheckExpiry'" *ngIf='showCreditCheckExpiryColumn'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]='isServerSidePaginationEnabled'
          class='w-10 pb-4'>
        {{ 'table.credit-check-expiry.label' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-10'>
        <broker-dashboard-credit-check-expiry-display
          [expiryDate]='asElement(element).creditCheckExpiry'></broker-dashboard-credit-check-expiry-display>
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container [matColumnDef]="'actions'">
      <th mat-header-cell *matHeaderCellDef class='w-5 pb-4'>
        {{ 'table.actions' | translate: lang }}
      </th>
      <td mat-cell *matCellDef='let element' class='w-5' (click)='$event.stopPropagation(); onRowClick(element)'>
        <broker-pencil-svg></broker-pencil-svg>
      </td>
    </ng-container>

    <!-- Expanded row details -->
    <ng-container matColumnDef='expandedDetail'>
      <td
        mat-cell
        *matCellDef='let element; let index = dataIndex'
        [attr.colspan]='columns?.length'
        class='detail-col ps-0'
      >
        <div class='element-detail px-3' [@detailExpand]="isExpanded(element) ? 'expanded' : 'collapsed'">
          <broker-application-details [details]='element' *ngIf='asElement(element).active'>
          </broker-application-details>
        </div>
      </td>
    </ng-container>

    <!-- Columns -->
    <tr mat-header-row *matHeaderRowDef='columns'></tr>
    <tr
      mat-row
      *matRowDef='let row; columns: columns'
      [class.expanded-row]='isExpanded(row)'
      (click)='toggleRow(row)'
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class='detail-row'></tr>
  </table>

  <ng-container *ngIf='isLoadingResults'>
    <div class='table-spinner-container mt-4 mb-2'>
      <mat-spinner [diameter]='60'></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf='!isLoadingResults'>
    <ng-container *ngIf='showSelectCompanyMessage'>
      <div class='empty-message p-3 mb-4'>{{ 'table.messages.select-company' | translate:lang }}</div>
    </ng-container>

    <ng-container *ngIf='!showSelectCompanyMessage'>

      <ng-container *ngIf='showEmptyMessage'>
        <p class='mt-4 text-center'>{{ 'table.messages.empty' | translate: lang }}</p>
      </ng-container>

      <ng-container *ngIf='!showEmptyMessage'>
        <mat-paginator
          class='default-select'
          [color]="'primary'"
          [pageSize]='metadata?.pageSize || DEFAULT_PAGINATOR_CONFIG.size'
          [pageIndex]='(metadata?.page || DEFAULT_PAGINATOR_CONFIG.page) - 1'
          [pageSizeOptions]='DEFAULT_PAGINATOR_CONFIG.sizeOptions'
          [length]='resultsLength'
          (page)='onPageChange($event)'>
        </mat-paginator>

        <div *ngIf='hasReachedMaxSupportedPageNumber' class='mat-error me-4 text-end'>
          <span>{{ 'table.messages.pagination-max-page-reached' | translate: lang }}</span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
