import { Component, Input } from '@angular/core';

import { IconPosition } from '../../button.component';

@Component({
  selector: 'broker-button-arrow-svg',
  templateUrl: './arrow-svg.component.svg'
})
export class ArrowSvgComponent {
  @Input() position: IconPosition = 'left';
}
