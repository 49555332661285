import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../../shared/services/auth/auth.service';
import { FcaNumberModel } from '../../../shared/models/auth/fca-number.model';
import { LocalizableComponent } from '../../../shared/localization/localizable.component';
import { trimValue } from '../../../shared/utils/input-validator';
import { LoggerService } from '../../../shared/services/logger/logger.service';

@Component({
  selector: 'broker-register-step1',
  templateUrl: './register-step1.component.html'
})
export class RegisterStep1Component extends LocalizableComponent implements OnInit {
  @Output()
  readonly fcaNumberValidated: EventEmitter<string> = new EventEmitter<string>();

  fcaForm: UntypedFormGroup;

  loading = false;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly logger: LoggerService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  get fcaNumberControl(): UntypedFormControl {
    return this.fcaForm.get('fcaNumber') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * Trims the form control value.
   */
  trimValue(formControl: UntypedFormControl): void {
    trimValue(formControl);
  }

  /**
   * Register a new user.
   */
  validateFcaNumber(): void {
    this.loading = true;
    this.fcaForm.setErrors(null);

    const value: FcaNumberModel = { ...this.fcaForm.value };

    this.authService
      .validateFcaNumber(value)
      .then((result: boolean) => {
        this.loading = false;
        if (result) {
          this.authService.fcaNumber = value.fcaNumber;
          this.fcaNumberValidated.emit(value.fcaNumber);
        } else {
          this.router
            .navigate(['auth/call-us'])
            .catch((error) => this.logger.error('Error navigating to Auth Call-Us', error));
        }
      })
      .catch(() => {
        this.loading = false;
        this.fcaForm.setErrors({
          generic: true
        });
      });
  }

  private buildForm(): void {
    this.fcaForm = this.formBuilder.group({
      fcaNumber: ['', [Validators.required, Validators.maxLength(10)]]
    });
  }
}
