import { LoggerLevel } from '../app/shared/services/logger/logger-level';
import packageInfo from '../../package.json';
import { IOptions } from 'rx-polling';

/**
 * Data for the DEV/QA environment.
 */
export const environment: any = {
  cognito: window._env_.cognito,
  mixpanel: window._env_.mixpanel,
  intercom: window._env_.intercom,
  consumerApiPath: window._env_.consumerApiPath,
  postcodes: window._env_.postcodes,
  production: window._env_.production,
  canExecuteTrackers: true,
  logLevel: LoggerLevel.DEBUG,
  applicationVersion: packageInfo.version,
  helpfulLinks: {
    documents: {
      eligibility: 'https://resources.selinafinance.co.uk/Selina_Home+FlexiLoan+Criteria+Guide.pdf'
    },
    consumer: {
      productGuide: 'https://resources.selinafinance.co.uk/Selina_Product+Guide+Office+Print.pdf',
      accountantCertificate: 'https://resources.selinafinance.co.uk/Selina_Accountant+Cert.pdf',
      debtConsolidationForm: 'https://resources.selinafinance.co.uk/Selina_Debt+Con.pdf',
      deedOfConsentSingleApplication:
        'https://resources.selinafinance.co.uk/Selina_Deed+of+consent_Single+application.pdf',
      deedOfConsentJointApplication:
        'https://resources.selinafinance.co.uk/Selina_Deed+of+consent_Joint+application.pdf',
      tariffOfCharges: 'https://resources.selinafinance.co.uk/Selina_Tariff+of+Charges.pdf',
      termsAndConditions: 'https://www.selinaadvance.com/terms',
      privacyPolicy: 'https://www.selinaadvance.com/privacy-policy',
      apiDocumentation: 'https://docs.selinafinance.co.uk/index.html',
      directDebitMandate: 'https://resources.selinafinance.co.uk/Selina_Direct+Debit+Mandate.pdf',
      submissionChecklist: 'https://resources.selinafinance.co.uk/selina-submission-checklist.pdf',
      packagingGuide: 'https://resources.selinafinance.co.uk/Selina_Packaging_Guide.pdf',
      consumerDutyAssessment: 'https://resources.selinafinance.co.uk/selina_consumer_duty_assessment.pdf'
    },
    epcRegisterLink: 'https://www.epcregister.com/reportSearchAddressTerms.html'
  },
  mobilePhone: 'tel:+442039848578',
  feedbackEmail: 'growth@selinafinance.co.uk',
  maximumAmountOfPollsForCalculationStatus: 24, // 120 seconds / 5 seconds poll
  maximumAmountOfPollsForCreditFile: 6, // 30 seconds / 5 seconds poll
  maximumAmountOfPollsForEsisStatus: 11, // 120 seconds / 10 seconds poll - should be 12 but the last request should include a query parameter
  pollConfiguration: {
    interval: 5000, // Make a poll every 5 seconds
    attempts: 2, // Allow for server to retry again in case of an error for the 2nd time only
    backoffStrategy: 'consecutive', // Makes a poll every x seconds fixed
    backgroundPolling: true // Keeps polling even if the user has a browser window inactive
  } as IOptions,
  pollEsisDocumentConfiguration: {
    interval: 10000, // Make a poll every 10 seconds
    attempts: 2, // Allow for server to retry again in case of an error for the 2nd time only
    backoffStrategy: 'consecutive', // Makes a poll every x seconds fixed
    backgroundPolling: true // Keeps polling even if the user has a browser window inactive
  } as IOptions,
  referEmail: 'enquiries@selinafinance.co.uk',
  sentry: {
    dsn: window._env_.sentry.dsn,
    environment: window._env_.sentry.environment,
    tracesSampleRate: window._env_.sentry.tracesSampleRate,
    replaysSessionSampleRate: window._env_.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: window._env_.sentry.replaysOnErrorSampleRate
  }
};
