/**
 * The form control names for the Consumer Details Consent form group.
 */
export enum ConsentFormControlNamesEnum {
  HAS_CONSENT_TO_SUBMIT_FUNDING_APPLICATION = 'hasConsentToSubmitFundingApplication',
  HAS_CERTIFIED_CURRENT_FINANCIAL_CIRCUMSTANCES = 'hasCertifiedCurrentFinancialCircumstances',
  HAS_ACCEPTED_TERMS_AND_COND_AND_PRIVACY_POLICY = 'hasAcceptedTermsAndConditionsAndPrivacyPolicy',
  HAS_GIVEN_CONSENT_FOR_HARD_CREDIT_CHECK = 'hasGivenConsentForHardCreditCheck',
  HAS_GIVEN_CONSENT_FOR_MARKETING_COMMUNICATIONS = 'hasGivenConsentForMarketingCommunications',
  HAS_GIVEN_CONSENT_FOR_ID_VERIFICATION = 'hasGivenConsentForIdVerification',
  DISBURSEMENT_BANK_DETAILS = 'disbursementBankDetails',
  DISBURSEMENT_BANK_DETAILS_ACCOUNT_SORT_CODE = 'bankAccountSortCode',
  DISBURSEMENT_BANK_DETAILS_ACCOUNT_NUMBER = 'bankAccountNumber',
  DISBURSEMENT_BANK_DETAILS_ACCOUNT_HOLDER_FULL_NAME = 'accountHolderFullName',
  COMPLETION_DETAILS = 'completionDetails',
  COMPLETION_DETAILS_DISBURSEMENT_OPTION = 'disbursementOption',
  COMPLETION_DETAILS_DISBURSEMENT_AMOUNT = 'disbursementAmount',
  HAS_PRE_CONSENT_FUNDING = 'hasAccessToPcf',
  PRE_CONSENT_FUNDING = 'pcfConsent',
  PRE_CONSENT_FUNDING_OPT_IN = 'optInToPcf',
  PRE_CONSENT_FUNDING_CHC_REQUESTED = 'chcRequested'
}
