import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { PageLinkItemModel } from '../../shared/models/page-link-item.model';
import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { AuthService } from '../../shared/services/auth/auth.service';
import { IntercomService } from '../../shared/services/intercom/intercom.service';
import { LoggerService } from '../../shared/services/logger/logger.service';

/**
 * The left panel component for the B2B flow.
 */
@Component({
  selector: 'broker-dashboard-left-panel',
  templateUrl: './dashboard-left-panel.component.html',
  styleUrls: ['./dashboard-left-panel.component.scss']
})
export class DashboardLeftPanelComponent extends LocalizableComponent {
  @Input() pages: PageLinkItemModel = null;

  constructor(
    @Inject(L10N_LOCALE) locale: L10nLocale,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly intercom: IntercomService,
    private readonly logger: LoggerService
  ) {
    super(locale);
  }

  get userNameParams(): any {
    return {
      name: this.authService.getUserName() ?? '-'
    };
  }

  /**
   * Logout user.
   */
  logout(): void {
    this.authService.logout().then(() => {
      this.intercom.shutdown();
      void this.router.navigate(['auth/login']).catch((error) => this.logger.error('Error navigating to login', error));
    });
  }

  /**
   * Function to change page
   * @param namePage name of the page to activate
   */
  changePage(namePage: string): void {
    for (const page in this.pages) {
      this.pages[page] = page === namePage;
    }
  }
}
