/**
 * The Mixpanel event properties enum.
 */
export enum MixpanelEventPropertiesEnum {
  /**
   * The Opportunity reference number.
   */
  REFERENCE_NUMBER = 'Reference Number',

  /**
   * The actual page.
   */
  PAGE = 'Page'
}
