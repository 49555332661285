import { FormRegexPatternsUtil } from './form-regex-patterns.util';

/**
 * DIP validation rules.
 */
export const DIP_VALIDATION: any = {
  eligibilityCriteria: {
    required: true,
    requiredTrue: true
  },
  facilityDetails: {
    numberOfApplicants: {
      required: true
    },
    requestedLoanAmount: {
      required: true,
      min: 10000,
      max: 1000000,
      maxLength: 9 // 7 + 2 commas
    },
    requestedLoanTerm: {
      required: true,
      min: 5,
      max: 30,
      maxLength: 2
    },
    facilityAllocationPurpose: {
      required: true
    },
    facilityAllocationAmount: {
      required: true,
      min: 1000,
      maxLength: 9 // 7 + 2 commas
    },
    isUserTheAdvisingBroker: {
      required: true
    },
    intermediary: {
      firmName: {
        required: true,
        minLength: 3,
        maxLength: 255
      },
      fcaNumber: {
        required: true,
        minLength: 3,
        maxLength: 20
      },
      contactFirstName: {
        required: true,
        minLength: 3,
        maxLength: 255,
        pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
      },
      contactLastName: {
        required: true,
        minLength: 3,
        maxLength: 255,
        pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
      }
    }
  },
  propertyDetails: {
    isSameAsResidentialAddress: {
      required: true
    },
    estimatedValue: {
      required: true,
      min: 100000,
      maxLength: 12 // 9 + 2 commas + £ symbol
    },
    purchaseYear: {
      required: true,
      minLength: 4,
      maxLength: 4,
      yearDifference: 200
    },
    purchasePrice: {
      required: true,
      min: 1000,
      maxLength: 12 // 9 + 2 commas + £ symbol
    },
    propertyType: {
      required: true
    },
    numberOfBedrooms: {
      required: true,
      min: 1,
      max: 50
    }
  },
  brokerFees: {
    addProductFeeToLoan: {
      required: true
    },
    addArrangementFeeSelina: {
      required: true
    },
    addArrangementFeeSelinaToLoan: {
      required: true
    },
    commissionFee: {
      min: 0,
      max: 99999,
      maxLength: 7 // 5 + 1 comma + £ symbol
    },
    arrangementFee: {
      min: 0,
      max: 99999,
      maxLength: 7 // 5 + 1 comma + £ symbol
    },
    valuationFee: {
      min: 0,
      max: 99999,
      maxLength: 7 // 5 + 1 comma + £ symbol
    },
    thirdPartyFee: {
      min: 0,
      max: 99999,
      maxLength: 7 // 5 + 1 comma + £ symbol
    },
    addCommissionFeeToLoan: {
      required: true
    },
    addArrangementFeeToLoan: {
      required: true
    },
    addValuationFeeToLoan: {
      required: true
    },
    addThirdPartyFeeToLoan: {
      required: true
    },
    intermediary: {
      adviceFee: {
        min: 0,
        max: 99999,
        maxLength: 7 // 5 + 1 comma + £ symbol
      },
      addAdviceFeeToLoan: {
        required: true
      }
    }
  },
  creditCommitments: {
    securityProperty: {
      required: true
    },
    consolidate: {
      required: true
    },
    ignore: {
      required: true
    },
    source: {
      required: true
    },
    data: {
      applicants: {
        mandatory: true,
        required: true
      },
      type: {
        mandatory: true,
        required: true
      },
      outstandingBalance: {
        mandatory: true,
        required: true,
        min: 0,
        max: 999999999.0,
        maxLength: 15 // 9 + 2 commas + 1 dot + 2 decimals + £ symbol
      },
      monthlyPayments: {
        mandatory: true,
        required: true,
        min: 0,
        max: 999999999.0,
        maxLength: 15 // 9 + 2 commas + 1 dot + 2 decimals + £ symbol
      },
      startDate: {
        mandatory: true,
        required: true,
        min: new Date(1900, 0, 1)
      },
      endDate: {
        mandatory: true,
        required: true
      },
      securityProperty: {
        required: true
      },
      consolidate: {
        required: true
      },
      amountToConsolidate: {
        required: true,
        min: 0,
        max: 999999999.0,
        maxLength: 15 // 9 + 2 commas + 1 dot + 2 decimals + £ symbol
      },
      repaymentType: {
        required: true
      },
      repaymentVehicle: {
        required: true
      },
      costOfRepaymentVehicle: {
        required: true,
        min: 0,
        max: 999999.0,
        maxLength: 11 // 6 + 1 commas + 1 dot + 2 decimals + £ symbol
      },
      interestOnlyBalance: {
        required: true,
        min: 0,
        max: 999999999.0,
        maxLength: 15 // 9 + 2 commas + 1 dot + 2 decimals + £ symbol
      },
      interestRateType: {
        required: true
      },
      fixedRateEndDate: {
        required: true,
        maxYearDifference: 50 // Years in the future
      },
      reasonToIgnore: {
        required: true
      },
      settlementDate: {
        mandatory: true,
        required: true
      },
      registrationDate: {
        mandatory: true,
        required: true
      },
      category: {
        required: true
      },
      amount: {
        required: true,
        min: 0,
        max: 9999999.0,
        maxLength: 13 // 7 + 2 commas + 1 dot + 2 decimals + £ symbol
      },
      satisfactionDate: {
        mandatory: true,
        required: true
      },
      status: {
        required: true
      }
    }
  },
  householdExpenditures: {
    childDependantsCount: {
      required: true
    },
    numberOfChildDependants: {
      required: true,
      min: 4,
      max: 10,
      maxLength: 2
    },
    adultDependantsCount: {
      required: true
    },
    numberOfAdultDependants: {
      required: true,
      min: 4,
      max: 10,
      maxLength: 2
    },
    foodDrinkAndOtherHousekeepingCosts: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    clothingAndFootwear: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    tvPhoneAndInternet: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    utilities: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    furnishingAndPropertyMaintenance: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    insurance: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    councilTax: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    transport: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    recreation: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    groundRentAndServiceCharge: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    schoolNurseryAndChildcareFees: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    alimonyAndChildMaintenance: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    },
    otherMonthlyExpenditure: {
      min: 0,
      max: 9_999_999,
      maxLength: 10 // 7 + 2 comma + £ symbol
    }
  },
  applicant: {
    title: {
      required: true
    },
    firstName: {
      mandatory: true,
      required: true,
      minLength: 2,
      maxLength: 255,
      pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
    },
    middleName: {
      required: false,
      minLength: 2,
      maxLength: 255,
      pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
    },
    surname: {
      mandatory: true,
      required: true,
      minLength: 2,
      maxLength: 255,
      pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
    },
    applicantUsedAnotherName: {
      required: true
    },
    previousNames: {
      maxArrayLength: 5,
      previousTitle: {
        required: true
      },
      previousFirstName: {
        required: true,
        minLength: 2,
        maxLength: 255,
        pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
      },
      previousMiddleName: {
        required: false,
        minLength: 2,
        maxLength: 255,
        pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
      },
      previousSurname: {
        required: true,
        minLength: 2,
        maxLength: 255,
        pattern: FormRegexPatternsUtil.NAME_SURNAME_MIDDLE_NAME_PATTERN
      }
    },
    estimatedRetirementAge: {
      required: true,
      min: 18,
      max: 99
    },
    dateOfBirth: {
      required: true,
      maxAge: '01/01/1900'
    },
    nationality: {
      required: true
    },
    maritalStatus: {
      required: true
    },
    livedInCurrentAddressFor3Years: {
      required: true
    },
    applicant2LivesWithApplicant1: {
      required: true
    },
    applicant2LivesWithApplicant1For3Years: {
      required: true
    },
    currentAddressMovedInDate: {
      required: true
    },
    previousAddress: {
      from: {
        required: true
      },
      to: {
        required: true
      }
    },
    employmentStatus: {
      required: true
    },
    employment: {
      employmentType: {
        required: true
      },
      isInProbationPeriod: {
        required: true
      },
      contractStartDate: {
        required: true,
        minYearsAgo: 60 // Years ago
      },
      contractEndDate: {
        required: true
      },
      isFirstTimeContractor: {
        required: true
      },
      ownershipShare: {
        required: true,
        pattern: FormRegexPatternsUtil.PERCENT_PATTERN
      },
      timeInSelfEmployment: {
        required: true
      },
      fiscalYear: {
        required: true
      }
    },
    incomeType: {
      required: true
    },
    incomeAmount: {
      commonValidation: {
        maxLength: 11, // 9 + 2 commas
        min: 0,
        max: 10000000
      }
    },
    expectsFutureIncomeDecrease: {
      required: true
    },
    futureIncomeDecreaseReason: {
      required: true
    },
    contractorIncome: {
      dailyRate: {
        required: true,
        min: 1,
        maxLength: 11 // 9 + 2 commas
      },
      daysWorkedWeekly: {
        required: true,
        min: 0.1,
        max: 7,
        maxLength: 3
      }
    }
  }
};
