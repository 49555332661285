import { Component, Inject, Input } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { TableItemModel } from '../../shared/models/table-item.model';

@Component({
  selector: 'broker-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent extends LocalizableComponent {
  @Input()
  details: TableItemModel;

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }
}
