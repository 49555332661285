<div class='p-1'>
  <p class='title'>{{'dialog.credit-check-expiry.title' | translate:lang}}</p>
  <div class='info'>
    <p *ngIf='!isFromCCsPage'>{{'dialog.credit-check-expiry.info.line-1' | translate:lang}}</p>
    <p>{{'dialog.credit-check-expiry.info.line-2' | translate:lang}}</p>
    <p>{{'dialog.credit-check-expiry.info.line-3' | translate:lang}}</p>
  </div>
  <div class='d-flex justify-content-between'>
    <broker-button [id]="'cancel'" [height]="'large'" [width]="'compact'" [disabled]='isLoading'
                   [style]="'outline-dark'" (clickEvent)='cancelEvent.emit()'>
      {{ cancelButtonKey | translate: lang }}
    </broker-button>
    <broker-button [id]="'submit'" [height]="'large'" [width]="'compact'" [loading]='isLoading'
                   [icon]="'arrow'" [iconPosition]="'right'"
                   (clickEvent)='submitEvent.emit()'>
      {{ 'dialog.credit-check-expiry.submit.' + (isLoading ? 'loading' : 'default') | translate: lang }}
    </broker-button>
  </div>
  <div *ngIf='requestError' class='dialog-error d-flex p-2 pb-0'>
    <broker-warning-octagon-svg></broker-warning-octagon-svg>
    <span class='d-block fw-bold ms-2'>{{'dialog.credit-check-expiry.submit.error' | translate:lang}}</span>
  </div>
</div>
