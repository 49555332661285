import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CloseCaseDialogModel } from './model/close-case-dialog.model';
import { CloseCaseModel } from './model/close-case.model';

/**
 * The case dialog component.
 */
@Component({
  selector: 'broker-close-case-dialog',
  templateUrl: './close-case-dialog.component.html',
  styleUrls: ['./close-case-dialog.component.scss']
})
export class CloseCaseDialogComponent {
  /**
   * Emits an event when the navigation bar back button click event occurs.
   */
  @Output() navigationBarBackButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits an event when the navigation bar next button click event occurs.
   */
  @Output() navigationBarNextButtonClickEvent: EventEmitter<CloseCaseModel> = new EventEmitter<CloseCaseModel>();

  /**
   * The next request pending state.
   */
  get nextRequestPending(): boolean {
    return this._nextRequestPending;
  }

  set nextRequestPending(value: boolean) {
    if (value) {
      this.closeCaseReason.disable();
    } else {
      this.closeCaseReason.enable();
    }
    this._nextRequestPending = value;
  }

  /**
   * The next request error state.
   */
  nextRequestError = false;

  /**
   * Form control for the close case reason field.
   */
  closeCaseReason: UntypedFormControl = new UntypedFormControl(null, Validators.required);

  private _nextRequestPending = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CloseCaseDialogModel) {}

  /**
   * Handles the navigation bar back button click event.
   */
  handleNavigationBarBackButtonClickEvent(): void {
    this.navigationBarBackButtonClickEvent.emit();
  }

  /**
   * Handles the navigation bar next button click event.
   */
  handleNavigationBarNextButtonClickEvent(): void {
    this.navigationBarNextButtonClickEvent.emit({
      reason: this.closeCaseReason.value
    });
  }
}
