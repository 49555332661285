import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { BaseRouteEnum } from '../enums/route/base-route.enum';

/**
 * Guard to check if user is active.
 */
@Injectable({
  providedIn: 'root'
})
export class IsUserActiveGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly authService: AuthService) {}

  canActivate(): boolean | UrlTree {
    if (this.authService.isUserActive) {
      return true;
    }
    return this.router.createUrlTree([BaseRouteEnum.EMPTY]);
  }
}
