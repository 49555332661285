import { Injectable } from '@angular/core';

import { StorageKeyEnum } from '../enum/storage-key.enum';

/**
 * Storage factory service.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageFactoryService {
  /**
   * Gets the correct storage for the specific key.
   */
  getStorage(key: StorageKeyEnum): Storage {
    switch (key) {
      case StorageKeyEnum.MIXPANEL_EMAIL:
      case StorageKeyEnum.WAS_WHATS_NEW_POPUP_VIEWED:
        return localStorage;
      case StorageKeyEnum.DASHBOARD_SELECTED_COMPANY:
      case StorageKeyEnum.CAN_SUBMIT_CONSUMER_FLOW:
      case StorageKeyEnum.CAN_REQUEST_ADMIN_ACCESS:
      case StorageKeyEnum.CAN_REQUEST_ANY_ACCOUNT_ACCESS:
      case StorageKeyEnum.IS_ADMIN_VIEW_ENABLED:
      case StorageKeyEnum.IS_ANY_ACCOUNT_VIEW_ENABLED:
      case StorageKeyEnum.BROKER_DATA:
      case StorageKeyEnum.REGISTERED_USER_INFO:
      case StorageKeyEnum.SESSION_FCA_NUMBER:
      case StorageKeyEnum.SESSION_RECOVER_EMAIL:
      case StorageKeyEnum.DIP_DATA:
      case StorageKeyEnum.DIP_OFFERS_DATA:
      case StorageKeyEnum.DIP_CREDIT_COMMITMENTS_DATA:
      case StorageKeyEnum.FULL_APP_DATA:
      case StorageKeyEnum.USER_OPPORTUNITIES:
        return sessionStorage;
      default:
        return sessionStorage;
    }
  }
}
