import { L10nLocale } from 'angular-l10n';

/**
 * The supported languages.
 */
export type SupportedLanguage = 'en-GB';

/**
 * Language information.
 */
export interface LanguageInfo {
  /** The language code. */
  code: SupportedLanguage;

  /** The translation key for the language. */
  translationKey: string;

  /** The locale. */
  locale: L10nLocale;
}

/**
 * The type for a language set.
 */
export type LanguageSet = readonly [LanguageInfo];

/** The app currency. */
export const APP_CURRENCY = 'GBP';

/** Creates a en-GB locale. */
export const EN_GB_LOCALE: () => L10nLocale = (): L10nLocale =>
  ({
    language: 'en-GB',
    currency: APP_CURRENCY,
    timeZone: 'Europe/London'
  } as const);

/**
 * The supported languages information.
 */
export const SUPPORTED_LANGUAGES_SET: LanguageSet = [
  {
    code: 'en-GB',
    translationKey: 'language.en.gb',
    locale: EN_GB_LOCALE()
  }
] as const;
