<div class="col mt-3 mb-3">
  <span class="additional-title">
    {{ 'applications.details.title' | translate: lang }}
  </span>

  <div class="row mt-4 d-flex">
    <div class="details-col">
      <span class="additional-label">{{ 'applications.details.property' | translate: lang }}</span>
    </div>
    <div class="flex-grow-1 details-col">
      {{ details.securityAddress || '-' }}
    </div>
  </div>
</div>
