import { Component, Input } from '@angular/core';

import { IconPosition } from '../../button.component';

@Component({
  selector: 'broker-button-loading-svg',
  templateUrl: './loading-svg.component.svg',
  styleUrls: ['./loading-svg.component.scss']
})
export class LoadingSvgComponent {
  @Input() position: IconPosition = 'left';
}
