/**
 * The Dialog type enum. Used to specify which modal configuration to load
 */
export enum DialogTypeEnum {
  CONSUMER_OPPORTUNITY_SUBMISSION_DIALOG = 'ConsumerOpportunitySubmissionDialog',
  CONSUMER_DIP_MISSING_INFORMATION = 'ConsumerDipMissingInformationDialog',
  CONSUMER_DIP_PRODUCT_FEE_WARNING = 'ConsumerDipProductFeeDialog',
  CONSUMER_DIP_CONFIRM_CHANGE_DIALOG = 'ConsumerDipConfirmChangeDialog',
  CONSUMER_CLOSE_CASE_DIALOG = 'ConsumerCloseCaseDialog',
  CONSUMER_FULL_APPLICATION_SUBMISSION_DIALOG = 'ConsumerFullApplicationSubmissionDialog',
  CREDIT_COMMITMENTS_INVALID_FORM_DIALOG = 'CreditCommitmentsInvalidFormDialog',
  CREDIT_COMMITMENTS_UNSAVED_CHANGES_DIALOG = 'CreditCommitmentsUnsavedChangesDialog',
  DOCUMENTS_INVALID_FORM_DIALOG = 'DocumentsInvalidFormDialog',
  DOCUMENTS_UNSAVED_CHANGES_DIALOG = 'DocumentsUnsavedChangesDialog',
  DISCARD_CHANGES_DIALOG = 'DiscardChangesDialog',
  PROPERTY_VALUATION_CHANGE_DIALOG = 'PropertyValuationChangeDialog',
  CREDIT_CHECK_EXPIRY_DIALOG = 'CreditCheckExpiryDialog',
  WHATS_NEW_DIALOG = 'WhatsNewDialog'
}
