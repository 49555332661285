import { toDateString, toLocalDate } from '../../../utils/date.util';
import { ApplicantModel } from '../model/applicant.model';
import { ApplicantOptions } from '../model/credit-commitments-constants';
import {
  ApplicantCreditScoreModel,
  CcjModel,
  MortgageOrSecuredCreditCommitmentModel,
  UnsecuredCreditCommitmentModel
} from '../model/credit-commitments-details.model';
import {
  ApplicantScoreViewModel,
  CcjViewModel,
  CreditCommitmentsViewModel,
  SecuredCreditCommitmentViewModel,
  UnsecuredCreditCommitmentViewModel
} from '../model/view-model/credit-commitments-details.view-model';
import { toNumber } from './mapping-utils';

export class CreditCommitmentsMapper {
  static checkForMissingApplicantCreditFile(
    scores: ApplicantScoreViewModel[],
    applicant1: ApplicantModel,
    numberOfApplicants: number,
    applicant2: ApplicantModel
  ): void {
    if (
      scores.length === 0 ||
      scores.filter((score: ApplicantScoreViewModel) => score.applicantNumber === 1).length === 0
    ) {
      scores.push({
        applicantNumber: 1,
        applicant: applicant1,
        creditFileFound: false
      } as ApplicantScoreViewModel);
    }
    if (numberOfApplicants === 2 && scores.length < 2) {
      if (scores.filter((score: ApplicantScoreViewModel) => score.applicantNumber === 2).length === 0) {
        scores.push({
          applicantNumber: 2,
          applicant: applicant2,
          creditFileFound: false
        } as ApplicantScoreViewModel);
      }
    }
  }

  static toCcjDetailsModel(ccj: CcjViewModel): CcjModel {
    return {
      applicants: ccj.source === 'user' && ccj.saved ? this.findApplicants(ccj) : ccj.data?.applicants,
      registrationDate:
        ccj.source === 'user' && ccj.saved
          ? toDateString(ccj.registrationDate)
          : toDateString(ccj.data?.registrationDate),
      id: ccj.id,
      source: ccj.source,
      amount: ccj.source === 'user' && ccj.saved ? toNumber(ccj.amount) : toNumber(ccj.data?.amount),
      satisfactionDate:
        ccj.source === 'user' && ccj.saved
          ? toDateString(ccj.satisfactionDate)
          : toDateString(ccj.data?.satisfactionDate),
      status: ccj.source === 'system' || ccj.saved ? ccj.status : ccj.data?.status,
      category: ccj.source === 'user' && ccj.saved ? ccj.category : ccj.data?.category
    };
  }

  static toUnsecuredCreditCommitmentsDetailsModel(
    unsecured: UnsecuredCreditCommitmentViewModel
  ): UnsecuredCreditCommitmentModel {
    return {
      amountToConsolidate: toNumber(unsecured.data?.amountToConsolidate),
      applicants:
        unsecured.source === 'user' && unsecured.saved ? this.findApplicants(unsecured) : unsecured.data?.applicants,
      consolidate:
        unsecured.source === 'system' || unsecured.saved ? unsecured.consolidate : unsecured.data?.consolidate,
      endDate:
        unsecured.source === 'user' && unsecured.saved
          ? toDateString(unsecured.endDate)
          : toDateString(unsecured.data?.endDate),
      id: unsecured.id,
      source: unsecured.source,
      ignore: unsecured.source === 'system' || unsecured.saved ? unsecured.ignore : null,
      monthlyPayment:
        unsecured.source === 'user' && unsecured.saved
          ? toNumber(unsecured.monthlyPayment)
          : toNumber(unsecured.data?.monthlyPayments),
      outstandingBalance:
        unsecured.source === 'user' && unsecured.saved
          ? toNumber(unsecured.outstandingBalance)
          : toNumber(unsecured.data?.outstandingBalance),
      reasonToIgnore: unsecured.data?.reasonToIgnore,
      startDate:
        unsecured.source === 'user' && unsecured.saved
          ? toDateString(unsecured.startDate)
          : toDateString(unsecured.data?.startDate),
      status: unsecured.status,
      type: unsecured.source === 'user' && unsecured.saved ? unsecured.type : unsecured.data?.type,
      settlementDate:
        unsecured.source === 'user' && unsecured.saved
          ? toDateString(unsecured.settlementDate)
          : toDateString(unsecured.data?.settlementDate),
      consolidationRequired: unsecured.consolidationRequired
    };
  }

  static toSecuredCreditCommitmentDetailsModel(
    secured: SecuredCreditCommitmentViewModel
  ): MortgageOrSecuredCreditCommitmentModel {
    return {
      amountToConsolidate: toNumber(secured.data?.amountToConsolidate),
      applicants: secured.source === 'user' && secured.saved ? this.findApplicants(secured) : secured.data?.applicants,
      consolidate: secured.source === 'system' || secured.saved ? secured.consolidate : secured.data?.consolidate,
      costOfRepaymentVehicle: toNumber(secured.data?.costOfRepaymentVehicle),
      endDate:
        secured.source === 'user' && secured.saved
          ? toDateString(secured.endDate)
          : toDateString(secured.data?.endDate),
      fixedRatePeriodEndDate: toDateString(secured.data?.fixedRateEndDate),
      id: secured.id,
      source: secured.source,
      ignore: secured.source === 'system' || secured.saved ? secured.ignore : null,
      interestOnlyBalanceOfPartAndPartMortgage: toNumber(secured.data?.interestOnlyBalance),
      interestRateType: secured.data?.interestRateType,
      monthlyPayment:
        secured.source === 'user' && secured.saved
          ? toNumber(secured.monthlyPayment)
          : toNumber(secured.data?.monthlyPayments),
      outstandingBalance:
        secured.source === 'user' && secured.saved
          ? toNumber(secured.outstandingBalance)
          : toNumber(secured.data?.outstandingBalance),
      reasonToIgnore: secured.data?.reasonToIgnore,
      repaymentType: secured.data?.repaymentType,
      repaymentVehicle: secured.data?.repaymentVehicle,
      securityProperty:
        secured.source === 'system' || secured.saved ? secured.securityProperty : secured.data?.securityProperty,
      startDate:
        secured.source === 'user' && secured.saved
          ? toDateString(secured.startDate)
          : toDateString(secured.data?.startDate),
      status: secured.status,
      type: secured.source === 'user' && secured.saved ? secured.type : secured.data?.type,
      settlementDate:
        secured.source === 'user' && secured.saved
          ? toDateString(secured.settlementDate)
          : toDateString(secured.data?.settlementDate)
    };
  }

  static mergeCcjViewModel(
    currentCreditCommitmentsData: CreditCommitmentsViewModel,
    index: number,
    ccj: CcjViewModel
  ): CcjViewModel {
    const currentCcj: CcjViewModel = currentCreditCommitmentsData.ccjs[index];
    if (ccj.source === 'user' && currentCcj.saved) {
      return {
        ...ccj,
        ...currentCcj,
        data: {
          ...ccj.data,
          ...currentCcj.data
        }
      };
    }
    return ccj;
  }

  static mergeUnsecuredCreditCommitmentViewModel(
    currentCreditCommitmentsData: CreditCommitmentsViewModel,
    index: number,
    unsecured: UnsecuredCreditCommitmentViewModel
  ): UnsecuredCreditCommitmentViewModel {
    const currentUnsecured: UnsecuredCreditCommitmentViewModel =
      currentCreditCommitmentsData.unsecuredCreditCommitments[index];
    if (unsecured.source === 'user' && currentUnsecured.saved) {
      const mergedUnsecure: UnsecuredCreditCommitmentViewModel = {
        ...unsecured,
        ...currentUnsecured,
        data: {
          ...unsecured.data,
          ...currentUnsecured.data,
          reasonToIgnore: unsecured.data.reasonToIgnore ?? null,
          amountToConsolidate: unsecured.data.amountToConsolidate ?? null
        }
      };
      if (!mergedUnsecure.consolidate) {
        mergedUnsecure.data.consolidate = false;
        mergedUnsecure.data.amountToConsolidate = null;
      }
      if (!mergedUnsecure.ignore) {
        mergedUnsecure.data.reasonToIgnore = null;
      }
      return mergedUnsecure;
    }
    return unsecured;
  }

  // noinspection JSMethodCanBeStatic
  static mergeSecuredCreditCommitmentViewModel(
    currentCreditCommitmentsData: CreditCommitmentsViewModel,
    index: number,
    secured: SecuredCreditCommitmentViewModel
  ): SecuredCreditCommitmentViewModel {
    const currentSecured: SecuredCreditCommitmentViewModel =
      currentCreditCommitmentsData.securedCreditCommitments[index];
    if (secured.source === 'user' && currentSecured.saved) {
      const mergedSecure: SecuredCreditCommitmentViewModel = {
        ...secured,
        ...currentSecured,
        data: {
          ...secured.data,
          ...currentSecured.data,
          reasonToIgnore: secured.data.reasonToIgnore ?? null,
          amountToConsolidate: secured.data.amountToConsolidate ?? null,
          repaymentType: secured.data.repaymentType ?? null,
          repaymentVehicle: secured.data.repaymentVehicle ?? null,
          costOfRepaymentVehicle: secured.data.costOfRepaymentVehicle ?? null,
          interestOnlyBalance: secured.data.interestOnlyBalance ?? null,
          interestRateType: secured.data.interestRateType ?? null,
          fixedRateEndDate: secured.data.fixedRateEndDate ?? null
        }
      };
      if (!mergedSecure.securityProperty) {
        mergedSecure.data.securityProperty = false;
        mergedSecure.data.repaymentType = null;
        mergedSecure.data.repaymentVehicle = null;
        mergedSecure.data.costOfRepaymentVehicle = null;
        mergedSecure.data.interestOnlyBalance = null;
        mergedSecure.data.interestRateType = null;
        mergedSecure.data.fixedRateEndDate = null;
      }
      if (!mergedSecure.consolidate) {
        mergedSecure.data.consolidate = false;
        mergedSecure.data.amountToConsolidate = null;
      }
      if (!mergedSecure.ignore) {
        mergedSecure.data.reasonToIgnore = null;
      }
      return mergedSecure;
    }
    return secured;
  }

  static toApplicantScoreViewModel(
    score: ApplicantCreditScoreModel,
    applicant1: ApplicantModel,
    applicant2: ApplicantModel
  ): ApplicantScoreViewModel {
    return {
      applicantNumber: score.applicantNumber,
      dateTime: toLocalDate(score.dateTime),
      numberAccounts: score.numberAccounts,
      numberActiveAccounts: score.numberActiveAccounts,
      outstandingBalance: score.outstandingBalance,
      numberInfo: score.numberInfo,
      totalValue: score.totalValue,
      creditScore: score.creditScore,
      applicant: score.applicantNumber === 1 ? applicant1 : applicant2,
      creditFileFound: true
    };
  }

  static toCcjViewModel(ccj: CcjModel): CcjViewModel {
    return {
      saved: true,
      active: false,
      source: ccj.source,
      applicant1: ccj.applicants === '1' || ccj.applicants === 'both',
      applicant2: ccj.applicants === '2' || ccj.applicants === 'both',
      registrationDate: toLocalDate(ccj.registrationDate),
      category: ccj.category,
      amount: ccj.amount,
      status: ccj.status,
      satisfactionDate: toLocalDate(ccj.satisfactionDate),
      address: ccj.address,
      id: ccj.id,
      data: {
        category: ccj.category,
        amount: ccj.amount,
        status: ccj.status,
        applicants: ccj.applicants,
        registrationDate: toLocalDate(ccj.registrationDate),
        satisfactionDate: toLocalDate(ccj.satisfactionDate)
      }
    };
  }

  static toUnsecuredCreditCommitmentsViewModel(
    unsecured: UnsecuredCreditCommitmentModel
  ): UnsecuredCreditCommitmentViewModel {
    return {
      saved: true,
      active: false,
      source: unsecured.source,
      applicant1: unsecured.applicants === '1' || unsecured.applicants === 'both',
      applicant2: unsecured.applicants === '2' || unsecured.applicants === 'both',
      consolidate: unsecured.consolidate,
      id: unsecured.id,
      ignore: unsecured.ignore,
      monthlyPayment: unsecured.monthlyPayment,
      outstandingBalance: unsecured.outstandingBalance,
      startDate: toLocalDate(unsecured.startDate),
      endDate: toLocalDate(unsecured.endDate),
      status: unsecured.status,
      type: unsecured.type,
      paymentProfile: unsecured.paymentProfile,
      settlementDate: toLocalDate(unsecured.settlementDate),
      address: unsecured.address,
      data: {
        amountToConsolidate: unsecured.amountToConsolidate,
        applicants: unsecured.applicants,
        consolidate: unsecured.consolidate,
        endDate: toLocalDate(unsecured.endDate),
        monthlyPayments: unsecured.monthlyPayment,
        outstandingBalance: unsecured.outstandingBalance,
        reasonToIgnore: unsecured.reasonToIgnore,
        startDate: toLocalDate(unsecured.startDate),
        settlementDate: toLocalDate(unsecured.settlementDate),
        type: unsecured.type
      },
      consolidationRequired: unsecured.consolidationRequired
    };
  }

  static toSecuredCreditCommitmentsViewModel(
    secured: MortgageOrSecuredCreditCommitmentModel
  ): SecuredCreditCommitmentViewModel {
    return {
      saved: true,
      active: false,
      source: secured.source,
      applicant1: secured.applicants === '1' || secured.applicants === 'both',
      applicant2: secured.applicants === '2' || secured.applicants === 'both',
      consolidate: secured.consolidate,
      endDate: toLocalDate(secured.endDate),
      id: secured.id,
      ignore: secured.ignore,
      monthlyPayment: secured.monthlyPayment,
      outstandingBalance: secured.outstandingBalance,
      securityProperty: secured.securityProperty,
      startDate: toLocalDate(secured.startDate),
      status: secured.status,
      type: secured.type,
      paymentProfile: secured.paymentProfile,
      settlementDate: toLocalDate(secured.settlementDate),
      address: secured.address,
      data: {
        amountToConsolidate: secured.amountToConsolidate,
        applicants: secured.applicants,
        consolidate: secured.consolidate,
        costOfRepaymentVehicle: secured.costOfRepaymentVehicle,
        endDate: toLocalDate(secured.endDate),
        fixedRateEndDate: toLocalDate(secured.fixedRatePeriodEndDate),
        interestOnlyBalance: secured.interestOnlyBalanceOfPartAndPartMortgage,
        interestRateType: secured.interestRateType,
        monthlyPayments: secured.monthlyPayment,
        outstandingBalance: secured.outstandingBalance,
        reasonToIgnore: secured.reasonToIgnore,
        repaymentType: secured.repaymentType,
        repaymentVehicle: secured.repaymentVehicle,
        securityProperty: secured.securityProperty,
        startDate: toLocalDate(secured.startDate),
        settlementDate: toLocalDate(secured.settlementDate),
        type: secured.type
      }
    };
  }

  private static findApplicants(
    value: SecuredCreditCommitmentViewModel | UnsecuredCreditCommitmentViewModel | CcjViewModel
  ): ApplicantOptions {
    if (value.applicant1 && value.applicant2) {
      return 'both';
    }
    if (value.applicant2) {
      return '2';
    }
    return value.applicant1 ? '1' : null;
  }
}
