import { Component, EventEmitter, Inject, Output } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../../../localization/localizable.component';
import { WhatsNewAnimationsService } from '../../../../services/whats-new/whats-new-animations.service';
import { WhatsNewService } from '../../../../services/whats-new/whats-new.service';

@Component({
  selector: 'broker-whats-new-dialog',
  templateUrl: './whats-new-dialog.component.html',
  styleUrls: ['./whats-new-dialog.component.scss']
})
export class WhatsNewDialogComponent extends LocalizableComponent {
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  currentModalIndex = 0;

  get whatsNewItems() {
    return this.whatsNew.whatsNewItems;
  }

  constructor(
    @Inject(L10N_LOCALE) locale: L10nLocale,
    private readonly whatsNewAnimations: WhatsNewAnimationsService,
    private readonly whatsNew: WhatsNewService
  ) {
    super(locale);
  }

  showNextPage(page: number) {
    this.currentModalIndex = page;
    this.whatsNewAnimations.animateOutWhatsNewPopupCard(page);
  }

  showPreviousPage(page: number) {
    this.currentModalIndex = page;
    this.whatsNewAnimations.animateInWhatsNewPopupCard(page);
  }

  close() {
    this.currentModalIndex = 0; // Reset to the first modal when closing
    this.closeEvent.emit();
  }
}
