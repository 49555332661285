<form *ngIf='searchForm' [formGroup]='searchForm' class='consumer-flow'>
  <div class='py-3 position-relative search-wrapper'>
    <div class='d-flex justify-content-between'>
      <label class='d-none' for='search{{ randomId }}'></label>
    </div>
    <mat-form-field appearance='standard' class='search-input'>
      <input
        id='search{{ randomId }}'
        type='text'
        matInput
        [placeholder]="'table.search.placeholder' | translate: lang"
        [maxLength]='256'
        [formControl]='searchControl'
        (keyup.enter)='search()'
        autocomplete='off'
      />
      <button
        mat-button
        matSuffix
        type='button'
        mat-icon-button
        aria-label='Clear'
        (click)="searchControl.setValue(''); search()"
        *ngIf='searchControl.value'
      >
        <mat-icon>close</mat-icon>
      </button>
      <button mat-button matSuffix type='button' [color]="'accent'" class='search-button' (click)='search()'>
        <broker-search-svg></broker-search-svg>
      </button>
    </mat-form-field>
  </div>
</form>
