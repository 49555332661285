/**
 * The form control names for the DIP form group.
 */
export enum DipFormControlNamesEnum {
  ELIGIBILITY_CRITERIA = 'eligibilityCriteria',
  FACILITY_DETAILS = 'loanInformation',
  PROPERTY_DETAILS = 'propertyDetails',
  APPLICANT1 = 'applicant1',
  APPLICANT2 = 'applicant2',
  CREDIT_COMMITMENTS = 'creditCommitments',
  EXPENDITURE = 'expenditure',
  BROKER_FEES = 'brokerFees'
}
