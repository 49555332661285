<div>
  <h1 class="mt-5">{{ 'authentication.register.title' | translate: lang }}</h1>
  <p>
    <span class="me-2">{{ 'authentication.register.login.label' | translate: lang }}</span>
    <a [routerLink]="['/auth/login']">
      {{ 'authentication.register.login.sign-in' | translate: lang }}
    </a>
  </p>
  <broker-register-step1 *ngIf="step === 1" (fcaNumberValidated)="handleFcaNumberValidated($event)">
  </broker-register-step1>
  <broker-register-step2 *ngIf="step === 2" [fcaNumber]="fcaNumber"></broker-register-step2>
</div>
