/**
 * The sub stage name enum.
 */
export enum SubStageNameEnum {
  PRODUCT_SELECTION = 'Product Selection',
  CUSTOMER_ACCEPTANCE = 'Customer Acceptance',
  CUSTOMER_INPUT_NEEDED = 'Customer Input Needed',
  INTERNAL_APPROVAL = 'Internal Approval',
  ERROR = 'ERROR'
}
