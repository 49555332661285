import { Component, Inject, OnDestroy } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../../shared/localization/localizable.component';
import { WhatsNewService } from '../../../shared/services/whats-new/whats-new.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'broker-whats-new-box',
  templateUrl: './whats-new-box.component.html',
  styleUrls: ['./whats-new-box.component.scss']
})
export class WhatsNewBoxComponent extends LocalizableComponent implements OnDestroy {
  get whatsNewItems() {
    return this.whatsNew.whatsNewItems;
  }

  get shouldWhatsNewBoxBeVisible() {
    return this._shouldWhatsNewBoxBeVisible;
  }

  set shouldWhatsNewBoxBeVisible(value) {
    this._shouldWhatsNewBoxBeVisible = value;
  }

  _shouldWhatsNewBoxBeVisible = false;

  private readonly subscriptions: Subscription[] = [];

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale, private readonly whatsNew: WhatsNewService) {
    super(locale);
    this.subscriptions.push(
      this.whatsNew.showWhatsNewBoxOnDashboard$.subscribe(
        (shouldBeVisible) => (this.shouldWhatsNewBoxBeVisible = shouldBeVisible)
      )
    );
  }

  openWhatsNewPopup() {
    this.whatsNew.closeWhatsNewBoxOnDashboard();
    this.whatsNew.showWhatsNewPopup();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
