import { TableItemModel } from '../../models/table-item.model';

export interface PaginationSummaryModel {
  totalLiveApplications: number;
  totalClosedApplications: number;
  totalFundedApplications: number;
}

export interface DashboardModel {
  metadata?: PaginationMetadataModel;
  records: TableItemModel[];
  summary: PaginationSummaryModel;
}

export interface PaginationMetadataModel {
  page: number;
  pageSize: number;
  pageCount: number;
  totalCount: number;
  maxSupportedPageNumber: number;
}

export const emptyPaginationSummaryModel: () => PaginationSummaryModel = (): PaginationSummaryModel => ({
  totalLiveApplications: 0,
  totalClosedApplications: 0,
  totalFundedApplications: 0
});
