import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../shared/services/auth/auth.service';
import { RegisterModel } from '../../shared/models/auth/register.model';
import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { MixpanelService } from '../../shared/services/mixpanel/mixpanel.service';
import { trimValue } from '../../shared/utils/input-validator';
import { LoggerService } from '../../shared/services/logger/logger.service';

@Component({
  selector: 'broker-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends LocalizableComponent implements OnInit {
  loginForm: UntypedFormGroup;

  disabled = false;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly mixpanelService: MixpanelService,
    private readonly logger: LoggerService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  get emailControl(): UntypedFormControl {
    return this.loginForm.get('email') as UntypedFormControl;
  }

  get passwordControl(): UntypedFormControl {
    return this.loginForm.get('password') as UntypedFormControl;
  }

  /**
   * Initialize the control.
   */
  ngOnInit(): void {
    this.authService.cleanUp();
    this.buildForm();
  }

  /**
   * Trims the form control value.
   */
  trimValue(formControl: UntypedFormControl): void {
    trimValue(formControl);
  }

  /**
   * Login the user.
   */
  login(): void {
    this.disabled = true;
    this.loginForm.setErrors(null);
    this.authService.login(this.loginForm.value).then((result: [boolean, RegisterModel]) => {
      this.disabled = false;
      if (result[0]) {
        this.mixpanelService.trackLoginEvent(result[1]);
        this.router.navigate(['']).catch((error) => this.logger.error('Error navigating to root', error));
      } else {
        this.loginForm.setErrors({
          invalid: true
        });
      }
    });
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(255)]]
    });
  }
}
