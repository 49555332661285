import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';

import { CloseCaseDialogComponent } from './close-case-dialog/close-case-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmSelectedProductDialogComponent } from './confirm-selected-product-dialog/confirm-selected-product-dialog.component';
import { ConfirmSubmissionDialogComponent } from './confirm-submission-dialog/confirm-submission-dialog.component';
import { CreditCheckExpiryDialogComponent } from './credit-check-expiry-dialog/credit-check-expiry-dialog.component';
import { MissingInformationDialogComponent } from './missing-information-dialog/missing-information-dialog.component';
import { PropertyValuationChangeDialogComponent } from './property-valuation-change-dialog/property-valuation-change-dialog.component';
import { OpportunitySubmissionDetailsDialogComponent } from './opportunity-submission-details-dialog/opportunity-submission-details-dialog.component';
import { SvgModule } from '../../svg/svg.module';
import { MaterialModule } from '../../../material/material.module';
import { NavigationBarModule } from '../navigation-bar/navigation-bar.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '../../label/label.module';
import { WhatsNewDialogComponent } from './whats-new-dialog/whats-new-dialog.component';
import { ButtonModule } from '../../button/button.module';

@NgModule({
  declarations: [
    CloseCaseDialogComponent,
    ConfirmDialogComponent,
    ConfirmSelectedProductDialogComponent,
    ConfirmSubmissionDialogComponent,
    CreditCheckExpiryDialogComponent,
    MissingInformationDialogComponent,
    PropertyValuationChangeDialogComponent,
    OpportunitySubmissionDetailsDialogComponent,
    WhatsNewDialogComponent
  ],
  imports: [
    CommonModule,
    L10nTranslationModule,
    L10nIntlModule,
    SvgModule,
    MaterialModule,
    NavigationBarModule,
    ReactiveFormsModule,
    LabelModule,
    ButtonModule
  ],
  exports: [
    CloseCaseDialogComponent,
    ConfirmDialogComponent,
    ConfirmSelectedProductDialogComponent,
    ConfirmSubmissionDialogComponent,
    CreditCheckExpiryDialogComponent,
    MissingInformationDialogComponent,
    PropertyValuationChangeDialogComponent,
    OpportunitySubmissionDetailsDialogComponent,
    WhatsNewDialogComponent
  ]
})
export class DialogsModule {}
