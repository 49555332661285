import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { L10nTranslationModule } from 'angular-l10n';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

import { ConsumerNameSurnameDirective } from './directives/consumer-name-surname.directive';
import { ConsumerUKPostcodeFormatDirective } from './directives/consumer-uk-postcode-directive';
import { LoggerService } from './services/logger/logger.service';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { LabelModule } from './components/label/label.module';
import { ReferInfoBoxModule } from './components/refer-info-box/refer-info-box.module';
import { LeftPanelConsumerModule } from './components/left-panel/consumer/left-panel-consumer.module';
import { NavigationBarModule } from './components/consumer/navigation-bar/navigation-bar.module';
import { ConsumerStepperModule } from './components/stepper/consumer-stepper.module';
import { DirectivesModule } from './directives/directives.module';

/**
 * Shared module.
 */
@NgModule({
  imports: [
    CommonModule,
    NgxMatIntlTelInputComponent,
    L10nTranslationModule,
    RouterModule,
    ComponentsModule,
    PipesModule,
    LabelModule,
    ReferInfoBoxModule,
    LeftPanelConsumerModule,
    NavigationBarModule,
    ConsumerStepperModule,
    DirectivesModule
  ],
  exports: [
    CommonModule,
    ConsumerNameSurnameDirective,
    ConsumerUKPostcodeFormatDirective,
    NgxMatIntlTelInputComponent,
    RouterModule,
    ComponentsModule,
    LabelModule,
    ReferInfoBoxModule,
    PipesModule,
    LeftPanelConsumerModule,
    NavigationBarModule,
    ConsumerStepperModule,
    DirectivesModule
  ]
})
export class SharedModule {
  /**
   * Provider export method.
   */
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [LoggerService]
    };
  }
}
