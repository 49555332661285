<div class='consumer-flow'>
  <h1 class='mt-5'>{{ 'authentication.confirm.title' | translate: lang }}</h1>
  <p [innerHTML]="'authentication.confirm.sub-title' | translate: lang"></p>

  <p>
    <span class='me-2'>{{ 'authentication.confirm.forgot.label' | translate: lang }} </span>
    <a href='#' (click)='$event.preventDefault(); resendCode()'>
      {{ 'authentication.confirm.forgot.resend' | translate: lang }}
    </a>
    <mat-spinner [diameter]='12' class='d-inline me-3' *ngIf='resending'></mat-spinner>
  </p>

  <ng-container *ngIf='confirmForm'>

    <div class='warning mt-4' *ngIf='confirmForm.errors?.invalid'>
      {{ 'authentication.confirm.error' | translate: lang }}
    </div>

    <form
      [formGroup]='confirmForm'
      (ngSubmit)='confirm()'
      novalidate
      [ngClass]="confirmForm.errors?.invalid ? 'mt-4' : 'mt-5'"
    >
      <div class='mb-3'>
        <broker-label
          [for]="'code'"
          [label]="'authentication.confirm.form.code.label' | translate:lang">
        </broker-label>
        <mat-form-field appearance='standard'>
          <input
            type='text'
            matInput
            id='code'
            required
            maxlength='16'
            [formControl]='codeControl'
            [placeholder]="'authentication.confirm.form.code.label' | translate: lang"
            (blur)='trimValue(codeControl)'
            autocomplete='off'
          />
        </mat-form-field>
      </div>

      <broker-button [id]="'login'" [height]="'large'" [width]="'full-width'"
                     [disabled]='!confirmForm.valid || disabled'>{{ 'authentication.confirm.submit' | translate: lang }}</broker-button>
    </form>
  </ng-container>
</div>
