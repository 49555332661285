import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { finalize, takeWhile } from 'rxjs/operators';
import { isNull, isUndefined } from 'lodash-es';

import { BrokerFeesControlNamesEnum } from '../../../consumer/dip/form/broker-fees/enum/broker-fees-form-control-names.enum';
import { CloseCaseDialogComponent } from '../../components/consumer/dialogs/close-case-dialog/close-case-dialog.component';
import { CloseCaseModel } from '../../components/consumer/dialogs/close-case-dialog/model/close-case.model';
import { ConfirmDialogComponent } from '../../components/consumer/dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmSubmissionDialogComponent } from '../../components/consumer/dialogs/confirm-submission-dialog/confirm-submission-dialog.component';
import { MissingInformationDialogComponent } from '../../components/consumer/dialogs/missing-information-dialog/missing-information-dialog.component';
import { ConsumerApiFullAppService } from '../../consumer/details/consumer-api-full-app.service';
import { FullApplicationDetailsModel } from '../../consumer/details/model/full-application-details.model';
import { ConsumerApiDipService } from '../../consumer/dip/consumer-api-dip.service';
import { ConsumerFormDipService } from '../../consumer/dip/consumer-form-dip.service';
import { DialogTypeEnum } from '../../consumer/dip/enum/dialog-type.enum';
import { StageNameEnum } from '../../consumer/dip/enum/stage-name.enum';
import { DecisionInPrincipleDetailsModel } from '../../consumer/dip/model/decision-in-principle-details.model';
import { FacilityDetailsModel } from '../../consumer/dip/model/facility-details.model';
import { StepperService } from '../../consumer/stepper/stepper.service';
import { ConsumerStorageDipService } from '../../consumer/storage/consumer-storage-dip.service';
import { LoggerService } from '../logger/logger.service';
import { BooleanMessage } from '../../messages/boolean.message';
import { MixpanelEventsEnum } from '../mixpanel/enum/mixpanel-events.enum';
import { MixpanelService } from '../mixpanel/mixpanel.service';
import { NavigatorService } from '../navigator/navigator.service';
import { scrollToElementWithSelector } from '../../utils/scroll-to.util';
import { DialogConfigurationService } from './dialog-configuration.service';
import { PropertyValuationChangeDialogComponent } from '../../components/consumer/dialogs/property-valuation-change-dialog/property-valuation-change-dialog.component';
import { ConsumerStorageFullAppService } from '../../consumer/storage/consumer-storage-full-app.service';
import { DipRouteEnum } from '../../enums/route/dip-route.enum';
import { EmptyMessage } from '../../messages/empty.message';
import { CreditCheckExpiryDialogComponent } from '../../components/consumer/dialogs/credit-check-expiry-dialog/credit-check-expiry-dialog.component';
import { WhatsNewDialogComponent } from '../../components/consumer/dialogs/whats-new-dialog/whats-new-dialog.component';
import { WhatsNewAnimationsService } from '../whats-new/whats-new-animations.service';

/**
 * Service to manage dialog.
 */
@Injectable({
  providedIn: 'root'
})
export class DialogService {
  skipConfirmChangeDialog = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly dialogConfigurationService: DialogConfigurationService,
    private readonly consumerApiDipService: ConsumerApiDipService,
    private readonly consumerApiFullAppService: ConsumerApiFullAppService,
    private readonly consumerStorageDip: ConsumerStorageDipService,
    private readonly formDipService: ConsumerFormDipService,
    private readonly navigatorService: NavigatorService,
    private readonly stepperService: StepperService,
    private readonly logger: LoggerService,
    private readonly consumerFormDipService: ConsumerFormDipService,
    private readonly mixpanelService: MixpanelService,
    private readonly consumerStorageFullApp: ConsumerStorageFullAppService,
    private readonly whatsNewAnimations: WhatsNewAnimationsService
  ) {}

  /**
   * Opens dialog on broker fees page for consumer opportunity submission
   */
  openConsumerOpportunitySubmissionDialog(wasSaved: boolean): void {
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
      ConfirmDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CONSUMER_OPPORTUNITY_SUBMISSION_DIALOG)
    );
    this.handleConsumerOpportunitySubmissionDialogSubscriptions(dialogRef, wasSaved);
  }

  /**
   * Opens DIP close case dialog
   */
  openConsumerDipCloseCaseDialog(): void {
    const dialogRef: MatDialogRef<CloseCaseDialogComponent> = this.dialog.open(
      CloseCaseDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CONSUMER_CLOSE_CASE_DIALOG)
    );
    this.handleCloseCaseDialogSubscriptions(dialogRef, false);
  }

  /**
   * Opens submit full application data.
   */
  openConsumerFullApplicationSubmission(): void {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(
        DialogTypeEnum.CONSUMER_FULL_APPLICATION_SUBMISSION_DIALOG
      )
    );
    this.handleConsumerFullApplicationSubmissionDialogSubscriptions(dialogRef);
  }

  /**
   * Opens the missing information dialog.
   */
  openConsumerDipMissingInformationDialog(): void {
    const dialogRef: MatDialogRef<MissingInformationDialogComponent> = this.dialog.open(
      MissingInformationDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CONSUMER_DIP_MISSING_INFORMATION)
    );
    this.handleConsumerDipMissingInformationDialogSubscriptions(dialogRef);
  }

  /**
   * Open DIP add product fee warning.
   */
  openConsumerDipProductFeeDialog(): Promise<boolean> {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CONSUMER_DIP_PRODUCT_FEE_WARNING)
    );
    return this.handleDialogSubscription(dialogRef);
  }

  /**
   * Open DIP add product fee warning.
   */
  openConsumerDipConfirmChangeDialog(hideCancel: boolean): Promise<boolean> {
    if (this.skipConfirmChangeDialog) {
      return Promise.resolve(true);
    }
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(
        DialogTypeEnum.CONSUMER_DIP_CONFIRM_CHANGE_DIALOG,
        hideCancel
      )
    );
    return this.handleConsumerDipConfirmChangeDialogSubscriptions(dialogRef);
  }

  /**
   * Opens the credit commitments invalid form dialog.
   */
  openCreditCommitmentsInvalidFormDialog(): Promise<boolean> {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CREDIT_COMMITMENTS_INVALID_FORM_DIALOG)
    );
    return this.handleDialogSubscription(dialogRef);
  }

  /**
   * Opens the credit commitments unsaved changes dialog.
   */
  openCreditCommitmentsUnsavedChangesDialog(): Promise<boolean> {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CREDIT_COMMITMENTS_UNSAVED_CHANGES_DIALOG)
    );
    return this.handleDialogSubscription(dialogRef);
  }

  /**
   * Opens the documents invalid form dialog.
   */
  openDocumentsInvalidFormDialog(): Promise<boolean> {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.DOCUMENTS_INVALID_FORM_DIALOG)
    );
    return this.handleDialogSubscription(dialogRef);
  }

  /**
   * Opens the documents unsaved changes dialog.
   */
  openDocumentsUnsavedChangesDialog(): Promise<boolean> {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.DOCUMENTS_UNSAVED_CHANGES_DIALOG)
    );
    return this.handleDialogSubscription(dialogRef);
  }

  /**
   * Opens the discard changes dialog.
   */
  openDiscardChangesDialog(): Promise<boolean> {
    const dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent> = this.dialog.open(
      ConfirmSubmissionDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.DISCARD_CHANGES_DIALOG)
    );
    return this.handleDialogSubscription(dialogRef);
  }

  openPropertyValuationChangeDialog(): Promise<void> {
    const dialogRef: MatDialogRef<PropertyValuationChangeDialogComponent> = this.dialog.open(
      PropertyValuationChangeDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.PROPERTY_VALUATION_CHANGE_DIALOG)
    );
    return this.handlePropertyValuationChangeDialogSubscriptions(dialogRef);
  }

  openCreditCheckExpiryDialog(isToReRunOnCcsPage = false): void {
    const dialogRef: MatDialogRef<CreditCheckExpiryDialogComponent> = this.dialog.open(
      CreditCheckExpiryDialogComponent,
      {
        ...this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.CREDIT_CHECK_EXPIRY_DIALOG),
        data: { isToReRunOnCcsPage }
      }
    );
    this.handleCreditCheckExpiryDialogSubscriptions(dialogRef);
  }

  openWhatsNewDialog(): Promise<void> {
    const dialogRef: MatDialogRef<WhatsNewDialogComponent> = this.dialog.open(
      WhatsNewDialogComponent,
      this.dialogConfigurationService.loadDialogConfiguration(DialogTypeEnum.WHATS_NEW_DIALOG)
    );
    return this.handleWhatsNewDialogSubscriptions(dialogRef);
  }

  /**
   * Handles events for close case dialog such as:
   * when dialog was closed, when close button is clicked, when submission button is clicked
   * unsubscribes when dialog gets closed
   */
  private handleCloseCaseDialogSubscriptions(
    dialogRef: MatDialogRef<CloseCaseDialogComponent>,
    isFullApp: boolean,
    id?: string
  ): void {
    let isCloseCaseDialogOpen = true;

    dialogRef.componentInstance.navigationBarBackButtonClickEvent
      .pipe(takeWhile(() => isCloseCaseDialogOpen))
      .subscribe(() => dialogRef.close());

    dialogRef.componentInstance.navigationBarNextButtonClickEvent
      .pipe(takeWhile(() => isCloseCaseDialogOpen))
      .subscribe((reason: CloseCaseModel) => {
        if (DialogService.wasDialogDestroyed(dialogRef)) return;

        DialogService.handleRequestPending(dialogRef);
        if (isFullApp) {
          this.consumerApiFullAppService
            .closeCase(id, reason)
            .then(() => {
              if (DialogService.wasDialogDestroyed(dialogRef)) return;
              DialogService.handleRequestFinishedAndClosesDialog(dialogRef);
              this.navigatorService.navigateToDashboard();
            })
            .catch((error: any) => {
              this.logger.error('Error closing the full app case.', error);
              DialogService.handleRequestError(dialogRef);
            });
        } else {
          this.consumerApiDipService
            .closeCase(reason)
            .then(() => {
              DialogService.handleRequestFinishedAndClosesDialog(dialogRef);
              this.navigatorService.navigateToDashboard();
            })
            .catch((error: any) => {
              this.logger.error('Error closing the DIP case.', error);
              DialogService.handleRequestError(dialogRef);
            });
        }
      });

    dialogRef
      .afterClosed()
      .pipe(finalize(() => (isCloseCaseDialogOpen = false)))
      .subscribe();
  }

  /**
   * Handles events for consumer opportunity submission dialog such as:
   * when dialog was closed, when close button is clicked, when submission button is clicked
   * unsubscribes when dialog gets closed
   */
  private handleConsumerOpportunitySubmissionDialogSubscriptions(
    dialogRef: MatDialogRef<ConfirmDialogComponent>,
    wasSaved: boolean
  ): void {
    let isConsumerOpportunitySubmissionDialogOpen = true;

    dialogRef.componentInstance.navigationBarBackButtonClickEvent
      .pipe(takeWhile(() => isConsumerOpportunitySubmissionDialogOpen))
      .subscribe(() => {
        if (this.consumerStorageDip.dipStage !== StageNameEnum.DIP_BROKER_FEES) {
          if (DialogService.wasDialogDestroyed(dialogRef)) return;
          DialogService.handleRequestPending(dialogRef);

          if (wasSaved) {
            this.consumerApiDipService
              .updateDipDataAndNavigateToTheAppropriatePage()
              .then(() => DialogService.handleRequestFinishedAndClosesDialog(dialogRef))
              .catch((error: any) => {
                this.logger.error('Error navigating to the appropriate page', error);
                DialogService.handleRequestError(dialogRef);
              });
          } else {
            DialogService.handleRequestFinishedAndClosesDialog(dialogRef);
          }
        } else {
          dialogRef.close();
        }
      });

    dialogRef.componentInstance.navigationBarNextButtonClickEvent
      .pipe(takeWhile(() => isConsumerOpportunitySubmissionDialogOpen))
      .subscribe(() => {
        if (DialogService.wasDialogDestroyed(dialogRef)) return;
        DialogService.handleRequestPending(dialogRef);

        const facilityDetails: FacilityDetailsModel = { ...this.consumerFormDipService.facilityDetailsFormGroup.value };
        const addProductFeesToFacility: boolean = this.consumerFormDipService.brokerFeesFormGroup.get(
          BrokerFeesControlNamesEnum.ADD_PRODUCT_FEE_TO_LOAN
        ).value;

        this.consumerApiDipService
          .calculateOffers(this.consumerStorageDip.dipId, facilityDetails, addProductFeesToFacility)
          .then(() => {
            if (DialogService.wasDialogDestroyed(dialogRef)) return;
            this.consumerApiDipService
              .updateDipDataAndNavigateToTheAppropriatePage()
              .then(() => DialogService.handleRequestFinishedAndClosesDialog(dialogRef))
              .catch((error: any) => {
                this.logger.error('Error navigating to the appropriate page', error);
                DialogService.handleRequestError(dialogRef);
              });
          })
          .catch((error: any) => {
            this.logger.error('Error calculating the opportunity offers', error);
            DialogService.handleRequestError(dialogRef);
          });
      });

    dialogRef
      .afterClosed()
      .pipe(finalize(() => (isConsumerOpportunitySubmissionDialogOpen = false)))
      .subscribe();
  }

  private handleConsumerFullApplicationSubmissionDialogSubscriptions(
    dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent>
  ): void {
    let isDialogOpen = true;

    dialogRef.componentInstance.navigationBarBackButtonClickEvent
      .pipe(takeWhile(() => isDialogOpen))
      .subscribe(() => dialogRef.close());

    dialogRef.componentInstance.navigationBarNextButtonClickEvent.pipe(takeWhile(() => isDialogOpen)).subscribe(() => {
      if (DialogService.wasDialogDestroyed(dialogRef)) return;
      DialogService.handleRequestPending(dialogRef);
      this.stepperService.stepLoading$.next([true, StageNameEnum.APPLICATION]);
      this.consumerApiFullAppService
        .submitFullApplication()
        .then((fullApp: FullApplicationDetailsModel) => {
          this.mixpanelService.trackEventWithReference(MixpanelEventsEnum.FULL_APP_COMPLETED, fullApp.reference);
          const dipData: DecisionInPrincipleDetailsModel = this.consumerStorageDip.dipData;
          dipData.decision = fullApp.decision;
          dipData.subStage = fullApp.subStage;
          dipData.stage = fullApp.stage;
          this.consumerStorageDip.dipData = dipData;
          this.formDipService.createDipFormGroup(dipData);
          this.stepperService.stepLoading$.next([false, StageNameEnum.APPLICATION]);

          DialogService.handleRequestFinishedAndClosesDialog(dialogRef);
          scrollToElementWithSelector('.details-wrapper');
        })
        .catch((error: any) => {
          this.stepperService.stepLoading$.next([false, StageNameEnum.APPLICATION]);
          this.logger.error('Error submitting the application', error);
          DialogService.handleRequestError(dialogRef);
        });
    });

    dialogRef
      .afterClosed()
      .pipe(finalize(() => (isDialogOpen = false)))
      .subscribe();
  }

  private handleConsumerDipConfirmChangeDialogSubscriptions(
    dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent>
  ): Promise<boolean> {
    return new Promise((resolve: BooleanMessage): void => {
      let isDialogOpen = true;

      dialogRef.componentInstance.navigationBarBackButtonClickEvent
        .pipe(takeWhile(() => isDialogOpen))
        .subscribe(() => {
          resolve(false);
          dialogRef.close();
        });

      dialogRef.componentInstance.navigationBarNextButtonClickEvent
        .pipe(takeWhile(() => isDialogOpen))
        .subscribe(() => {
          if (DialogService.wasDialogDestroyed(dialogRef)) return;
          dialogRef.componentInstance.nextRequestError = false;
          this.skipConfirmChangeDialog = true;
          resolve(true);
          DialogService.handleRequestFinishedAndClosesDialog(dialogRef);
        });

      dialogRef
        .afterClosed()
        .pipe(finalize(() => (isDialogOpen = false)))
        .subscribe();
    });
  }

  private handleConsumerDipMissingInformationDialogSubscriptions(
    dialogRef: MatDialogRef<MissingInformationDialogComponent>
  ): void {
    let isDialogOpen = true;

    dialogRef.componentInstance.navigationBarBackButtonClickEvent
      .pipe(takeWhile(() => isDialogOpen))
      .subscribe(() => dialogRef.close());

    dialogRef
      .afterClosed()
      .pipe(finalize(() => (isDialogOpen = false)))
      .subscribe();
  }

  private handleDialogSubscription(dialogRef: MatDialogRef<ConfirmSubmissionDialogComponent>): Promise<boolean> {
    return new Promise((resolve: BooleanMessage): void => {
      let isDialogOpen = true;

      dialogRef.componentInstance.navigationBarBackButtonClickEvent
        .pipe(takeWhile(() => isDialogOpen))
        .subscribe(() => {
          resolve(false);
          dialogRef.close();
        });

      dialogRef.componentInstance.navigationBarNextButtonClickEvent
        .pipe(takeWhile(() => isDialogOpen))
        .subscribe(() => {
          if (DialogService.wasDialogDestroyed(dialogRef)) return;
          dialogRef.componentInstance.nextRequestError = false;
          resolve(true);
          DialogService.handleRequestFinishedAndClosesDialog(dialogRef);
        });

      dialogRef
        .afterClosed()
        .pipe(finalize(() => (isDialogOpen = false)))
        .subscribe();
    });
  }

  private handlePropertyValuationChangeDialogSubscriptions(
    dialogRef: MatDialogRef<PropertyValuationChangeDialogComponent>
  ): Promise<void> {
    return new Promise((resolve: EmptyMessage) => {
      let isDialogOpen = true;

      function updateDipDataAndRedirectToDecisioningPage(
        applicationId,
        propertyValuation,
        consumerStorageDip,
        navigatorService,
        logger
      ) {
        consumerStorageDip.dipData = {
          ...consumerStorageDip.dipData,
          stage: StageNameEnum.DECISIONING,
          subStage: undefined,
          propertyDetails: { ...consumerStorageDip.dipData?.propertyDetails, estimatedValue: propertyValuation }
        };
        navigatorService
          .navigateToDipPage(applicationId, DipRouteEnum.DECISIONING, { skipFetchDipDetails: true })
          .then(() => handleClose())
          .catch((err: any) => handleError(logger, 'Error navigating to decisioning page', err));
      }

      function handleError(logger, message, object) {
        logger.error(message, object);
        dialogRef.componentInstance.isLoading = false;
        dialogRef.componentInstance.requestError = true;
      }

      function handleClose() {
        dialogRef.close();
        resolve();
      }

      dialogRef.componentInstance.submitEvent.pipe(takeWhile(() => isDialogOpen)).subscribe(() => {
        if (DialogService.wasDialogDestroyed(dialogRef)) return;
        dialogRef.componentInstance.isLoading = true;
        const applicationId = this.consumerStorageFullApp.fullAppId;
        this.consumerApiDipService
          .calculateOffers(applicationId, undefined, undefined, this.consumerStorageFullApp.propertyValuation)
          .then(() => {
            if (DialogService.wasDialogDestroyed(dialogRef)) return;
            updateDipDataAndRedirectToDecisioningPage(
              applicationId,
              this.consumerStorageFullApp.propertyValuation,
              this.consumerStorageDip,
              this.navigatorService,
              this.logger
            );
          })
          .catch((err: any) => handleError(this.logger, 'Error calculating the opportunity offers', err));
      });

      dialogRef.componentInstance.cancelEvent.pipe(takeWhile(() => isDialogOpen)).subscribe(() => handleClose());

      dialogRef
        .afterClosed()
        .pipe(finalize(() => (isDialogOpen = false)))
        .subscribe();
    });
  }

  private handleCreditCheckExpiryDialogSubscriptions(dialogRef: MatDialogRef<CreditCheckExpiryDialogComponent>): void {
    let isDialogOpen = true;

    function refreshCcsPage(stepper) {
      stepper.reloadCreditCommitmentsStepEvent$.next();
      handleClose();
    }

    function navigateToCcsPage(applicationId, stepper, navigator, logger) {
      stepper.goToStage(StageNameEnum.DIP_CREDIT_COMMITMENTS);
      navigator
        .navigateToDipPage(applicationId, DipRouteEnum.DIP)
        .then(() => handleClose())
        .catch((error) => handleError(logger, 'Error navigating to credit commitments page', error));
    }

    function handleClose() {
      dialogRef.close();
    }

    function handleError(logger, message, object) {
      logger.error(message, object);
      dialogRef.componentInstance.isLoading = false;
      dialogRef.componentInstance.requestError = true;
    }

    dialogRef.componentInstance.submitEvent.pipe(takeWhile(() => isDialogOpen)).subscribe(() => {
      if (DialogService.wasDialogDestroyed(dialogRef)) return;
      dialogRef.componentInstance.isLoading = true;

      this.consumerApiDipService
        .rerunCreditCheck(this.consumerStorageDip.dipId)
        .then(() => {
          this.consumerStorageDip.creditCommitmentsData = null;
          this.consumerStorageDip.dipData = {
            ...this.consumerStorageDip.dipData,
            stage: StageNameEnum.DIP_CREDIT_COMMITMENTS
          };
          if (dialogRef.componentInstance.isFromCCsPage) {
            refreshCcsPage(this.stepperService);
          } else {
            navigateToCcsPage(this.consumerStorageDip.dipId, this.stepperService, this.navigatorService, this.logger);
          }
        })
        .catch((error) => handleError(this.logger, 'Error re-running credit check', error));
    });

    dialogRef.componentInstance.cancelEvent.pipe(takeWhile(() => isDialogOpen)).subscribe(() => handleClose());

    dialogRef
      .afterClosed()
      .pipe(finalize(() => (isDialogOpen = false)))
      .subscribe();
  }

  private handleWhatsNewDialogSubscriptions(dialogRef: MatDialogRef<WhatsNewDialogComponent>): Promise<void> {
    return new Promise((resolve: EmptyMessage) => {
      let isDialogOpen = true;

      dialogRef.componentInstance.closeEvent.pipe(takeWhile(() => isDialogOpen)).subscribe(() => {
        this.whatsNewAnimations.animateOutWhatsNewPopup().then(() => dialogRef.close());
      });

      dialogRef
        .afterClosed()
        .pipe(finalize(() => (isDialogOpen = false)))
        .subscribe(() => resolve());
    });
  }

  private static handleRequestPending(dialogRef: MatDialogRef<any>): void {
    if (DialogService.wasDialogDestroyed(dialogRef)) return;
    dialogRef.componentInstance.nextRequestPending = true;
    dialogRef.componentInstance.nextRequestError = false;
  }

  private static handleRequestError(dialogRef: MatDialogRef<any>): void {
    if (DialogService.wasDialogDestroyed(dialogRef)) return;
    dialogRef.componentInstance.nextRequestError = true;
    dialogRef.componentInstance.nextRequestPending = false;
  }

  private static handleRequestFinishedAndClosesDialog(dialogRef: MatDialogRef<any>): void {
    if (DialogService.wasDialogDestroyed(dialogRef)) return;
    dialogRef.componentInstance.nextRequestPending = false;
    dialogRef.close();
  }

  private static wasDialogDestroyed(dialogRef: MatDialogRef<any>): boolean {
    return (
      isNull(dialogRef) ||
      isUndefined(dialogRef) ||
      isNull(dialogRef.componentInstance) ||
      isUndefined(dialogRef.componentInstance)
    );
  }
}
