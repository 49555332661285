import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { NavigationBarComponent } from './navigation-bar.component';
import { MaterialModule } from '../../../material/material.module';
import { ButtonModule } from '../../button/button.module';

@NgModule({
  declarations: [NavigationBarComponent],
  exports: [NavigationBarComponent],
  imports: [CommonModule, L10nTranslationModule, MaterialModule, ButtonModule]
})
export class NavigationBarModule {}
