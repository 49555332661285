import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { isNull } from 'lodash-es';

/**
 * Service to manage the base form.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseService {
  /**
   * Remove invalid values when is to Save&Exit
   */
  removeInvalidValues(formControl: UntypedFormGroup | UntypedFormArray): void {
    Object.values(formControl.controls).forEach((control: UntypedFormGroup | UntypedFormArray | UntypedFormControl) => {
      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.removeInvalidValues(control);
      } else if (!control.valid) {
        control.setValue(null, {
          emitModelToViewChange: false
        });
      }
    });
  }

  /**
   * Checks if all controls are valid and adds the mandatory error on fail.
   */
  protected canBeSaved(controls: AbstractControl[]): boolean {
    let canSave = true;

    for (const control of controls.filter((c) => !isNull(c))) {
      if (!control.valid) {
        control.markAsTouched();
        control.setErrors({ mandatory: true });
        canSave = false;
      }
    }

    return canSave;
  }
}
