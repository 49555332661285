import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';

import { AuthSignUpAttributesEnum } from '../../enums/auth/auth-sign-up-attributes.enum';
import { BooleanMessage } from '../../messages/boolean.message';
import { BrokerModel } from '../../models/auth/broker.model';
import { ConfirmRegistrationModel } from '../../models/auth/confirm-registration.model';
import { EmptyMessage } from '../../messages/empty.message';
import { environment } from '../../../../environments/environment';
import { ErrorResponseMessage } from '../../messages/error-response.message';
import { FcaNumberModel } from '../../models/auth/fca-number.model';
import { FcaNumberValidationModel } from '../../models/auth/fca-number-validation.model';
import { LoggerService } from '../logger/logger.service';
import { LoginModel } from '../../models/auth/login.model';
import { MixpanelService } from '../mixpanel/mixpanel.service';
import { RecoverPasswordModel } from '../../models/auth/recover-password.model';
import { RegisterModel } from '../../models/auth/register.model';
import { ResetPasswordModel } from '../../models/auth/reset-password.model';
import { StorageKeyEnum } from '../storage/enum/storage-key.enum';
import { StorageService } from '../storage/storage.service';
import { UserEmailValidationModel } from '../../models/auth/user-email-validation.model';
import { UserService } from '../user/user.service';
import { UserValidationModel } from '../user/model/user-validation.model';
import { FeatureFlagsService } from '../feature-flags/feature-flags.service';

/**
 * Service to manage AWS Cognito authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * If the user can submit the consumer flow.
   */
  get canSubmitConsumerFlow(): boolean {
    return this.storage.getValue(StorageKeyEnum.CAN_SUBMIT_CONSUMER_FLOW) !== null;
  }

  set canSubmitConsumerFlow(value: boolean) {
    this.storage.saveValue(StorageKeyEnum.CAN_SUBMIT_CONSUMER_FLOW, value ? 'true' : null);
  }

  /**
   * If the user can request admin access.
   */
  get canRequestAdminAccess(): boolean {
    return this.storage.getValue(StorageKeyEnum.CAN_REQUEST_ADMIN_ACCESS) !== null;
  }

  set canRequestAdminAccess(value: boolean) {
    this.storage.saveValue(StorageKeyEnum.CAN_REQUEST_ADMIN_ACCESS, value ? 'true' : null);
    if (!value) {
      this.isAdminViewEnabled = false;
    }
  }

  /**
   * If the user can request any broker account access.
   */
  get canRequestAnyAccountAccess(): boolean {
    return this.storage.getValue(StorageKeyEnum.CAN_REQUEST_ANY_ACCOUNT_ACCESS) !== null;
  }

  set canRequestAnyAccountAccess(value: boolean) {
    this.storage.saveValue(StorageKeyEnum.CAN_REQUEST_ANY_ACCOUNT_ACCESS, value ? 'true' : null);
    if (!value) {
      this.isAdminViewEnabled = false;
    }
  }

  /**
   * If the admin view is enabled.
   */
  get isAdminViewEnabled(): boolean {
    return this.storage.getValue(StorageKeyEnum.IS_ADMIN_VIEW_ENABLED) !== null;
  }

  set isAdminViewEnabled(value: boolean) {
    this.storage.saveValue(StorageKeyEnum.IS_ADMIN_VIEW_ENABLED, value ? 'true' : null);
  }

  /**
   * If any broker account view is enabled.
   */
  get isAnyAccountViewEnabled(): boolean {
    return this.storage.getValue(StorageKeyEnum.IS_ANY_ACCOUNT_VIEW_ENABLED) !== null;
  }

  set isAnyAccountViewEnabled(value: boolean) {
    this.storage.saveValue(StorageKeyEnum.IS_ANY_ACCOUNT_VIEW_ENABLED, value ? 'true' : null);
  }

  get isUserActive(): boolean {
    return this.storage.getValue(StorageKeyEnum.IS_USER_ACTIVE) === 'true';
  }

  set isUserActive(value: boolean) {
    this.storage.saveValue(StorageKeyEnum.IS_USER_ACTIVE, String(value));
  }

  get brokerData(): BrokerModel {
    const data: string = this.storage.getValue(StorageKeyEnum.BROKER_DATA);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  set brokerData(value: BrokerModel) {
    this.storage.saveValue(StorageKeyEnum.BROKER_DATA, value ? JSON.stringify(value) : null);
  }

  get registeredUser(): string {
    return this.storage.getValue(StorageKeyEnum.REGISTERED_USER_INFO);
  }

  set registeredUser(value: string) {
    this.storage.saveValue(StorageKeyEnum.REGISTERED_USER_INFO, value);
  }

  get fcaNumber(): string {
    return this.storage.getValue(StorageKeyEnum.SESSION_FCA_NUMBER);
  }

  set fcaNumber(value: string) {
    this.storage.saveValue(StorageKeyEnum.SESSION_FCA_NUMBER, value);
  }

  get recoverEmail(): string {
    return this.storage.getValue(StorageKeyEnum.SESSION_RECOVER_EMAIL);
  }

  set recoverEmail(value: string) {
    this.storage.saveValue(StorageKeyEnum.SESSION_RECOVER_EMAIL, value);
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly logger: LoggerService,
    private readonly userService: UserService,
    private readonly storage: StorageService,
    private readonly featureFlags: FeatureFlagsService
  ) {}

  private static get accessToken(): Promise<[string, string]> {
    return new Promise((resolve: any): void => {
      Auth.currentSession()
        .then((session: CognitoUserSession) => {
          if (session.isValid()) {
            resolve([session.getAccessToken().getJwtToken(), session.getIdToken().getJwtToken()]);
          } else {
            resolve(null);
          }
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  init(): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage): void => {
      this.isLoggedIn().then((loggedIn: boolean) => {
        if (loggedIn) {
          this.validateUserAccount().then(() => {
            resolve();
          });
        } else {
          this.canSubmitConsumerFlow = false;
          this.canRequestAdminAccess = false;
          this.canRequestAnyAccountAccess = false;
          resolve();
        }
      });
    });
  }

  /**
   * Indicates if the user is already logged in.
   */
  isLoggedIn(): Promise<boolean> {
    return new Promise((resolve: any): void => {
      Auth.currentSession()
        .then((session: CognitoUserSession) => {
          resolve(session.isValid());
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  /**
   * Gets the access token.
   */
  getAccessToken(): Promise<[string, string]> {
    return AuthService.accessToken;
  }

  async getCurrentUserData(): Promise<RegisterModel> {
    return new Promise(async (resolve: any) => {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      user.getUserAttributes((error: Error, attributes: CognitoUserAttribute[]) => {
        let model: Partial<RegisterModel> = {};
        if (!error && attributes) {
          model = {
            email: attributes.find((a: CognitoUserAttribute) => a.getName() === 'email')?.getValue(),
            firstName: attributes.find((a: CognitoUserAttribute) => a.getName() === 'given_name')?.getValue(),
            lastName: attributes.find((a: CognitoUserAttribute) => a.getName() === 'family_name')?.getValue(),
            fcaNumber: attributes.find((a: CognitoUserAttribute) => a.getName() === 'custom:fca_number')?.getValue(),
            phoneNumber: attributes.find((a: CognitoUserAttribute) => a.getName() === 'phone_number')?.getValue()
          };
        }
        resolve(model);
      });
    });
  }

  /**
   * Clean up stored data.
   */
  cleanUp(): void {
    this.registeredUser = null;
    this.fcaNumber = null;
    this.recoverEmail = null;
  }

  /**
   * Executes login request.
   */
  async login(credentials: LoginModel): Promise<[boolean, RegisterModel]> {
    try {
      await Auth.signIn(credentials.email, credentials.password).then((user: CognitoUser) =>
        user.getUserAttributes(AuthService.getUserAttributesCallback)
      );
      const model: RegisterModel = await this.getCurrentUserData();
      await this.validateUserAccount();
      this.cleanUp();
      return [true, model];
    } catch (error) {
      this.logger.log('Error signing in: ', error);
      return [false, null];
    }
  }

  /**
   * Executes logout request.
   */
  async logout(): Promise<void> {
    try {
      await Auth.signOut({ global: true });
      this.cleanUp();
      this.featureFlags.resetFeatureFlagsEnabled();
      this.storage.clearBrowserStorage();
    } catch (error) {
      this.logger.log('Error signing out: ', error);
    }
  }

  /**
   * Validate if an FCA number is registered.
   */
  validateFcaNumber(value: FcaNumberModel): Promise<boolean> {
    return new Promise<boolean>((resolve: BooleanMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post<FcaNumberValidationModel>(`${environment.consumerApiPath}/user/validateFcaNumber`, {
          fcaNumber: value.fcaNumber
        })
        .subscribe(
          (response: FcaNumberValidationModel) => resolve(response.fcaNumberExists),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Sign up a new user.
   */
  async register(value: RegisterModel): Promise<boolean> {
    const exists: boolean = await this.validateUserEmail(value.email);
    if (exists) {
      return false;
    }
    try {
      await Auth.signUp({
        username: value.email,
        password: value.password,
        attributes: {
          [AuthSignUpAttributesEnum.GIVEN_NAME]: value.firstName,
          [AuthSignUpAttributesEnum.FAMILY_NAME]: value.lastName,
          [AuthSignUpAttributesEnum.EMAIL]: value.email,
          [AuthSignUpAttributesEnum.PHONE_NUMBER]: value.phoneNumber,
          [AuthSignUpAttributesEnum.FCA_NUMBER]: value.fcaNumber,
          [AuthSignUpAttributesEnum.AGREED_TO_TERMS_AND_CONDITIONS]: value.acceptedTermsAndConditions ? 'true' : 'false'
        }
      });
      return true;
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        return false;
      }
      this.logger.log('Error signing in: ', error);
      throw error;
    }
  }

  /**
   * Confirm sign up of a new user.
   */
  async confirmRegistration(value: ConfirmRegistrationModel): Promise<boolean> {
    try {
      await Auth.confirmSignUp(value.email, value.code);
      return true;
    } catch (error) {
      this.logger.log('Error confirming registration: ', error);
      return false;
    }
  }

  /**
   * Resend the confirmation code.
   */
  async resendCode(email: string): Promise<void> {
    try {
      await Auth.resendSignUp(email);
    } catch (error) {
      this.logger.log('Error resending code: ', error);
    }
  }

  /**
   * Request a password recovery for a user.
   */
  async requestPasswordRecovery(value: RecoverPasswordModel): Promise<boolean> {
    try {
      await Auth.forgotPassword(value.email);
      return true;
    } catch (error) {
      this.logger.log('Error requesting password recovery: ', error);
      return false;
    }
  }

  /**
   * Request a password reset for a user.
   */
  async resetPassword(value: ResetPasswordModel): Promise<boolean> {
    try {
      await Auth.forgotPasswordSubmit(value.email, value.code, value.password);
      return true;
    } catch (error) {
      this.logger.log('Error setting new password: ', error);
      return false;
    }
  }

  getUserName(): string {
    const broker: BrokerModel = this.brokerData;
    if (broker) {
      return `${broker.firstName} ${broker.lastName}`;
    }
    return '-';
  }

  private async validateUserEmail(email: string): Promise<boolean> {
    return new Promise<boolean>((resolve: BooleanMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post<UserEmailValidationModel>(`${environment.consumerApiPath}/user/validateUserEmail`, {
          emailAddress: email
        })
        .subscribe(
          (response: UserEmailValidationModel) => resolve(response.userEmailExists),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  private validateUserAccount(): Promise<void> {
    return this.userService
      .validateAccount()
      .then((validation: UserValidationModel) => {
        this.logger.log('User account validated successfully.');

        this.canSubmitConsumerFlow = validation.canSubmitConsumerFlow && validation.isActive;
        this.canRequestAdminAccess = validation.isAdmin && validation.isActive;
        this.canRequestAnyAccountAccess = validation.canViewAllBrokerAccountsApplications && validation.isActive;
        this.brokerData = validation.userInfo;
        this.isUserActive = validation.isActive;
      })
      .catch((error: HttpErrorResponse) => {
        this.logger.error('User account validation has failed.', error);
        this.canSubmitConsumerFlow = false;
        this.canRequestAdminAccess = false;
        this.canRequestAnyAccountAccess = false;
      });
  }

  private static getUserAttributesCallback(
    error: Error | undefined,
    attributes: CognitoUserAttribute[] | undefined
  ): void {
    if (!error && attributes) {
      const fcaNumber: string | undefined = attributes
        .find((attribute: CognitoUserAttribute) => attribute.getName() === AuthSignUpAttributesEnum.FCA_NUMBER)
        ?.getValue();
      const email: string | undefined = attributes
        .find((attribute: CognitoUserAttribute) => attribute.getName() === AuthSignUpAttributesEnum.EMAIL)
        ?.getValue();
      MixpanelService.registerSuperProperties(email, fcaNumber);
    }
  }
}
