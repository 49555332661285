import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';

import { ApplicationDetailsComponent } from './application-details/application-details.component';
import { DashboardDocumentsComponent } from './dashboard-documents/dashboard-documents.component';
import { DashboardTableComponent } from './dashboard-table/dashboard-table.component';
import { DashboardComponent } from './dashboard.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { DashboardCreditCheckExpiryDisplayComponent } from './dashboard-table/credit-check-expiry-display/dashboard-credit-check-expiry-display.component';
import { LabelModule } from '../shared/components/label/label.module';
import { SvgModule } from '../shared/components/svg/svg.module';
import { MaterialModule } from '../shared/material/material.module';
import { NeedHelpModule } from '../shared/components/need-help/need-help.module';
import { DashboardLeftPanelComponent } from './left-panel/dashboard-left-panel.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { StateIndicatorModule } from '../shared/components/state-indicator/state-indicator.module';
import { DashboardTabComponent } from './dashboard-tab/dashboard-tab.component';
import { WhatsNewBoxComponent } from './left-panel/whats-new-box/whats-new-box.component';
import { ButtonModule } from '../shared/components/button/button.module';

/**
 * Module for dashboard.
 */
@NgModule({
  declarations: [
    DashboardComponent,
    DashboardTableComponent,
    SearchFieldComponent,
    DashboardDocumentsComponent,
    ApplicationDetailsComponent,
    DashboardCreditCheckExpiryDisplayComponent,
    DashboardLeftPanelComponent,
    DashboardTabComponent,
    WhatsNewBoxComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslationModule,
    L10nIntlModule,
    LabelModule,
    SvgModule,
    MaterialModule,
    NeedHelpModule,
    PipesModule,
    StateIndicatorModule,
    ButtonModule
  ]
})
export class DashboardModule {}
