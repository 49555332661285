import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { BaseRouteEnum } from '../../enums/route/base-route.enum';

/**
 * Guard to check if the user has access to the consumer flow.
 */
@Injectable({
  providedIn: 'root'
})
export class CanUserSubmitConsumerFlowGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly authService: AuthService) {}

  /**
   * Check if route can be activated.
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.canSubmitConsumerFlow(route?.firstChild?.firstChild?.params?.opportunityId);
  }

  private canSubmitConsumerFlow(opportunityId: string | undefined): boolean | UrlTree {
    if (opportunityId) {
      return true;
    } else {
      if (this.authService.canSubmitConsumerFlow) {
        return true;
      } else {
        return this.router.createUrlTree([BaseRouteEnum.EMPTY]);
      }
    }
  }
}
