/**
 * The form control names for the Eligibility Criteria form group.
 */
export enum PropertyDetailsControlNamesEnum {
  ADDRESS_FORM_GROUP = 'addressFormGroup',
  ESTIMATED_VALUE = 'estimatedValue',
  IS_SAME_AS_RESIDENTIAL_ADDRESS = 'isSameAsResidentialAddress',
  PURCHASE_YEAR = 'whenHasLastPurchased',
  PURCHASE_PRICE = 'purchaseValue',
  PROPERTY_TYPE = 'propertyType',
  NUMBER_OF_BEDROOMS = 'numberOfBedrooms'
}
