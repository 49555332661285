<div class='p-1'>
  <p class='title'>{{'dialog.property-valuation-change.title' | translate:lang}}</p>
  <div class='bg-white change-container d-flex justify-content-center py-3 rounded-3'>
    <div>
      <span class='valuation-label'>{{'dialog.property-valuation-change.estimated' | translate:lang}}</span>
      <span class='valuation-value'>{{ estimated | l10nNumber:lang:currencyFormat }}</span>
    </div>
    <div class='align-items-center d-flex'>
      <broker-trend-svg [isUp]='isUp' cssClasses='mx-4'></broker-trend-svg>
    </div>
    <div>
      <span class='valuation-label'>{{('dialog.property-valuation-change.' + type) | translate:lang}}</span>
      <span class='valuation-value'
            [ngClass]="isUp ? 'up' : 'down'">{{ valuation | l10nNumber:lang:currencyFormat }}</span>
    </div>
  </div>
  <p
    class='info'>{{'dialog.property-valuation-change.info' | translate:lang: { type: ('dialog.property-valuation-change.' + type | translate:lang) } }}</p>

  <div class='d-flex justify-content-between'>
    <broker-button [id]="'cancel'" [height]="'large'" [width]="'compact'" [disabled]='isLoading'
                   [style]="'outline-dark'" (clickEvent)='cancelEvent.emit()'>
      {{ 'dialog.property-valuation-change.cancel' | translate: lang }}
    </broker-button>
    <broker-button [id]="'submit'" [height]="'large'" [width]="'compact'" [disabled]='isDisabled' [loading]='isLoading'
                   [icon]="'arrow'" [iconPosition]="'right'"
                   (clickEvent)='submitEvent.emit()'>
      {{ 'dialog.property-valuation-change.submit.' + (isLoading ? 'loading' : 'default') | translate: lang }}
    </broker-button>
  </div>
  <div *ngIf='requestError' class='dialog-error d-flex p-2 pb-0'>
    <broker-warning-octagon-svg></broker-warning-octagon-svg>
    <span class='d-block fw-bold ms-2'>{{'dialog.property-valuation-change.submit.error' | translate:lang}}</span>
  </div>
</div>