import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { ConsumerStorageDipService } from '../../../consumer/storage/consumer-storage-dip.service';
import { LocalizableComponent } from '../../../localization/localizable.component';
import { MixpanelService } from '../../../services/mixpanel/mixpanel.service';
import { StepperService } from '../../../consumer/stepper/stepper.service';

/**
 * Navigation bar component for the DIP flow.
 */
@Component({
  selector: 'broker-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent extends LocalizableComponent {
  @Input() largeBar = false;
  @Input() relaxedBar = false;
  @Input() hideArrow = false;
  @Input() backButtonDisabled = false;
  @Input() backButtonHidden = false;
  @Input() backButtonLoading = false;

  get backButtonHasError(): boolean {
    return this._backButtonHasError;
  }

  @Input() set backButtonHasError(value: boolean) {
    this._backButtonHasError = value;
    if (value) {
      this.mixpanel.trackErrorMessageEvent(this.consumerStorageDip.dipStage, this.consumerStorageDip.dipReference);
    }
  }

  @Input() nextButtonDisabled = false;
  @Input() nextButtonLoading = false;

  get nextButtonHasError(): boolean {
    return this._nextButtonHasError;
  }

  @Input() set nextButtonHasError(value: boolean) {
    this._nextButtonHasError = value;
    if (value) {
      this.mixpanel.trackErrorMessageEvent(this.stepper.currentStage, this.consumerStorageDip.dipReference);
    }
  }

  @Input() lastStep = false;
  @Input() insideDialog: boolean;
  @Input() nextButtonText?: string;
  @Input() backButtonText?: string;
  @Output() readonly backButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly nextButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();

  private _backButtonHasError = false;
  private _nextButtonHasError = false;

  constructor(
    @Inject(L10N_LOCALE) locale: L10nLocale,
    private readonly mixpanel: MixpanelService,
    private readonly consumerStorageDip: ConsumerStorageDipService,
    private readonly stepper: StepperService
  ) {
    super(locale);
  }

  back(): void {
    this.backButtonClickEvent.emit();
  }

  next(): void {
    this.nextButtonClickEvent.emit();
  }
}
