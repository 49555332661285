import { NgModule } from '@angular/core';

import { ConsumerNameSurnameDirective } from './consumer-name-surname.directive';
import { ConsumerUKPostcodeFormatDirective } from './consumer-uk-postcode-directive';

@NgModule({
  declarations: [ConsumerNameSurnameDirective, ConsumerUKPostcodeFormatDirective],
  exports: [ConsumerNameSurnameDirective, ConsumerUKPostcodeFormatDirective]
})
export class DirectivesModule {}
