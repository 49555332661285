<div class='p-1'>
  <div class='row'>
    <div class='col-12 align-middle'>
      <h2 mat-dialog-title>{{ data.title }}</h2>
    </div>
  </div>

  <div class='row'>
    <div class='col-12 mt-3 mt-md-4'>
      <div class='d-flex justify-content-between'>
        <broker-label
          [for]="'closeCaseReason'"
          [label]='data.label'>
        </broker-label>
      </div>
      <mat-form-field appearance='standard'>
        <mat-select [formControl]='closeCaseReason' [placeholder]='data.dropdownPlaceholder' id='closeCaseReason'>
          <mat-option *ngFor='let option of data.dropdownCaseClosedOptions' [value]='option.value'>
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <broker-navigation-bar
    class='consumer-flow'
    [nextButtonHasError]='nextRequestError'
    [nextButtonLoading]='nextRequestPending'
    [nextButtonDisabled]='!closeCaseReason.valid'
    [backButtonDisabled]='nextRequestPending'
    [insideDialog]='true'
    [nextButtonText]='data.nextButton'
    [backButtonText]='data.backButton'
    (backButtonClickEvent)='handleNavigationBarBackButtonClickEvent()'
    (nextButtonClickEvent)='handleNavigationBarNextButtonClickEvent()'
  ></broker-navigation-bar>
</div>