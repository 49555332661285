import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../shared/services/auth/auth.service';
import { ResetPasswordModel } from '../../shared/models/auth/reset-password.model';
import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { passwordComplexityValidator, passwordNotMatchValidator, trimValue } from '../../shared/utils/input-validator';
import { LoggerService } from '../../shared/services/logger/logger.service';

@Component({
  selector: 'broker-verify-code',
  templateUrl: './verify-code.component.html'
})
export class VerifyCodeComponent extends LocalizableComponent implements OnInit {
  resetForm: UntypedFormGroup;

  disabled = false;

  resending = false;

  private email: string;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly logger: LoggerService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  get codeControl(): UntypedFormControl {
    return this.resetForm.get('code') as UntypedFormControl;
  }

  get passwordControl(): UntypedFormControl {
    return this.resetForm.get('password') as UntypedFormControl;
  }

  get confirmPasswordControl(): UntypedFormControl {
    return this.resetForm.get('confirmPassword') as UntypedFormControl;
  }

  ngOnInit(): void {
    if (!this.authService.recoverEmail?.length) {
      this.router.navigate(['/recover']).catch((error) => this.logger.error('Error navigating to Recover', error));
    } else {
      this.email = this.authService.recoverEmail;
      this.buildForm();
    }
  }

  /**
   * Trims the form control value.
   */
  trimValue(formControl: UntypedFormControl): void {
    trimValue(formControl);
  }

  /**
   * Request new password recovery code.
   */
  resendCode(): void {
    if (this.resending) {
      return;
    }
    this.resending = true;
    this.authService
      .requestPasswordRecovery({
        email: this.email
      })
      .then(() => {
        this.resending = false;
      });
  }

  /**
   * Reset the password.
   */
  resetPassword(): void {
    this.disabled = true;
    this.resetForm.setErrors(null);

    const value: ResetPasswordModel = {
      ...this.resetForm.value,
      email: this.email
    };
    this.authService.resetPassword(value).then((result: boolean) => {
      if (result) {
        this.authService.recoverEmail = null;
        this.authService
          .login({
            email: this.email,
            password: this.passwordControl.value
          })
          .then(() => {
            this.router.navigate(['']).catch((error) => this.logger.error('Error navigating to root', error));
          });
      } else {
        this.disabled = false;
        this.resetForm.setErrors({
          invalid: true
        });
      }
    });
  }

  private buildForm(): void {
    this.resetForm = this.formBuilder.group(
      {
        code: ['', [Validators.required, Validators.maxLength(16)]],
        password: ['', [Validators.required, Validators.maxLength(255), passwordComplexityValidator]],
        confirmPassword: ['', [Validators.required, Validators.maxLength(255)]]
      },
      {
        validators: [passwordNotMatchValidator(['password', 'confirmPassword'])]
      }
    );
  }
}
