import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

import { L10nTranslationModule } from 'angular-l10n';

import { SvgModule } from '../svg/svg.module';
import { ConsumerStepperComponent } from './consumer-stepper.component';
import { StepModule } from './step/step.module';

@NgModule({
  declarations: [ConsumerStepperComponent],
  imports: [CommonModule, L10nTranslationModule, SvgModule, MatDividerModule, StepModule],
  exports: [ConsumerStepperComponent]
})
export class ConsumerStepperModule {}
