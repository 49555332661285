/**
 * The employment status enum.
 */
export enum EmploymentStatusEnum {
  EMPLOYED = 'Employed',
  NOT_IN_PAID_EMPLOYMENT = 'Not in paid employment',
  RETIRED = 'Retired',
  SELF_EMPLOYED_CONTRACTOR = 'Self-employed (contractor)',
  SELF_EMPLOYED_LIMITED_COMPANY = 'Self-employed (limited company)',
  SELF_EMPLOYED_SOLE_TRADER_PARTNERSHIP = 'Self-employed (sole trader / partnership)'
}
