import { Injectable } from '@angular/core';

import { L10nLocale, L10nStorage } from 'angular-l10n';

import { EN_GB_LOCALE } from './supported-languages';

/**
 * Localization library storage engine.
 */
@Injectable()
export class LocalizationStorage implements L10nStorage {
  /**
   * Reads the locale value from the session storage.
   * @return the read value.
   */
  async read(): Promise<L10nLocale | null> {
    return Promise.resolve(EN_GB_LOCALE());
  }

  /**
   * Writes the locale value to the session storage.
   * @param _locale the locale to store.
   * @return an empty promise.
   */
  async write(_locale: L10nLocale): Promise<void> {
    // Intentionally empty
  }
}
