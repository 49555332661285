/**
 * Reasons for closing the case.
 */
export enum CloseReasonEnum {
  UNRESPONSIVE_NO_REASON_GIVEN = 'Unresponsive/ no reason given',
  FUNDING_NO_LONGER_NEEDED = 'Funding no longer needed',
  LOST_TO_COMPETITOR = 'Lost to competitor',
  ONLY_NEEDED_QUOTE = 'Only needed quote',
  PROCESS_TOO_LONG_OR_COMPLEX = 'Process too long or complex',
  UNWILLING_UNABLE_TO_PROVIDE_DOCS = 'Unwilling / unable to provide docs',
  RESTARTING_NEW_DETAILS = 'Restarting new details',
  RATES_TOO_HIGH = 'Rates too high',
  FEES_TO_HIGH = 'Fees too high',
  ISSUE_WITH_SECURED_ASPECT_OF_THE_LOAN = 'Issue with secured aspect of the loan',
  OTHER = 'Other'
}
