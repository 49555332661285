import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../shared/services/auth/auth.service';
import { RecoverPasswordModel } from '../../shared/models/auth/recover-password.model';
import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { trimValue } from '../../shared/utils/input-validator';
import { LoggerService } from '../../shared/services/logger/logger.service';

@Component({
  selector: 'broker-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent extends LocalizableComponent implements OnInit {
  recoverForm: UntypedFormGroup;

  disabled = false;

  currentYear: number = new Date().getFullYear();

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly logger: LoggerService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  get emailControl(): UntypedFormControl {
    return this.recoverForm.get('email') as UntypedFormControl;
  }

  /**
   * Initialize the control.
   */
  ngOnInit(): void {
    this.authService.cleanUp();
    this.buildForm();
  }

  /**
   * Trims the form control value.
   */
  trimValue(formControl: UntypedFormControl): void {
    trimValue(formControl);
  }

  /**
   * Request a password recovery.
   */
  recover(): void {
    this.disabled = true;
    this.recoverForm.setErrors(null);
    this.authService.requestPasswordRecovery(this.recoverForm.value).then((result: boolean) => {
      this.disabled = false;
      if (result) {
        this.authService.recoverEmail = (this.recoverForm.value as RecoverPasswordModel).email;
        this.router
          .navigate(['/auth/verify'])
          .catch((error) => this.logger.error('Error navigating to Auth Verify', error));
      } else {
        this.recoverForm.setErrors({
          generic: true
        });
      }
    });
  }

  private buildForm(): void {
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
}
