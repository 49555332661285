export function trimValue(value: string): string {
  return value?.trim().length ? value : null;
}

export function toNumber(input: any): number {
  const value: any = typeof input === 'string' ? trimValue(input) : input;

  if (value !== null && value !== undefined) {
    return +value;
  }

  return null;
}
