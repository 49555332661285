import { Component, Inject, Input } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../localization/localizable.component';

export type StateType = 'accept' | 'refer' | 'decline' | 'error';

@Component({
  selector: 'broker-state-indicator',
  templateUrl: './state-indicator.component.html',
  styleUrls: ['./state-indicator.component.scss']
})
export class StateIndicatorComponent extends LocalizableComponent {
  @Input() state: StateType = 'accept';
  @Input() cssClasses = '';

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }
}
