import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingSvgComponent } from './loading/loading-svg.component';
import { TrendSvgComponent } from './trend/trend-svg.component';
import { TooltipSvgComponent } from './tooltip/tooltip-svg.component';
import { WarningOctagonSvgComponent } from './warning-octagon/warning-octagon-svg.component';
import { CheckFilledSvgComponent } from './check/filled/check-filled-svg.component';
import { DeclineSvgComponent } from './decline/decline-svg.component';
import { WarningSvgComponent } from './warning/warning-svg.component';
import { PhoneSvgComponent } from './phone/phone-svg.component';
import { ExclamationSvgComponent } from './exclamation/exclamation-svg.component';
import { CheckOutlineSvgComponent } from './check/outline/check-outline-svg.component';
import { LockStepSvgComponent } from './lock/step/lock-step-svg.component';
import { TrendOutlineSvgComponent } from './trend/outline/trend-outline-svg.component';
import { ArrowSvgComponent } from './arrow/arrow-svg.component';
import { PencilSvgComponent } from './pencil/pencil-svg.component';
import { CheckSvgComponent } from './check/check-svg.component';
import { SearchSvgComponent } from './search/search-svg.component';
import { PickerSvgComponent } from './picker/picker-svg.component';
import { SelinaLogoSvgComponent } from './selina-logo/selina-logo-svg.component';
import { LogoutSvgComponent } from './logout/logout-svg.component';
import { LockSvgComponent } from './lock/lock-svg.component';

@NgModule({
  declarations: [
    LoadingSvgComponent,
    TrendSvgComponent,
    TooltipSvgComponent,
    WarningOctagonSvgComponent,
    WarningOctagonSvgComponent,
    CheckFilledSvgComponent,
    DeclineSvgComponent,
    WarningSvgComponent,
    PhoneSvgComponent,
    ExclamationSvgComponent,
    CheckOutlineSvgComponent,
    LockStepSvgComponent,
    TrendOutlineSvgComponent,
    ArrowSvgComponent,
    PencilSvgComponent,
    CheckSvgComponent,
    SearchSvgComponent,
    PickerSvgComponent,
    SelinaLogoSvgComponent,
    LogoutSvgComponent,
    LockSvgComponent
  ],
  imports: [CommonModule],
  exports: [
    LoadingSvgComponent,
    TrendSvgComponent,
    TooltipSvgComponent,
    WarningOctagonSvgComponent,
    WarningOctagonSvgComponent,
    CheckFilledSvgComponent,
    DeclineSvgComponent,
    WarningSvgComponent,
    PhoneSvgComponent,
    ExclamationSvgComponent,
    CheckOutlineSvgComponent,
    LockStepSvgComponent,
    TrendOutlineSvgComponent,
    ArrowSvgComponent,
    PencilSvgComponent,
    CheckSvgComponent,
    SearchSvgComponent,
    PickerSvgComponent,
    SelinaLogoSvgComponent,
    LogoutSvgComponent,
    LockSvgComponent
  ]
})
export class SvgModule {}
