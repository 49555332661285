import { Injectable } from '@angular/core';
import { EmptyMessage } from '../../messages/empty.message';

const ANIMATION_DURATION = 500;

enum WhatsNewElements {
  WHATS_NEW_BOX = 'whats-new-box',
  WHATS_NEW_POPUP = 'whats-new-container'
}

enum WhatsNewAnimations {
  DASHBOARD_BOX_SLIDE_IN = 'whats-new-dashboard-box-slide-in-animation',
  DASHBOARD_BOX_SLIDE_OUT = 'whats-new-dashboard-box-slide-out-animation',
  POPUP_CARD_SLIDE_IN = 'whats-new-card-slide-previous',
  POPUP_CARD_SLIDE_OUT = 'whats-new-card-slide-next',
  POPUP_CARD_BOUNCE = 'whats-new-card-bounce-back'
}

@Injectable({
  providedIn: 'root'
})
export class WhatsNewAnimationsService {
  animateOutWhatsNewBox(): Promise<void> {
    return new Promise((resolve: EmptyMessage) => {
      const whatsNewBoxElement = document.getElementsByClassName(WhatsNewElements.WHATS_NEW_BOX)[0];
      if (whatsNewBoxElement) {
        whatsNewBoxElement.classList.remove(WhatsNewAnimations.DASHBOARD_BOX_SLIDE_IN);
        whatsNewBoxElement.classList.add(WhatsNewAnimations.DASHBOARD_BOX_SLIDE_OUT);
        setTimeout(() => resolve(), ANIMATION_DURATION);
      } else {
        return resolve();
      }
    });
  }

  animateOutWhatsNewPopup(): Promise<void> {
    return new Promise((resolve: EmptyMessage) => {
      Array.from(document.getElementsByClassName(WhatsNewElements.WHATS_NEW_POPUP)).forEach((element) => {
        element.classList.remove(
          WhatsNewAnimations.POPUP_CARD_SLIDE_OUT,
          WhatsNewAnimations.POPUP_CARD_SLIDE_IN,
          WhatsNewAnimations.POPUP_CARD_BOUNCE
        );
        element.classList.add(WhatsNewAnimations.POPUP_CARD_SLIDE_OUT);
      });
      setTimeout(() => resolve(), ANIMATION_DURATION);
    });
  }

  animateOutWhatsNewPopupCard(card: number): void {
    const whatsNewChildren = Array.from(document.getElementsByClassName(WhatsNewElements.WHATS_NEW_POPUP));
    whatsNewChildren.forEach((child) => child.classList.remove(WhatsNewAnimations.POPUP_CARD_BOUNCE));

    setTimeout(() => {
      whatsNewChildren[card].classList.remove(WhatsNewAnimations.POPUP_CARD_SLIDE_OUT);
      whatsNewChildren[card].classList.add(WhatsNewAnimations.POPUP_CARD_SLIDE_IN);

      whatsNewChildren.forEach((child, index) => {
        if (index < card) {
          child.classList.add(WhatsNewAnimations.POPUP_CARD_BOUNCE);
        }
      });
    }, 0);
  }

  animateInWhatsNewPopupCard(card: number): void {
    const whatsNewChildren = Array.from(document.getElementsByClassName(WhatsNewElements.WHATS_NEW_POPUP));
    whatsNewChildren.forEach((child) => {
      child.classList.remove(WhatsNewAnimations.POPUP_CARD_BOUNCE, WhatsNewAnimations.POPUP_CARD_SLIDE_IN);
    });

    setTimeout(() => {
      whatsNewChildren[card - 1].classList.remove(WhatsNewAnimations.POPUP_CARD_SLIDE_IN);
      whatsNewChildren[card - 1].classList.add(WhatsNewAnimations.POPUP_CARD_SLIDE_OUT);

      whatsNewChildren.forEach((child, index) => {
        if (index < card - 1) {
          child.classList.add(WhatsNewAnimations.POPUP_CARD_BOUNCE);
        }
      });
    }, 0);
  }
}
