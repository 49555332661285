import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { NgxMaskModule } from 'ngx-mask';

import { ConsumerAddressComponent } from './address/consumer-address.component';
import { ConsumerBreadcrumbComponent } from './breadcrumb/consumer-breadcrumb.component';
import { ConsumerBrokerFeesComponent } from './broker-fees/broker-fees.component';
import { ClearComponent } from './clear/clear.component';
import { ConsumerDetailsFooterComponent } from './details/footer/consumer-details-footer.component';
import { ConsumerDetailsSaveAndContinueComponent } from './details/save-continue/consumer-details-save-and-continue.component';
import { ConsumerErrorComponent } from './error/consumer-error.component';
import { InfoPanelComponent } from './info-panel/info-panel.component';
import { InformationContainerComponent } from './information-container/information-container.component';
import { ConsumerLoadingComponent } from './loading/consumer-loading.component';
import { ConsumerNavbarComponent } from './navbar/consumer-navbar.component';
import { OffersExpansionPanelComponent } from './offers-expansion-panel/offers-expansion-panel.component';
import { DialogsModule } from './dialogs/dialogs.module';
import { MaterialModule } from '../../material/material.module';
import { ReferInfoBoxModule } from '../refer-info-box/refer-info-box.module';
import { StateIndicatorModule } from '../state-indicator/state-indicator.module';
import { LabelModule } from '../label/label.module';
import { RouterModule } from '@angular/router';
import { SvgModule } from '../svg/svg.module';
import { ButtonModule } from '../button/button.module';
import { YesNoButtonsModule } from '../yes-no-buttons/yes-no-buttons.module';

@NgModule({
  declarations: [
    ConsumerAddressComponent,
    ConsumerBreadcrumbComponent,
    ConsumerBrokerFeesComponent,
    ClearComponent,
    ConsumerDetailsFooterComponent,
    ConsumerDetailsSaveAndContinueComponent,
    ConsumerErrorComponent,
    InfoPanelComponent,
    InformationContainerComponent,
    ConsumerLoadingComponent,
    ConsumerNavbarComponent,
    OffersExpansionPanelComponent
  ],
  imports: [
    CommonModule,
    L10nTranslationModule,
    DialogsModule,
    MaterialModule,
    L10nIntlModule,
    ReferInfoBoxModule,
    StateIndicatorModule,
    ReactiveFormsModule,
    NgxMaskModule,
    LabelModule,
    RouterModule,
    SvgModule,
    ButtonModule,
    YesNoButtonsModule
  ],
  exports: [
    ConsumerAddressComponent,
    ConsumerBreadcrumbComponent,
    ConsumerBrokerFeesComponent,
    ClearComponent,
    ConsumerDetailsFooterComponent,
    ConsumerDetailsSaveAndContinueComponent,
    ConsumerErrorComponent,
    InfoPanelComponent,
    InformationContainerComponent,
    ConsumerLoadingComponent,
    ConsumerNavbarComponent,
    OffersExpansionPanelComponent,
    DialogsModule,
    L10nIntlModule,
    StateIndicatorModule
  ]
})
export class ConsumerModule {}
