import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';

import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';

import { ApplicantControlNamesEnum } from '../../../consumer/dip/form/applicant/enum/applicant-control-names.enum';
import { ApplicantIncomeControlNamesEnum } from '../../../consumer/dip/form/applicant/income-sources/enum/applicant-income-control-names.enum';
import { BrokerFeesControlNamesEnum } from '../../../consumer/dip/form/broker-fees/enum/broker-fees-form-control-names.enum';
import { CreditCommitmentsControlNamesEnum } from '../../../consumer/dip/form/credit-commitments/enum/credit-commitments-control-names.enum';
import { EligibilityCriteriaControlNamesEnum } from '../../../consumer/dip/form/eligibility/enum/eligibility-criteria-control-names.enum';
import { ExpenditureControlNamesEnum } from '../../../consumer/dip/form/expenditure/enum/expenditure-control-names.enum';
import { FacilityDetailsControlNamesEnum } from '../../../consumer/dip/form/facility-details/enum/facility-details-control-names.enum';
import { PropertyDetailsControlNamesEnum } from '../../../consumer/dip/form/property-details/enum/property-details-control-names.enum';
import { AddressModel } from '../../services/address/model/address.model';
import { AddressControlNamesEnum } from '../../components/consumer/address/enum/address-control-names.enum';
import { toLocalDate } from '../../utils/date.util';
import {
  facilityRequiredValidator,
  interestOnlyOfPartAndPartValidator,
  interestOnlyOfPartAndPartValueValidator,
  loanAmountSumValidator,
  settlementDateValidator,
  validateMinimumDate
} from '../../utils/input-validator';
import { getValidations } from '../../utils/validation-builder';
import { COMMON_VALIDATION } from '../../validations/common.validation';
import { DIP_VALIDATION } from '../../validations/dip.validation';
import { BaseService } from '../base.service';
import { ConsumerStorageDipService } from '../storage/consumer-storage-dip.service';
import { DipFormControlNamesEnum } from './enum/dip-form-control-names.enum';
import { EmploymentStatusEnum } from './enum/employment-status.enum';
import { IncomeSourceTypeEnum } from './enum/income-source-type.enum';
import { InterestRateTypeEnum } from './enum/interest-rate-type.enum';
import { RepaymentTypeEnum } from './enum/repayment-type.enum';
import { RepaymentVehicleEnum } from './enum/repayment-vehicle.enum';
import { ApplicantModel } from './model/applicant.model';
import { BrokerFeesDetailsModel } from './model/broker-fees-details.model';
import { ContractIncomeModel } from './model/contract-income.model';
import { DecisionInPrincipleDetailsModel } from './model/decision-in-principle-details.model';
import { EmploymentModel } from './model/employment.model';
import { FacilityAllocationModel } from './model/facility-allocation.model';
import { FacilityDetailsModel } from './model/facility-details.model';
import { IncomeSourceModel } from './model/income-source.model';
import { IncomeModel } from './model/income.model';
import { IntermediaryModel } from './model/intermediary.model';
import { PreviousAddressModel } from './model/previous-address.model';
import { PreviousNameModel } from './model/previous-name.model';
import { PropertyDetailsModel } from './model/property-details.model';
import {
  CcjViewModel,
  CreditCommitmentsViewModel,
  SecuredCreditCommitmentViewModel,
  UnsecuredCreditCommitmentViewModel
} from './model/view-model/credit-commitments-details.view-model';
import { DipFormViewModel } from './model/view-model/dip-form-view.model';
import { HouseholdExpenditureViewModel } from './model/view-model/expenditure-details.view-model';
import { IsNonEditableStage } from '../../utils/stage.utils';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags.service';
import { FeatureFlagsEnum } from '../../enums/feature-flags/feature-flags.enum';

export interface SaveEvent {
  canSave(can: boolean): void;

  action(): void;
}

/**
 * Service to manage the DIP form group.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsumerFormDipService extends BaseService {
  // ELIGIBILITY CRITERIA SECTION
  /**
   * Gets the Eligibility Criteria form group.
   */
  get eligibilityCriteriaFormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.ELIGIBILITY_CRITERIA) as UntypedFormGroup;
  }

  /**
   * Gets the Facility Details form group.
   */
  get facilityDetailsFormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.FACILITY_DETAILS) as UntypedFormGroup;
  }

  /**
   * Gets the Credit Commitments form group.
   */
  get creditCommitmentsFormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.CREDIT_COMMITMENTS) as UntypedFormGroup;
  }

  /**
   * Gets the Credit Commitments form group.
   */
  get expenditureFormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.EXPENDITURE) as UntypedFormGroup;
  }

  /**
   * Gets the Broker Fees form group.
   */
  get brokerFeesFormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.BROKER_FEES) as UntypedFormGroup;
  }

  /**
   * Gets the Property Details form group.
   */
  get propertyDetailsFormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.PROPERTY_DETAILS) as UntypedFormGroup;
  }

  /**
   * Gets the Applicant 1 form group.
   */
  get applicant1FormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.APPLICANT1) as UntypedFormGroup;
  }

  /**
   * Gets the Applicant 2 form group.
   */
  get applicant2FormGroup(): UntypedFormGroup {
    return this.dipFormGroup.get(DipFormControlNamesEnum.APPLICANT2) as UntypedFormGroup;
  }

  /**
   * Returns saved form value.
   */
  get savedFormValue(): Partial<DipFormViewModel> {
    return this._savedFormGroup.value;
  }

  readonly saveMandatorySubject: Subject<void> = new Subject<void>();

  readonly saveValidationSubject: Subject<void> = new Subject<void>();

  readonly handleValidationErrors: Subject<void> = new Subject<void>();

  readonly canProceedWithSave: Subject<SaveEvent> = new Subject<SaveEvent>();

  private dipFormGroup: UntypedFormGroup;

  private _savedFormGroup: UntypedFormGroup;

  private savedDipData?: DecisionInPrincipleDetailsModel;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly storageService: ConsumerStorageDipService,
    private readonly featureFlags: FeatureFlagsService
  ) {
    super();
  }

  // ------------------------------ FORM GROUP -----------------------------------------

  /**
   * Returns if the saved form is valid.
   */
  savedFormGroupIsValid(): boolean {
    const numberOfApplicants: number = this.savedFormValue.loanInformation?.numberOfApplicants ?? 1;

    return (
      this.storageService.creditCommitmentsData !== null &&
      this.getSavedFormGroup(DipFormControlNamesEnum.ELIGIBILITY_CRITERIA)?.valid &&
      this.getSavedFormGroup(DipFormControlNamesEnum.FACILITY_DETAILS)?.valid &&
      this.getSavedFormGroup(DipFormControlNamesEnum.PROPERTY_DETAILS)?.valid &&
      this.getSavedFormGroup(DipFormControlNamesEnum.APPLICANT1)?.valid &&
      (numberOfApplicants === 1 || this.getSavedFormGroup(DipFormControlNamesEnum.APPLICANT2)?.valid) &&
      this.getSavedFormGroup(DipFormControlNamesEnum.CREDIT_COMMITMENTS)?.valid &&
      this.getSavedFormGroup(DipFormControlNamesEnum.BROKER_FEES)?.valid
    );
  }

  /**
   * Returns saved form value.
   */
  getSavedFormGroup(formGroupName: DipFormControlNamesEnum): UntypedFormGroup {
    return this._savedFormGroup?.get(formGroupName) as UntypedFormGroup;
  }

  /**
   * If the property details form can be saved.
   */
  canSavePropertyDetails(): boolean {
    const addressGroup: AbstractControl = this.propertyDetailsFormGroup.get(
      PropertyDetailsControlNamesEnum.ADDRESS_FORM_GROUP
    );

    if (!addressGroup) {
      return true;
    }

    if (
      !addressGroup.get(AddressControlNamesEnum.POSTCODE).value &&
      !addressGroup.get(AddressControlNamesEnum.ADDRESS_LINE_1).value &&
      !addressGroup.get(AddressControlNamesEnum.ADDRESS_LINE_2).value &&
      !addressGroup.get(AddressControlNamesEnum.CITY).value
    ) {
      return true;
    }

    return this.canBeSaved([
      addressGroup.get(AddressControlNamesEnum.ADDRESS_LINE_1),
      addressGroup.get(AddressControlNamesEnum.CITY)
    ]);
  }

  /**
   * If the property details form can be saved.
   */
  canSaveApplicant(applicant: number): boolean {
    const formGroup: UntypedFormGroup = applicant === 2 ? this.applicant2FormGroup : this.applicant1FormGroup;

    return this.canBeSaved([
      formGroup.get(ApplicantControlNamesEnum.FIRST_NAME),
      formGroup.get(ApplicantControlNamesEnum.SURNAME),
      formGroup.get(ApplicantControlNamesEnum.ADDRESS_FORM_GROUP)
    ]);
  }

  canSaveCreditCommitments(): boolean {
    const controls: UntypedFormControl[] = [
      ...this.getMandatoryControls(CreditCommitmentsControlNamesEnum.SECURED_CREDIT_COMMITMENTS).filter(
        (c: UntypedFormControl) => !!c
      ),
      ...this.getMandatoryControls(CreditCommitmentsControlNamesEnum.UNSECURED_CREDIT_COMMITMENTS).filter(
        (c: UntypedFormControl) => !!c
      )
    ];
    return this.canBeSaved([...controls]);
  }

  /**
   * Updates the current saved data.
   */
  updateSavedData(): void {
    this._savedFormGroup = cloneDeep(this.dipFormGroup);
  }

  /**
   * Create DIP form group.
   */
  createDipFormGroup(data?: DecisionInPrincipleDetailsModel): void {
    this.savedDipData = data;
    this.dipFormGroup = this.formBuilder.group({
      [DipFormControlNamesEnum.ELIGIBILITY_CRITERIA]: this.createEligibilityCriteriaFormGroup(!!data?.loanInformation),
      [DipFormControlNamesEnum.FACILITY_DETAILS]: this.createFacilityDetailsFormGroup(data?.loanInformation),
      [DipFormControlNamesEnum.CREDIT_COMMITMENTS]: this.createCreditCommitmentsFormGroup(data?.dipCreditCommitments),
      [DipFormControlNamesEnum.EXPENDITURE]: this.createHouseholdExpendituresFormGroup(
        data?.expenditures,
        this.hasRentalIncome(data)
      ),
      [DipFormControlNamesEnum.BROKER_FEES]: this.createBrokerFeesFormGroup(
        data?.brokerFees,
        data?.loanInformation?.isUserTheAdvisingBroker
      ),
      [DipFormControlNamesEnum.PROPERTY_DETAILS]: this.createPropertyDetailsFormGroup(data?.propertyDetails),
      [DipFormControlNamesEnum.APPLICANT1]: this.createApplicantFormGroup(1, data?.applicant1),
      [DipFormControlNamesEnum.APPLICANT2]: this.createApplicantFormGroup(2, data?.applicant2)
    });

    this.updateSavedData();

    if (IsNonEditableStage(data?.stage)) {
      this.dipFormGroup.disable();
      this.disableControls(this.dipFormGroup);
    }
  }

  restoreFacilityDetailsForm(): void {
    this.dipFormGroup.setControl(
      DipFormControlNamesEnum.FACILITY_DETAILS,
      this.createFacilityDetailsFormGroup(this.savedDipData?.loanInformation)
    );
  }

  restoreApplicantDetailsForm(applicantNumber: number): void {
    if (applicantNumber === 1) {
      this.dipFormGroup.setControl(
        DipFormControlNamesEnum.APPLICANT1,
        this.createApplicantFormGroup(1, this.savedDipData?.applicant1)
      );
    } else {
      this.dipFormGroup.setControl(
        DipFormControlNamesEnum.APPLICANT2,
        this.createApplicantFormGroup(2, this.savedDipData?.applicant2)
      );
    }
  }

  restorePropertyDetailsForm(): void {
    this.dipFormGroup.setControl(
      DipFormControlNamesEnum.PROPERTY_DETAILS,
      this.createPropertyDetailsFormGroup(this.savedDipData?.propertyDetails)
    );
  }

  restoreCreditCommitmentsForm(): void {
    this.dipFormGroup.setControl(
      DipFormControlNamesEnum.CREDIT_COMMITMENTS,
      this.createCreditCommitmentsFormGroup(this.savedDipData?.dipCreditCommitments)
    );
  }

  restoreExpenditureForm(): void {
    this.dipFormGroup.setControl(
      DipFormControlNamesEnum.EXPENDITURE,
      this.createHouseholdExpendituresFormGroup(
        this.savedDipData?.expenditures,
        this.hasRentalIncome(this.savedDipData)
      )
    );
  }

  restoreBrokerFeesForm(): void {
    this.dipFormGroup.setControl(
      DipFormControlNamesEnum.BROKER_FEES,
      this.createBrokerFeesFormGroup(
        this.savedDipData?.brokerFees,
        this.savedDipData?.loanInformation?.isUserTheAdvisingBroker
      )
    );
  }

  /**
   * Create Broker Fees form group.
   */
  createBrokerFeesFormGroup(
    brokerFeesDetails?: BrokerFeesDetailsModel,
    isUserTheAdvisingBroker = true
  ): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [BrokerFeesControlNamesEnum.ADD_PRODUCT_FEE_TO_LOAN]: [
        brokerFeesDetails?.addProductFeeToLoan ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addProductFeeToLoan)
      ],
      [BrokerFeesControlNamesEnum.COMMISSION_FEE]: [
        brokerFeesDetails?.commissionFee?.toString(),
        getValidations(DIP_VALIDATION.brokerFees.commissionFee)
      ],
      [BrokerFeesControlNamesEnum.ARRANGEMENT_FEE]: [
        brokerFeesDetails?.arrangementFee?.toString(),
        getValidations(DIP_VALIDATION.brokerFees.arrangementFee)
      ],
      [BrokerFeesControlNamesEnum.VALUATION_FEE]: [
        brokerFeesDetails?.valuationFee?.toString(),
        getValidations(DIP_VALIDATION.brokerFees.valuationFee)
      ],
      [BrokerFeesControlNamesEnum.THIRD_PARTY_FEE]: [
        brokerFeesDetails?.thirdPartyFee?.toString(),
        getValidations(DIP_VALIDATION.brokerFees.thirdPartyFee)
      ],
      [BrokerFeesControlNamesEnum.ADD_COMMISSION_FEE_TO_LOAN]: [
        brokerFeesDetails?.addCommissionFeeToLoan ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addCommissionFeeToLoan)
      ],
      [BrokerFeesControlNamesEnum.ADD_ARRANGEMENT_FEE_TO_LOAN]: [
        brokerFeesDetails?.addArrangementFeeToLoan ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addArrangementFeeToLoan)
      ],
      [BrokerFeesControlNamesEnum.ADD_VALUATION_FEE_TO_LOAN]: [
        brokerFeesDetails?.addValuationFeeToLoan ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addValuationFeeToLoan)
      ],
      [BrokerFeesControlNamesEnum.ADD_THIRD_PARTY_FEE_TO_LOAN]: [
        brokerFeesDetails?.addThirdPartyFeeToLoan ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addThirdPartyFeeToLoan)
      ],
      [BrokerFeesControlNamesEnum.ADD_ARRANGEMENT_FEE_SELINA]: [
        brokerFeesDetails?.addArrangementFeeSelina ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addArrangementFeeSelina)
      ],
      [BrokerFeesControlNamesEnum.ADD_ARRANGEMENT_FEE_SELINA_TO_LOAN]: [
        brokerFeesDetails?.addArrangementFeeSelinaToLoan ?? false,
        getValidations(DIP_VALIDATION.brokerFees.addArrangementFeeSelinaToLoan)
      ]
    });
    if (!isUserTheAdvisingBroker) {
      this.createIntermediaryFeesFormGroup(formGroup, brokerFeesDetails);
    }
    return formGroup;
  }

  createIntermediaryFeesFormGroup(formGroup: UntypedFormGroup, brokerFeesDetails: BrokerFeesDetailsModel): void {
    formGroup.addControl(
      BrokerFeesControlNamesEnum.INTERMEDIARY_SECTION,
      this.formBuilder.group({
        [BrokerFeesControlNamesEnum.INTERMEDIARY_ADVICE_FEE]: [
          brokerFeesDetails?.intermediary?.adviceFee,
          getValidations(DIP_VALIDATION.brokerFees.intermediary.adviceFee)
        ],
        [BrokerFeesControlNamesEnum.INTERMEDIARY_ADD_ADVICE_FEE_TO_LOAN]: [
          brokerFeesDetails?.intermediary?.addAdviceFeeToLoan ?? false,
          getValidations(DIP_VALIDATION.brokerFees.intermediary.addAdviceFeeToLoan)
        ]
      })
    );
  }

  /**
   * Create Loan Information form controls.
   */
  createLoanInformationControls(
    facilityDetails?: FacilityDetailsModel
  ): { requestedLoanAmount: (string | ValidatorFn[])[]; requestedLoanTerm: (string | ValidatorFn[])[] } {
    return {
      [FacilityDetailsControlNamesEnum.LOAN_AMOUNT_REQUESTED]: [
        facilityDetails?.requestedLoanAmount?.toString(),
        getValidations(DIP_VALIDATION.facilityDetails.requestedLoanAmount)
      ],
      [FacilityDetailsControlNamesEnum.LOAN_TERM_REQUESTED]: [
        facilityDetails?.requestedLoanTerm?.toString(),
        getValidations(DIP_VALIDATION.facilityDetails.requestedLoanTerm)
      ]
    };
  }

  /**
   * Creates the previous name form group for the applicant section.
   */
  createPreviousNameFormGroup(previousName?: PreviousNameModel): UntypedFormGroup {
    return this.formBuilder.group({
      [ApplicantControlNamesEnum.PREVIOUS_TITLE]: [
        previousName?.title,
        getValidations(DIP_VALIDATION.applicant.previousNames.previousTitle)
      ],
      [ApplicantControlNamesEnum.PREVIOUS_FIRST_NAME]: [
        previousName?.firstName,
        getValidations(DIP_VALIDATION.applicant.previousNames.previousFirstName)
      ],
      [ApplicantControlNamesEnum.PREVIOUS_MIDDLE_NAME]: [
        previousName?.middleName,
        getValidations(DIP_VALIDATION.applicant.previousNames.previousMiddleName)
      ],
      [ApplicantControlNamesEnum.PREVIOUS_SURNAME]: [
        previousName?.surname,
        getValidations(DIP_VALIDATION.applicant.previousNames.previousSurname)
      ]
    });
  }

  /**
   * Creates the employment form group for the applicant section.
   */
  createEmploymentFormGroup(employment?: EmploymentModel): UntypedFormGroup {
    switch (employment?.employmentStatus) {
      case EmploymentStatusEnum.EMPLOYED:
        return this.formBuilder.group({
          [ApplicantControlNamesEnum.EMPLOYMENT_TYPE]: [
            employment?.employed?.employmentType,
            getValidations(DIP_VALIDATION.applicant.employment.employmentType)
          ],
          [ApplicantControlNamesEnum.IS_IN_PROBATION_PERIOD]: [
            employment?.employed?.isInProbationPeriod ?? false,
            getValidations(DIP_VALIDATION.applicant.employment.isInProbationPeriod)
          ],
          [ApplicantControlNamesEnum.CONTRACT_START_DATE]: [
            toLocalDate(employment?.employed?.contractStartDate),
            getValidations(DIP_VALIDATION.applicant.employment.contractStartDate)
          ]
        });
      case EmploymentStatusEnum.SELF_EMPLOYED_LIMITED_COMPANY:
      case EmploymentStatusEnum.SELF_EMPLOYED_SOLE_TRADER_PARTNERSHIP:
        return this.formBuilder.group({
          [ApplicantControlNamesEnum.FISCAL_YEAR_REPORTED]: [
            employment?.selfEmployed?.fiscalYear,
            getValidations(DIP_VALIDATION.applicant.employment.fiscalYear)
          ],
          [ApplicantControlNamesEnum.OWNERSHIP_SHARE]: [
            employment?.selfEmployed?.ownershipShare,
            getValidations(DIP_VALIDATION.applicant.employment.ownershipShare)
          ],
          [ApplicantControlNamesEnum.TIME_IN_SELF_EMPLOYMENT]: [
            employment?.selfEmployed?.timeInSelfEmployment,
            getValidations(DIP_VALIDATION.applicant.employment.timeInSelfEmployment)
          ]
        });
      case EmploymentStatusEnum.SELF_EMPLOYED_CONTRACTOR:
        return this.formBuilder.group({
          [ApplicantControlNamesEnum.CONTRACT_START_DATE]: [
            toLocalDate(employment?.contractor?.contractStartDate),
            getValidations(DIP_VALIDATION.applicant.employment.contractStartDate)
          ],
          [ApplicantControlNamesEnum.CONTRACT_END_DATE]: [
            toLocalDate(employment?.contractor?.contractEndDate),
            getValidations(DIP_VALIDATION.applicant.employment.contractEndDate)
          ],
          [ApplicantControlNamesEnum.IS_FIRST_TIME_CONTRACTOR]: [
            employment?.contractor?.isFirstTimeContractor,
            getValidations(DIP_VALIDATION.applicant.employment.isFirstTimeContractor)
          ]
        });
      case EmploymentStatusEnum.RETIRED:
      case EmploymentStatusEnum.NOT_IN_PAID_EMPLOYMENT:
        break;
    }

    return this.formBuilder.group({});
  }

  /**
   * Creates the income source form group for the applicant section.
   */
  createIncomeSourcesFormGroup(
    applicantNumber: number,
    employmentStatus?: EmploymentStatusEnum,
    incomeSources?: IncomeSourceModel[],
    doesNotHaveAnyIncome?: boolean,
    contractDayRate?: ContractIncomeModel
  ): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [ApplicantIncomeControlNamesEnum.ADD_INCOME_SOURCE_QUESTION]: false
    });

    if (!employmentStatus) {
      return formGroup;
    }

    if (employmentStatus === EmploymentStatusEnum.EMPLOYED) {
      formGroup.addControl(ApplicantIncomeControlNamesEnum.SALARY_INPUT, this.createIncomeAmountControl());
      formGroup.addControl(ApplicantIncomeControlNamesEnum.BONUS_QUESTION, this.formBuilder.control(false));
      formGroup.addControl(ApplicantIncomeControlNamesEnum.OVERTIME_QUESTION, this.formBuilder.control(false));
      formGroup.addControl(ApplicantIncomeControlNamesEnum.COMMISSION_QUESTION, this.formBuilder.control(false));
    }

    if (employmentStatus === EmploymentStatusEnum.SELF_EMPLOYED_LIMITED_COMPANY) {
      formGroup.addControl(ApplicantIncomeControlNamesEnum.DIRECTOR_SALARY_INPUT, this.createIncomeAmountControl());
    }

    if (employmentStatus === EmploymentStatusEnum.SELF_EMPLOYED_LIMITED_COMPANY) {
      formGroup.addControl(ApplicantIncomeControlNamesEnum.DIVIDENDS_INPUT, this.createIncomeAmountControl());
    }

    if (employmentStatus === EmploymentStatusEnum.SELF_EMPLOYED_SOLE_TRADER_PARTNERSHIP) {
      formGroup.addControl(ApplicantIncomeControlNamesEnum.NET_PROFIT_INPUT, this.createIncomeAmountControl());
    }

    if (employmentStatus === EmploymentStatusEnum.SELF_EMPLOYED_CONTRACTOR) {
      formGroup.addControl(
        ApplicantIncomeControlNamesEnum.CONTRACT_DAY_RATE_INPUT,
        this.formBuilder.control(
          contractDayRate?.dailyRate ?? null,
          getValidations(DIP_VALIDATION.applicant.contractorIncome.dailyRate)
        )
      );

      formGroup.addControl(
        ApplicantIncomeControlNamesEnum.COUNT_DAYS_WORKED_WEEKLY_INPUT,
        this.formBuilder.control(
          contractDayRate?.daysWorkedWeekly ?? null,
          getValidations(DIP_VALIDATION.applicant.contractorIncome.daysWorkedWeekly)
        )
      );
    }

    const extraIncome: UntypedFormArray = this.formBuilder.array([]);
    const retiredIncome: UntypedFormArray = this.formBuilder.array([]);

    incomeSources?.forEach((income: IncomeSourceModel) => {
      switch (income.type) {
        case IncomeSourceTypeEnum.GROSS_SALARY:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.SALARY_INPUT)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.SALARY_INPUT).setValue(income.amount);
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.DIRECTOR_GROSS_SALARY:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.DIRECTOR_SALARY_INPUT)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.DIRECTOR_SALARY_INPUT).setValue(income.amount);
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.NET_PROFIT:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.NET_PROFIT_INPUT)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.NET_PROFIT_INPUT).setValue(income.amount);
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.DIVIDENDS:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.DIVIDENDS_INPUT)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.DIVIDENDS_INPUT).setValue(income.amount);
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.DRAWINGS:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.DRAWINGS_INPUT)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.DRAWINGS_INPUT).setValue(income.amount);
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.BONUS:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.BONUS_QUESTION)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.BONUS_QUESTION).setValue(true);
            formGroup.addControl(
              ApplicantIncomeControlNamesEnum.BONUS_INPUT,
              this.createIncomeAmountControl(income.amount)
            );
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.COMMISSION:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.COMMISSION_QUESTION)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.COMMISSION_QUESTION).setValue(true);
            formGroup.addControl(
              ApplicantIncomeControlNamesEnum.COMMISSION_INPUT,
              this.createIncomeAmountControl(income.amount)
            );
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.OVERTIME:
          if (formGroup.contains(ApplicantIncomeControlNamesEnum.OVERTIME_QUESTION)) {
            formGroup.get(ApplicantIncomeControlNamesEnum.OVERTIME_QUESTION).setValue(true);
            formGroup.addControl(
              ApplicantIncomeControlNamesEnum.OVERTIME_INPUT,
              this.createIncomeAmountControl(income.amount)
            );
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        case IncomeSourceTypeEnum.ARMED_FORCES_PENSION:
        case IncomeSourceTypeEnum.PRIVATE_PENSION:
        case IncomeSourceTypeEnum.STATE_PENSION:
          if (employmentStatus === EmploymentStatusEnum.RETIRED) {
            retiredIncome.push(this.populateIncomeSourceFormGroup(income));
          } else {
            extraIncome.push(this.populateIncomeSourceFormGroup(income));
          }
          break;
        default:
          extraIncome.push(this.populateIncomeSourceFormGroup(income));
          break;
      }
    });

    if (employmentStatus === EmploymentStatusEnum.RETIRED) {
      if (retiredIncome.length === 0) {
        retiredIncome.push(this.createNewIncomeSource());
      }

      formGroup.addControl(ApplicantIncomeControlNamesEnum.RETIRED_INCOME_SOURCE_ARRAY, retiredIncome);
    }

    if (employmentStatus === EmploymentStatusEnum.NOT_IN_PAID_EMPLOYMENT) {
      if (applicantNumber === 2) {
        formGroup.addControl(
          ApplicantIncomeControlNamesEnum.DOES_NOT_HAVE_ANY_INCOME,
          this.formBuilder.control(doesNotHaveAnyIncome ?? false)
        );
      }

      if (extraIncome.length === 0) {
        extraIncome.push(this.createNewIncomeSource(applicantNumber === 2 && doesNotHaveAnyIncome));
      }
    }

    if (extraIncome.length > 0) {
      formGroup.get(ApplicantIncomeControlNamesEnum.ADD_INCOME_SOURCE_QUESTION).setValue(true);
      formGroup.addControl(ApplicantIncomeControlNamesEnum.EXTRA_INCOME_SOURCE_ARRAY, extraIncome);
    }

    return formGroup;
  }

  /**
   * Creates the previous address form models for the applicant section.
   */
  createPreviousAddressFormModel(address?: PreviousAddressModel): { [key: string]: any } {
    return {
      ...ConsumerFormDipService.createAddressFormModel(address),
      [ApplicantControlNamesEnum.DATE_APPLICANT_MOVED_IN]: [
        toLocalDate(address?.from),
        getValidations(DIP_VALIDATION.applicant.previousAddress.from)
      ],
      [ApplicantControlNamesEnum.DATE_APPLICANT_MOVED_OUT]: [
        toLocalDate(address?.to),
        getValidations(DIP_VALIDATION.applicant.previousAddress.to)
      ]
    };
  }

  // FACILITY DETAILS SECTION
  /**
   * Create the advice details form group.
   */
  createIntermediaryDetailsBrokerFormGroup(formGroup: UntypedFormGroup, intermediary: IntermediaryModel): void {
    return formGroup.addControl(
      FacilityDetailsControlNamesEnum.INTERMEDIARY_DETAILS,
      this.formBuilder.group({
        [FacilityDetailsControlNamesEnum.FIRM_NAME]: [
          intermediary?.firmName,
          getValidations(DIP_VALIDATION.facilityDetails.intermediary.firmName)
        ],
        [FacilityDetailsControlNamesEnum.FCA_NUMBER]: [
          intermediary?.fcaNumber,
          getValidations(DIP_VALIDATION.facilityDetails.intermediary.fcaNumber)
        ],
        [FacilityDetailsControlNamesEnum.CONTACT_FIRST_NAME]: [
          intermediary?.contactFirstName,
          getValidations(DIP_VALIDATION.facilityDetails.intermediary.contactFirstName)
        ],
        [FacilityDetailsControlNamesEnum.CONTACT_LAST_NAME]: [
          intermediary?.contactLastName,
          getValidations(DIP_VALIDATION.facilityDetails.intermediary.contactLastName)
        ]
      })
    );
  }

  createAddressFormGroup(address?: AddressModel): UntypedFormGroup {
    return this.formBuilder.group(ConsumerFormDipService.createAddressFormModel(address));
  }

  getSecuredCreditCommitmentFormGroup(item: SecuredCreditCommitmentViewModel): UntypedFormGroup {
    if (item.source === 'user' && !item.saved) {
      const formGroup: UntypedFormGroup = this.formBuilder.group(
        {
          [CreditCommitmentsControlNamesEnum.APPLICANTS]: [
            item.data?.applicants,
            getValidations(DIP_VALIDATION.creditCommitments.data.applicants)
          ],
          [CreditCommitmentsControlNamesEnum.TYPE]: [
            item.data?.type,
            getValidations(DIP_VALIDATION.creditCommitments.data.type)
          ],
          [CreditCommitmentsControlNamesEnum.OUTSTANDING_BALANCE]: [
            item.data?.outstandingBalance,
            getValidations(DIP_VALIDATION.creditCommitments.data.outstandingBalance)
          ],
          [CreditCommitmentsControlNamesEnum.MONTHLY_PAYMENTS]: [
            item.data?.monthlyPayments,
            getValidations(DIP_VALIDATION.creditCommitments.data.monthlyPayments)
          ],
          [CreditCommitmentsControlNamesEnum.START_DATE]: [
            toLocalDate(item.data?.startDate),
            getValidations(DIP_VALIDATION.creditCommitments.data.startDate)
          ],
          [CreditCommitmentsControlNamesEnum.END_DATE]: [
            toLocalDate(item.data?.endDate),
            getValidations(DIP_VALIDATION.creditCommitments.data.endDate)
          ]
        },
        {
          validators: [interestOnlyOfPartAndPartValidator, settlementDateValidator]
        }
      );

      if (item.status === 'Settled') {
        this.createSettlementDateForm(formGroup, item);
      } else {
        formGroup.addControl(
          CreditCommitmentsControlNamesEnum.SECURITY_PROPERTY,
          this.formBuilder.control(
            item.data?.securityProperty ?? false,
            getValidations(DIP_VALIDATION.creditCommitments.data.securityProperty)
          )
        );
        formGroup.addControl(
          CreditCommitmentsControlNamesEnum.CONSOLIDATE,
          this.formBuilder.control(
            item.data?.consolidate ?? false,
            getValidations(DIP_VALIDATION.creditCommitments.data.consolidate)
          )
        );
        if (item.data?.securityProperty) {
          this.createSecurityPropertyForm(formGroup, item);
        }
        if (item.data?.consolidate) {
          this.createConsolidateForm(formGroup, item);
        }
      }
      return formGroup;
    } else {
      const formGroup: UntypedFormGroup = this.formBuilder.group(
        {},
        { validators: [interestOnlyOfPartAndPartValueValidator(item?.outstandingBalance)] }
      );

      if (item.status !== 'Settled') {
        if (item.ignore) {
          return this.formBuilder.group({
            [CreditCommitmentsControlNamesEnum.REASON_TO_IGNORE]: [
              item.data?.reasonToIgnore,
              getValidations(DIP_VALIDATION.creditCommitments.data.reasonToIgnore)
            ]
          });
        }

        if (item.securityProperty) {
          this.createSecurityPropertyForm(formGroup, item);
        }
        if (item.consolidate) {
          this.createConsolidateForm(formGroup, item);
        }
      }

      return formGroup;
    }
  }

  getUnsecuredCreditCommitmentFormGroup(item: UnsecuredCreditCommitmentViewModel): UntypedFormGroup {
    if (item.source === 'user' && !item.saved) {
      const formGroup: UntypedFormGroup = this.formBuilder.group(
        {
          [CreditCommitmentsControlNamesEnum.APPLICANTS]: [
            item.data?.applicants,
            getValidations(DIP_VALIDATION.creditCommitments.data.applicants)
          ],
          [CreditCommitmentsControlNamesEnum.TYPE]: [
            item.data?.type,
            getValidations(DIP_VALIDATION.creditCommitments.data.type)
          ],
          [CreditCommitmentsControlNamesEnum.OUTSTANDING_BALANCE]: [
            item.data?.outstandingBalance,
            getValidations(DIP_VALIDATION.creditCommitments.data.outstandingBalance)
          ],
          [CreditCommitmentsControlNamesEnum.MONTHLY_PAYMENTS]: [
            item.data?.monthlyPayments,
            getValidations(DIP_VALIDATION.creditCommitments.data.monthlyPayments)
          ],
          [CreditCommitmentsControlNamesEnum.START_DATE]: [
            toLocalDate(item.data?.startDate),
            getValidations(DIP_VALIDATION.creditCommitments.data.startDate)
          ],
          [CreditCommitmentsControlNamesEnum.END_DATE]: [toLocalDate(item.data?.endDate)]
        },
        {
          validators: [settlementDateValidator]
        }
      );

      if (item.status === 'Settled') {
        this.createSettlementDateForm(formGroup, item);
      } else {
        formGroup.addControl(
          CreditCommitmentsControlNamesEnum.CONSOLIDATE,
          this.formBuilder.control(
            item.data?.consolidate ?? false,
            getValidations(DIP_VALIDATION.creditCommitments.data.consolidate)
          )
        );
        if (item.data?.consolidate) {
          this.createConsolidateForm(formGroup, item);
        }
      }
      return formGroup;
    } else {
      const formGroup: UntypedFormGroup = this.formBuilder.group({}, {});

      if (item.status !== 'Settled') {
        if (item.ignore) {
          return this.formBuilder.group({
            [CreditCommitmentsControlNamesEnum.REASON_TO_IGNORE]: [
              item.data?.reasonToIgnore,
              getValidations(DIP_VALIDATION.creditCommitments.data.reasonToIgnore)
            ]
          });
        }

        if (item.consolidate) {
          this.createConsolidateForm(formGroup, item);
        }
      }

      return formGroup;
    }
  }

  getCcjFormGroup(item: CcjViewModel): UntypedFormGroup {
    if (item.source === 'user' && !item.saved) {
      const formGroup: UntypedFormGroup = this.formBuilder.group({
        [CreditCommitmentsControlNamesEnum.APPLICANTS]: [
          item.data?.applicants,
          getValidations(DIP_VALIDATION.creditCommitments.data.applicants)
        ],
        [CreditCommitmentsControlNamesEnum.REGISTRATION_DATE]: [
          item.data?.registrationDate,
          getValidations(DIP_VALIDATION.creditCommitments.data.registrationDate)
        ],
        [CreditCommitmentsControlNamesEnum.CATEGORY]: [
          item.data?.category,
          getValidations(DIP_VALIDATION.creditCommitments.data.category)
        ],
        [CreditCommitmentsControlNamesEnum.AMOUNT]: [
          item.data?.amount,
          getValidations(DIP_VALIDATION.creditCommitments.data.amount)
        ],
        [CreditCommitmentsControlNamesEnum.STATUS]: [
          item.data?.status,
          getValidations(DIP_VALIDATION.creditCommitments.data.status)
        ]
      });
      if (item.data?.status === 'Satisfied') {
        formGroup.addControl(
          CreditCommitmentsControlNamesEnum.SATISFACTION_DATE,
          this.formBuilder.control(
            item.data?.satisfactionDate,
            getValidations(DIP_VALIDATION.creditCommitments.data.satisfactionDate)
          )
        );
      }
      return formGroup;
    }
    return this.formBuilder.group({});
  }

  updateCreditCommitmentsData(creditCommitmentsData: CreditCommitmentsViewModel): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.createCreditCommitmentsFormGroup(creditCommitmentsData);
    this.dipFormGroup.setControl(DipFormControlNamesEnum.CREDIT_COMMITMENTS, formGroup);
    if (IsNonEditableStage(this.storageService.dipStage)) {
      this.disableControls(formGroup);
    }
    return formGroup;
  }

  getCreditCommitmentsAmountToConsolidateControls(): UntypedFormControl[] {
    const controls: UntypedFormControl[] = [];
    controls.push(
      ...this.getCreditCommitmentsFromArray(
        this.creditCommitmentsFormGroup.get(
          CreditCommitmentsControlNamesEnum.SECURED_CREDIT_COMMITMENTS
        ) as UntypedFormArray
      )
    );
    controls.push(
      ...this.getCreditCommitmentsFromArray(
        this.creditCommitmentsFormGroup.get(
          CreditCommitmentsControlNamesEnum.UNSECURED_CREDIT_COMMITMENTS
        ) as UntypedFormArray
      )
    );
    return controls;
  }

  disableControls(control: AbstractControl): void {
    if (control instanceof UntypedFormGroup) {
      for (const key of Object.keys(control.controls)) {
        this.disableControls(control.get(key));
      }
    }
    control.disable();
  }

  enableControls(control: AbstractControl): void {
    const toDisable: UntypedFormControl[] = [];
    this.enableAllControl(control, control.value?.incomeSourceFormGroup?.doesNotHaveAnyIncome, toDisable);
    for (const c of toDisable) {
      c.disable();
    }
  }

  private enableAllControl(
    control: AbstractControl | UntypedFormGroup,
    noIncome: boolean,
    toDisable: UntypedFormControl[]
  ): void {
    if (control instanceof UntypedFormGroup) {
      for (const key of Object.keys(control.controls)) {
        if (ConsumerFormDipService.cannotEnable(noIncome, key)) {
          toDisable.push(control.get(key) as UntypedFormControl);
          continue;
        }
        this.enableAllControl(control.get(key), noIncome, toDisable);
      }
    } else if (control instanceof UntypedFormArray) {
      if (control.length === 0) {
        control.enable();
      } else {
        for (let i = 0; i < control.length; i++) {
          this.enableAllControl(control.at(i), noIncome, toDisable);
        }
      }
    } else {
      control.enable();
    }
  }

  private static cannotEnable(noIncome: boolean, key: string): boolean {
    return (
      noIncome &&
      (key === ApplicantControlNamesEnum.ANY_FUTURE_DECREASE ||
        key === ApplicantIncomeControlNamesEnum.EXTRA_INCOME_SOURCE_ARRAY)
    );
  }

  // noinspection JSMethodCanBeStatic
  private getCreditCommitmentsFromArray(formArray: UntypedFormArray): UntypedFormControl[] {
    const controls: any[] = [];
    for (let i = 0; i < formArray.length; i++) {
      const control: AbstractControl | undefined = formArray
        .at(i)
        .get(CreditCommitmentsControlNamesEnum.DATA)
        ?.get(CreditCommitmentsControlNamesEnum.AMOUNT_TO_CONSOLIDATE);
      if (control) {
        controls.push(control);
      }
    }
    return controls;
  }

  private getMandatoryControls(path: CreditCommitmentsControlNamesEnum): UntypedFormControl[] {
    const controls: UntypedFormControl[] = [];
    const array: UntypedFormArray = this.creditCommitmentsFormGroup.get(path) as UntypedFormArray;
    for (let i = 0; i < array.length; i++) {
      const group: UntypedFormGroup = array.at(i).get(CreditCommitmentsControlNamesEnum.DATA) as UntypedFormGroup;
      controls.push(group.get(CreditCommitmentsControlNamesEnum.APPLICANTS) as UntypedFormControl);
      controls.push(group.get(CreditCommitmentsControlNamesEnum.TYPE) as UntypedFormControl);
      controls.push(group.get(CreditCommitmentsControlNamesEnum.OUTSTANDING_BALANCE) as UntypedFormControl);
      controls.push(group.get(CreditCommitmentsControlNamesEnum.MONTHLY_PAYMENTS) as UntypedFormControl);
      controls.push(group.get(CreditCommitmentsControlNamesEnum.START_DATE) as UntypedFormControl);
      controls.push(group.get(CreditCommitmentsControlNamesEnum.SETTLEMENT_DATE) as UntypedFormControl);
      if (path === CreditCommitmentsControlNamesEnum.SECURED_CREDIT_COMMITMENTS) {
        controls.push(group.get(CreditCommitmentsControlNamesEnum.END_DATE) as UntypedFormControl);
      }
    }
    return controls;
  }

  private createConsolidateForm(
    formGroup: UntypedFormGroup,
    item: SecuredCreditCommitmentViewModel | UnsecuredCreditCommitmentViewModel
  ): void {
    formGroup.addControl(
      CreditCommitmentsControlNamesEnum.AMOUNT_TO_CONSOLIDATE,
      this.formBuilder.control(
        item.data?.amountToConsolidate,
        getValidations(DIP_VALIDATION.creditCommitments.data.amountToConsolidate)
      )
    );
  }

  private createSettlementDateForm(
    formGroup: UntypedFormGroup,
    item: SecuredCreditCommitmentViewModel | UnsecuredCreditCommitmentViewModel
  ): void {
    formGroup.addControl(
      CreditCommitmentsControlNamesEnum.SETTLEMENT_DATE,
      this.formBuilder.control(
        item.data?.settlementDate,
        getValidations(DIP_VALIDATION.creditCommitments.data.settlementDate)
      )
    );
  }

  private createSecurityPropertyForm(formGroup: UntypedFormGroup, item: SecuredCreditCommitmentViewModel): void {
    if (item.status === 'Settled') {
      return;
    }
    formGroup.addControl(
      CreditCommitmentsControlNamesEnum.REPAYMENT_TYPE,
      this.formBuilder.control(
        item.data?.repaymentType,
        getValidations(DIP_VALIDATION.creditCommitments.data.repaymentType)
      )
    );
    formGroup.addControl(
      CreditCommitmentsControlNamesEnum.INTEREST_RATE_TYPE,
      this.formBuilder.control(
        item.data?.interestRateType,
        getValidations(DIP_VALIDATION.creditCommitments.data.interestRateType)
      )
    );

    if (
      item?.data?.repaymentType === RepaymentTypeEnum.INTEREST_ONLY ||
      item?.data?.repaymentType === RepaymentTypeEnum.PART_AND_PART
    ) {
      formGroup.addControl(
        CreditCommitmentsControlNamesEnum.REPAYMENT_VEHICLE,
        this.formBuilder.control(
          item.data?.repaymentVehicle,
          getValidations(DIP_VALIDATION.creditCommitments.data.repaymentVehicle)
        )
      );

      if (
        item?.data?.repaymentVehicle &&
        item?.data?.repaymentVehicle !== RepaymentVehicleEnum.SALE_OF_SECURITY_PROPERTY
      ) {
        formGroup.addControl(
          CreditCommitmentsControlNamesEnum.COST_OF_REPAYMENT_VEHICLE,
          this.formBuilder.control(
            item.data?.costOfRepaymentVehicle,
            getValidations(DIP_VALIDATION.creditCommitments.data.costOfRepaymentVehicle)
          )
        );
      }
    }
    if (item?.data?.repaymentType === RepaymentTypeEnum.PART_AND_PART) {
      formGroup.addControl(
        CreditCommitmentsControlNamesEnum.INTEREST_ONLY_BALANCE,
        this.formBuilder.control(
          item.data?.interestOnlyBalance,
          getValidations(DIP_VALIDATION.creditCommitments.data.interestOnlyBalance)
        )
      );
    }
    if (item?.data?.interestRateType === InterestRateTypeEnum.FIXED) {
      formGroup.addControl(
        CreditCommitmentsControlNamesEnum.FIXED_RATE_END_DATE,
        this.formBuilder.control(
          toLocalDate(item.data?.fixedRateEndDate),
          getValidations(DIP_VALIDATION.creditCommitments.data.fixedRateEndDate)
        )
      );
    }
  }

  /**
   * Creates the Facility Details form group.
   */
  private createFacilityDetailsFormGroup(facilityDetails?: FacilityDetailsModel): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group(
      {
        [FacilityDetailsControlNamesEnum.NUMBER_OF_APPLICANTS]: [
          facilityDetails?.numberOfApplicants,
          getValidations(DIP_VALIDATION.facilityDetails.numberOfApplicants)
        ],
        ...this.createLoanInformationControls(facilityDetails),
        [FacilityDetailsControlNamesEnum.FACILITIES_ARRAY]: this.formBuilder.array([
          this.createFacility(facilityDetails?.facilities[0], true),
          this.createFacility(facilityDetails?.facilities[1]),
          this.createFacility(facilityDetails?.facilities[2])
        ]),
        [FacilityDetailsControlNamesEnum.IS_THE_ADVISING_BROKER]: [
          facilityDetails?.isUserTheAdvisingBroker,
          getValidations(DIP_VALIDATION.facilityDetails.isUserTheAdvisingBroker)
        ]
      },
      { validators: loanAmountSumValidator }
    );
    if (facilityDetails?.isUserTheAdvisingBroker === false) {
      this.createIntermediaryDetailsBrokerFormGroup(formGroup, facilityDetails?.intermediary);
    }
    return formGroup;
  }

  // ELIGIBILITY CRITERIA SECTION
  private createEligibilityCriteriaFormGroup(eligibility?: boolean): UntypedFormGroup {
    return this.formBuilder.group({
      [EligibilityCriteriaControlNamesEnum.ELIGIBILITY_CRITERIA]: [
        eligibility ?? false,
        getValidations(DIP_VALIDATION.eligibilityCriteria)
      ]
    });
  }

  private createFacility(facility?: FacilityAllocationModel, required = false): UntypedFormGroup {
    return this.formBuilder.group(
      {
        [FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_PURPOSE]: [
          facility?.allocationPurpose,
          getValidations(DIP_VALIDATION.facilityDetails.facilityAllocationPurpose, required)
        ],
        [FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_AMOUNT]: [
          facility?.allocationAmount?.toString(),
          getValidations(DIP_VALIDATION.facilityDetails.facilityAllocationAmount, required)
        ]
      },
      { validators: facilityRequiredValidator }
    );
  }

  // PROPERTY DETAILS SECTION
  private createPropertyDetailsFormGroup(propertyDetails: PropertyDetailsModel): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [PropertyDetailsControlNamesEnum.IS_SAME_AS_RESIDENTIAL_ADDRESS]: [
        propertyDetails?.isSameAsResidentialAddress ?? true,
        getValidations(DIP_VALIDATION.propertyDetails.estimatedValue)
      ],
      [PropertyDetailsControlNamesEnum.ESTIMATED_VALUE]: [
        propertyDetails?.estimatedValue?.toString(),
        getValidations(DIP_VALIDATION.propertyDetails.estimatedValue)
      ],
      [PropertyDetailsControlNamesEnum.PURCHASE_YEAR]: [
        propertyDetails?.whenHasLastPurchased?.toString(),
        getValidations(DIP_VALIDATION.propertyDetails.purchaseYear)
      ],
      [PropertyDetailsControlNamesEnum.PURCHASE_PRICE]: [
        propertyDetails?.purchaseValue?.toString(),
        getValidations(DIP_VALIDATION.propertyDetails.purchasePrice)
      ],
      [PropertyDetailsControlNamesEnum.PROPERTY_TYPE]: [
        propertyDetails?.propertyType,
        getValidations(DIP_VALIDATION.propertyDetails.propertyType)
      ],
      [PropertyDetailsControlNamesEnum.NUMBER_OF_BEDROOMS]: [
        propertyDetails?.numberOfBedrooms,
        getValidations(DIP_VALIDATION.propertyDetails.numberOfBedrooms)
      ]
    });
    if (propertyDetails?.isSameAsResidentialAddress === false) {
      formGroup.addControl(
        PropertyDetailsControlNamesEnum.ADDRESS_FORM_GROUP,
        this.createAddressFormGroup(propertyDetails.address)
      );
    }
    return formGroup;
  }

  // APPLICANT 1 & 2 SECTION
  private createApplicantFormGroup(applicantNumber: number, applicant?: ApplicantModel): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [ApplicantControlNamesEnum.TITLE]: [applicant?.title, getValidations(DIP_VALIDATION.applicant.title)],
      [ApplicantControlNamesEnum.FIRST_NAME]: [
        applicant?.firstName,
        getValidations(DIP_VALIDATION.applicant.firstName)
      ],
      [ApplicantControlNamesEnum.MIDDLE_NAME]: [
        applicant?.middleName,
        getValidations(DIP_VALIDATION.applicant.middleName)
      ],
      [ApplicantControlNamesEnum.SURNAME]: [applicant?.surname, getValidations(DIP_VALIDATION.applicant.surname)],
      [ApplicantControlNamesEnum.USED_ANOTHER_NAME]: [
        applicant?.applicantUsedAnotherName ?? false,
        getValidations(DIP_VALIDATION.applicant.applicantUsedAnotherName)
      ],
      [ApplicantControlNamesEnum.PREVIOUS_NAME_ARRAY]: this.populatePreviousNameArray(applicant?.previousName),
      [ApplicantControlNamesEnum.RETIREMENT_AGE]: [
        applicant?.estimatedRetirementAge,
        getValidations(DIP_VALIDATION.applicant.estimatedRetirementAge)
      ],
      [ApplicantControlNamesEnum.DATE_OF_BIRTH]: [
        toLocalDate(applicant?.dateOfBirth),
        getValidations(DIP_VALIDATION.applicant.dateOfBirth)
      ],
      [ApplicantControlNamesEnum.COUNTRY_OF_CITIZENSHIP]: [
        applicant?.nationality,
        getValidations(DIP_VALIDATION.applicant.nationality)
      ],
      [ApplicantControlNamesEnum.MARITAL_STATUS]: [
        applicant?.maritalStatus,
        getValidations(DIP_VALIDATION.applicant.maritalStatus)
      ],
      [ApplicantControlNamesEnum.PREVIOUS_ADDRESS_ARRAY]: this.populatePreviousAddressArray(
        applicant?.previousAddresses
      ),
      [ApplicantControlNamesEnum.EMPLOYMENT_STATUS]: [
        applicant?.employment?.employmentStatus,
        getValidations(DIP_VALIDATION.applicant.employmentStatus)
      ],
      [ApplicantControlNamesEnum.EMPLOYMENT_FORM_GROUP]: this.createEmploymentFormGroup(applicant?.employment),
      [ApplicantControlNamesEnum.INCOME_SOURCE_FORM_GROUP]: this.createIncomeSourcesFormGroup(
        applicantNumber,
        applicant?.employment?.employmentStatus,
        applicant?.income?.income,
        applicant?.income?.doesNotHaveAnyIncome,
        applicant?.income?.contractorIncome
      ),
      [ApplicantControlNamesEnum.ANY_FUTURE_DECREASE]: [
        {
          value: applicant?.income?.expectsFutureIncomeDecrease ?? false,
          disabled: !!applicant?.income?.doesNotHaveAnyIncome
        },
        getValidations(DIP_VALIDATION.applicant.expectsFutureIncomeDecrease)
      ],
      ...ConsumerFormDipService.createReasonForDecrease(applicant?.income)
    });

    if (applicantNumber === 1) {
      formGroup.addControl(
        ApplicantControlNamesEnum.ADDRESS_FORM_GROUP,
        this.createAddressFormGroup(applicant?.currentAddress)
      );
      formGroup.addControl(
        ApplicantControlNamesEnum.LIVED_AT_ADDRESS_3_YEARS,
        this.formBuilder.control(
          applicant?.livedInCurrentAddressFor3Years ?? true,
          getValidations(DIP_VALIDATION.applicant.livedInCurrentAddressFor3Years)
        )
      );

      if (!(applicant?.livedInCurrentAddressFor3Years ?? true)) {
        formGroup.addControl(
          ApplicantControlNamesEnum.CURRENT_ADDRESS_MOVED_IN_DATE,
          this.formBuilder.control(
            toLocalDate(applicant?.currentAddressMovedInDate),
            (getValidations(DIP_VALIDATION.applicant.currentAddressMovedInDate), { validators: validateMinimumDate })
          )
        );
      }
    } else if (applicantNumber === 2) {
      formGroup.addControl(
        ApplicantControlNamesEnum.APPLICANT_2_LIVES_WITH_APPLICANT_1,
        this.formBuilder.control(
          applicant?.applicant2LivesWithApplicant1,
          getValidations(DIP_VALIDATION.applicant.applicant2LivesWithApplicant1)
        )
      );

      if (applicant?.applicant2LivesWithApplicant1) {
        formGroup.addControl(
          ApplicantControlNamesEnum.APPLICANT_2_LIVES_WITH_APPLICANT_1_FOR_3_YEARS,
          this.formBuilder.control(
            applicant?.applicant2LivesWithApplicant1For3Years,
            getValidations(DIP_VALIDATION.applicant.applicant2LivesWithApplicant1For3Years)
          )
        );

        if (!(applicant?.applicant2LivesWithApplicant1For3Years ?? true)) {
          formGroup.addControl(
            ApplicantControlNamesEnum.CURRENT_ADDRESS_MOVED_IN_DATE,
            this.formBuilder.control(
              toLocalDate(applicant?.currentAddressMovedInDate),
              (getValidations(DIP_VALIDATION.applicant.currentAddressMovedInDate), { validators: validateMinimumDate })
            )
          );
        }
      } else if (!(applicant?.applicant2LivesWithApplicant1 ?? true)) {
        formGroup.addControl(
          ApplicantControlNamesEnum.ADDRESS_FORM_GROUP,
          this.createAddressFormGroup(applicant?.currentAddress)
        );
        formGroup.addControl(
          ApplicantControlNamesEnum.LIVED_AT_ADDRESS_3_YEARS,
          this.formBuilder.control(
            applicant?.livedInCurrentAddressFor3Years ?? true,
            getValidations(DIP_VALIDATION.applicant.livedInCurrentAddressFor3Years)
          )
        );

        if (!(applicant?.livedInCurrentAddressFor3Years ?? true)) {
          formGroup.addControl(
            ApplicantControlNamesEnum.CURRENT_ADDRESS_MOVED_IN_DATE,
            this.formBuilder.control(
              toLocalDate(applicant?.currentAddressMovedInDate),
              (getValidations(DIP_VALIDATION.applicant.currentAddressMovedInDate), { validators: validateMinimumDate })
            )
          );
        }
      }
    }

    return formGroup;
  }

  private populatePreviousNameArray(previousNames?: PreviousNameModel[]): UntypedFormArray {
    const array: UntypedFormArray = this.formBuilder.array([]);

    previousNames?.forEach((name: PreviousNameModel) => {
      array.push(this.createPreviousNameFormGroup(name));
    });

    return array;
  }

  private populatePreviousAddressArray(addresses?: PreviousAddressModel[]): UntypedFormArray {
    const array: UntypedFormArray = this.formBuilder.array([]);

    addresses?.forEach((address: PreviousAddressModel) => {
      array.push(this.formBuilder.group(this.createPreviousAddressFormModel(address)));
    });

    return array;
  }

  private populateIncomeSourceFormGroup(income: IncomeSourceModel): UntypedFormGroup {
    return this.formBuilder.group({
      [ApplicantIncomeControlNamesEnum.INCOME_TYPE]: [income.type, getValidations(DIP_VALIDATION.applicant.incomeType)],
      [ApplicantIncomeControlNamesEnum.INCOME_AMOUNT]: [
        income.amount,
        getValidations({
          ...DIP_VALIDATION.applicant.incomeAmount.commonValidation,
          min: DIP_VALIDATION.applicant.incomeAmount[income.type]
        })
      ]
    });
  }

  private createNewIncomeSource(disabled = false): UntypedFormGroup {
    return this.formBuilder.group({
      [ApplicantIncomeControlNamesEnum.INCOME_TYPE]: [
        {
          value: null,
          disabled
        },
        getValidations(DIP_VALIDATION.applicant.incomeType)
      ]
    });
  }

  private createIncomeAmountControl(value: number = null): UntypedFormControl {
    return this.formBuilder.control(
      value,
      getValidations({
        ...DIP_VALIDATION.applicant.incomeAmount.commonValidation
      })
    );
  }

  // CREDIT COMMITMENTS
  private createCreditCommitmentsFormGroup(data?: CreditCommitmentsViewModel): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [CreditCommitmentsControlNamesEnum.SECURED_CREDIT_COMMITMENTS]: this.formBuilder.array([]),
      [CreditCommitmentsControlNamesEnum.UNSECURED_CREDIT_COMMITMENTS]: this.formBuilder.array([]),
      [CreditCommitmentsControlNamesEnum.CCJS]: this.formBuilder.array([])
    });
    if (data?.securedCreditCommitments?.length > 0) {
      const formArray: UntypedFormArray = formGroup.get(
        CreditCommitmentsControlNamesEnum.SECURED_CREDIT_COMMITMENTS
      ) as UntypedFormArray;
      for (const item of data?.securedCreditCommitments) {
        const control: UntypedFormGroup = this.formBuilder.group({
          [CreditCommitmentsControlNamesEnum.ID]: [item.id],
          [CreditCommitmentsControlNamesEnum.STATUS]: [item.status],
          [CreditCommitmentsControlNamesEnum.SOURCE]: [
            item.source,
            getValidations(DIP_VALIDATION.creditCommitments.source)
          ],
          [CreditCommitmentsControlNamesEnum.DATA]: this.getSecuredCreditCommitmentFormGroup(item)
        });
        if (item.status !== 'Settled' && (item.source === 'system' || item.saved)) {
          control.addControl(
            CreditCommitmentsControlNamesEnum.SECURITY_PROPERTY,
            this.formBuilder.control(
              item.securityProperty ?? false,
              getValidations(DIP_VALIDATION.creditCommitments.securityProperty)
            )
          );
          control.addControl(
            CreditCommitmentsControlNamesEnum.CONSOLIDATE,
            this.formBuilder.control(
              item.consolidate ?? false,
              getValidations(DIP_VALIDATION.creditCommitments.consolidate)
            )
          );
          control.addControl(
            CreditCommitmentsControlNamesEnum.IGNORE,
            this.formBuilder.control(item.ignore ?? false, getValidations(DIP_VALIDATION.creditCommitments.ignore))
          );
        }
        formArray.push(control);
      }
    }
    if (data?.unsecuredCreditCommitments?.length > 0) {
      const formArray: UntypedFormArray = formGroup.get(
        CreditCommitmentsControlNamesEnum.UNSECURED_CREDIT_COMMITMENTS
      ) as UntypedFormArray;
      for (const item of data?.unsecuredCreditCommitments) {
        const isConsolidationRequired: boolean = this.featureFlags.isFeatureFlagEnabled(
          FeatureFlagsEnum.CONSOLIDATION_REQUIRED_CREDIT_COMMITMENTS
        )
          ? item.consolidationRequired
          : false;
        item.consolidate = isConsolidationRequired ? true : item.consolidate;
        item.ignore = isConsolidationRequired ? false : item.ignore;

        const control: UntypedFormGroup = this.formBuilder.group({
          [CreditCommitmentsControlNamesEnum.ID]: [item.id],
          [CreditCommitmentsControlNamesEnum.STATUS]: [item.status],
          [CreditCommitmentsControlNamesEnum.SOURCE]: [
            item.source,
            getValidations(DIP_VALIDATION.creditCommitments.source)
          ],
          [CreditCommitmentsControlNamesEnum.DATA]: this.getUnsecuredCreditCommitmentFormGroup(item)
        });
        if (item.status !== 'Settled' && (item.source === 'system' || item.saved)) {
          control.addControl(
            CreditCommitmentsControlNamesEnum.CONSOLIDATE,
            this.formBuilder.control(
              { value: item.consolidate ?? false, disabled: isConsolidationRequired },
              getValidations(DIP_VALIDATION.creditCommitments.consolidate)
            )
          );

          control.addControl(
            CreditCommitmentsControlNamesEnum.IGNORE,
            this.formBuilder.control(
              {
                value: item.ignore ?? false,
                disabled: isConsolidationRequired
              },
              getValidations(DIP_VALIDATION.creditCommitments.ignore)
            )
          );
        }
        formArray.push(control);
      }
    }
    if (data?.ccjs?.length > 0) {
      const formArray: UntypedFormArray = formGroup.get(CreditCommitmentsControlNamesEnum.CCJS) as UntypedFormArray;
      for (const item of data?.ccjs) {
        const control: UntypedFormGroup = this.formBuilder.group({
          [CreditCommitmentsControlNamesEnum.ID]: [item.id],
          [CreditCommitmentsControlNamesEnum.STATUS]: [item.status],
          [CreditCommitmentsControlNamesEnum.SOURCE]: [
            item.source,
            getValidations(DIP_VALIDATION.creditCommitments.source)
          ],
          [CreditCommitmentsControlNamesEnum.DATA]: this.getCcjFormGroup(item)
        });
        formArray.push(control);
      }
    }
    return formGroup;
  }

  private createHouseholdExpendituresFormGroup(
    householdExpenditureDetails?: HouseholdExpenditureViewModel,
    hasRentalIncome = false
  ): UntypedFormGroup {
    const formGroup: UntypedFormGroup = this.formBuilder.group({
      [ExpenditureControlNamesEnum.CHILD_DEPENDANTS_COUNT]: [
        householdExpenditureDetails?.childDependantsCount,
        getValidations(DIP_VALIDATION.householdExpenditures.childDependantsCount)
      ],
      [ExpenditureControlNamesEnum.ADULT_DEPENDANTS_COUNT]: [
        householdExpenditureDetails?.adultDependantsCount,
        getValidations(DIP_VALIDATION.householdExpenditures.adultDependantsCount)
      ],
      [ExpenditureControlNamesEnum.FOOD_DRINK_AND_OTHER_HOUSEKEEPING_COSTS]: [
        householdExpenditureDetails?.foodDrinkAndOtherHousekeepingCosts,
        getValidations(DIP_VALIDATION.householdExpenditures.foodDrinkAndOtherHousekeepingCosts)
      ],
      [ExpenditureControlNamesEnum.CLOTHING_AND_FOOTWEAR]: [
        householdExpenditureDetails?.clothingAndFootwear,
        getValidations(DIP_VALIDATION.householdExpenditures.clothingAndFootwear)
      ],
      [ExpenditureControlNamesEnum.TV_PHONE_AND_INTERNET]: [
        householdExpenditureDetails?.tvPhoneAndInternet,
        getValidations(DIP_VALIDATION.householdExpenditures.tvPhoneAndInternet)
      ],
      [ExpenditureControlNamesEnum.UTILITIES]: [
        householdExpenditureDetails?.utilities,
        getValidations(DIP_VALIDATION.householdExpenditures.utilities)
      ],
      [ExpenditureControlNamesEnum.INSURANCE]: [
        householdExpenditureDetails?.insurance,
        getValidations(DIP_VALIDATION.householdExpenditures.insurance)
      ],
      [ExpenditureControlNamesEnum.COUNCIL_TAX]: [
        householdExpenditureDetails?.councilTax,
        getValidations(DIP_VALIDATION.householdExpenditures.councilTax)
      ],
      [ExpenditureControlNamesEnum.TRANSPORT]: [
        householdExpenditureDetails?.transport,
        getValidations(DIP_VALIDATION.householdExpenditures.transport)
      ],
      [ExpenditureControlNamesEnum.RECREATION]: [
        householdExpenditureDetails?.recreation,
        getValidations(DIP_VALIDATION.householdExpenditures.recreation)
      ],
      [ExpenditureControlNamesEnum.GROUND_RENT_AND_SERVICE_CHARGE]: [
        householdExpenditureDetails?.groundRentAndServiceCharge,
        getValidations(DIP_VALIDATION.householdExpenditures.groundRentAndServiceCharge)
      ],
      [ExpenditureControlNamesEnum.OTHER_MONTHLY_EXPENDITURE]: [
        householdExpenditureDetails?.otherMonthlyExpenditure,
        getValidations(DIP_VALIDATION.householdExpenditures.otherMonthlyExpenditure)
      ]
    });

    if (hasRentalIncome) {
      formGroup.addControl(
        ExpenditureControlNamesEnum.FURNISHING_AND_PROPERTY_MAINTENANCE,
        this.formBuilder.control(
          householdExpenditureDetails?.furnishingAndPropertyMaintenance,
          getValidations(DIP_VALIDATION.householdExpenditures.furnishingAndPropertyMaintenance)
        )
      );
    }

    if ((householdExpenditureDetails?.childDependantsCount ?? '0') !== '0') {
      formGroup.addControl(
        ExpenditureControlNamesEnum.SCHOOL_NURSERY_AND_CHILDCARE_FEES,
        this.formBuilder.control(
          householdExpenditureDetails?.schoolNurseryAndChildcareFees,
          getValidations(DIP_VALIDATION.householdExpenditures.schoolNurseryAndChildcareFees)
        )
      );
      formGroup.addControl(
        ExpenditureControlNamesEnum.ALIMONY_AND_CHILD_MAINTENANCE,
        this.formBuilder.control(
          householdExpenditureDetails?.alimonyAndChildMaintenance,
          getValidations(DIP_VALIDATION.householdExpenditures.alimonyAndChildMaintenance)
        )
      );
    }

    if (householdExpenditureDetails?.childDependantsCount === '4+') {
      formGroup.addControl(
        ExpenditureControlNamesEnum.NUMBER_OF_CHILD_DEPENDANTS,
        this.formBuilder.control(
          householdExpenditureDetails?.numberOfChildDependants,
          getValidations(DIP_VALIDATION.householdExpenditures.numberOfChildDependants)
        )
      );
    }

    if (householdExpenditureDetails?.adultDependantsCount === '4+') {
      formGroup.addControl(
        ExpenditureControlNamesEnum.NUMBER_OF_ADULT_DEPENDANTS,
        this.formBuilder.control(
          householdExpenditureDetails?.numberOfAdultDependants,
          getValidations(DIP_VALIDATION.householdExpenditures.numberOfAdultDependants)
        )
      );
    }

    return formGroup;
  }

  private static createAddressFormModel(address?: AddressModel): { [key: string]: any } {
    return {
      [AddressControlNamesEnum.POSTCODE]: [address?.postcode, getValidations(COMMON_VALIDATION.address.postcode)],
      [AddressControlNamesEnum.ADDRESS_LINE_1]: [
        address?.addressLine1,
        getValidations(COMMON_VALIDATION.address.addressLine1)
      ],
      [AddressControlNamesEnum.ADDRESS_LINE_2]: [
        address?.addressLine2,
        getValidations(COMMON_VALIDATION.address.addressLine2)
      ],
      [AddressControlNamesEnum.CITY]: [address?.city, getValidations(COMMON_VALIDATION.address.city)],
      [AddressControlNamesEnum.SUB_BUILDING_NAME]: [address?.subBuildingName],
      [AddressControlNamesEnum.BUILDING_NAME]: [address?.buildingName],
      [AddressControlNamesEnum.BUILDING_NUMBER]: [address?.buildingNumber],
      [AddressControlNamesEnum.UDPRN]: [address?.udprn],
      [AddressControlNamesEnum.PO_BOX]: [address?.poBox],
      [AddressControlNamesEnum.COUNTY]: [address?.county],
      [AddressControlNamesEnum.COUNTRY]: [address?.country],
      [AddressControlNamesEnum.UPRN]: [address?.uprn]
    };
  }

  private static createReasonForDecrease(income: IncomeModel): { [p: string]: any } {
    if (income?.expectsFutureIncomeDecrease) {
      return {
        [ApplicantControlNamesEnum.REASON_FOR_FUTURE_DECREASE]: [
          income?.futureIncomeDecreaseReason,
          getValidations(DIP_VALIDATION.applicant.futureIncomeDecreaseReason)
        ]
      };
    }

    return {};
  }

  private hasRentalIncome(dip?: DecisionInPrincipleDetailsModel): boolean {
    if (!dip) {
      return false;
    }
    return this.applicantHasRentalIncome(dip.applicant1) || this.applicantHasRentalIncome(dip.applicant2);
  }

  private applicantHasRentalIncome(applicant?: ApplicantModel): boolean {
    if ((applicant?.income?.income?.length ?? 0) === 0) {
      return false;
    }
    return applicant.income.income.some((i: IncomeSourceModel) => i.type === IncomeSourceTypeEnum.RENTAL_INCOME);
  }
}
