import { Component, EventEmitter, Inject, Output } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../../../localization/localizable.component';
import { PropertyValuationChangeDialogModel } from './model/property-valuation-change-dialog.model';
import { PropertyValuationType } from '../../../../consumer/details/model/property/property-valuation.model';

@Component({
  selector: 'broker-property-valuation-change-dialog',
  templateUrl: './property-valuation-change-dialog.component.html'
})
export class PropertyValuationChangeDialogComponent extends LocalizableComponent {
  @Output() submitEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  isLoading: boolean;
  isDisabled: boolean;
  requestError: boolean;

  get isUp(): boolean {
    return this.data?.newValuation > this.data?.oldValuation;
  }

  get estimated(): number {
    return this.data?.oldValuation || 0;
  }

  get valuation(): number {
    return this.data?.newValuation || 0;
  }

  get type(): PropertyValuationType {
    return this.data?.type;
  }

  constructor(
    @Inject(L10N_LOCALE) locale: L10nLocale,
    @Inject(MAT_DIALOG_DATA) public data: PropertyValuationChangeDialogModel
  ) {
    super(locale);
  }
}
