import { Component, Inject, Input } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { environment } from '../../../environments/environment';
import { LocalizableComponent } from '../../shared/localization/localizable.component';

@Component({
  selector: 'broker-dashboard-documents',
  templateUrl: './dashboard-documents.component.html',
  styleUrls: ['./dashboard-documents.component.scss']
})
export class DashboardDocumentsComponent extends LocalizableComponent {
  @Input()
  canSubmitConsumerFlow: boolean;

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }

  /**
   * Gets the consumer link from configuration.
   */
  getConsumerUrl(link: string): string {
    return environment.helpfulLinks.consumer[link];
  }
}
