/**
 * Date fns adapter options.
 */
export interface MatDateFnsAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * {@default false}
   */
  useUtc: boolean;
}

/**
 * Date fns adapter default options.
 */
export const dateFnsAdapterDefaultOptions: MatDateFnsAdapterOptions = {
  useUtc: false
};
