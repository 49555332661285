import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../shared/services/auth/auth.service';
import { ConfirmRegistrationModel } from '../../shared/models/auth/confirm-registration.model';
import { RegisterModel } from '../../shared/models/auth/register.model';
import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { trimValue } from '../../shared/utils/input-validator';
import { LoggerService } from '../../shared/services/logger/logger.service';

/**
 * Component to handle registration confirmation.
 */
@Component({
  selector: 'broker-confirm-registration',
  templateUrl: './confirm-registration.component.html'
})
export class ConfirmRegistrationComponent extends LocalizableComponent implements OnInit {
  confirmForm: UntypedFormGroup;

  disabled = false;

  resending = false;

  private email: string;

  private password: string;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly logger: LoggerService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  get codeControl(): UntypedFormControl {
    return this.confirmForm.get('code') as UntypedFormControl;
  }

  ngOnInit(): void {
    if (!this.authService.registeredUser) {
      this.router.navigate(['auth/login']).catch((error) => this.logger.error('Error navigating to login', error));
    } else {
      const model: RegisterModel = JSON.parse(atob(this.authService.registeredUser)) as RegisterModel;
      this.email = model.email;
      this.password = model.password;
      this.buildForm();
    }
  }

  /**
   * Trims the form control value.
   */
  trimValue(formControl: UntypedFormControl): void {
    trimValue(formControl);
  }

  /**
   * Confirm a new registration.
   */
  confirm(): void {
    this.disabled = true;
    this.confirmForm.setErrors(null);

    const value: ConfirmRegistrationModel = {
      ...this.confirmForm.value,
      email: this.email
    };
    this.authService.confirmRegistration(value).then((result: boolean) => {
      if (result) {
        this.authService.registeredUser = null;
        this.authService
          .login({
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.router.navigate(['']).catch((error) => this.logger.error('Error navigating to root', error));
          });
      } else {
        this.disabled = false;
        this.confirmForm.setErrors({
          invalid: true
        });
      }
    });
  }

  /**
   * Resend the confirmation code.
   */
  resendCode(): void {
    if (this.resending) {
      return;
    }
    this.resending = true;
    this.authService.resendCode(this.email).then(() => {
      this.resending = false;
    });
  }

  private buildForm(): void {
    this.confirmForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.maxLength(16)]]
    });
  }
}
