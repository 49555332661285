import { Injectable } from '@angular/core';

import mixpanel from 'mixpanel-browser';

import { environment } from '../../../../environments/environment';
import { StageNameEnum } from '../../consumer/dip/enum/stage-name.enum';
import { MixpanelEventPropertiesEnum } from './enum/mixpanel-event-properties.enum';
import { MixpanelEventsEnum } from './enum/mixpanel-events.enum';
import { MixpanelUserProfilePropertiesEnum } from './enum/mixpanel-user-profile-properties.enum';
import { RegisterModel } from '../../models/auth/register.model';
import { StorageKeyEnum } from '../storage/enum/storage-key.enum';
import { StorageService } from '../storage/storage.service';

/**
 * Service to manage MixPanel tracking operations.
 */
@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  constructor(private readonly storage: StorageService) {
    if (environment.canExecuteTrackers) {
      MixpanelService.init();
    }
    this.storage.saveValue(StorageKeyEnum.MIXPANEL_EMAIL, null);
  }

  /**
   * Register the Super Properties that will be added to every tracked event.
   */
  static registerSuperProperties(email: string | undefined, fcaNumber: string | undefined): void {
    if (environment.canExecuteTrackers) {
      mixpanel.register({
        [MixpanelUserProfilePropertiesEnum.EMAIL]: email,
        [MixpanelUserProfilePropertiesEnum.FCA_NUMBER]: fcaNumber
      });
    }
  }

  /**
   * Tracks the Sign-Up event. Creates a Mixpanel User with the register data.
   */
  trackSignUpEvent(registerData: RegisterModel): void {
    if (environment.canExecuteTrackers) {
      MixpanelService.registerSuperProperties(registerData.email, registerData.fcaNumber);
      mixpanel.track(MixpanelEventsEnum.SIGN_UP);
      mixpanel.identify(registerData.email);
      mixpanel.people.set({
        [MixpanelUserProfilePropertiesEnum.FIRST_NAME]: registerData.firstName,
        [MixpanelUserProfilePropertiesEnum.LAST_NAME]: registerData.lastName,
        [MixpanelUserProfilePropertiesEnum.PHONE_NUMBER]: registerData.phoneNumber,
        [MixpanelUserProfilePropertiesEnum.EMAIL]: registerData.email,
        [MixpanelUserProfilePropertiesEnum.FCA_NUMBER]: registerData.fcaNumber,
        [MixpanelUserProfilePropertiesEnum.CREATED_DATE]: new Date().toISOString()
      });
    }
  }

  /**
   * Tracks the Mixpanel login event.
   */
  trackLoginEvent(registerData: RegisterModel): void {
    if (environment.canExecuteTrackers) {
      this.storage.saveValue(StorageKeyEnum.MIXPANEL_EMAIL, registerData.email);
      mixpanel.track(MixpanelEventsEnum.LOGIN);
      mixpanel.identify(this.storage.getValue(StorageKeyEnum.MIXPANEL_EMAIL));
      mixpanel.people.set({
        [MixpanelUserProfilePropertiesEnum.FIRST_NAME]: registerData.firstName,
        [MixpanelUserProfilePropertiesEnum.LAST_NAME]: registerData.lastName,
        [MixpanelUserProfilePropertiesEnum.PHONE_NUMBER]: registerData.phoneNumber,
        [MixpanelUserProfilePropertiesEnum.EMAIL]: registerData.email,
        [MixpanelUserProfilePropertiesEnum.FCA_NUMBER]: registerData.fcaNumber,
        [MixpanelUserProfilePropertiesEnum.LAST_TIME_LOGIN]: new Date().toISOString()
      });
      mixpanel.people.increment(MixpanelUserProfilePropertiesEnum.LIFETIME_LOGIN);
    }
  }

  /**
   * Tracks the Mixpanel Start Consumer event.
   */
  trackStartConsumerEvent(): void {
    if (environment.canExecuteTrackers) {
      mixpanel.track(MixpanelEventsEnum.START_CONSUMER);
      mixpanel.identify(this.storage.getValue(StorageKeyEnum.MIXPANEL_EMAIL));
      mixpanel.people.set({ [MixpanelUserProfilePropertiesEnum.LAST_START_CONSUMER]: new Date().toISOString() });
      mixpanel.people.set_once(MixpanelUserProfilePropertiesEnum.FIRST_START_CONSUMER, new Date());
      mixpanel.people.increment(MixpanelUserProfilePropertiesEnum.LIFETIME_START_CONSUMER);
    }
  }

  /**
   * Tracks a Mixpanel event with the opportunity reference.
   */
  trackEventWithReference(event: MixpanelEventsEnum, reference?: string): void {
    if (environment.canExecuteTrackers) {
      mixpanel.track(event, { [MixpanelEventPropertiesEnum.REFERENCE_NUMBER]: reference });
    }
  }

  /**
   * Tracks the Mixpanel Error Page event. Triggered when an error page is shown to a broker.
   */
  trackErrorPageEvent(page: StageNameEnum, reference: string): void {
    MixpanelService.trackEvent(MixpanelEventsEnum.ERROR_PAGE, page, reference);
  }

  /**
   * Tracks the Mixpanel Error Message event. Triggered when an error messages appears below a CTA.
   */
  trackErrorMessageEvent(page: StageNameEnum, reference: string): void {
    MixpanelService.trackEvent(MixpanelEventsEnum.ERROR_MESSAGE, page, reference);
  }

  private static init(): void {
    mixpanel.init(environment.mixpanel.token);
  }

  private static trackEvent(event: MixpanelEventsEnum, page: StageNameEnum, reference: string): void {
    if (environment.canExecuteTrackers && page && reference) {
      mixpanel.track(event, {
        [MixpanelEventPropertiesEnum.PAGE]: page,
        [MixpanelEventPropertiesEnum.REFERENCE_NUMBER]: reference
      });
    }
  }
}
