import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { L10nTranslationModule } from 'angular-l10n';

import { StepComponent } from './step.component';
import { SvgModule } from '../../svg/svg.module';
import { StepCreditCheckExpiryInfoComponent } from './credit-check-expiry-info/step-credit-check-expiry-info.component';

@NgModule({
  declarations: [StepComponent, StepCreditCheckExpiryInfoComponent],
  imports: [CommonModule, SvgModule, MatProgressSpinnerModule, L10nTranslationModule],
  exports: [StepComponent]
})
export class StepModule {}
