import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Scrolls to the first invalid control in the document.
 */
export function scrollToFirstInvalidControl(isFullApp = false, saveAndExit = false): void {
  interval(50)
    .pipe(take(1))
    .subscribe(() => {
      const container: string = isFullApp ? '.details-wrapper' : '.form-container';
      const invalidClass: string = saveAndExit ? '.mandatory' : '.ng-invalid';
      const firstInvalidControl: HTMLElement = document.querySelector(
        `${container} ${invalidClass}:not(div):not(form):not(broker-consumer-address)`
      );

      if (!firstInvalidControl) {
        return;
      }

      const elementLabel: HTMLElement = firstInvalidControl.closest('.col-12')?.querySelector('.label');
      const scrollTo: HTMLElement = elementLabel ?? firstInvalidControl;

      const focusElement: HTMLElement = firstInvalidControl.querySelector('input') ?? firstInvalidControl;
      focusElement.focus({ preventScroll: true });

      window.scroll({
        top: scrollTo.getBoundingClientRect().top + window.scrollY - 48,
        left: 0,
        behavior: 'smooth'
      });
    });
}

/**
 * Scrolls to the first invalid control in the document.
 */
export function scrollToFirstInvalidTouchedControl(isFullApp = false, saveAndExit = false): void {
  interval(50)
    .pipe(take(1))
    .subscribe(() => {
      const container: string = isFullApp ? '.details-wrapper' : '.form-container';
      const invalidClass: string = saveAndExit ? '.mandatory' : '.ng-invalid .ng-touched';
      const firstInvalidControl: HTMLElement = document.querySelector(
        `${container} ${invalidClass}:not(div):not(form):not(broker-consumer-address)`
      );

      if (!firstInvalidControl) {
        return;
      }

      const elementLabel: HTMLElement = firstInvalidControl.closest('.col-12')?.querySelector('.label');
      const scrollTo: HTMLElement = elementLabel ?? firstInvalidControl;

      const focusElement: HTMLElement = firstInvalidControl.querySelector('input') ?? firstInvalidControl;
      focusElement.focus({ preventScroll: true });

      window.scroll({
        top: scrollTo.getBoundingClientRect().top + window.scrollY - 48,
        left: 0,
        behavior: 'smooth'
      });
    });
}

/**
 * Scrolls to the first incomplete section in the document.
 */
export function scrollToFirstIncompleteSection(): void {
  interval(50)
    .pipe(take(1))
    .subscribe(() => {
      const invalidButton: HTMLElement = document.querySelector('.default-section .ng-invalid');

      if (!invalidButton) {
        return;
      }

      const scrollTo: HTMLElement = invalidButton.closest('.default-section');
      const focusElement: HTMLElement = scrollTo.querySelector('button') ?? scrollTo;
      focusElement.focus({ preventScroll: true });

      window.scroll({
        top: scrollTo.getBoundingClientRect().top + window.scrollY,
        left: 0,
        behavior: 'smooth'
      });
    });
}

/**
 * Scrolls to the first element that matches the selector.
 */
export function scrollToElementWithSelector(selector: string, spaceAbove?: number): void {
  const topSpacing: number = spaceAbove ? spaceAbove : 0;
  interval(50)
    .pipe(take(1))
    .subscribe(() => {
      const scrollTo: HTMLElement = document.querySelector(selector);

      if (!scrollTo) {
        return;
      }

      window.scroll({
        top: scrollTo.getBoundingClientRect().top + window.scrollY + topSpacing,
        left: 0,
        behavior: 'smooth'
      });
    });
}
