import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import {
  ApplicantDocumentGroup,
  LoanInformationDocumentGroup
} from '../details/model/documents/full-application-documents.model';
import { ApplicantDocumentModel } from '../details/model/documents/applicant-document.model';
import { BaseService } from '../base.service';
import {
  AllocationPurposeDocumentModel,
  DocumentModel,
  emptyDocumentModel,
  IncomeDocumentModel
} from '../details/model/documents/document.model';
import { FileInfoModel } from '../details/model/file-info.model';
import { FullApplicationDetailsApplicantEligibilityModel } from '../details/model/applicant/full-application-details-applicant-eligibility.model';
import { FullApplicationDetailsApplicantIncomeModel } from '../details/model/applicant/full-application-details-applicant-income.model';
import { FullApplicationDetailsApplicantModel } from '../details/model/applicant/full-application-details-applicant.model';
import { FullApplicationDetailsApplicantPersonalDetailsModel } from '../details/model/applicant/full-application-details-applicant-personal-details.model';
import { FullApplicationDetailsApplicantWithNameModel } from '../details/model/applicant/full-application-details-applicant-with-name.model';
import { FullApplicationDetailsConsentModel } from '../details/model/full-application-details-consent.model';
import { FullApplicationDetailsConsentCompletionAmountModel } from '../details/model/full-application-details-consent-completion-amount.model';
import { FullApplicationDetailsCreditCommitmentsModel } from '../details/model/credit-commitments/full-application-details-credit-commitments.model';
import { FullApplicationDetailsDocumentsModel } from '../details/model/documents/full-application-details-documents.model';
import { FullApplicationDetailsModel } from '../details/model/full-application-details.model';
import { FullApplicationDetailsPropertyModel } from '../details/model/property/full-application-details-property.model';
import { FullApplicationDetailsPropertyWithNameModel } from '../details/model/property/full-application-details-property-with-name.model';
import { getApplicantFullName } from '../../utils/application';
import {
  SecuredCreditCommitmentModel,
  UnsecuredCreditCommitmentModel
} from '../details/model/credit-commitments/credit-commitment.model';
import { StageNameEnum } from '../dip/enum/stage-name.enum';
import { StorageKeyEnum } from '../../services/storage/enum/storage-key.enum';
import { StorageService } from '../../services/storage/storage.service';
import { FamilyTypeEnum } from '../details/enum/family-type.enum';
import { PropertyValuationType } from '../details/model/property/property-valuation.model';
import { LoanInformationDocumentModel } from '../details/model/documents/loan-information-document.model';
import { BaseDocumentModel } from '../details/model/documents/base-document.model';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags.service';
import { FeatureFlagsEnum } from '../../enums/feature-flags/feature-flags.enum';

/**
 * Service to handle the consumer details for the Consumer flow.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsumerStorageFullAppService extends BaseService {
  /**
   * The save and exit subject to handle the Save&Exit button click event.
   */
  saveAndExitSubject: Subject<void> = new Subject<void>();

  /**
   * Subscribe to be notified when the full app is updated.
   */
  readonly fullAppUpdated: Subject<void> = new Subject<void>();

  constructor(private readonly storage: StorageService, private readonly featureFlags: FeatureFlagsService) {
    super();
  }

  /**
   * Gets the Full App data from the localstorage.
   */
  get fullApp(): FullApplicationDetailsModel {
    return JSON.parse(this.storage.getValue(StorageKeyEnum.FULL_APP_DATA));
  }

  /**
   * Sets the Full App data to the localstorage.
   */
  set fullApp(value: FullApplicationDetailsModel) {
    this.storage.saveValue(StorageKeyEnum.FULL_APP_DATA, value ? JSON.stringify(value) : null);
  }

  /**
   * Gets the Full App id.
   */
  get fullAppId(): string {
    return this.fullApp?.id;
  }

  /**
   * Gets the Full APp stage.
   */
  get fullAppStage(): StageNameEnum {
    return this.fullApp?.stage;
  }

  /**
   * Gets the Consent data of the Full App.
   */
  get fullAppDataConsent(): FullApplicationDetailsConsentModel {
    return this.fullApp?.consent;
  }

  get fullAppDataDocuments(): FullApplicationDetailsDocumentsModel {
    const fullAppData: FullApplicationDetailsModel = this.fullApp;
    const isSubmissionChecklistEnabled = this.featureFlags.isFeatureFlagEnabled(
      FeatureFlagsEnum.SUBMISSION_CHECKLIST_UPLOAD
    );
    return {
      applicant1Documents: this.mapApplicantDocuments(fullAppData?.documents?.applicant1Income),
      applicant2Documents: this.mapApplicantDocuments(fullAppData?.documents?.applicant2Income),
      propertyDocuments: fullAppData?.documents?.valuation ?? emptyDocumentModel(),
      otherDocuments: fullAppData?.documents?.otherV2?.documents ?? [],
      loanInformationDocuments: this.mapLoanInformationDocuments(fullAppData?.documents?.loanInformation),
      checklistDocuments: isSubmissionChecklistEnabled
        ? fullAppData?.documents?.checklist ?? emptyDocumentModel()
        : undefined
    };
  }

  /**
   * Gets the full app reference.
   */
  get fullAppReference(): string {
    return this.fullApp?.reference;
  }

  /**
   * Gets the product family type of the Full Application.
   */
  get fullAppProductFamily(): FamilyTypeEnum {
    return this.fullApp?.selectedOffer?.family;
  }

  get fullAppOtherDocumentsMessages(): string[] {
    return this.fullApp?.documents?.otherV2?.sectionMessages || [];
  }

  get propertyValuation(): number {
    return +(
      this.fullApp?.propertyDetails?.characteristics?.propertyValuation?.hometrackValuation ||
      this.fullApp?.propertyDetails?.characteristics?.propertyValuation?.ricsValuation
    );
  }

  get propertyValuationType(): PropertyValuationType {
    return this.fullApp?.propertyDetails?.characteristics?.propertyValuation?.type;
  }

  /**
   * Gets the name of the given applicant of the Full App.
   */
  getFullAppApplicantName(applicantNumber: number): string {
    return applicantNumber === 2
      ? getApplicantFullName(this.fullApp?.applicant2?.name)
      : getApplicantFullName(this.fullApp?.applicant1?.name);
  }

  /**
   * Gets the applicant data of the given applicant of the Full Application.
   */
  getFullAppApplicantData(applicantNumber: number): FullApplicationDetailsApplicantWithNameModel {
    return applicantNumber === 2 ? this.fullApp?.applicant2 : this.fullApp?.applicant1;
  }

  /**
   * Gets the eligibility data of the given applicant of the Full Application.
   */
  getFullAppApplicantEligibilityData(applicantNumber: number): FullApplicationDetailsApplicantEligibilityModel {
    return applicantNumber === 2 ? this.fullApp?.applicant2?.eligibility : this.fullApp?.applicant1?.eligibility;
  }

  /**
   * Gets the personal data of the given applicant of the Full Application.
   */
  getFullAppApplicantPersonalData(applicantNumber: number): FullApplicationDetailsApplicantPersonalDetailsModel {
    return applicantNumber === 2
      ? this.fullApp?.applicant2?.personalDetails
      : this.fullApp?.applicant1?.personalDetails;
  }

  /**
   * Gets the income data of the given applicant of the Full Application.
   */
  getFullAppApplicantIncomeData(applicantNumber: number): FullApplicationDetailsApplicantIncomeModel {
    return applicantNumber === 2 ? this.fullApp?.applicant2?.income : this.fullApp?.applicant1?.income;
  }

  /**
   * Updates the consent data of the Full Application.
   */
  updateFullAppConsentData(data: FullApplicationDetailsConsentModel): void {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    const completionDetails: FullApplicationDetailsConsentCompletionAmountModel = opportunity.consent.completionDetails;
    completionDetails.disbursementAmount = data.completionDetails.disbursementAmount;
    completionDetails.disbursementOption = data.completionDetails.disbursementOption;
    opportunity.consent = {
      ...data,
      completionDetails: completionDetails
    };
    this.fullApp = opportunity;
  }

  /**
   * Updates the data of the given applicant of the Full Application.
   */
  updateFullAppApplicantData(applicantNumber: number, data: FullApplicationDetailsApplicantModel): void {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    if (applicantNumber === 2) {
      opportunity.applicant2 = {
        ...data,
        name: {
          ...opportunity.applicant2.name
        }
      };
    } else {
      opportunity.applicant1 = {
        ...data,
        name: {
          ...opportunity.applicant1.name
        }
      };
    }
    this.fullApp = opportunity;
  }

  /**
   * Gets the Property Details data of the Full Application.
   */
  getFullAppPropertyDetailsData(): FullApplicationDetailsPropertyWithNameModel {
    return this.fullApp?.propertyDetails;
  }

  updateFullAppPropertyDetailsData(data: FullApplicationDetailsPropertyModel): void {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    opportunity.propertyDetails = {
      ...data,
      name: opportunity.propertyDetails.name
    };
    this.fullApp = opportunity;
  }

  /**
   * Gets the full application credit commitments.
   */
  getFullAppCreditCommitmentsData(): FullApplicationDetailsCreditCommitmentsModel {
    return this.fullApp?.creditCommitments;
  }

  /**
   * Updates the full application credit commitments.
   */
  updateFullAppCreditCommitmentsData(data: FullApplicationDetailsCreditCommitmentsModel): void {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    opportunity.creditCommitments = {
      unsecuredCreditCommitments: opportunity.creditCommitments.unsecuredCreditCommitments?.map(
        (c: UnsecuredCreditCommitmentModel, index: number) => ({
          ...c,
          ...data.unsecuredCreditCommitments[index]
        })
      ),
      securedCreditCommitments: opportunity.creditCommitments.securedCreditCommitments?.map(
        (c: SecuredCreditCommitmentModel, index: number) => ({
          ...c,
          ...data.securedCreditCommitments[index]
        })
      )
    };
    this.fullApp = opportunity;
  }

  /**
   * Updates the uploaded other documents data of the Full Application.
   */
  updateFullAppOtherDocumentsData(documentsData: DocumentModel[]): void {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    opportunity.documents.otherV2.documents = documentsData;
    this.fullApp = opportunity;
  }

  addFullAppApplicantIncomeDocumentsData(
    applicantNumber: number,
    documentType: string,
    fileInfo: FileInfoModel
  ): ApplicantDocumentModel[] {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    let applicantDocuments: ApplicantDocumentGroup;
    if (applicantNumber === 1) {
      applicantDocuments = opportunity.documents.applicant1Income;
    } else {
      applicantDocuments = opportunity.documents.applicant2Income;
    }
    let documentModel: IncomeDocumentModel = applicantDocuments.all.find(
      (d: IncomeDocumentModel) => d.documentType === documentType
    );
    if (!documentModel) {
      for (const model of applicantDocuments.oneOf) {
        documentModel = model.find((d: IncomeDocumentModel) => d.documentType === documentType);
        if (documentModel) {
          break;
        }
      }
    }
    documentModel.files.push(fileInfo);
    this.fullApp = opportunity;
    return this.mapApplicantDocuments(applicantDocuments);
  }

  addFullAppLoanInformationAllocationPurposeDocumentsData(
    documentType: string,
    fileInfo: FileInfoModel
  ): LoanInformationDocumentModel[] {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    const loanInformationDocuments = opportunity.documents.loanInformation;

    let documentModel: AllocationPurposeDocumentModel = loanInformationDocuments.all.find(
      (d) => d.documentType === documentType
    );
    if (!documentModel) {
      for (const model of loanInformationDocuments.oneOf) {
        documentModel = model.find((d) => d.documentType === documentType);
        if (documentModel) {
          break;
        }
      }
    }
    documentModel.files.push(fileInfo);
    this.fullApp = opportunity;
    return this.mapLoanInformationDocuments(loanInformationDocuments);
  }

  addFullAppDocumentData(property: 'valuation' | 'checklist', fileInfo: FileInfoModel): DocumentModel {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    if (!opportunity.documents[property]) {
      opportunity.documents[property] = emptyDocumentModel();
    }
    if (!opportunity.documents[property].files) {
      opportunity.documents[property].files = [];
    }
    opportunity.documents[property].files.push(fileInfo);
    this.fullApp = opportunity;
    return opportunity.documents[property];
  }

  removeFullAppValuationDocumentsData(index: number): DocumentModel {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    if (!opportunity.documents?.valuation?.files?.length) {
      return null;
    }
    opportunity.documents.valuation.files.splice(index, 1);
    this.fullApp = opportunity;
    return opportunity.documents.valuation;
  }

  removeFullAppApplicantIncomeDocumentsData(applicantNumber: number, fileId: string): ApplicantDocumentModel[] {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    let applicantDocuments: ApplicantDocumentGroup;
    if (applicantNumber === 1) {
      applicantDocuments = opportunity.documents.applicant1Income;
    } else {
      applicantDocuments = opportunity.documents.applicant2Income;
    }
    let found = false;
    for (const document of applicantDocuments.all) {
      if (document.files?.length > 0) {
        const index: number = document.files.findIndex((f: FileInfoModel) => f.id === fileId);
        if (index >= 0) {
          document.files.splice(index, 1);
          found = true;
          break;
        }
      }
    }
    if (!found) {
      outer: for (const oneOf of applicantDocuments.oneOf) {
        for (const document of oneOf) {
          if (document.files?.length > 0) {
            const index: number = document.files.findIndex((f: FileInfoModel) => f.id === fileId);
            if (index >= 0) {
              document.files.splice(index, 1);
              break outer;
            }
          }
        }
      }
    }
    this.fullApp = opportunity;
    return this.mapApplicantDocuments(applicantDocuments);
  }

  removeFullAppLoanInformationAllocationPurposeDocumentsData(fileId: string): LoanInformationDocumentModel[] {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    const loanInformationDocuments = opportunity.documents.loanInformation;
    let found = false;
    for (const document of loanInformationDocuments.all) {
      if (document.files?.length > 0) {
        const index: number = document.files.findIndex((f: FileInfoModel) => f.id === fileId);
        if (index >= 0) {
          document.files.splice(index, 1);
          found = true;
          break;
        }
      }
    }
    if (!found) {
      outer: for (const oneOf of loanInformationDocuments.oneOf) {
        for (const document of oneOf) {
          if (document.files?.length > 0) {
            const index: number = document.files.findIndex((f: FileInfoModel) => f.id === fileId);
            if (index >= 0) {
              document.files.splice(index, 1);
              break outer;
            }
          }
        }
      }
    }
    this.fullApp = opportunity;
    return this.mapLoanInformationDocuments(loanInformationDocuments);
  }

  removeFullAppChecklistDocumentsData(index: number): DocumentModel {
    const opportunity: FullApplicationDetailsModel = this.fullApp;
    if (!opportunity.documents?.checklist?.files?.length) {
      return null;
    }
    opportunity.documents.checklist.files.splice(index, 1);
    this.fullApp = opportunity;
    return opportunity.documents.checklist;
  }

  // noinspection JSMethodCanBeStatic
  private mapApplicantDocuments(documents: ApplicantDocumentGroup): ApplicantDocumentModel[] {
    if (!documents) {
      return [];
    }
    const result: ApplicantDocumentModel[] = [];
    if (documents.all?.length > 0) {
      for (const document of documents.all) {
        result.push(this.mapIncomeDocument(document));
      }
    }
    if (documents.oneOf?.length > 0) {
      for (const documentSet of documents.oneOf) {
        result.push(
          documentSet
            .map((document: IncomeDocumentModel) => this.mapIncomeDocument(document))
            .reduce((previous: ApplicantDocumentModel, current: ApplicantDocumentModel) => {
              previous.documentTypes = this.mergeArrays(previous.documentTypes, current.documentTypes, false);
              previous.incomeTypes = this.mergeArrays(previous.incomeTypes, current.incomeTypes, true);
              previous.files = [...current.files, ...previous.files];
              previous.selectedDocumentType = previous.selectedDocumentType ?? current.selectedDocumentType;
              previous.hintText = previous.hintText ?? current.hintText;
              previous.documentSupportingText = previous.documentSupportingText ?? current.documentSupportingText;
              return previous;
            })
        );
      }
    }
    return result;
  }

  private mapLoanInformationDocuments(documents: LoanInformationDocumentGroup): LoanInformationDocumentModel[] {
    if (!documents) {
      return [];
    }
    const result: LoanInformationDocumentModel[] = [];
    if (documents.all?.length > 0) {
      for (const document of documents.all) {
        result.push(this.mapAllocationPurposeDocument(document));
      }
    }
    if (documents.oneOf?.length > 0) {
      for (const documentSet of documents.oneOf) {
        if (documentSet?.length > 0) {
          result.push(
            documentSet
              .map((document) => this.mapAllocationPurposeDocument(document))
              .reduce((previous, current) => {
                previous.documentTypes = this.mergeArrays(previous.documentTypes, current.documentTypes, false);
                previous.allocationPurpose = this.mergeArrays(
                  previous.allocationPurpose,
                  current.allocationPurpose,
                  true
                );
                previous.files = [...current.files, ...previous.files];
                previous.selectedDocumentType = previous.selectedDocumentType ?? current.selectedDocumentType;
                previous.hintText = previous.hintText ?? current.hintText;
                previous.documentSupportingText = previous.documentSupportingText ?? current.documentSupportingText;
                return previous;
              })
          );
        }
      }
    }
    return result;
  }

  // noinspection JSMethodCanBeStatic
  private mergeArrays(previous: string[], current: string[], sortItems: boolean): string[] {
    let merged: string[] = [...previous, ...current];
    if (sortItems) {
      merged = merged.sort();
    }
    merged = merged.filter((item: string, index: number) => merged.indexOf(item) === index);
    return merged;
  }

  // noinspection JSMethodCanBeStatic
  private mapIncomeDocument(document: IncomeDocumentModel): ApplicantDocumentModel {
    return {
      incomeTypes: document.incomeTypes,
      documentTypes: [document.documentType],
      selectedDocumentType: document.files?.length > 0 ? document.documentType : null,
      ...this.mapBaseDocument(document)
    };
  }

  private mapAllocationPurposeDocument(document: AllocationPurposeDocumentModel): LoanInformationDocumentModel {
    return {
      allocationPurpose: document.allocationPurpose,
      documentTypes: [document.documentType],
      selectedDocumentType: document.files?.length > 0 ? document.documentType : null,
      ...this.mapBaseDocument(document)
    };
  }

  private mapBaseDocument(document: BaseDocumentModel): BaseDocumentModel {
    return {
      id: document.id,
      explanation: document.explanation,
      title: document.title,
      files: [...(document.files ?? [])],
      hintText: document.hintText,
      documentSupportingText: document.documentSupportingText
    };
  }
}
