import { Component, Input } from '@angular/core';

export type LabelType = 'text' | 'html';

@Component({
  selector: 'broker-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  @Input() for = '';
  @Input() label = '';
  @Input() tooltip: string = undefined;
  @Input() cssClasses = '';
  @Input() type: LabelType = 'text';
}
