<div class='consumer-flow'>
  <h1 class='mt-5'>{{ 'authentication.login.title' | translate: lang }}</h1>

  <div class='warning mt-4' *ngIf='loginForm.errors?.invalid'>
    {{ 'authentication.login.error' | translate: lang }}
  </div>

  <form [formGroup]='loginForm' (ngSubmit)='login()' novalidate [ngClass]="loginForm.errors?.invalid ? 'mt-4' : 'mt-5'">
    <div class='mb-3'>
      <broker-label
        [for]="'email'"
        [label]="'authentication.login.form.email.label' | translate:lang">
      </broker-label>
      <mat-form-field appearance='standard'>
        <input
          type='email'
          matInput
          id='email'
          autocomplete='username'
          [formControl]='emailControl'
          [placeholder]="'authentication.login.form.email.placeholder' | translate: lang"
          (blur)='trimValue(emailControl)'
        />
      </mat-form-field>
      <mat-error *ngIf='emailControl.errors?.email'>
        {{ 'authentication.login.form.email.errors.email' | translate: lang }}
      </mat-error>
    </div>

    <div class='mb-3'>
      <broker-label
        [for]="'password'"
        [label]="'authentication.login.form.password.label' | translate:lang">
      </broker-label>
      <mat-form-field appearance='standard'>
        <input
          type='password'
          matInput
          id='password'
          autocomplete='current-password'
          [formControl]='passwordControl'
          [placeholder]="'authentication.login.form.password.placeholder' | translate: lang"
          (blur)='trimValue(passwordControl)'
        />
      </mat-form-field>
    </div>

    <div class='mb-3 text-end'>
      <a [routerLink]="['/recover']">{{ 'authentication.login.forgot.label' | translate: lang }}</a>
    </div>

    <broker-button [id]="'login'" [height]="'large'" [width]="'full-width'"
                   [disabled]='!loginForm.valid || disabled'>{{ 'authentication.login.submit' | translate: lang }}</broker-button>

    <p class='mt-4'>
      <span class='me-2'>{{ 'authentication.login.register.label' | translate: lang }} </span>
      <a [routerLink]="['/auth/register']">{{ 'authentication.login.register.create' | translate: lang }}</a>
    </p>
  </form>
</div>
