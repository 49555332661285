import {
  CcjStatusType,
  CreditCommitmentStatus
} from '../../../../shared/consumer/dip/model/credit-commitments-constants';

export function asSortableCcj(status: CcjStatusType): string {
  switch (status) {
    case 'Active':
      return '1';
    case 'Satisfied':
      return '2';
    default:
      return '3';
  }
}
export function asSortable(status: CreditCommitmentStatus): string {
  switch (status) {
    case 'Active':
      return '1';
    case 'Delinquent':
      return '2';
    case 'Default':
      return '3';
    case 'Settled':
      return '4';
    default:
      return '5';
  }
}
