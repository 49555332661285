import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { LeftPanelConsumerComponent } from './left-panel-consumer.component';
import { MaterialModule } from '../../../material/material.module';
import { ConsumerStepperModule } from '../../stepper/consumer-stepper.module';
import { SvgModule } from '../../svg/svg.module';
import { ButtonModule } from '../../button/button.module';

@NgModule({
  declarations: [LeftPanelConsumerComponent],
  imports: [CommonModule, L10nTranslationModule, MaterialModule, ConsumerStepperModule, SvgModule, ButtonModule],
  exports: [LeftPanelConsumerComponent]
})
export class LeftPanelConsumerModule {}
