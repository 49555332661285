import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { NeedHelpComponent } from './need-help.component';
import { SvgModule } from '../svg/svg.module';

@NgModule({
  declarations: [NeedHelpComponent],
  imports: [CommonModule, L10nTranslationModule, SvgModule],
  exports: [NeedHelpComponent]
})
export class NeedHelpModule {}
