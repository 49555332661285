/**
 * The stage name enum.
 */
export enum StageNameEnum {
  DIP_FACILITY_DETAILS = 'DIP - Funding Requirement',
  DIP_PROPERTY_DETAILS = 'DIP - Property Details',
  DIP_APPLICANT_1 = 'DIP - Applicant 1 Details',
  DIP_APPLICANT_2 = 'DIP - Applicant 2 Details',
  DIP_CREDIT_COMMITMENTS = 'DIP - Credit Commitments',
  DIP_EXPENDITURE = 'DIP - Expenditure',
  DIP_BROKER_FEES = 'DIP - Broker Fees',
  DECISIONING = 'Decisioning',
  DIP_QUALIFIED = 'DIP - Qualified',
  APPLICATION = 'Application',
  UNDERWRITING = 'Underwriting',
  OFFER = 'Offer',
  FUNDED = 'Funded',
  CLOSED = 'Closed',
  FACT_FIND = 'Fact Find',
  SQL = 'SQL'
}
