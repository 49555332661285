import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';

// Interceptor Constants
const CONTENT_TYPE = 'Content-Type';
const ACCEPT = 'Accept';
const AUTHORIZATION = 'Authorization';
const X_USER_PROFILE = 'X-User-Profile';
const APPLICATION_JSON_MIME_TYPE = 'application/json';

type StringFunction = (value: string) => string;
type HasHeaderFunction = (request: HttpRequest<any>, header: string) => boolean;
type HasBodyFunction = (request: HttpRequest<any>) => boolean;

const bearer: StringFunction = (value: string): string => {
  return `Bearer ${value}`;
};

const hasHeader: HasHeaderFunction = (request: HttpRequest<any>, header: string): boolean => {
  return request?.headers?.has(header);
};

const hasBody: HasBodyFunction = (request: HttpRequest<any>): boolean => {
  return request.method === 'POST' || request.method === 'PUT' || request.method === 'PATCH';
};

/**
 * Session Expired Error Codes
 */
export const sessionExpiredErrorCodes: number[] = [401, 403];

/**
 * Interceptor for token authentication.
 */
export class TokenInterceptor implements HttpInterceptor {
  private isTokenInvalid = false;

  private _router: Router;

  private _authService: AuthService;

  constructor(private readonly injector: Injector) {}

  private get authService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  private get router(): Router {
    if (!this._router) {
      this._router = this.injector.get(Router);
    }
    return this._router;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.authService.getAccessToken()).pipe(
      switchMap((tokens: [string, string]) => {
        this.isTokenInvalid = !tokens;
        const nextRequest: HttpRequest<any> = this.setHeaders(request, tokens);
        return next.handle(nextRequest).pipe(
          catchError((error: any) => {
            if (error instanceof HttpErrorResponse) {
              if (this.isTokenInvalid && sessionExpiredErrorCodes.indexOf(error.status) !== -1) {
                this.handleSessionError();
              }
            }
            return throwError(error);
          })
        );
      })
    );
  }

  private setHeaders(request: HttpRequest<any>, tokens: [string, string]): HttpRequest<any> {
    const headers: any = {};
    let mutate = false;

    if (!hasHeader(request, CONTENT_TYPE) && hasBody(request) && !(request.body instanceof FormData)) {
      headers[CONTENT_TYPE] = APPLICATION_JSON_MIME_TYPE;
      mutate = true;
    }
    if (!hasHeader(request, ACCEPT)) {
      headers[ACCEPT] = APPLICATION_JSON_MIME_TYPE;
      mutate = true;
    }

    // Does not add the authorization header if we don't have access token, if the request is not to assets and
    // if the request is not to fetch postcodes.
    // Browser responds 400 if we request assets with a large authorization header.
    const isPostCode: boolean = request.url.indexOf(environment.postcodes.apiPath) >= 0;
    if (tokens?.length >= 2 && request.url.indexOf('/assets/') < 0 && !isPostCode) {
      headers[AUTHORIZATION] = bearer(tokens[0]);
      headers[X_USER_PROFILE] = tokens[1];
      mutate = true;
    }

    return mutate ? request.clone({ setHeaders: headers }) : request;
  }

  private handleSessionError(): void {
    this.isTokenInvalid = true;
    this._authService.logout().then(() => {
      this.router.navigate(['auth/login']).catch();
    });
  }
}
