export enum CreditCommitmentsControlNamesEnum {
  SECURED_CREDIT_COMMITMENTS = 'securedCreditCommitments',
  UNSECURED_CREDIT_COMMITMENTS = 'unsecuredCreditCommitments',
  CCJS = 'ccjs',

  SECURITY_PROPERTY = 'securityProperty',
  CONSOLIDATE = 'consolidate',
  IGNORE = 'ignore',
  SOURCE = 'source',
  STATUS = 'status',
  ID = 'id',

  DATA = 'data',

  APPLICANTS = 'applicants',
  TYPE = 'type',
  OUTSTANDING_BALANCE = 'outstandingBalance',
  MONTHLY_PAYMENTS = 'monthlyPayments',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  AMOUNT_TO_CONSOLIDATE = 'amountToConsolidate',
  REPAYMENT_TYPE = 'repaymentType',
  REPAYMENT_VEHICLE = 'repaymentVehicle',
  COST_OF_REPAYMENT_VEHICLE = 'costOfRepaymentVehicle',
  INTEREST_ONLY_BALANCE = 'interestOnlyBalance',
  INTEREST_RATE_TYPE = 'interestRateType',
  FIXED_RATE_END_DATE = 'fixedRateEndDate',
  REASON_TO_IGNORE = 'reasonToIgnore',
  SETTLEMENT_DATE = 'settlementDate',

  REGISTRATION_DATE = 'registrationDate',
  CATEGORY = 'category',
  AMOUNT = 'amount',
  SATISFACTION_DATE = 'satisfactionDate'
}
