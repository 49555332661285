import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../shared/localization/localizable.component';
import { CreditCheckExpiryService } from '../../shared/services/credit-check-expiry/credit-check-expiry.service';
import { TableItemModel } from '../../shared/models/table-item.model';
import { PaginationMetadataModel } from '../../shared/consumer/model/dashboard.model';
import { ApplicationsPageSortSearchFilter } from '../../shared/services/dashboard/dashboard.service';

@Component({
  selector: 'broker-dashboard-tab',
  templateUrl: './dashboard-tab.component.html',
  styleUrls: ['./dashboard-tab.component.scss']
})
export class DashboardTabComponent extends LocalizableComponent {
  @Input() totalOfApplications = 0;
  @Input() isAdminViewEnabled = false;
  @Input() isAnyAccountViewEnabled = false;
  @Input() tableRecords: TableItemModel[] = [];
  @Input() tableMetadata: PaginationMetadataModel;
  @Input() isCompanySelected = false;
  @Input() isLoadingResults = false;
  @Output() fetchApplications = new EventEmitter<ApplicationsPageSortSearchFilter>();

  get isToShowCreditCheckExpiryColumn(): boolean {
    return this.creditCheckExpiry.isToShowCreditCheckExpiry;
  }

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale, private readonly creditCheckExpiry: CreditCheckExpiryService) {
    super(locale);
  }
}
