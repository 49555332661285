import { Component, EventEmitter, Inject, Output } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../../../localization/localizable.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'broker-credit-check-expiry-dialog',
  templateUrl: './credit-check-expiry-dialog.component.html'
})
export class CreditCheckExpiryDialogComponent extends LocalizableComponent {
  @Output() submitEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  isLoading: boolean;
  requestError: boolean;

  get cancelButtonKey(): string {
    return `dialog.credit-check-expiry.${this.isFromCCsPage ? 'cancel' : 're-run-later'}`;
  }

  get isFromCCsPage(): boolean {
    return this.data.isToReRunOnCcsPage;
  }

  constructor(
    @Inject(L10N_LOCALE) locale: L10nLocale,
    @Inject(MAT_DIALOG_DATA) private data: { isToReRunOnCcsPage: boolean }
  ) {
    super(locale);
  }
}
