import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonStyle = 'primary' | 'secondary' | 'outline-dark' | 'outline-light';
export type ButtonHeight = 'small' | 'medium' | 'large';
export type ButtonWidth = 'compact' | 'expanded' | 'full-width';
export type IconPosition = 'left' | 'right';
export type IconVariant = 'arrow' | 'add' | 'x' | 'remove';

@Component({
  selector: 'broker-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() id = '';
  @Input() style: ButtonStyle = 'primary';
  @Input() height: ButtonHeight = 'medium';
  @Input() width: ButtonWidth = 'expanded';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() icon: IconVariant = null;
  @Input() iconPosition: IconPosition = 'left';
  @Output() clickEvent: EventEmitter<void> = new EventEmitter<void>();
}
