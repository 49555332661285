import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BaseOpportunityModel, DecisionType } from '../../consumer/base-opportunity.model';
import { StageNameEnum } from '../../consumer/dip/enum/stage-name.enum';
import { SubStageNameEnum } from '../../consumer/dip/enum/sub-stage-name.enum';
import { LoggerService } from '../logger/logger.service';
import { MixpanelEventsEnum } from '../mixpanel/enum/mixpanel-events.enum';
import { MixpanelService } from '../mixpanel/mixpanel.service';
import { BaseRouteEnum } from '../../enums/route/base-route.enum';
import { DipRouteEnum } from '../../enums/route/dip-route.enum';
import { FullAppRouteEnum } from '../../enums/route/full-app-route.enum';

export interface NavigationState {
  skipFetchDipDetails: boolean;
}

/**
 * Service to handle routing navigations.
 */
@Injectable({
  providedIn: 'root'
})
export class NavigatorService {
  /**
   * URL of the details child. Is filled when the guard checks if is a child from the details route and contains the
   * additional parameters that needs to be added to the navigation.
   */
  fullAppDetailsChildUrl: string[] = [];

  /**
   * Flag that indicates if it was a table click action. If true then the fullAppDetailsChildUrl should not be added to
   * the navigation.
   */
  isFromTableClick = false;

  constructor(
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly mixpanelService: MixpanelService
  ) {
    this.fullAppDetailsChildUrl = [];
    this.isFromTableClick = false;
  }

  // DASHBOARD

  /**
   * Navigates to dashboard route.
   */
  navigateToDashboard(): void {
    this.router.navigate([BaseRouteEnum.EMPTY]).catch(() => this.logger.error('Error navigating to dashboard'));
  }

  // DIP

  /**
   * Navigates to the DIP form route.
   */
  navigateToDipForm(opportunityID: string = null): Promise<boolean> {
    const commands: string[] = [DipRouteEnum.DIP];
    if (opportunityID?.length > 0) {
      commands.push(opportunityID);
    }

    return this.router.navigate(commands);
  }

  /**
   * Navigates to a DIP page.
   */
  navigateToDipPage(id: string, stage: DipRouteEnum, state?: NavigationState): Promise<boolean> {
    const commands: string[] = [DipRouteEnum.DIP, id, stage];
    return this.router.navigate(commands, { replaceUrl: true, ...(state && { state: state }) });
  }

  // FULL APP

  /**
   * Navigates to Full App base route.
   */
  navigateToFullApp(opportunityID: string): Promise<boolean | void> {
    const commands: string[] = [FullAppRouteEnum.FULL_APP, opportunityID];
    return this.router
      .navigate(commands)
      .catch(() => this.logger.error('Error navigating to full application details'));
  }

  /**
   * Navigates to Full App specific route.
   */
  navigateToFullAppPage(id: string, stage: FullAppRouteEnum, replaceUrl = false): Promise<boolean> {
    const commands: string[] = [FullAppRouteEnum.FULL_APP, id, stage];
    return this.router.navigate(commands, { replaceUrl });
  }

  /**
   * Redirect user to the appropriate route, according to the stage and the stage reason of the full application.
   */
  redirectToAppropriateRoute(id: string, application: BaseOpportunityModel): Promise<boolean> {
    if (!id?.length || !application) {
      return Promise.reject('Neither id or application provided.');
    }

    const stage: StageNameEnum = application.stage;
    const subStage: SubStageNameEnum = application.subStage;
    const decision: DecisionType = application.decision;

    switch (stage) {
      case StageNameEnum.FACT_FIND:
      case StageNameEnum.SQL:
      case StageNameEnum.DIP_FACILITY_DETAILS:
      case StageNameEnum.DIP_PROPERTY_DETAILS:
      case StageNameEnum.DIP_APPLICANT_1:
      case StageNameEnum.DIP_APPLICANT_2:
      case StageNameEnum.DIP_CREDIT_COMMITMENTS:
      case StageNameEnum.DIP_EXPENDITURE:
      case StageNameEnum.DIP_BROKER_FEES:
        return this.navigateToDipForm(id);
      case StageNameEnum.DECISIONING:
        if (subStage === SubStageNameEnum.ERROR || decision === 'ERROR') {
          return this.navigateToDipPage(id, DipRouteEnum.DECISIONING_ERROR);
        }
        return this.navigateToDipPage(id, DipRouteEnum.DECISIONING);
      case StageNameEnum.DIP_QUALIFIED:
        this.mixpanelService.trackEventWithReference(MixpanelEventsEnum.DIP_COMPLETE, application.reference);
        if (decision === 'ACCEPT') {
          if (subStage === SubStageNameEnum.PRODUCT_SELECTION) {
            return this.navigateToDipPage(id, DipRouteEnum.PRODUCT_SELECTION);
          }
          return this.navigateToDipPage(id, DipRouteEnum.CUSTOMER_ACCEPTANCE);
        }
        if (decision === 'DECLINE') {
          if (subStage === SubStageNameEnum.PRODUCT_SELECTION) {
            return this.navigateToDipPage(id, DipRouteEnum.PRODUCT_SELECTION);
          }
          return this.navigateToDipPage(id, DipRouteEnum.CUSTOMER_ACCEPTANCE);
        }
        return this.navigateToDipPage(id, DipRouteEnum.DECISIONING_ERROR);
      case StageNameEnum.CLOSED:
        return this.navigateToFullAppPage(id, FullAppRouteEnum.CLOSED, true);
      default:
        return this.navigateToFullAppDetails(id);
    }
  }

  private navigateToFullAppDetails(id: string): Promise<boolean> {
    const commands: string[] = [FullAppRouteEnum.FULL_APP, id, FullAppRouteEnum.DETAILS];

    if (this.isFromTableClick) {
      this.isFromTableClick = false;
      this.fullAppDetailsChildUrl = [];
    } else if (this.fullAppDetailsChildUrl?.length) {
      this.fullAppDetailsChildUrl.forEach((value: string) => commands.push(value));
      this.fullAppDetailsChildUrl = [];
    }

    return this.router.navigate(commands, { replaceUrl: true });
  }
}
