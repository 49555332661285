import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { getConsumerOpportunityModalData } from '../../../consumer/dip/form/broker-fees/mappers/broker-fees-consumer-opportunity.mapper';
import { CloseCaseDialogModel } from '../../components/consumer/dialogs/close-case-dialog/model/close-case-dialog.model';
import { ConfirmDialogModel } from '../../components/consumer/dialogs/confirm-dialog/model/confirm-dialog.model';
import { ConfirmSubmissionDialogModel } from '../../components/consumer/dialogs/confirm-submission-dialog/model/confirm-submission-dialog.model';
import { MissingInformationDialogModel } from '../../components/consumer/dialogs/missing-information-dialog/model/missing-information-dialog.model';
import { OpportunitySubmissionDetailsDialogComponent } from '../../components/consumer/dialogs/opportunity-submission-details-dialog/opportunity-submission-details-dialog.component';
import { CloseReasonEnum } from '../../consumer/details/enum/close-reason.enum';
import { ConsumerFormDipService } from '../../consumer/dip/consumer-form-dip.service';
import { DialogTypeEnum } from '../../consumer/dip/enum/dialog-type.enum';
import { LocalizationService } from '../../localization/localization.service';
import { newSelectItem } from '../../models/select-item.model';
import { ConsumerStorageDipService } from '../../consumer/storage/consumer-storage-dip.service';
import { ConsumerStorageFullAppService } from '../../consumer/storage/consumer-storage-full-app.service';

/**
 * Service to retrieve dialogs configurations.
 */
@Injectable({
  providedIn: 'root'
})
export class DialogConfigurationService {
  constructor(
    private readonly consumerFormDipService: ConsumerFormDipService,
    private readonly localization: LocalizationService,
    private readonly consumerStorageDip: ConsumerStorageDipService,
    private readonly consumerStorageFullApp: ConsumerStorageFullAppService
  ) {}

  /**
   * Registers and loads each dialog configuration data
   */
  loadDialogConfiguration(dialogType: DialogTypeEnum, hideCancel?: boolean): MatDialogConfig {
    switch (dialogType) {
      case DialogTypeEnum.CONSUMER_OPPORTUNITY_SUBMISSION_DIALOG:
        return this.getConsumerOpportunitySubmissionDialogConfiguration();
      case DialogTypeEnum.CONSUMER_DIP_MISSING_INFORMATION:
        return this.getConsumerDipMissingInformationDialogConfiguration();
      case DialogTypeEnum.CONSUMER_DIP_PRODUCT_FEE_WARNING:
        return this.getConsumerDipProductFeeDialogConfiguration();
      case DialogTypeEnum.CONSUMER_DIP_CONFIRM_CHANGE_DIALOG:
        return this.getConsumerDipConfirmChangeDialogConfiguration(hideCancel);
      case DialogTypeEnum.CONSUMER_CLOSE_CASE_DIALOG:
        return this.getCloseCaseDialogConfiguration();
      case DialogTypeEnum.CONSUMER_FULL_APPLICATION_SUBMISSION_DIALOG:
        return this.getConsumerFullApplicationSubmissionDialogConfiguration();
      case DialogTypeEnum.CREDIT_COMMITMENTS_INVALID_FORM_DIALOG:
        return this.getCreditCommitmentsInvalidFormDialogConfiguration();
      case DialogTypeEnum.CREDIT_COMMITMENTS_UNSAVED_CHANGES_DIALOG:
        return this.getCreditCommitmentsUnsavedChangesDialogConfiguration();
      case DialogTypeEnum.DOCUMENTS_INVALID_FORM_DIALOG:
        return this.getDocumentsInvalidFormDialogConfiguration();
      case DialogTypeEnum.DOCUMENTS_UNSAVED_CHANGES_DIALOG:
        return this.getDocumentsUnsavedChangesDialogConfiguration();
      case DialogTypeEnum.DISCARD_CHANGES_DIALOG:
        return this.getDiscardChangesDialogConfiguration();
      case DialogTypeEnum.PROPERTY_VALUATION_CHANGE_DIALOG:
        return this.getPropertyValuationChangeDialogConfiguration();
      case DialogTypeEnum.CREDIT_CHECK_EXPIRY_DIALOG:
        return this.getCreditCheckExpiryDialogConfiguration();
      case DialogTypeEnum.WHATS_NEW_DIALOG:
        return this.getWhatsNewDialogConfiguration();
      default:
        break;
    }
  }

  /**
   * Loads close case dialog modal configuration
   */
  private getCloseCaseDialogConfiguration(): MatDialogConfig<CloseCaseDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.close-case.title'),
        label: this.localization.translate('dialog.close-case.label'),
        backButton: this.localization.translate('dialog.close-case.cancelButton'),
        nextButton: this.localization.translate('dialog.close-case.nextButton'),
        dropdownPlaceholder: this.localization.translate('consumer.form.please-select'),
        dropdownCaseClosedOptions: Object.entries(CloseReasonEnum).map(([key, value]: string[]) =>
          newSelectItem(this.localization.translate(`dialog.close-case.options.${key}`), value)
        )
      }
    };
  }

  /**
   * Loads consumer opportunity submission dialog modal configuration
   */
  private getConsumerOpportunitySubmissionDialogConfiguration(): MatDialogConfig<ConfirmDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        component: OpportunitySubmissionDetailsDialogComponent,
        modalData: getConsumerOpportunityModalData(
          this.consumerFormDipService.facilityDetailsFormGroup,
          this.consumerFormDipService.propertyDetailsFormGroup,
          this.consumerFormDipService.applicant1FormGroup,
          this.consumerFormDipService.applicant2FormGroup,
          this.localization
        ),
        backButton: this.localization.translate('dialog.consumer-opportunity-submission.backButton'),
        nextButton: this.localization.translate('dialog.consumer-opportunity-submission.nextButton'),
        title: this.localization.translate('dialog.consumer-opportunity-submission.title'),
        infoPanelMessage: null
      }
    };
  }

  private getConsumerFullApplicationSubmissionDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.full-application-submission.title'),
        messages: [
          this.localization.translate('dialog.full-application-submission.label.1'),
          this.localization.translate('dialog.full-application-submission.label.2')
        ],
        cancelButton: this.localization.translate('dialog.full-application-submission.cancel-button'),
        submitButton: this.localization.translate('dialog.full-application-submission.submit-button')
      }
    };
  }

  private getConsumerDipMissingInformationDialogConfiguration(): MatDialogConfig<MissingInformationDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.missing-information.title'),
        infoPanelMessage: this.localization.translate('dialog.missing-information.message'),
        backButton: this.localization.translate('dialog.missing-information.back-button')
      }
    };
  }

  /**
   * Loads consumer DIP product fee dialog modal configuration
   */
  private getConsumerDipProductFeeDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.product-fee.refresh.title'),
        messages: [
          this.localization.translate('dialog.product-fee.refresh.label.1'),
          this.localization.translate('dialog.product-fee.refresh.label.2')
        ],
        cancelButton: this.localization.translate('dialog.product-fee.refresh.cancel-button'),
        submitButton: this.localization.translate('dialog.product-fee.refresh.submit-button')
      }
    };
  }

  /**
   * Loads consumer DIP product fee dialog modal configuration
   */
  private getConsumerDipConfirmChangeDialogConfiguration(
    hideCancel: boolean
  ): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.confirm-change.title'),
        messages: [this.localization.translate('dialog.confirm-change.label.1')],
        cancelButton: this.localization.translate('dialog.confirm-change.cancel-button'),
        submitButton: this.localization.translate('dialog.confirm-change.submit-button'),
        hideCancel: hideCancel
      }
    };
  }

  /**
   * Loads credit commitments invalid form dialog modal configuration
   */
  private getCreditCommitmentsInvalidFormDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.credit-commitments-invalid-form.title'),
        messages: [this.localization.translate('dialog.credit-commitments-invalid-form.label.1')],
        cancelButton: this.localization.translate('dialog.credit-commitments-invalid-form.cancel-button'),
        submitButton: this.localization.translate('dialog.credit-commitments-invalid-form.submit-button'),
        hideArrow: true
      }
    };
  }

  /**
   * Loads credit commitments invalid form dialog modal configuration
   */
  private getCreditCommitmentsUnsavedChangesDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.credit-commitments-unsaved-changes.title'),
        messages: [this.localization.translate('dialog.credit-commitments-unsaved-changes.label.1')],
        cancelButton: this.localization.translate('dialog.credit-commitments-unsaved-changes.cancel-button'),
        submitButton: this.localization.translate('dialog.credit-commitments-unsaved-changes.submit-button'),
        hideArrow: true
      }
    };
  }

  /**
   * Loads documents invalid form dialog modal configuration
   */
  private getDocumentsInvalidFormDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.documents-invalid-form.title'),
        messages: [this.localization.translate('dialog.documents-invalid-form.label.1')],
        cancelButton: this.localization.translate('dialog.documents-invalid-form.cancel-button'),
        submitButton: this.localization.translate('dialog.documents-invalid-form.submit-button'),
        hideArrow: true
      }
    };
  }

  /**
   * Loads documents invalid form dialog modal configuration
   */
  private getDocumentsUnsavedChangesDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.documents-unsaved-changes.title'),
        messages: [this.localization.translate('dialog.documents-unsaved-changes.label.1')],
        cancelButton: this.localization.translate('dialog.documents-unsaved-changes.cancel-button'),
        submitButton: this.localization.translate('dialog.documents-unsaved-changes.submit-button'),
        hideArrow: true
      }
    };
  }

  /**
   * Loads documents invalid form dialog modal configuration
   */
  private getDiscardChangesDialogConfiguration(): MatDialogConfig<ConfirmSubmissionDialogModel> {
    return {
      ...this.getDefaultMatDialogConfigurations(),
      data: {
        title: this.localization.translate('dialog.discard-changes.title'),
        messages: [this.localization.translate('dialog.discard-changes.title')],
        cancelButton: this.localization.translate('dialog.discard-changes.cancel-button'),
        submitButton: this.localization.translate('dialog.discard-changes.submit-button'),
        hideArrow: true
      }
    };
  }

  private getPropertyValuationChangeDialogConfiguration(): MatDialogConfig {
    return {
      data: {
        oldValuation: this.consumerStorageDip.propertyEstimatedValue,
        newValuation: this.consumerStorageFullApp.propertyValuation,
        type: this.consumerStorageFullApp.propertyValuationType
      },
      ...this.getDefaultMatDialogConfigurations('dialog-container', '460')
    };
  }

  private getCreditCheckExpiryDialogConfiguration(): MatDialogConfig {
    return this.getDefaultMatDialogConfigurations('dialog-container', '460');
  }

  private getWhatsNewDialogConfiguration(): MatDialogConfig {
    return this.getDefaultMatDialogConfigurations(
      ['dialog-container-whats-new', 'whats-new-popup-slide-in-animation'],
      null
    );
  }

  private getDefaultMatDialogConfigurations(
    panelClass: string | string[] = 'confirm-dialog-container',
    width: '600' | '460' = '600'
  ): MatDialogConfig {
    return {
      disableClose: true,
      autoFocus: false,
      width: `${width}px`,
      maxWidth: '100%',
      hasBackdrop: true,
      panelClass,
      backdropClass: '',
      scrollStrategy: new NoopScrollStrategy()
    };
  }
}
