import { BaseDocumentModel } from './base-document.model';

/**
 * Interface for the documents data models.
 */
export interface DocumentModel extends BaseDocumentModel {
  documentType: string;
}

export interface IncomeDocumentModel extends DocumentModel {
  incomeTypes: string[];

  /**
   * The document supporting text.
   */
  documentSupportingText: string;
}

export interface AllocationPurposeDocumentModel extends DocumentModel {
  allocationPurpose: string[];
}

export const emptyDocumentModel: () => DocumentModel = (): DocumentModel => ({
  documentType: '',
  files: []
});
