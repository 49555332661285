import { Injectable } from '@angular/core';

import { CalculationOfferStatusResponseModel } from '../dip/model/calculation-offer-status.model';
import { CreditCommitmentsViewModel } from '../dip/model/view-model/credit-commitments-details.view-model';
import { DecisionInPrincipleDetailsModel } from '../dip/model/decision-in-principle-details.model';
import { DecisionType } from '../base-opportunity.model';
import { StageNameEnum } from '../dip/enum/stage-name.enum';
import { StepperService } from '../stepper/stepper.service';
import { StorageKeyEnum } from '../../services/storage/enum/storage-key.enum';
import { StorageService } from '../../services/storage/storage.service';
import { SubStageNameEnum } from '../dip/enum/sub-stage-name.enum';
import { StorageUserOpportunityModel } from './model/storage-user-opportunity.model';
import { FacilityAllocationModel } from '../dip/model/facility-allocation.model';
import { toDateOrNull } from '../../utils/date.util';
import { IsFactFindApplication, IsSqlApplication } from '../../utils/stage.utils';

/**
 * Service to handle the storage for the consumer DIP flow.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsumerStorageDipService {
  constructor(private readonly stepperService: StepperService, private readonly storage: StorageService) {}

  /**
   * Gets the DIP data from the localstorage.
   */
  get dipData(): DecisionInPrincipleDetailsModel {
    return JSON.parse(this.storage.getValue(StorageKeyEnum.DIP_DATA));
  }

  /**
   * Sets the DIP data to the localstorage.
   */
  set dipData(value: DecisionInPrincipleDetailsModel) {
    this.storage.saveValue(StorageKeyEnum.DIP_DATA, value ? JSON.stringify(value) : null);
  }

  /**
   * Gets the DIP offers data from the localstorage.
   */
  get offersData(): CalculationOfferStatusResponseModel {
    return JSON.parse(this.storage.getValue(StorageKeyEnum.DIP_OFFERS_DATA));
  }

  /**
   * Sets the DIP offers data to the localstorage.
   */
  set offersData(value: CalculationOfferStatusResponseModel) {
    this.storage.saveValue(StorageKeyEnum.DIP_OFFERS_DATA, value ? JSON.stringify(value) : null);
  }

  /**
   * Gets the credit commitments data.
   */
  get creditCommitmentsData(): CreditCommitmentsViewModel {
    return JSON.parse(this.storage.getValue(StorageKeyEnum.DIP_CREDIT_COMMITMENTS_DATA));
  }

  /**
   * Sets the credit commitments data.
   */
  set creditCommitmentsData(value: CreditCommitmentsViewModel) {
    if (this.dipData) {
      const existingDipData: DecisionInPrincipleDetailsModel = this.dipData;
      existingDipData.dipCreditCommitments = value;
      this.dipData = existingDipData;
    }
    this.storage.saveValue(StorageKeyEnum.DIP_CREDIT_COMMITMENTS_DATA, value ? JSON.stringify(value) : null);
  }

  get creditCommitmentsExpiryDate(): Date | null {
    return toDateOrNull(this.creditCommitmentsData?.creditCheckExpiry);
  }

  /**
   * Gets the DIP id.
   */
  get dipId(): string {
    return this.dipData?.id;
  }

  /**
   * Gets the DIP stage.
   */
  get dipStage(): StageNameEnum {
    return this.dipData?.stage;
  }

  /**
   * Gets the DIP stage.
   */
  get inDipStage(): boolean {
    switch (this.dipData?.stage) {
      case StageNameEnum.DIP_FACILITY_DETAILS:
      case StageNameEnum.DIP_PROPERTY_DETAILS:
      case StageNameEnum.DIP_APPLICANT_1:
      case StageNameEnum.DIP_APPLICANT_2:
      case StageNameEnum.DIP_CREDIT_COMMITMENTS:
      case StageNameEnum.DIP_EXPENDITURE:
      case StageNameEnum.DIP_BROKER_FEES:
        return true;
      default:
        return false;
    }
  }

  /**
   * Gets the DIP sub stage.
   */
  get dipSubStage(): SubStageNameEnum {
    return this.dipData?.subStage;
  }

  /**
   * Gets the DIP decision.
   */
  get dipDecision(): DecisionType {
    return this.dipData?.decision;
  }

  /**
   * Gets the DIP reference number.
   */
  get dipReference(): string | undefined {
    return this.dipData?.reference;
  }

  /**
   * Sets the user opportunities data to the localstorage.
   */
  set userOpportunities(value: StorageUserOpportunityModel[]) {
    this.storage.saveValue(StorageKeyEnum.USER_OPPORTUNITIES, value ? JSON.stringify(value) : null);
  }

  /**
   * Gets the user opportunities data.
   */
  get userOpportunities(): StorageUserOpportunityModel[] {
    return JSON.parse(this.storage.getValue(StorageKeyEnum.USER_OPPORTUNITIES));
  }

  get propertyEstimatedValue(): number {
    return this.dipData?.propertyDetails?.estimatedValue;
  }

  get facilityDetails(): FacilityAllocationModel[] {
    return this.dipData?.loanInformation?.facilities || [];
  }

  updateSavedStage(stage: StageNameEnum): void {
    const data: DecisionInPrincipleDetailsModel = { ...this.dipData };

    const shouldNotUpdateLocalStage = IsFactFindApplication(data.stage) || IsSqlApplication(data.stage);
    if (shouldNotUpdateLocalStage) {
      return;
    }

    if (this.isStageAfter(stage, data.stage)) {
      data.stage = stage;
      this.dipData = data;
    }
  }

  private isStageAfter(newStage: StageNameEnum, currentStage: StageNameEnum): boolean {
    if (!currentStage) {
      return true;
    }

    const currentStepNumber: number = this.stepperService.getStepNumber(currentStage);
    const newStepNumber: number = this.stepperService.getStepNumber(newStage);

    return newStepNumber > currentStepNumber;
  }
}
