export enum ApplicantIncomeControlNamesEnum {
  SALARY_INPUT = 'salaryInput',

  BONUS_QUESTION = 'bonusQuestion',
  BONUS_INPUT = 'bonusInput',

  COMMISSION_QUESTION = 'commissionQuestion',
  COMMISSION_INPUT = 'commissionInput',

  OVERTIME_QUESTION = 'overtimeQuestion',
  OVERTIME_INPUT = 'overtimeInput',

  DIRECTOR_SALARY_INPUT = 'directorSalaryInput',
  DIVIDENDS_INPUT = 'dividendsInput',
  NET_PROFIT_INPUT = 'netProfitInput',
  DRAWINGS_INPUT = 'drawingsInput',
  CONTRACT_DAY_RATE_INPUT = 'contractDayRateInput',
  COUNT_DAYS_WORKED_WEEKLY_INPUT = 'countDaysWorkedWeeklyInput',

  RETIRED_INCOME_SOURCE_ARRAY = 'retiredIncomeSourceArray',

  DOES_NOT_HAVE_ANY_INCOME = 'doesNotHaveAnyIncome',

  ADD_INCOME_SOURCE_QUESTION = 'addIncomeSourceQuestion',
  EXTRA_INCOME_SOURCE_ARRAY = 'extraIncomeSourceArray',

  INCOME_TYPE = 'incomeType',
  INCOME_AMOUNT = 'incomeAmount'
}
