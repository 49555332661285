import { Component, Input } from '@angular/core';
import { Rotate } from '../shared/rotate.class';

@Component({
  selector: 'broker-trend-svg',
  templateUrl: './trend-svg.component.svg'
})
export class TrendSvgComponent extends Rotate {
  @Input() cssClasses: string;
  @Input() set isUp(value: boolean) {
    this.shouldRotate = !value;
  }

  constructor() {
    super();
  }
}
