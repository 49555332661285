<div class='p-1'>
  <div class='row'>
    <div class='col-12 align-middle'>
      <h2 mat-dialog-title>{{ data.title }}</h2>
    </div>
  </div>

  <div class='row'>
    <div class='col-12'>
      <div class='center p-3'>
        <p
          class='info-panel-text mb-0 px-5'
          *ngFor='let message of data.messages; let i = index'
          [ngClass]="{ 'mt-3': i > 0 }"
        >
          {{ message }}
        </p>
      </div>
    </div>
  </div>

  <broker-navigation-bar
    class='consumer-flow'
    [nextButtonHasError]='nextRequestError'
    [nextButtonLoading]='nextRequestPending'
    [nextButtonDisabled]='isButtonDisabled'
    [backButtonDisabled]='nextRequestPending'
    [backButtonHidden]='data.hideCancel'
    [nextButtonText]='data.submitButton'
    [backButtonText]='data.cancelButton'
    [hideArrow]='!!data.hideArrow'
    [insideDialog]='true'
    (backButtonClickEvent)='handleNavigationBarBackButtonClickEvent()'
    (nextButtonClickEvent)='handleNavigationBarNextButtonClickEvent()'
  ></broker-navigation-bar>
</div>