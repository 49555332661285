import { Injectable } from '@angular/core';

import { FeatureFlagsService } from '../feature-flags/feature-flags.service';
import { FeatureFlagsEnum } from '../../enums/feature-flags/feature-flags.enum';
import { diffInDays } from '../../utils/date.util';
import { isNull, isUndefined } from 'lodash-es';
import { ConsumerStorageDipService } from '../../consumer/storage/consumer-storage-dip.service';

export type Place = 'dashboard' | 'navigation' | 'CCs';

@Injectable({
  providedIn: 'root'
})
export class CreditCheckExpiryService {
  get isToShowCreditCheckExpiry(): boolean {
    return this.featureFlags.isFeatureFlagEnabled(FeatureFlagsEnum.MPS_CREDIT_FILE_EXPIRY);
  }

  constructor(
    private readonly featureFlags: FeatureFlagsService,
    private readonly consumerStorageDip: ConsumerStorageDipService
  ) {}

  getNumberOfDaysUntilExpires(expiryDate: Date): number | null {
    return diffInDays(expiryDate);
  }

  getTranslationKeyFor(place: Place, days: number): string {
    switch (place) {
      case 'dashboard':
        return this.dashboardTranslationKey(days);
      case 'navigation':
        return this.navigationTranslationKey(days);
      case 'CCs':
        return this.creditCommitmentsTranslationKey(days);
    }
  }

  isToShowCreditCheckExpiryDialog(): boolean {
    return (
      this.isToShowCreditCheckExpiry && this.isCreditCheckExpired(this.consumerStorageDip.creditCommitmentsExpiryDate)
    );
  }

  private isCreditCheckExpired(expiryDate: Date | null): boolean {
    return expiryDate ? this.getNumberOfDaysUntilExpires(expiryDate) <= 0 : false;
  }

  private dashboardTranslationKey(days: number): string {
    if (isNull(days) || isUndefined(days)) return 'table.credit-check-expiry.information.empty';

    if (days <= 0) return 'table.credit-check-expiry.information.expired';
    if (days === 1) return 'table.credit-check-expiry.information.one';
    if (days > 1) return 'table.credit-check-expiry.information.more-than-one';

    return 'table.credit-check-expiry.information.empty';
  }

  private navigationTranslationKey(days: number): string {
    if (isNull(days) || isUndefined(days)) return null;

    if (days <= 0) return 'consumer.stepper.steps.four.credit-check-expiry-information.expired';
    if (days === 1) return 'consumer.stepper.steps.four.credit-check-expiry-information.one-day';
    if (days > 1) return 'consumer.stepper.steps.four.credit-check-expiry-information.default';

    return null;
  }

  private creditCommitmentsTranslationKey(days: number): string {
    if (isNull(days) || isUndefined(days)) return null;

    if (days <= 0) return 'consumer.form.credit-commitments.credit-check-expiry-information.expired';
    if (days === 1) return 'consumer.form.credit-commitments.credit-check-expiry-information.one-day';
    if (days > 1 && days <= 5) return 'consumer.form.credit-commitments.credit-check-expiry-information.almost-expired';

    return 'consumer.form.credit-commitments.credit-check-expiry-information.valid';
  }
}
