import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { cloneDeep, isNull, isUndefined } from 'lodash-es';

import { environment } from '../../../../environments/environment';
import { CloseCaseModel } from '../../components/consumer/dialogs/close-case-dialog/model/close-case.model';
import { EmptyMessage } from '../../messages/empty.message';
import { ErrorResponseMessage } from '../../messages/error-response.message';
import { StringMessage } from '../../messages/string.message';
import { toDateString } from '../../utils/date.util';
import { ConsumerStorageFullAppService } from '../storage/consumer-storage-full-app.service';
import { FullApplicationDetailsMessage } from './message/full-application-details.message';
import { FullApplicationDetailsApplicantIncomeModel } from './model/applicant/full-application-details-applicant-income.model';
import { FullApplicationDetailsApplicantPersonalDetailsModel } from './model/applicant/full-application-details-applicant-personal-details.model';
import { FullApplicationDetailsApplicantModel } from './model/applicant/full-application-details-applicant.model';
import { FullApplicationDetailsCreditCommitmentsModel } from './model/credit-commitments/full-application-details-credit-commitments.model';
import { DocumentModel, emptyDocumentModel } from './model/documents/document.model';
import {
  emptyApplicantDocumentGroup,
  emptyLoanInformationDocumentGroup,
  emptyOtherDocumentsModel
} from './model/documents/full-application-documents.model';
import { FileInfoModel } from './model/file-info.model';
import { FullApplicationDetailsConsentModel } from './model/full-application-details-consent.model';
import { FullApplicationDetailsModel } from './model/full-application-details.model';
import { FullApplicationDetailsPropertyCharacteristicsModel } from './model/property/full-application-details-property-characteristics.model';
import { FullApplicationDetailsPropertyModel } from './model/property/full-application-details-property.model';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags.service';
import { FeatureFlagsEnum } from '../../enums/feature-flags/feature-flags.enum';
import { toNumber } from '../dip/mappers/mapping-utils';

/**
 * Service to manage the Full App requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsumerApiFullAppService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly consumerStorageFullApp: ConsumerStorageFullAppService,
    private readonly featureFlags: FeatureFlagsService
  ) {}

  /**
   * Fetch Full Application details from server by ID.
   */
  fetchFullApplicationDetailsById(id: string, includeDip = false): Promise<FullApplicationDetailsModel> {
    return new Promise<FullApplicationDetailsModel>(
      (resolve: FullApplicationDetailsMessage, reject: ErrorResponseMessage): void => {
        const options = includeDip ? { params: new HttpParams().set('includeDip', includeDip) } : {};
        this.httpClient.get(`${environment.consumerApiPath}/fullApplication/${id}`, options).subscribe(
          (response: FullApplicationDetailsModel) => {
            if (!response.documents) {
              response.documents = {
                applicant1Income: emptyApplicantDocumentGroup(),
                applicant2Income: emptyApplicantDocumentGroup(),
                valuation: emptyDocumentModel(),
                otherV2: emptyOtherDocumentsModel(),
                loanInformation: emptyLoanInformationDocumentGroup()
              };
              if (this.featureFlags.isFeatureFlagEnabled(FeatureFlagsEnum.SUBMISSION_CHECKLIST_UPLOAD)) {
                response.documents.checklist = emptyDocumentModel();
              }
            }

            response.documents.otherV2?.documents?.forEach((document: DocumentModel) => {
              document.uploaded = true;
            });

            resolve(response);
          },
          (error: HttpErrorResponse) => reject(error)
        );
      }
    );
  }

  /**
   * Updates the consent data of the Full Application.
   */
  updateFullApplicationConsent(
    id: string,
    data: FullApplicationDetailsConsentModel,
    partialSave: boolean
  ): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .patch(
          `${environment.consumerApiPath}/fullApplication/${id}/consent`,
          ConsumerApiFullAppService.mapConsent(data),
          {
            params: new HttpParams().append('partialSave', `${partialSave}`)
          }
        )
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  updateFullApplicationApplicant(
    id: string,
    applicantNumber: number,
    data: FullApplicationDetailsApplicantModel,
    partialSave: boolean
  ): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .patch(
          `${environment.consumerApiPath}/fullApplication/${id}/applicant/${applicantNumber}`,
          {
            ...data,
            personalDetails: ConsumerApiFullAppService.mapApplicantPersonalDetails(data.personalDetails),
            income: ConsumerApiFullAppService.mapApplicantIncome(data.income)
          } as FullApplicationDetailsApplicantModel,
          {
            params: { partialSave: partialSave ? 'true' : 'false' }
          }
        )
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Uploads a single file for the applicant documentation on full application.
   */
  uploadFullApplicationApplicantFile(
    id: string,
    applicantNumber: number,
    documentType: string,
    file: File
  ): Promise<string> {
    return new Promise<string>((resolve: StringMessage, reject: ErrorResponseMessage): void => {
      const data: FormData = new FormData();
      data.append('type', documentType);
      data.append('file', file);

      this.httpClient
        .post<FileInfoModel>(
          `${environment.consumerApiPath}/fullApplication/${id}/document/applicant/${applicantNumber}`,
          data
        )
        .subscribe(
          (response: FileInfoModel) => resolve(response.id),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  uploadFullApplicationLoanPurposeFile(id: string, documentType: string, file: File): Promise<string> {
    return new Promise<string>((resolve: StringMessage, reject: ErrorResponseMessage): void => {
      const data: FormData = new FormData();
      data.append('type', documentType);
      data.append('file', file);

      this.httpClient
        .post<FileInfoModel>(`${environment.consumerApiPath}/fullApplication/${id}/document/loanInformation`, data)
        .subscribe(
          (response: FileInfoModel) => resolve(response.id),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Deletes a list of files from the applicant documentation on the full application.
   */
  deleteFullApplicationApplicantFiles(id: string, applicantNumber: number, filesToDelete: string[]): Promise<void> {
    if (filesToDelete.length === 0) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      let params: HttpParams = new HttpParams();
      for (const file of filesToDelete) {
        params = params.append('fileIds', file);
      }
      this.httpClient
        .delete(`${environment.consumerApiPath}/fullApplication/${id}/document/applicant/${applicantNumber}`, {
          params
        })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  deleteFullApplicationLoanPurposeFiles(id: string, filesToDelete: string[]): Promise<void> {
    if (filesToDelete.length === 0) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      let params: HttpParams = new HttpParams();
      for (const file of filesToDelete) {
        params = params.append('fileIds', file);
      }
      this.httpClient
        .delete(`${environment.consumerApiPath}/fullApplication/${id}/document/loanInformation`, {
          params
        })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  updateFullApplicationPropertyDetails(
    id: string,
    data: FullApplicationDetailsPropertyModel,
    partialSave: boolean
  ): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .patch(
          `${environment.consumerApiPath}/fullApplication/${id}/property`,
          {
            ...data,
            characteristics: ConsumerApiFullAppService.mapPropertyCharacteristics(data.characteristics)
          } as FullApplicationDetailsPropertyModel,
          {
            params: { partialSave: partialSave ? 'true' : 'false' }
          }
        )
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  uploadFullApplicationDocumentFileByType(type: 'valuation' | 'checklist', id: string, file: File): Promise<string> {
    return new Promise<string>((resolve: StringMessage, reject: ErrorResponseMessage): void => {
      const data: FormData = new FormData();
      data.append('file', file);

      this.httpClient
        .post<FileInfoModel>(`${environment.consumerApiPath}/fullApplication/${id}/document/${type}`, data)
        .subscribe(
          (response: FileInfoModel) => resolve(response.id),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  deleteFullApplicationDocumentFileByType(
    type: 'valuation' | 'checklist',
    id: string,
    filesToDelete: string[]
  ): Promise<void> {
    if (filesToDelete.length === 0) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      let params: HttpParams = new HttpParams();
      for (const file of filesToDelete) {
        params = params.append('fileIds', file);
      }

      this.httpClient
        .delete(`${environment.consumerApiPath}/fullApplication/${id}/document/${type}`, {
          params
        })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Updates the credit commitments data of the Full Application.
   * PartialSave is true when the user wants to Save&Exit
   */
  updateFullApplicationCreditCommitments(
    id: string,
    data: FullApplicationDetailsCreditCommitmentsModel,
    partialSave: boolean
  ): Promise<void> {
    const shouldMapConsolidationAmount = this.featureFlags.isFeatureFlagEnabled(
      FeatureFlagsEnum.MPS_DEBT_CON_AMOUNT_FULL_APP
    );
    const body: FullApplicationDetailsCreditCommitmentsModel = ConsumerApiFullAppService.mapCreditCommitments(
      data,
      shouldMapConsolidationAmount
    );
    if (!data?.securedCreditCommitments?.length && !data?.unsecuredCreditCommitments?.length) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .patch(`${environment.consumerApiPath}/fullApplication/${id}/creditCommitments`, body, {
          params: { partialSave: partialSave ? 'true' : 'false' }
        })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Submits the application.
   */
  submitFullApplication(): Promise<FullApplicationDetailsModel> {
    return new Promise<FullApplicationDetailsModel>(
      (resolve: (value: FullApplicationDetailsModel) => void, reject: ErrorResponseMessage): void => {
        const id: string = this.consumerStorageFullApp.fullAppId;
        this.httpClient.post(`${environment.consumerApiPath}/fullApplication/${id}/submitForApproval`, {}).subscribe(
          () => {
            this.fetchFullApplicationDetailsById(id)
              .then((fullApp: FullApplicationDetailsModel) => {
                this.consumerStorageFullApp.fullApp = fullApp;
                this.consumerStorageFullApp.fullAppUpdated.next();
                resolve(fullApp);
              })
              .catch((error: any) => reject(error));
          },
          (error: HttpErrorResponse) => reject(error)
        );
      }
    );
  }

  /**
   * Closes a case.
   */
  closeCase(id: string, reason: CloseCaseModel): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient.post(`${environment.consumerApiPath}/fullApplication/${id}/closeCase`, reason).subscribe(
        () => resolve(),
        (error: HttpErrorResponse) => reject(error)
      );
    });
  }

  /**
   * Proceed the full application to the final steps.
   */
  proceedToFinalSteps(id: string): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient.post(`${environment.consumerApiPath}/fullApplication/${id}/proceedToFinalSteps`, id).subscribe(
        () => {
          this.fetchFullApplicationDetailsById(id)
            .then((fullApp: FullApplicationDetailsModel) => {
              this.consumerStorageFullApp.fullApp = fullApp;
              this.consumerStorageFullApp.fullAppUpdated.next();
              resolve();
            })
            .catch((error: any) => reject(error));
        },
        (error: HttpErrorResponse) => reject(error)
      );
    });
  }

  uploadNewFullApplicationOtherFile(id: string, title: string, file: File): Promise<string> {
    return new Promise<string>((resolve: StringMessage, reject: ErrorResponseMessage): void => {
      const data: FormData = new FormData();
      data.append('title', title);
      data.append('file', file);

      this.httpClient
        .post<DocumentModel>(`${environment.consumerApiPath}/fullApplication/${id}/document`, data)
        .subscribe(
          (response: DocumentModel) => resolve(response.id),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  deleteFullApplicationOtherFiles(id: string, documentsToDelete: string[]): Promise<void> {
    if (documentsToDelete.length === 0) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      let params: HttpParams = new HttpParams();
      for (const file of documentsToDelete) {
        params = params.append('documentIds', file);
      }
      this.httpClient
        .delete(`${environment.consumerApiPath}/fullApplication/${id}/document`, {
          params
        })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  private static mapApplicantPersonalDetails(
    personalDetails: FullApplicationDetailsApplicantPersonalDetailsModel
  ): FullApplicationDetailsApplicantPersonalDetailsModel {
    const data: FullApplicationDetailsApplicantPersonalDetailsModel = cloneDeep(personalDetails);

    if (data.emailAddress?.length === 0) {
      data.emailAddress = null;
    }
    if (data.mobilePhoneNumber?.length === 0) {
      data.mobilePhoneNumber = null;
    }

    return data;
  }

  private static mapApplicantIncome(
    model: FullApplicationDetailsApplicantIncomeModel
  ): FullApplicationDetailsApplicantIncomeModel {
    const data: FullApplicationDetailsApplicantIncomeModel = cloneDeep(model);

    if (data.jobTitle?.length === 0) {
      data.jobTitle = null;
    }
    if (data.companyName?.length === 0) {
      data.companyName = null;
    }
    if (data.dateOfIncorporation) {
      data.dateOfIncorporation = toDateString(new Date(data.dateOfIncorporation));
    }

    return data;
  }

  private static mapCreditCommitments(
    model: FullApplicationDetailsCreditCommitmentsModel,
    shouldMapConsolidationAmount: boolean
  ): FullApplicationDetailsCreditCommitmentsModel {
    const data: FullApplicationDetailsCreditCommitmentsModel = cloneDeep(model);

    if (data.securedCreditCommitments?.length > 0) {
      for (const commitment of data.securedCreditCommitments) {
        commitment.accountNumber = this.trim(commitment.accountNumber);
        commitment.lenderName = this.trim(commitment.lenderName);
      }
    }
    if (data.unsecuredCreditCommitments?.length > 0) {
      for (const commitment of data.unsecuredCreditCommitments) {
        commitment.accountNumber = this.trim(commitment.accountNumber);
        commitment.lenderName = this.trim(commitment.lenderName);

        if (shouldMapConsolidationAmount) {
          if (
            isNull(commitment.amountToConsolidateConfirmedByUser) ||
            isUndefined(commitment.amountToConsolidateConfirmedByUser)
          ) {
            commitment.amountToConsolidateConfirmedByUser = false;
            commitment.amountToConsolidate = undefined;
            continue;
          }
          if (commitment.amountToConsolidateConfirmedByUser === false) {
            commitment.amountToConsolidateConfirmedByUser = true;
            commitment.amountToConsolidate = undefined;
            continue;
          }
          if (commitment.amountToConsolidateConfirmedByUser === true) {
            if (isNull(commitment.amountToConsolidate) || isUndefined(commitment.amountToConsolidate)) {
              commitment.amountToConsolidateConfirmedByUser = undefined;
              commitment.amountToConsolidate = undefined;
            } else {
              commitment.amountToConsolidateConfirmedByUser = true;
              commitment.amountToConsolidate = toNumber(commitment.amountToConsolidate);
            }
          }
        }
      }
    }
    return data;
  }

  private static mapPropertyCharacteristics(
    model: FullApplicationDetailsPropertyCharacteristicsModel
  ): FullApplicationDetailsPropertyCharacteristicsModel {
    const data: FullApplicationDetailsPropertyCharacteristicsModel = cloneDeep(model);

    if (data.tenurePropertyCharacteristics) {
      data.tenurePropertyCharacteristics.yearsRemainingOnLease = this.toNumber(
        data.tenurePropertyCharacteristics.yearsRemainingOnLease
      );
    }
    if (data.flatTypePropertyCharacteristics) {
      data.flatTypePropertyCharacteristics.numberOfFloorsInTheBuilding = this.toNumber(
        data.flatTypePropertyCharacteristics.numberOfFloorsInTheBuilding
      );
    }
    if (data.flatTypePropertyCharacteristics) {
      data.flatTypePropertyCharacteristics.flatFloorNumber = this.toNumber(
        data.flatTypePropertyCharacteristics.flatFloorNumber
      );
    }
    if (data.propertyValuation) {
      data.propertyValuation.hometrackValuation = this.toNumber(data.propertyValuation.hometrackValuation);
      data.propertyValuation.hometrackConfidenceLevel = this.toNumber(data.propertyValuation.hometrackConfidenceLevel);
      data.propertyValuation.ricsValuation = this.toNumber(data.propertyValuation.ricsValuation);
    }

    return data;
  }

  private static mapConsent(data: FullApplicationDetailsConsentModel): FullApplicationDetailsConsentModel {
    const mappedData: FullApplicationDetailsConsentModel = data;
    if (data.disbursementBankDetails) {
      mappedData.disbursementBankDetails.accountHolderFullName = this.trim(
        data.disbursementBankDetails.accountHolderFullName
      );
      mappedData.disbursementBankDetails.bankAccountNumber = this.trim(data.disbursementBankDetails.bankAccountNumber);
      mappedData.disbursementBankDetails.bankAccountSortCode = this.trim(
        data.disbursementBankDetails.bankAccountSortCode
      );
    }
    if (data.completionDetails) {
      mappedData.completionDetails.disbursementAmount = this.toNumber(data.completionDetails.disbursementAmount);
      mappedData.completionDetails.disbursementOption = this.trim(data.completionDetails.disbursementOption);
    }

    return mappedData;
  }

  private static trim(value: string): string {
    return value?.length > 0 ? value.trim() : null;
  }

  private static toNumber(value: any): number {
    if (typeof value === 'string') {
      return value?.length > 0 ? +this.trim(value) : null;
    }
    return value;
  }
}
