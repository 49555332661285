import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { isNull, isUndefined } from 'lodash-es';

import { ApplicationFilterStage, ApplicationsFilter } from '../dashboard.service';
import { DashboardModel } from '../../../consumer/model/dashboard.model';
import { ConsumerResponseService } from '../../../consumer/application/consumer-response.service';
import { DashboardMessage } from '../../../messages/dashboard.message';
import { ErrorResponseMessage } from '../../../messages/error-response.message';
import { environment } from '../../../../../environments/environment';
import {
  ApplicationsQueryParamsNames,
  NonPaginatedApplicationsResponseModel,
  PaginatedApplicationsResponseModel
} from '../../../consumer/application/model/application.model';

export abstract class AbstractFetchApplicationService {
  abstract fetchApplications(filters: ApplicationsFilter): Promise<DashboardModel>;

  protected abstract createParams(filters: ApplicationsFilter): HttpParams;

  protected appendParam(
    params: HttpParams,
    newParam: {
      name: ApplicationsQueryParamsNames;
      value: string | number;
    }
  ): HttpParams {
    let auxParams = params;
    if (!isUndefined(newParam.value) && !isNull(newParam.value)) {
      auxParams = auxParams.append(newParam.name, newParam.value);
    }
    return auxParams;
  }
}

export class FetchNonPaginatedApplicationsService extends AbstractFetchApplicationService {
  constructor(private readonly httpClient: HttpClient, private readonly response: ConsumerResponseService) {
    super();
  }

  fetchApplications(filters: ApplicationsFilter): Promise<DashboardModel> {
    return new Promise<DashboardModel>((resolve: DashboardMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .get(`${environment.consumerApiPath}/application`, { params: this.createParams(filters) })
        .subscribe(
          (response: NonPaginatedApplicationsResponseModel) =>
            resolve(this.response.mapNonPaginatedApplications(response, filters.stage)),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  protected createParams(filters: ApplicationsFilter): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.BROKER_ACCOUNT_ID,
      value: filters.brokerAccountId
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.ALL_APPLICATIONS,
      value: String(filters.allApplications)
    });
    httpParams = this.appendParam(httpParams, { name: this.getStageNameParam(filters.stage), value: 'true' });

    return httpParams;
  }

  private getStageNameParam(stage: ApplicationFilterStage): ApplicationsQueryParamsNames {
    if (stage === 'live') return ApplicationsQueryParamsNames.INCLUDE_LIVE;
    if (stage === 'closed') return ApplicationsQueryParamsNames.INCLUDE_CLOSED;
    if (stage === 'funded') return ApplicationsQueryParamsNames.INCLUDE_FUNDED;
  }
}

export class FetchPaginatedApplicationsService extends AbstractFetchApplicationService {
  constructor(private readonly httpClient: HttpClient, private readonly response: ConsumerResponseService) {
    super();
  }

  fetchApplications(filters: ApplicationsFilter): Promise<DashboardModel> {
    return new Promise<DashboardModel>((resolve: DashboardMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .get(`${environment.consumerApiPath}/application/paginated`, { params: this.createParams(filters) })
        .subscribe(
          (response: PaginatedApplicationsResponseModel) =>
            resolve({
              ...response,
              records: this.response.mapApplications(response.records)
            }),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  protected createParams(filters: ApplicationsFilter): HttpParams {
    let httpParams = new HttpParams();

    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.ALL_APPLICATIONS,
      value: String(filters.allApplications)
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.STAGE,
      value: filters.stage
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.BROKER_ACCOUNT_ID,
      value: filters.brokerAccountId
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.PAGE_SIZE,
      value: filters.pageSize
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.PAGE_NUMBER,
      value: filters.pageNumber
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.SORT_BY,
      value: filters.sortBy
    });
    httpParams = this.appendParam(httpParams, {
      name: ApplicationsQueryParamsNames.SEARCH_TERM,
      value: filters.searchTerm
    });
    return httpParams;
  }
}
