import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { DashboardModel } from '../../consumer/model/dashboard.model';
import { CompanyArrayMessage } from '../../messages/company-array.message';
import { ErrorResponseMessage } from '../../messages/error-response.message';
import { CompanyModel } from '../../models/dashboard/company.model';
import { FetchApplicationsFactoryService } from './factory/fetch-applications-factory.service';

export type ApplicationFilterStage = 'live' | 'closed' | 'funded';

export interface ApplicationsFilter extends ApplicationsPageSortSearchFilter {
  allApplications: boolean;
  stage: ApplicationFilterStage;
  brokerAccountId: string;
}

export interface ApplicationsPageFilter {
  pageNumber?: number;
  pageSize?: number;
}

export interface ApplicationsSortFilter {
  sortBy?: string;
}

export interface ApplicationsSearchFilter {
  searchTerm?: string;
}

export interface ApplicationsPageSortSearchFilter
  extends ApplicationsPageFilter,
    ApplicationsSortFilter,
    ApplicationsSearchFilter {}

/**
 * Service to manage dashboard.
 */
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly fetchApplicationsFactory: FetchApplicationsFactoryService
  ) {}

  /**
   * Fetch the applications list from server.
   */
  fetchApplications(filters: ApplicationsFilter): Promise<DashboardModel> {
    const service = this.fetchApplicationsFactory.getService();
    return service.fetchApplications(filters);
  }

  /**
   * Fetches the list of companies from the API.
   */
  fetchCompanies(): Promise<CompanyModel[]> {
    return new Promise<CompanyModel[]>((resolve: CompanyArrayMessage, reject: ErrorResponseMessage): void => {
      this.httpClient.get<CompanyModel[]>(`${environment.consumerApiPath}/account`).subscribe(
        (response: CompanyModel[]) => resolve(response),
        (error: HttpErrorResponse) => reject(error)
      );
    });
  }
}
