<div class='d-flex'>
  <broker-dashboard-left-panel [pages]='pages'></broker-dashboard-left-panel>
  <div class='right-section flex-grow-1'>
    <ng-container *ngIf='!isLoadingComponentData; else loading'>
      <div class='container consumer-flow pb-5'>
        <div class='container-wrapper'>
          <div *ngIf='pages.dashboard' class='form-wrapper large last-child'>
            <div class='d-flex justify-content-between'>
              <h2 class='mb-2'>{{ 'applications.title' | translate: lang }}</h2>
              <broker-need-help></broker-need-help>
            </div>
            <div *ngIf='canRequestAdminAccess || canRequestAnyAccountAccess' class='pb-3'>
              <ng-container [ngTemplateOutlet]='toggleView'></ng-container>
            </div>
            <mat-tab-group
              id='dashboard-tab'
              animationDuration='0ms'
              backgroundColor='transparent'
              (selectedIndexChange)='caseTabChanged($event)'
              [selectedIndex]='caseTabIndex'
              *ngIf='!hasError'
            >
              <mat-tab *ngFor='let tab of Object.values(dashboardTabs); let i = index'>
                <ng-container *ngIf='getTabInformation(tab) as tabInformation'>
                  <ng-template mat-tab-label>
                    {{ 'tabs.' + tab | translate:lang }}
                    <span class='counter-badge'>{{ tabInformation.count }}</span>
                  </ng-template>
                  <broker-dashboard-tab *ngIf='i === caseTabIndex'
                                        [isAdminViewEnabled]='isAdminViewEnabled'
                                        [isAnyAccountViewEnabled]='isAnyAccountViewEnabled'
                                        [tableRecords]='tabInformation.records'
                                        [tableMetadata]='tabInformation.metadata'
                                        [isCompanySelected]='!!selectedCompanyId'
                                        [isLoadingResults]='isLoadingApplications'
                                        (fetchApplications)='handleFetchApplications($event)'>
                  </broker-dashboard-tab>
                </ng-container>
              </mat-tab>
            </mat-tab-group>

            <div class='text-center px-5' *ngIf='hasError'>
              <span class='dashboard-warning'>{{ 'applications.error' | translate: lang }}</span>
            </div>
          </div>
          <div *ngIf='pages.documents' class='form-wrapper large last-child px-5'>
            <div class='d-flex justify-content-between'>
              <h2 class='mb-2'>{{ 'applications.documents' | translate: lang }}</h2>
              <broker-need-help></broker-need-help>
            </div>
            <div *ngIf='canRequestAdminAccess || canRequestAnyAccountAccess' class='pb-3'>
              <ng-container [ngTemplateOutlet]='toggleView'></ng-container>
            </div>
            <broker-dashboard-documents [canSubmitConsumerFlow]='canSubmitConsumerFlow'></broker-dashboard-documents>
          </div>
        </div>

        <div class='text-center form-wrapper' *ngIf='!isUserActive'>
          <p>{{ 'account.inactive.line-1' | translate: lang }}</p>
          <p>{{ 'account.inactive.line-2' | translate: lang }}</p>
        </div>
      </div>
      <div *ngIf='isUserActive && canSubmitConsumerFlow' class='buttons-wrapper p-3 position-sticky d-flex justify-content-center align-items-center'
      >
        <broker-button [id]="'new'" [height]="'large'" (clickEvent)='openDIPForm()'>
          {{ 'applications.submit-new' | translate: lang }}
        </broker-button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class='centered-container'>
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #toggleView>
  <div class='d-flex flex-column' *ngIf='canRequestAdminAccess && !canRequestAnyAccountAccess'>
    <div class='d-flex justify-content-end gap-3'>
      <div class='align-items-center d-flex gap-2'>
        <span class='toggle-label'>{{'consumer.dashboard.admin-view.title' | translate:lang}}</span>
        <broker-tooltip-svg [matTooltip]="'consumer.dashboard.admin-view.tooltip' | translate: lang"></broker-tooltip-svg>
      </div>
      <mat-slide-toggle
        id='adminView'
        class='green-toggle greener'
        [checked]='isAdminViewEnabled'
        [disabled]='isLoadingApplications'
        (change)='handleAdminToggleChange($event)'
      >
      </mat-slide-toggle>
    </div>
  </div>
  <div class='d-flex flex-column god-view' *ngIf='canRequestAnyAccountAccess && !isAnyAccountViewEnabled'>
    <div class='d-flex justify-content-end gap-3'>
      <div class='align-items-center d-flex gap-2'>
        <span class='toggle-label'>{{'consumer.dashboard.any-account-view.title' | translate:lang}}</span>
        <broker-tooltip-svg [matTooltip]="'consumer.dashboard.any-account-view.tooltip' | translate: lang"></broker-tooltip-svg>
      </div>
      <mat-slide-toggle
        id='anyAccountViewTab'
        class='green-toggle greener'
        [checked]='isAnyAccountViewEnabled'
        [disabled]='isLoadingApplications'
        (change)='handleAnyAccountToggleChange($event)'
      >
      </mat-slide-toggle>
    </div>
  </div>
  <div
    class='d-flex align-items-center justify-content-between'
    *ngIf='canRequestAnyAccountAccess && isAnyAccountViewEnabled'
  >
    <div class='flex-grow-1 d-flex flex-row align-items-center me-5 pe-5'>
      <mat-form-field appearance='standard' class='max-w-312'>
        <mat-select
          id='brokerAccount'
          [placeholder]="'consumer.form.please-select' | translate: lang"
          [disabled]='isLoadingCompanies || isLoadingApplications'
          (selectionChange)='handleCompanySelectChange()'
          [(value)]='selectedCompanyId'
        >
          <mat-option *ngFor='let option of companyOptions' [value]='option.value'>
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-spinner [diameter]='24' *ngIf='isLoadingCompanies' class='ms-3'></mat-spinner>
    </div>
    <div class='d-flex flex-column'>
      <div class='d-flex justify-content-end gap-3'>
        <broker-label
          [label]="'consumer.dashboard.any-account-view.title' | translate:lang"
          [tooltip]="'consumer.dashboard.any-account-view.tooltip' | translate: lang">
        </broker-label>
        <mat-slide-toggle
          id='anyAccountViewMain'
          class='green-toggle greener'
          [checked]='isAnyAccountViewEnabled'
          [disabled]='isLoadingApplications'
          (change)='handleAnyAccountToggleChange($event)'
        >
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</ng-template>
