<div class='left-section position-sticky d-flex flex-column justify-content-between'>
  <div>
    <broker-selina-logo-svg></broker-selina-logo-svg>
    <span class='text-name' [innerHTML]="'app.hello' | translate: lang:userNameParams"></span>
    <div *ngIf='pages' class='menu-page'>
      <div [class.selected]='pages.dashboard' class='page' (click)="changePage('dashboard')">
        <img [src]="'assets/images/dashboard-icon.svg'" alt='dashboard' /><span>{{
        'app.menu-link.dashboard' | translate: lang
        }}</span>
      </div>
      <div [class.selected]='pages.documents' class='page' (click)="changePage('documents')">
        <img [src]="'assets/images/document-icon.svg'" alt='documents' /><span>{{
        'app.menu-link.documents' | translate: lang
        }}</span>
      </div>
    </div>
  </div>

  <div>
    <broker-whats-new-box></broker-whats-new-box>

    <div class='logout-link'>
      <a (click)='logout()'>
        <broker-logout-svg></broker-logout-svg>
        <span class='ps-2'>{{ 'app.logout' | translate: lang }}</span>
      </a>
    </div>
  </div>
</div>
