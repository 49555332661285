import { Component, Input } from '@angular/core';

type FillColorType = 'white' | 'red' | 'disabled';

@Component({
  selector: 'broker-search-svg',
  templateUrl: './search-svg.component.svg',
  styleUrls: ['./search-svg.component.scss']
})
export class SearchSvgComponent {
  @Input() fillColor: FillColorType = 'red';
}
