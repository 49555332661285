<div class='p-1'>
  <div class='row'>
    <div class='col-12 align-middle'>
      <h2 mat-dialog-title>{{ data.title }}</h2>
    </div>
  </div>

  <div class='row my-3'>
    <div class='col-12'>
      <div class='center p-3'>
        <p class='info-panel-text mb-0 ps-5 pe-5'>{{ data.infoPanelMessage }}</p>
      </div>
    </div>
  </div>

  <broker-button [id]="'back'" [height]="'large'" [width]="'compact'" [icon]="'arrow'" [style]="'outline-dark'"
                 (clickEvent)='handleNavigationBarBackButtonClickEvent()'>
    {{ data.backButton }}
  </broker-button>
</div>