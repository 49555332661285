import { Component, OnInit } from '@angular/core';

import { AuthService } from './shared/services/auth/auth.service';

/**
 * Main app component.
 */
@Component({
  selector: 'broker-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  /**
   * The translations loading state.
   */
  translationsLoading: boolean;

  /**
   * Gets if the account has been validated.
   */
  validatingAccount: boolean;

  constructor(private readonly oAuthService: AuthService) {
    this.translationsLoading = true;
    this.validatingAccount = true;
  }

  /**
   * Initializes the default component data.
   */
  ngOnInit(): void {
    this.oAuthService
      .init()
      .then(() => (this.validatingAccount = false))
      .catch();
    this.oAuthService
      .isLoggedIn()
      .then((loggedIn: boolean) => {
        if (!loggedIn) {
          this.validatingAccount = false;
        }
      })
      .catch();
    this.translationsLoading = false;
  }
}
