import { Component, Input } from '@angular/core';

@Component({
  selector: 'broker-arrow-svg',
  templateUrl: './arrow-svg.component.svg'
})
export class ArrowSvgComponent {
  @Input() up = false;
  @Input() cssClasses = '';
}
