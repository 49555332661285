import { Component, Inject, OnInit } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../shared/services/auth/auth.service';
import { LocalizableComponent } from '../../shared/localization/localizable.component';

/**
 * Component to handle registration.
 */
@Component({
  selector: 'broker-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent extends LocalizableComponent implements OnInit {
  /**
   * The current registration step.
   */
  step = 1;

  fcaNumber: string;

  constructor(private readonly authService: AuthService, @Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }

  ngOnInit(): void {
    if (this.authService.fcaNumber?.length) {
      this.handleFcaNumberValidated(this.authService.fcaNumber);
    }
  }

  handleFcaNumberValidated(fcaNumber: string): void {
    this.fcaNumber = fcaNumber;
    this.step = 2;
  }
}
