import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js/max';

/**
 * Returns phone number in e164 number format.
 */
export function trimPhoneNumber(value: string | null): string | null {
  try {
    if (value?.length > 0) {
      const phoneNumber: PhoneNumber = parsePhoneNumber(value, 'GB');

      if (phoneNumber.isValid()) {
        return phoneNumber.number as string;
      }
    }
  } catch (err) {
    // Intentionally empty
  }

  return null;
}
