import { UntypedFormGroup } from '@angular/forms';

import { AddressControlNamesEnum } from 'src/app/shared/components/consumer/address/enum/address-control-names.enum';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { ApplicantControlNamesEnum } from '../../applicant/enum/applicant-control-names.enum';
import { FacilityDetailsControlNamesEnum } from '../../facility-details/enum/facility-details-control-names.enum';
import { OpportunitySubmissionModel } from 'src/app/shared/consumer/dip/model/broker-fees-details.model';
import { PropertyDetailsControlNamesEnum } from '../../property-details/enum/property-details-control-names.enum';

const formatNumber: (value: number) => string = (value: number): string =>
  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

/**
 * Mapper that provides data for the modal on the broker fees page for submitting the consumer opportunity
 * logic put here into separate mapper file to not populate already existing services
 */
export const getConsumerOpportunityModalData: (
  facilityDetailsFormGroup: UntypedFormGroup,
  propertyDetailsFormGroup: UntypedFormGroup,
  applicant1FormGroup: UntypedFormGroup,
  applicant2FormGroup: UntypedFormGroup,
  localization: LocalizationService
) => OpportunitySubmissionModel = (
  facilityDetailsFormGroup: UntypedFormGroup,
  propertyDetailsFormGroup: UntypedFormGroup,
  applicant1FormGroup: UntypedFormGroup,
  applicant2FormGroup: UntypedFormGroup,
  localization: LocalizationService
): OpportunitySubmissionModel => {
  const termDurationRequested: string = facilityDetailsFormGroup?.get(
    FacilityDetailsControlNamesEnum.LOAN_TERM_REQUESTED
  )?.value;
  const facilityAmount: string = facilityDetailsFormGroup?.get(FacilityDetailsControlNamesEnum.LOAN_AMOUNT_REQUESTED)
    ?.value;
  const formatted: string = formatNumber(+facilityAmount);
  return {
    clientName: getClientsNames(applicant1FormGroup, applicant2FormGroup, localization),
    loanAmountRequested: facilityAmount ? `${localization.translate('input.prefix.pounds')}${formatted}` : null,
    termDurationRequested: termDurationRequested
      ? localization.translate('dialog.consumer-opportunity-submission.loan-term-duration.value', {
          value: +termDurationRequested
        })
      : null,
    securityPropertyDetails: getPropertyAddressDetails(applicant1FormGroup, propertyDetailsFormGroup),
    webCopy: {
      clientLabel: localization.translate('dialog.consumer-opportunity-submission.client'),
      loanAmountLabel: localization.translate('dialog.consumer-opportunity-submission.loan-amount.label'),
      termDurationLabel: localization.translate('dialog.consumer-opportunity-submission.loan-term-duration.label'),
      securityPropertyLabel: localization.translate('dialog.consumer-opportunity-submission.security-property')
    }
  };
};

/**
 * Maps customer property address details for consumer opportunity submission modal on the broker fees page
 */
export const getPropertyAddressDetails: (
  applicant1FormGroup: UntypedFormGroup,
  propertyDetailsFormGroup: UntypedFormGroup
) => string = (applicant1FormGroup: UntypedFormGroup, propertyDetailsFormGroup: UntypedFormGroup): string => {
  const isSameAsApplicantAddress: boolean = propertyDetailsFormGroup.get(
    PropertyDetailsControlNamesEnum.IS_SAME_AS_RESIDENTIAL_ADDRESS
  ).value;
  let addressLine1: string;
  let postCode: string;
  if (isSameAsApplicantAddress) {
    addressLine1 = applicant1FormGroup
      ?.get(ApplicantControlNamesEnum.ADDRESS_FORM_GROUP)
      ?.get(AddressControlNamesEnum.ADDRESS_LINE_1)?.value;
    postCode = applicant1FormGroup
      ?.get(ApplicantControlNamesEnum.ADDRESS_FORM_GROUP)
      ?.get(AddressControlNamesEnum.POSTCODE)?.value;
  } else {
    addressLine1 = propertyDetailsFormGroup
      ?.get(PropertyDetailsControlNamesEnum.ADDRESS_FORM_GROUP)
      ?.get(AddressControlNamesEnum.ADDRESS_LINE_1)?.value;
    postCode = propertyDetailsFormGroup
      ?.get(PropertyDetailsControlNamesEnum.ADDRESS_FORM_GROUP)
      ?.get(AddressControlNamesEnum.POSTCODE)?.value;
  }

  if (addressLine1 && postCode) {
    return `${addressLine1}, ${postCode}`;
  }
  if (postCode) {
    return postCode;
  }
  if (addressLine1) {
    return addressLine1;
  }
  return null;
};

/**
 * Maps clients names details for the consumer opportunity submission modal on the broker fees page
 */
export const getClientsNames: (
  applicant1FormGroup: UntypedFormGroup,
  applicant2FormGroup: UntypedFormGroup,
  localization: LocalizationService
) => string = (
  applicant1FormGroup: UntypedFormGroup,
  applicant2FormGroup: UntypedFormGroup,
  localization: LocalizationService
): string => {
  let applicantOne = `${applicant1FormGroup?.get(ApplicantControlNamesEnum.FIRST_NAME)?.value || ''} ${
    applicant1FormGroup?.get(ApplicantControlNamesEnum.SURNAME)?.value || ''
  }`;
  applicantOne = applicantOne.replace(/ +/g, ' ');
  if (applicant2FormGroup?.get(ApplicantControlNamesEnum.FIRST_NAME)?.value) {
    let applicantTwo = `${applicant2FormGroup?.get(ApplicantControlNamesEnum.FIRST_NAME)?.value || ''} ${
      applicant2FormGroup?.get(ApplicantControlNamesEnum.SURNAME)?.value || ''
    }`;
    applicantTwo = applicantTwo.replace(/ +/g, ' ');
    return `${applicantOne} ${localization.translate('dialog.consumer-opportunity-submission.and')} ${applicantTwo}`;
  }

  return applicantOne;
};
