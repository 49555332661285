import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { YesNoButtonsComponent } from './yes-no-buttons.component';
import { LabelModule } from '../label/label.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [YesNoButtonsComponent],
  imports: [CommonModule, LabelModule, ButtonModule, L10nTranslationModule],
  exports: [YesNoButtonsComponent]
})
export class YesNoButtonsModule {}
