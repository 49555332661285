/**
 * The existing route links for the Consumer flow - DIP.
 */
export enum DipRouteEnum {
  /**
   * The DIP route.
   */
  DIP = 'dip',

  /**
   * The DIP - decisioning route.
   */
  DECISIONING = 'decisioning',

  /**
   * The DIP - product selection route.
   */
  PRODUCT_SELECTION = 'selection',

  /**
   * The DIP - customer acceptance route.
   */
  CUSTOMER_ACCEPTANCE = 'acceptance',

  /**
   * The DIP - decisioning error route.
   */
  DECISIONING_ERROR = 'decisioning-error'
}
