import { FormRegexPatternsUtil } from './form-regex-patterns.util';
import { ValidationGroupModel } from './validation.model';

/**
 * The common validation rules models.
 */
interface CommonValidationModel {
  /**
   * The address validation rules.
   */
  address: ValidationGroupModel;
}

/**
 * Common validation rules.
 */
export const COMMON_VALIDATION: CommonValidationModel = {
  address: {
    postcode: {
      required: true,
      minLength: 5,
      maxLength: 8,
      pattern: FormRegexPatternsUtil.UK_POST_CODE_PATTERN
    },
    addressLine1: {
      required: true,
      minLength: 3,
      maxLength: 255
    },
    addressLine2: {
      required: false,
      minLength: 3,
      maxLength: 255
    },
    city: {
      required: true,
      minLength: 3,
      maxLength: 255,
      pattern: FormRegexPatternsUtil.ONLY_LETTERS_PATTERN
    }
  }
};
