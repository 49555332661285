import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponent } from './button.component';
import { LoadingSvgComponent } from './svg/loading/loading-svg.component';
import { ArrowSvgComponent } from './svg/arrow/arrow-svg.component';
import { AddSvgComponent } from './svg/add/add-svg.component';
import { XSvgComponent } from './svg/x/x-svg.component';
import { RemoveSvgComponent } from './svg/remove/remove-svg.component';

@NgModule({
  declarations: [
    ButtonComponent,
    LoadingSvgComponent,
    ArrowSvgComponent,
    AddSvgComponent,
    XSvgComponent,
    RemoveSvgComponent
  ],
  imports: [CommonModule],
  exports: [ButtonComponent]
})
export class ButtonModule {}
