import { Component, Inject, Input } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../localization/localizable.component';

@Component({
  selector: 'broker-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss']
})
export class NeedHelpComponent extends LocalizableComponent {
  @Input() cssClasses: string;

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }
}
