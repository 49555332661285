<div class='p-1'>
  <div class='row'>
    <div class='col-12 align-middle'>
      <h2 mat-dialog-title>{{ data.title }}</h2>
    </div>
  </div>

  <div class='row'>
    <div class='col-12'>
      <div class='center p-3' *ngIf='message'>
        <p class='info-panel-text mb-0'>{{ message }}</p>
      </div>
      <div class='center p-3' *ngIf='messageArray'>
        <p
          class='info-panel-text mb-0'
          *ngFor='let messageElement of messageArray; let i = index'
          [ngClass]="{ 'mt-3': i > 0 }"
        >
          {{ messageElement }}
        </p>
      </div>
    </div>
  </div>

  <mat-dialog-content>
    <ng-container *ngComponentOutlet='data.component'></ng-container>
  </mat-dialog-content>

  <broker-navigation-bar
    class='consumer-flow'
    [nextButtonHasError]='nextRequestError'
    [nextButtonLoading]='nextRequestPending'
    [nextButtonDisabled]='isButtonDisabled'
    [backButtonDisabled]='nextRequestPending'
    [insideDialog]='true'
    [backButtonText]='data.backButton'
    [nextButtonText]='data.nextButton'
    (backButtonClickEvent)='handleNavigationBarBackButtonClickEvent()'
    (nextButtonClickEvent)='handleNavigationBarNextButtonClickEvent()'
  ></broker-navigation-bar>
</div>