/**
 * The existing route links for the Consumer flow - FULL APP.
 */
export enum FullAppRouteEnum {
  /**
   * The FULL APP route.
   */
  FULL_APP = 'full-app',

  /**
   * The details route.
   */
  DETAILS = 'details',

  /**
   * The applicant route.
   */
  APPLICANT = 'applicant',

  /**
   * The applicant ID route parameter.
   */
  APPLICANT_ID = ':applicantId',

  /**
   * The documents route.
   */
  DOCUMENTS = 'documents',

  /**
   * The property route.
   */
  PROPERTY = 'property',

  /**
   * The credit route.
   */
  CREDIT = 'credit',

  /**
   * The consent route.
   */
  CONSENT = 'consent',

  /**
   * The closed route.
   */
  CLOSED = 'closed',

  /**
   * The funded route.
   */
  FUNDED = 'funded'
}
