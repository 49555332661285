export enum ExpenditureControlNamesEnum {
  CHILD_DEPENDANTS_COUNT = 'childDependantsCount',
  ADULT_DEPENDANTS_COUNT = 'adultDependantsCount',

  NUMBER_OF_CHILD_DEPENDANTS = 'numberOfChildDependants',
  NUMBER_OF_ADULT_DEPENDANTS = 'numberOfAdultDependants',

  FOOD_DRINK_AND_OTHER_HOUSEKEEPING_COSTS = 'foodDrinkAndOtherHousekeepingCosts',
  CLOTHING_AND_FOOTWEAR = 'clothingAndFootwear',
  TV_PHONE_AND_INTERNET = 'tvPhoneAndInternet',
  UTILITIES = 'utilities',
  INSURANCE = 'insurance',
  COUNCIL_TAX = 'councilTax',
  TRANSPORT = 'transport',
  RECREATION = 'recreation',
  GROUND_RENT_AND_SERVICE_CHARGE = 'groundRentAndServiceCharge',
  OTHER_MONTHLY_EXPENDITURE = 'otherMonthlyExpenditure',

  FURNISHING_AND_PROPERTY_MAINTENANCE = 'furnishingAndPropertyMaintenance',

  SCHOOL_NURSERY_AND_CHILDCARE_FEES = 'schoolNurseryAndChildcareFees',
  ALIMONY_AND_CHILD_MAINTENANCE = 'alimonyAndChildMaintenance'
}
