import { MatDateFormats } from '@angular/material/core';

/**
 * Default date fns format.
 */
export const MAT_DATE_FNS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'P'
  },
  display: {
    dateInput: 'P',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};
