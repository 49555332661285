/**
 * Converts a value to local Date.
 */
import { DateTime } from 'luxon';
import { isNull, isUndefined } from 'lodash-es';

export function toLocalDate(value: any): Date {
  if (!value) return null;

  const date: Date = new Date(value);
  return isDateValid(date) ? date : null;
}

/**
 * Converts a Date to utc.
 */
export function toDateString(date: Date): string {
  if (typeof date === 'string') {
    date = new Date(Date.parse(date));
  }

  return isDateValid(date) ? DateTime.fromJSDate(date).toISODate() : null;
}

export function asDate(value: any): Date {
  if (!value) return new Date(0, 0, 0, 0, 0, 0, 0);
  if (value instanceof Date) return value;
  if (typeof value === 'string') return new Date(Date.parse(value));
}

export function diffInDays(date: Date): number | null {
  return isDateValid(date) ? Math.round(DateTime.fromJSDate(date).diff(DateTime.now(), 'days').days) : null;
}

export function toDateOrNull(value: Date | string | undefined): Date | null {
  if (!value || isNull(value) || isUndefined(value)) return null;
  return asDate(value);
}

function isDateValid(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}
