import { Component, Input } from '@angular/core';

import { IconPosition } from '../../button.component';

@Component({
  selector: 'broker-button-remove-svg',
  templateUrl: './remove-svg.component.svg'
})
export class RemoveSvgComponent {
  @Input() position: IconPosition = 'left';
}
