/**
 * The Mixpanel user profile properties enum.
 */
export enum MixpanelUserProfilePropertiesEnum {
  /**
   * First name of broker
   */
  FIRST_NAME = '$first_name',

  /**
   * Last name of broker
   */
  LAST_NAME = '$last_name',

  /**
   * Telephone number of broker
   */
  PHONE_NUMBER = '$phone',

  /**
   * Email address of broker
   */
  EMAIL = '$email',

  /**
   * FCA number of broker
   */
  FCA_NUMBER = 'FCA Number',

  /**
   * Timestamp of account creation
   */
  CREATED_DATE = '$created',

  /**
   * Last time broker logs in
   */
  LAST_TIME_LOGIN = 'Last login',

  /**
   * Total number of times broker logs in
   */
  LIFETIME_LOGIN = 'Lifetime login',

  /**
   * First time broker starts a consumer opportunity
   */
  FIRST_START_CONSUMER = 'First start consumer',

  /**
   * Last time broker starts a consumer opportunity
   */
  LAST_START_CONSUMER = 'Last start consumer',

  /**
   * Total number of times broker has started a consumer opportunity
   */
  LIFETIME_START_CONSUMER = 'Lifetime start consumer'
}
