import { NgModule } from '@angular/core';

import { SanitizePipe } from './sanitize/sanitize.pipe';

/**
 * Module for dashboard.
 */
@NgModule({
  declarations: [SanitizePipe],
  exports: [SanitizePipe]
})
export class PipesModule {}
