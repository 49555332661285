<div class='consumer-flow'>
  <h1 class='mt-5'>{{ 'authentication.reset.title' | translate: lang }}</h1>
  <p [innerHTML]="'authentication.reset.sub-title' | translate: lang"></p>

  <p>
    <span class='me-2'>{{ 'authentication.reset.resend.label' | translate: lang }} </span>
    <a href='#' (click)='$event.preventDefault(); resendCode()'>
      {{ 'authentication.reset.resend.resend' | translate: lang }}
    </a>
    <mat-spinner [diameter]='12' class='d-inline me-3' *ngIf='resending'></mat-spinner>
  </p>

  <ng-container *ngIf='resetForm'>
    <div class='warning mt-4' *ngIf='!resetForm.valid && resetForm?.errors?.invalid'>
      {{ 'authentication.reset.error' | translate: lang }}
    </div>

    <form
      [formGroup]='resetForm'
      (ngSubmit)='resetPassword()'
      novalidate
      [ngClass]="resetForm.valid && !resetForm?.errors?.invalid ? 'mt-4' : 'mt-5'"
    >
      <div class='mb-3'>
        <broker-label
          [for]="'code'"
          [label]="'authentication.confirm.form.code.label' | translate:lang">
        </broker-label>
        <mat-form-field appearance='standard'>
          <input
            type='text'
            matInput
            id='code'
            required
            maxlength='16'
            [formControl]='codeControl'
            [placeholder]="'authentication.confirm.form.code.label' | translate: lang"
            (blur)='trimValue(codeControl)'
            autocomplete='off'
          />
        </mat-form-field>
      </div>

      <div class='mb-3'>
        <broker-label
          [for]="'password'"
          [label]="'authentication.register.form.password.label' | translate:lang">
        </broker-label>
        <mat-form-field [appearance]="'standard'">
          <input
            type='password'
            matInput
            id='password'
            required
            maxlength='255'
            [formControl]='passwordControl'
            (blur)='trimValue(passwordControl)'
            [placeholder]="'authentication.register.form.password.placeholder' | translate: lang"
            autocomplete='new-password'
          />
        </mat-form-field>
        <mat-error *ngIf='passwordControl.errors?.complexity'>
          {{ 'authentication.register.form.password.errors.complexity' | translate: lang }}
        </mat-error>
      </div>

      <div class='mb-3'>
        <broker-label
          [for]="'confirmPassword'"
          [label]="'authentication.register.form.confirm-password.label' | translate:lang">
        </broker-label>
        <mat-form-field [appearance]="'standard'">
          <input
            type='password'
            matInput
            id='confirmPassword'
            required
            maxlength='255'
            [formControl]='confirmPasswordControl'
            (blur)='trimValue(confirmPasswordControl)'
            [placeholder]="'authentication.register.form.confirm-password.placeholder' | translate: lang"
            autocomplete='new-password'
          />
        </mat-form-field>
        <mat-error *ngIf='confirmPasswordControl.errors?.match'>
          {{ 'authentication.register.form.confirm-password.errors.match' | translate: lang }}
        </mat-error>
      </div>

      <broker-button [id]="'login'" [height]="'large'" [width]="'full-width'"
                     [disabled]='!resetForm.valid || disabled'>{{ 'authentication.reset.submit' | translate: lang }}</broker-button>
    </form>
  </ng-container>
</div>
