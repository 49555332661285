import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { L10nTranslationModule } from 'angular-l10n';

import { ReferInfoBoxComponent } from './refer-info-box.component';
import { StateIndicatorModule } from '../state-indicator/state-indicator.module';

@NgModule({
  declarations: [ReferInfoBoxComponent],
  exports: [ReferInfoBoxComponent],
  imports: [CommonModule, L10nTranslationModule, StateIndicatorModule]
})
export class ReferInfoBoxModule {}
