export enum FeatureFlagsEnum {
  PROPERTY_VALUATION_CHECK = 'property-valuation-check',
  VALUATION_DOC_UPLOAD_ON_PROPERTY_DETAILS = 'valuation-doc-upload-on-property-details',
  MPS_LOAN_PURPOSE = 'mps-loan-purpose',
  MPS_CREDIT_FILE_EXPIRY = 'mps-credit-file-expiry',
  MPS_DEBT_CON_CALCULATOR = 'mps-debt-con-calculator',
  MPS_DEBT_CON_LENDER_NAME = 'mps-debt-con-lender-name',
  MPS_OTHER_DOCUMENT_MESSAGES = 'mps-other-document-messages',
  MPS_DEBT_CON_AMOUNT_FULL_APP = 'mps-debt-con-amount-full-app',
  SERVER_SIDE_PAGINATION = 'server-side-pagination',
  SUBMISSION_CHECKLIST_UPLOAD = 'submission-checklist-upload',
  SHOW_WHATS_NEW_POPUP = 'show-whats-new-popup',
  CONSOLIDATION_REQUIRED_CREDIT_COMMITMENTS = 'consolidation-required-credit-commitments'
}
