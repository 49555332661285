import { StageNameEnum } from '../consumer/dip/enum/stage-name.enum';
import { isNull, isUndefined } from 'lodash-es';

const underwritingOrOfferOrFundedStages: () => StageNameEnum[] = () => {
  return [StageNameEnum.UNDERWRITING, StageNameEnum.OFFER, StageNameEnum.FUNDED];
};

const pastApplicationStages: () => StageNameEnum[] = () => {
  return [StageNameEnum.APPLICATION, ...underwritingOrOfferOrFundedStages()];
};

const dipQualifiedOrApplication: () => StageNameEnum[] = () => {
  return [StageNameEnum.DIP_QUALIFIED, ...pastApplicationStages()];
};

export function IsUnderwritingOrOfferOrFunded(stage: StageNameEnum): boolean {
  return underwritingOrOfferOrFundedStages().includes(stage);
}

export function IsPastApplicationStages(stage: StageNameEnum): boolean {
  return pastApplicationStages().includes(stage);
}

export function IsDipQualifiedOrPastApplication(stage: StageNameEnum): boolean {
  return dipQualifiedOrApplication().includes(stage);
}

export function IsFactFindApplication(stage: StageNameEnum): boolean {
  return stage === StageNameEnum.FACT_FIND;
}

export function IsSqlApplication(stage: StageNameEnum): boolean {
  return stage === StageNameEnum.SQL;
}

export function IsNonEditableStage(stage: StageNameEnum): boolean {
  return IsUnderwritingOrOfferOrFunded(stage) || IsFactFindApplication(stage);
}
export function IsNotADipYet(stage: StageNameEnum): boolean {
  return isNull(stage) || isUndefined(stage) || stage === StageNameEnum.DIP_FACILITY_DETAILS;
}

export function IsClosedApplication(stage: StageNameEnum): boolean {
  return stage === StageNameEnum.CLOSED;
}
