<button id='{{id}}' [ngClass]='[style, height, width]' [disabled]='disabled' (click)='!loading && clickEvent.emit()'>
  <span>
    <ng-container *ngIf="iconPosition === 'left'" [ngTemplateOutlet]='icons'
                  [ngTemplateOutletContext]="{position: 'left'}"></ng-container>
    <ng-content></ng-content>
    <ng-container *ngIf="iconPosition === 'right'" [ngTemplateOutlet]='icons'
                  [ngTemplateOutletContext]="{position: 'right'}"></ng-container>
  </span>
</button>

<ng-template #icons let-position='position'>
  <broker-button-loading-svg *ngIf='loading' [position]='position'></broker-button-loading-svg>
  <ng-container *ngIf='!loading' [ngSwitch]='icon'>
    <broker-button-arrow-svg *ngSwitchCase="'arrow'" [position]='position'></broker-button-arrow-svg>
    <broker-button-add-svg *ngSwitchCase="'add'" [position]='position'></broker-button-add-svg>
    <broker-button-x-svg *ngSwitchCase="'x'"></broker-button-x-svg>
    <broker-button-remove-svg *ngSwitchCase="'remove'"></broker-button-remove-svg>
  </ng-container>
</ng-template>
