import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { ErrorResponseMessage } from '../../messages/error-response.message';
import { UserValidationModel } from './model/user-validation.model';
import { UserValidationMessage } from './message/user-validation.message';

/**
 * Service to manage the user requests.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Validate user account.
   */
  validateAccount(): Promise<UserValidationModel> {
    return new Promise<UserValidationModel>((resolve: UserValidationMessage, reject: ErrorResponseMessage): void => {
      this.httpClient.get(`${environment.consumerApiPath}/user/validateAccount`).subscribe(
        (response: UserValidationModel) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }
}
