import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { StageNameEnum } from '../dip/enum/stage-name.enum';
import { SaveStepStatusModel } from '../model/save-step-status.model';

/**
 * Service to handle the stepper operations for the Consumer flow.
 */
@Injectable({
  providedIn: 'root'
})
export class StepperService {
  /**
   * Step changed event.
   */
  stepChanged$: Observable<void>;

  /**
   * Step loading event.
   */
  stepLoading$: Subject<[boolean, StageNameEnum]> = new Subject<[boolean, StageNameEnum]>();

  /**
   * Step changed event.
   */
  saveCurrentStepEvent$: Subject<SaveStepStatusModel> = new Subject<SaveStepStatusModel>();

  /**
   * Step changed event.
   */
  saveCurrentStepTryAgainEvent$: Subject<void> = new Subject<void>();

  /**
   * Step changed event.
   */
  retryAgainEvent$: Subject<void> = new Subject<void>();

  reloadCreditCommitmentsStepEvent$: Subject<void> = new Subject<void>();

  /**
   * Gets the current step.
   */
  get currentStep(): number {
    return this.step;
  }

  /**
   * Sets the current step.
   */
  set currentStep(step: number) {
    if (step !== null && step >= this.START_STEP && step <= this.endStep) {
      this.step = step;
    }
  }

  /**
   * Gets the number of applicants subject.
   */
  get numberOfApplicants(): number {
    return this._numberOfApplicants;
  }

  /**
   * Gets the number of applicants subject.
   */
  set numberOfApplicants(value: number) {
    if (value <= 0) {
      this._numberOfApplicants = 0;
    } else if (value >= 2) {
      this._numberOfApplicants = 2;
    } else {
      this._numberOfApplicants = value;
    }

    this.stepChangedEvent.next();
  }

  /**
   * Gets the total number of steps.
   */
  get numberOfSteps(): number {
    return this.endStep;
  }

  /**
   * Gets the current stage.
   */
  get currentStage(): StageNameEnum {
    switch (this.step) {
      case 1:
        return StageNameEnum.DIP_FACILITY_DETAILS;
      case 2:
        return StageNameEnum.DIP_APPLICANT_1;
      default:
        if (this.numberOfApplicants === 1) {
          switch (this.step) {
            case 3:
              return StageNameEnum.DIP_PROPERTY_DETAILS;
            case 4:
              return StageNameEnum.DIP_CREDIT_COMMITMENTS;
            case 5:
              return StageNameEnum.DIP_EXPENDITURE;
            case 6:
              return StageNameEnum.DIP_BROKER_FEES;
            case 7:
              return StageNameEnum.DECISIONING;
            case 8:
              return StageNameEnum.APPLICATION;
          }
        } else if (this.numberOfApplicants === 2) {
          switch (this.step) {
            case 3:
              return StageNameEnum.DIP_APPLICANT_2;
            case 4:
              return StageNameEnum.DIP_PROPERTY_DETAILS;
            case 5:
              return StageNameEnum.DIP_CREDIT_COMMITMENTS;
            case 6:
              return StageNameEnum.DIP_EXPENDITURE;
            case 7:
              return StageNameEnum.DIP_BROKER_FEES;
            case 8:
              return StageNameEnum.DECISIONING;
            case 9:
              return StageNameEnum.APPLICATION;
          }
        }

        return null;
    }
  }

  private get endStep(): number {
    return this.numberOfApplicants === 2 ? 9 : 8;
  }

  private step: number;

  private _numberOfApplicants: number;

  private readonly START_STEP: number = 0;

  private readonly stepChangedEvent: Subject<void> = new Subject<void>();

  constructor() {
    this.step = 0;
    this.numberOfApplicants = 0;
    this.stepChanged$ = this.stepChangedEvent.asObservable();
  }

  /**
   * Handles the back button click event.
   */
  back(): void {
    if (this.step > this.START_STEP) {
      this.step--;
    } else {
      this.step = this.START_STEP;
    }

    this.stepChangedEvent.next();
  }

  /**
   * Handles the next button click event.
   */
  next(): void {
    if (this.step < this.endStep) {
      this.step++;
    } else {
      this.step = this.endStep;
    }

    this.stepChangedEvent.next();
  }

  /**
   * Handles the go to step method.
   */
  goToStage(stage: StageNameEnum): void {
    this.step = this.getStepNumber(stage);
    this.stepChangedEvent.next();
  }

  /**
   * Gets the step number from stage name.
   */
  getStepNumber(stage: StageNameEnum): number {
    switch (stage) {
      case StageNameEnum.DIP_FACILITY_DETAILS:
        return 1;
      case StageNameEnum.DIP_APPLICANT_1:
        return 2;
      case StageNameEnum.DIP_APPLICANT_2:
        return 3;
      case StageNameEnum.DIP_PROPERTY_DETAILS:
        return 2 + this.numberOfApplicants;
      case StageNameEnum.DIP_CREDIT_COMMITMENTS:
        return 3 + this.numberOfApplicants;
      case StageNameEnum.DIP_EXPENDITURE:
        return 4 + this.numberOfApplicants;
      case StageNameEnum.DIP_BROKER_FEES:
        return 5 + this.numberOfApplicants;
      case StageNameEnum.DECISIONING:
      case StageNameEnum.DIP_QUALIFIED:
        return 6 + this.numberOfApplicants;
      case StageNameEnum.APPLICATION:
      case StageNameEnum.UNDERWRITING:
      case StageNameEnum.OFFER:
      case StageNameEnum.CLOSED:
      case StageNameEnum.FUNDED:
        return 7 + this.numberOfApplicants;
      default:
        return 0;
    }
  }
}
