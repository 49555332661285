<div class='p-4'>
  <div class='text-center mb-3'>
    <span class='dashboard-warning'>
      <span id='adminViewWarning' *ngIf='isAdminViewEnabled'>{{ 'consumer.dashboard.admin-view.warning' | translate: lang }}</span>
      <span id='godViewWarning' *ngIf='isAnyAccountViewEnabled'>{{ 'consumer.dashboard.any-account-view.warning' | translate: lang }}</span>
    </span>
  </div>

  <broker-dashboard-table
    [isLoadingResults]='isLoadingResults'
    [isCompanySelected]='isCompanySelected'
    [records]='tableRecords'
    [metadata]='tableMetadata'
    [showCreditCheckExpiryColumn]='isToShowCreditCheckExpiryColumn'
    [isAnyAccountViewEnabled]='isAnyAccountViewEnabled'
    (fetchApplications)='fetchApplications.emit($event)'
  >
  </broker-dashboard-table>
</div>
