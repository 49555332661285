import { Injectable } from '@angular/core';

import { StorageFactoryService } from './factory/storage-factory.service';
import { StorageKeyEnum } from './enum/storage-key.enum';

/**
 * Storage service.
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private readonly storageFactory: StorageFactoryService) {}

  /**
   * Gets the stored value for the specific key from the correct storage.
   */
  getValue(key: StorageKeyEnum): string {
    return this.storageFactory.getStorage(key).getItem(key);
  }

  /**
   * Saves the key/value on the correct storage.
   */
  saveValue(key: StorageKeyEnum, value: string): void {
    const storage: Storage = this.storageFactory.getStorage(key);
    if (value) {
      storage.setItem(key, value);
    } else {
      storage.removeItem(key);
    }
  }

  /**
   * Clears both storages.
   */
  clearBrowserStorage(): void {
    localStorage.clear();
    this.clearSessionStorage();
  }

  /**
   * Clears the session storage.
   */
  clearSessionStorage(): void {
    sessionStorage.clear();
  }
}
