import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MissingInformationDialogModel } from './model/missing-information-dialog.model';

/**
 * The missing information dialog component.
 */
@Component({
  selector: 'broker-missing-information-dialog',
  templateUrl: './missing-information-dialog.component.html'
})
export class MissingInformationDialogComponent {
  /**
   * Emits an event when the navigation bar back button click event occurs.
   */
  @Output() navigationBarBackButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: MissingInformationDialogModel) {}

  /**
   * Handles the navigation bar back button click event.
   */
  handleNavigationBarBackButtonClickEvent(): void {
    this.navigationBarBackButtonClickEvent.emit();
  }
}
