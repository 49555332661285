import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { CloseCaseModel } from '../../components/consumer/dialogs/close-case-dialog/model/close-case.model';
import { LoggerService } from '../../services/logger/logger.service';
import { EmptyMessage } from '../../messages/empty.message';
import { ErrorResponseMessage } from '../../messages/error-response.message';
import { NavigatorService } from '../../services/navigator/navigator.service';
import { StepperService } from '../stepper/stepper.service';
import { ConsumerStorageDipService } from '../storage/consumer-storage-dip.service';
import { ConsumerDipResponseService } from './consumer-dip-response.service';
import { ConsumerFormDipService } from './consumer-form-dip.service';
import { DecisionInPrincipleDetailsMessage } from './message/decision-in-principle-details.message';
import { ApplicantModel } from './model/applicant.model';
import { BrokerFeesDetailsModel } from './model/broker-fees-details.model';
import { CalculationOfferStatusResponseModel } from './model/calculation-offer-status.model';
import { CreditCommitmentsDetailsModel } from './model/credit-commitments-details.model';
import { DecisionInPrincipleDetailsModel, DecisionInPrincipleModel } from './model/decision-in-principle-details.model';
import { EsisStatusModel } from './model/esis-status.model';
import { ExpenditureDetailsModel } from './model/expenditure-details.model';
import { FacilityDetailsModel } from './model/facility-details.model';
import { PropertyDetailsModel } from './model/property-details.model';
import { ApplicantViewModel } from './model/view-model/applicant.view-model';
import {
  BaseCreditCommitmentsViewModel,
  CreditCommitmentsViewModel
} from './model/view-model/credit-commitments-details.view-model';
import { HouseholdExpenditureViewModel } from './model/view-model/expenditure-details.view-model';
import { PropertyDetailsViewModel } from './model/view-model/property-details.view-model';
import { LoanDetailsRequiredDocumentsMessage } from '../../messages/loan-details-required-documents.message';
import { LoanInformationDocumentGroup } from '../details/model/documents/full-application-documents.model';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

/**
 * Service to manage the dip requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsumerApiDipService {
  readonly onRequestCurrentCreditCommitments: Subject<(cc: CreditCommitmentsViewModel) => void> = new Subject<
    (cc: CreditCommitmentsViewModel) => void
  >();

  private _skipOfferReload = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly storageDipService: ConsumerStorageDipService,
    private readonly responseService: ConsumerDipResponseService,
    private readonly formDipService: ConsumerFormDipService,
    private readonly navigatorService: NavigatorService,
    private readonly stepper: StepperService,
    private readonly logger: LoggerService
  ) {}

  /**
   * A flag indicating the offers need to be checked.
   */
  get skipOfferReload(): boolean {
    return this._skipOfferReload;
  }

  set skipOfferReload(value: boolean) {
    this._skipOfferReload = value;
  }

  /**
   * Fetch DIP from server by id.
   */
  fetchDipById(id: string): Promise<DecisionInPrincipleDetailsModel> {
    return new Promise<DecisionInPrincipleDetailsModel>(
      (resolve: DecisionInPrincipleDetailsMessage, reject: ErrorResponseMessage): void => {
        this.httpClient.get(`${environment.consumerApiPath}/dip/${id}`).subscribe(
          (response: DecisionInPrincipleModel) => {
            const dip: DecisionInPrincipleDetailsModel = this.responseService.mapDip(response);

            this.storageDipService.dipData = dip;
            this.storageDipService.creditCommitmentsData =
              dip.dipCreditCommitments?.creditCommitmentsReady && !dip.dipCreditCommitments?.creditCommitmentsError
                ? dip.dipCreditCommitments
                : null;
            resolve(dip);
          },
          (error: HttpErrorResponse) => reject(error)
        );
      }
    );
  }

  /**
   * Creates or updates a DIP and saves the facility details.
   */
  createsOrUpdatesFacilityDetails(data: FacilityDetailsModel): Promise<void> {
    if (this.storageDipService.dipId) {
      return this.updateFacilityDetails(data, false);
    }

    return this.saveFacilityDetails(data);
  }

  /**
   * Queries which documents will need to be submitted for a particular loan information structure.
   */
  getNeededDocuments(data: FacilityDetailsModel): Promise<LoanInformationDocumentGroup> {
    return new Promise<LoanInformationDocumentGroup>(
      (resolve: LoanDetailsRequiredDocumentsMessage, reject: ErrorResponseMessage) => {
        const facilityDetails: FacilityDetailsModel = this.responseService.mapFacilityDetails(data);
        this.httpClient
          .post(`${environment.consumerApiPath}/dip/loaninformation/neededDocuments`, facilityDetails)
          .subscribe(
            (response: LoanInformationDocumentGroup) => resolve(response),
            (error: HttpErrorResponse) => reject(error)
          );
      }
    );
  }

  /**
   * Saves the applicant data of an existing DIP.
   */
  saveApplicant(applicantNumber: number, applicantData: ApplicantViewModel, partialSave: boolean): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      const data: ApplicantModel = this.responseService.mapApplicant(applicantData);
      this.httpClient
        .patch(
          `${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/applicant/${applicantNumber}`,
          data,
          { params: { partialSave: partialSave ? 'true' : 'false' } }
        )
        .subscribe(
          (response: { id: string; newCreditFilePulled: boolean }) => {
            if (applicantNumber === 1) {
              this.storageDipService.dipData = {
                ...this.storageDipService.dipData,
                applicant1: data
              };
            } else {
              this.storageDipService.dipData = {
                ...this.storageDipService.dipData,
                applicant2: data
              };
            }
            if (response.newCreditFilePulled) {
              this.storageDipService.creditCommitmentsData = null;
            }
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Saves the property details of an existing DIP.
   */
  savePropertyDetails(propertyDetails: PropertyDetailsViewModel, partialSave: boolean): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      const data: PropertyDetailsModel = this.responseService.mapPropertyDetails(propertyDetails);
      this.httpClient
        .patch(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/propertyDetails`, data, {
          params: { partialSave: partialSave ? 'true' : 'false' }
        })
        .subscribe(
          () => {
            this.storageDipService.dipData = {
              ...this.storageDipService.dipData,
              propertyDetails: data
            };
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Fetches the credit commitments for the current application.
   */
  fetchCreditCommitments(): Observable<CreditCommitmentsViewModel> {
    return this.httpClient
      .get<CreditCommitmentsDetailsModel>(
        `${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/creditCommitments`
      )
      .pipe(
        map((creditCommitments: CreditCommitmentsDetailsModel) => {
          const dipData: DecisionInPrincipleDetailsModel = this.storageDipService.dipData;
          return this.responseService.mapCreditCommitmentModel(
            creditCommitments,
            dipData?.applicant1,
            dipData?.applicant2,
            this.stepper.numberOfApplicants
          );
        })
      );
  }

  /**
   * Updates/Saves credit commitments / debt and expenditure.
   */
  updateCreditCommitments(creditCommitmentsDetails: CreditCommitmentsViewModel, partialSave: boolean): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      let currentCreditCommitmentsData: CreditCommitmentsViewModel;
      this.onRequestCurrentCreditCommitments.next((cc: CreditCommitmentsViewModel) => {
        currentCreditCommitmentsData = cc;
      });
      const mergedData: BaseCreditCommitmentsViewModel = this.responseService.mergeCreditCommitments(
        creditCommitmentsDetails,
        currentCreditCommitmentsData
      );
      this.httpClient
        .patch(
          `${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/creditCommitments`,
          this.responseService.mapCreditCommitmentDetails(mergedData),
          { params: { partialSave: partialSave ? 'true' : 'false' } }
        )
        .subscribe(
          () => {
            this.fetchCreditCommitments().subscribe((commitments: CreditCommitmentsViewModel) => {
              this.storageDipService.creditCommitmentsData = commitments;
              resolve();
            });
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Updates/Saves credit commitments / debt and expenditure.
   */
  updateExpenditure(expenditureDetails: HouseholdExpenditureViewModel, partialSave: boolean): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      const data: ExpenditureDetailsModel = this.responseService.mapExpenditureDetails(expenditureDetails);
      this.httpClient
        .patch(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/expenditures`, data, {
          params: { partialSave: partialSave ? 'true' : 'false' }
        })
        .subscribe(
          () => {
            this.storageDipService.dipData = {
              ...this.storageDipService.dipData,
              expenditures: expenditureDetails
            };
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Calls the server to calculate consumer loan opportunity of an existing DIP.
   */
  calculateOffers(
    opportunityId: string,
    facilityDetails?: FacilityDetailsModel,
    addProductFeesToFacility?: boolean,
    propertyValue?: number
  ): Promise<void> {
    if (!opportunityId?.length) {
      return Promise.reject();
    }

    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post(
          `${environment.consumerApiPath}/dip/${opportunityId}/calculateOffers`,
          this.responseService.mapProductSelectionCalculationDetails(
            facilityDetails,
            addProductFeesToFacility,
            propertyValue
          )
        )
        .subscribe(
          () => {
            this.skipOfferReload = false;
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Updates/Saves the broker fees.
   */
  updateBrokerFees(brokerFeesDetails: BrokerFeesDetailsModel, partialSave: boolean): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      const data = this.responseService.mapBrokerFeesDetails(brokerFeesDetails);
      this.httpClient
        .patch(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/brokerFees`, data, {
          params: { partialSave: partialSave ? 'true' : 'false' }
        })
        .subscribe(
          () => {
            this.storageDipService.dipData = {
              ...this.storageDipService.dipData,
              brokerFees: { ...this.storageDipService.dipData?.brokerFees, ...data }
            };
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Submits the select product offer and request the ESIS to be sent to the broker.
   */
  generateEsis(opportunityId: string, offerId: string): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post(`${environment.consumerApiPath}/dip/${opportunityId}/generateEsis`, {
          id: offerId
        })
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Gets the url for the product offer calculation status API.
   */
  getCheckOfferCalculationStatus(): Observable<CalculationOfferStatusResponseModel> {
    return this.httpClient.get<CalculationOfferStatusResponseModel>(
      `${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/checkOfferCalculationStatus`
    );
  }

  /**
   * Takes the DIP back to the product selection stage so the broker can reevaluate the offers.
   */
  reevaluateOffers(): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/reevaluateOffers`, {})
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Submits the DIP to be converted to full application.
   */
  submitFullApplication(): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/submitFullApplication`, {})
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Closes a case.
   */
  closeCase(reason: CloseCaseModel): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .post(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/closeCase`, reason)
        .subscribe(
          () => resolve(),
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Fetch the latest dip data and navigate to the appropriate route.
   */
  updateDipDataAndNavigateToTheAppropriatePage(): Promise<void> {
    const opportunityId: string = this.storageDipService.dipId;

    return new Promise<void>((resolve: EmptyMessage, reject: EmptyMessage): void => {
      this.fetchDipById(opportunityId)
        .then((data: DecisionInPrincipleDetailsModel) => {
          this.stepper.numberOfApplicants = data?.loanInformation?.numberOfApplicants ?? 0;
          this.stepper.goToStage(data?.stage ?? null);

          this.navigatorService
            .redirectToAppropriateRoute(opportunityId, data)
            .then(() => {
              resolve();
            })
            .catch((err: any) => {
              this.logger.error(`Error navigating to the appropriate route`, err);
              reject();
            });
        })
        .catch((err: any) => {
          this.logger.error(`Error fetching the DIP opportunity.`, err);
          reject();
        });
    });
  }

  /**
   * Updates the facility details of an existing DIP.
   */
  updateFacilityDetails(data: FacilityDetailsModel, partialSave = false): Promise<void> {
    const facilityDetails: FacilityDetailsModel = this.responseService.mapFacilityDetails(data);

    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .patch(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/loaninformation`, facilityDetails, {
          params: { partialSave: partialSave ? 'true' : 'false' }
        })
        .subscribe(
          () => {
            this.stepper.numberOfApplicants = +facilityDetails.numberOfApplicants;
            const dipData: DecisionInPrincipleDetailsModel = {
              ...this.storageDipService.dipData,
              loanInformation: data
            };
            if (+facilityDetails.numberOfApplicants === 1) {
              dipData.applicant2 = null;
              this.formDipService.restoreApplicantDetailsForm(2);
            }
            this.storageDipService.dipData = dipData;
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Download document ESIS.
   */
  downloadEsis(): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient
        .get(`${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/downloadEsis`, {
          responseType: 'blob',
          headers: new HttpHeaders({ accept: 'application/pdf' })
        })
        .subscribe(
          (data: Blob) => {
            this.saveFile(data, 'ESIS.pdf');
            resolve();
          },
          (error: HttpErrorResponse) => reject(error)
        );
    });
  }

  /**
   * Checks if the ESIS document is ready to be downloaded.
   */
  checkEsisDocumentStatus(isFinalAttempt = false): Observable<EsisStatusModel> {
    return this.httpClient.get<EsisStatusModel>(
      `${environment.consumerApiPath}/dip/${this.storageDipService.dipId}/downloadEsis`,
      {
        params: { finalAttempt: isFinalAttempt ? 'true' : 'false' },
        responseType: 'json'
      }
    );
  }

  rerunCreditCheck(id: string): Promise<void> {
    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient.post(`${environment.consumerApiPath}/dip/${id}/newCreditFile`, {}).subscribe(
        () => resolve(),
        (error: HttpErrorResponse) => reject(error)
      );
    });
  }

  /**
   * Creates a new DIP and saves the facility details.
   */
  private saveFacilityDetails(data: FacilityDetailsModel): Promise<void> {
    const facilityDetails: FacilityDetailsModel = this.responseService.mapFacilityDetails(data);

    return new Promise<void>((resolve: EmptyMessage, reject: ErrorResponseMessage): void => {
      this.httpClient.post(`${environment.consumerApiPath}/dip/loaninformation`, facilityDetails).subscribe(
        (response: DecisionInPrincipleDetailsModel) => {
          const dipData: DecisionInPrincipleDetailsModel = {
            ...response,
            loanInformation: data
          };
          if (+facilityDetails.numberOfApplicants === 1) {
            dipData.applicant2 = null;
          }
          this.storageDipService.dipData = dipData;
          resolve();
        },
        (error: HttpErrorResponse) => reject(error)
      );
    });
  }

  // noinspection JSMethodCanBeStatic
  private saveFile(blob: Blob, fileName: string): void {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const blobUrl: any = window.URL.createObjectURL(blob);
      const htmlDocument: HTMLDocument = window.document;
      const link: HTMLAnchorElement = htmlDocument.createElement('a');

      link.download = fileName;
      link.href = blobUrl;
      htmlDocument.body.appendChild(link);
      link.click();
      htmlDocument.body.removeChild(link);
    }
  }
}
