import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { AuthService } from '../services/auth/auth.service';

/**
 * Guard to check authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private readonly router: Router, private readonly oauthService: AuthService) {}

  /**
   * Check if route can be activated.
   * @param _route if the route can be activated.
   * @param _state the state of the router.
   */
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.checkLogin();
  }

  /**
   * Check if route can be activated.
   * @param _childRoute if the route can be activated.
   * @param _state the state of the router.
   */
  canActivateChild(_childRoute: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.checkLogin();
  }

  private checkLogin(): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve: any): void => {
      this.oauthService
        .isLoggedIn()
        .then((loggedIn: boolean) => {
          if (!loggedIn) {
            resolve(this.router.createUrlTree(['auth/login']));
          } else {
            resolve(true);
          }
        })
        .catch(() => {
          resolve(this.router.createUrlTree(['auth/login']));
        });
    });
  }
}
