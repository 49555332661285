import { Inject } from '@angular/core';

import { L10N_LOCALE, L10nDateTimeFormatOptions, L10nLocale, L10nNumberFormatOptions } from 'angular-l10n';

import { EN_GB_LOCALE } from './supported-languages';

/**
 * Base class for localizable components.
 */
export abstract class LocalizableComponent {
  /** The date format. */
  format: L10nDateTimeFormatOptions = {
    dateStyle: 'short',
    year: 'numeric',
    timeStyle: 'short'
  };

  /** The date only format. */
  dateFormat: L10nDateTimeFormatOptions = {
    dateStyle: 'short',
    year: 'numeric'
  };

  /** The time only format. */
  timeFormat: L10nDateTimeFormatOptions = {
    timeStyle: 'short'
  };

  /** Number format options. */
  percentFormat: L10nNumberFormatOptions = {
    style: 'percent',
    digits: '1.1-2'
  };

  /** Currency format options. */
  currencyFormat: L10nNumberFormatOptions = {
    digits: '1.0-0',
    style: 'currency',
    currency: this.currency,
    currencyDisplay: 'symbol'
  };

  /** Currency format options. */
  decimalCurrencyFormat: L10nNumberFormatOptions = {
    digits: '1.2-2',
    style: 'currency',
    currency: this.currency,
    currencyDisplay: 'symbol'
  };

  protected constructor(@Inject(L10N_LOCALE) private readonly locale: L10nLocale) {}

  /**
   * Gets the selected language.
   * @return the selected language.
   */
  get lang(): string {
    return this.locale.language || EN_GB_LOCALE().language;
  }

  /**
   * Gets the selected currency.
   * @return the selected currency.
   */
  get currency(): string {
    return this.locale.currency ?? 'GBP';
  }
}
