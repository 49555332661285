import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';

import { L10nIntlService, L10nTranslationService } from 'angular-l10n';

/**
 * Service to handle localization related tasks.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  constructor(private readonly locale: L10nTranslationService, private readonly intl: L10nIntlService) {}

  /**
   * Translates a key.
   * @param key The key to translate.
   * @param params The parameters to add to the translation string.
   * @return The translation.
   */
  translate(key: string, params: any = null): string {
    return this.locale.translate(key, params);
  }

  /**
   * Translates a key.
   * @param translationKey The key to translate.
   * @param params The parameters to add to the translation string.
   * @param isCurrency If the param values should be converted to a currency string.
   * @return The translation.
   */
  translateWithParams(translationKey: string, params: KeyValue<string, number>[], isCurrency = false): string {
    const currencyParams: any = params.reduce(
      (previousValue: any, { key, value }: KeyValue<string, number>) => ({
        ...previousValue,
        [key]: isCurrency
          ? this.intl.formatNumber(
              value,
              {
                digits: '1.0-0',
                style: 'currency',
                currency: this.locale.getLocale().currency,
                currencyDisplay: 'symbol'
              },
              this.locale.getLocale().language
            )
          : value
      }),
      {}
    );

    return this.translate(translationKey, currencyParams);
  }
}
