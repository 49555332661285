/**
 * The Mixpanel track events.
 */
export enum MixpanelEventsEnum {
  /**
   * Triggered when a new user in auth db is created successfully
   */
  SIGN_UP = 'Sign Up',

  /**
   * Triggered when a users logs into Broker Portal
   */
  LOGIN = 'Login',

  /**
   * Triggered when clicked on "Submit Consumer Opportunity"
   */
  START_CONSUMER = 'Start Consumer',

  /**
   * Triggered when the DiP Qualified page loads
   */
  DIP_COMPLETE = 'DiP Complete',

  /**
   * Triggered when the DiP Qualified page is NOT Error
   */
  SUCCESSFUL_DIP_COMPLETE = 'Successful DiP Complete',

  /**
   * Triggered when the DiP Qualified page is NOT Error
   */
  PART_SUCCESSFUL_DIP_COMPLETE = 'Part successful DiP Complete',

  /**
   * Triggered when the DiP Qualified page is NOT Error
   */
  UNSUCCESSFUL_DIP_COMPLETE = 'Unsuccessful DiP Complete',

  /**
   * Triggered when the DiP Qualified - Error page loads
   */
  ERRORED_DIP_COMPLETE = 'Errored DiP Complete',

  /**
   * Triggered when DiP - Customer Acceptance page loads
   */
  DIP_ACCEPTED = 'DiP Accepted',

  /**
   * Triggered when Full App page loads
   */
  FULL_APP_STARTED = 'Full App Started',

  /**
   * Triggered when a user clicks on "Submit application" on the pop-up
   */
  FULL_APP_COMPLETED = 'Full App Completed',

  /**
   * Triggered when Funded page loads
   */
  FUNDED = 'Funded',

  /**
   * Triggered when a user clicks on an opportunity on the dashboard of the broker portal
   */
  CONTINUE_CONSUMER = 'Continue Consumer',

  /**
   * Triggered the ESIS document is downloaded
   */
  ESIS_DOWNLOADED = 'ESIS downloaded',

  /**
   * Triggered when an error page is shown to a broker
   */
  ERROR_PAGE = 'Error Page',

  /**
   * Triggered when an error messages appears below a CTA
   */
  ERROR_MESSAGE = 'Error Message'
}
