import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

import { Amplify } from 'aws-amplify';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { L10nIntlModule, L10nLoader, L10nTranslationModule, L10nValidationModule } from 'angular-l10n';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { CallUsComponent } from './authentication/call-us/call-us.component';
import { ConfirmRegistrationComponent } from './authentication/confirm-registration/confirm-registration.component';
import { LoginComponent } from './authentication/login/login.component';
import { RecoverComponent } from './authentication/recover/recover.component';
import { RegisterStep1Component } from './authentication/register/register-step1/register-step1.component';
import { RegisterStep2Component } from './authentication/register/register-step2/register-step2.component';
import { RegisterComponent } from './authentication/register/register.component';
import { VerifyCodeComponent } from './authentication/verify-code/verify-code.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { TokenInterceptor } from './shared/services/auth/token.interceptor';
import { HttpTranslationLoader } from './shared/localization/http-translation-loader';
import { LocaleValidation } from './shared/localization/locale-validation';
import { LocalizationStorage } from './shared/localization/localization-storage';
import { initL10n, l10nConfig } from './shared/localization/localization.configuration';
import { SharedModule } from './shared/shared.module';
import { ButtonModule } from './shared/components/button/button.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

declare global {
  interface Window {
    _env_: any;
    Intercom: any;
  }
}

Amplify.configure({
  Auth: {
    ...environment.cognito
  }
});

/**
 * Module for app.
 */
@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    LoginComponent,
    RegisterComponent,
    RecoverComponent,
    ConfirmRegistrationComponent,
    RegisterStep1Component,
    RegisterStep2Component,
    CallUsComponent,
    VerifyCodeComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DashboardModule,
    HttpClientModule,
    L10nTranslationModule.forRoot(l10nConfig, {
      storage: LocalizationStorage,
      translationLoader: HttpTranslationLoader
    }),
    L10nIntlModule,
    L10nValidationModule.forRoot({ validation: LocaleValidation }),
    SharedModule.forRoot(),
    NgxMaskModule.forRoot(options),
    ButtonModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      deps: [Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
