import { Component, Inject } from '@angular/core';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../shared/localization/localizable.component';

@Component({
  selector: 'broker-call-us',
  templateUrl: './call-us.component.html'
})
export class CallUsComponent extends LocalizableComponent {
  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
  }
}
