import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { LocalizableComponent } from '../../shared/localization/localizable.component';

@Component({
  selector: 'broker-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent extends LocalizableComponent {
  @Input() set searchTerm(value: string) {
    this.searchControl.setValue(value);
  }

  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();

  randomId: string;

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl('', Validators.maxLength(256))
  });

  get searchControl(): UntypedFormControl {
    return this.searchForm.get('search') as UntypedFormControl;
  }

  constructor(@Inject(L10N_LOCALE) locale: L10nLocale) {
    super(locale);
    this.randomId = `${Math.floor(Math.random() * 899999 + 100000)}`;
  }

  search(): void {
    this.searchChanged.emit(this.searchControl.value);
  }
}
