import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { FeatureFlagsEnum } from '../../enums/feature-flags/feature-flags.enum';
import { environment } from '../../../../environments/environment';
import { LoggerService } from '../logger/logger.service';
import { EmptyMessage } from '../../messages/empty.message';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private enabledFeatureFlags: FeatureFlagsEnum[] = [];

  constructor(private readonly httpClient: HttpClient, private readonly logger: LoggerService) {}

  isFeatureFlagEnabled(featureFlag: FeatureFlagsEnum): boolean {
    return this.enabledFeatureFlags.includes(featureFlag);
  }

  resetFeatureFlagsEnabled(): void {
    this.enabledFeatureFlags = [];
  }

  checkFeatureFlags(): Promise<void> {
    this.resetFeatureFlagsEnabled();
    return new Promise<void>((resolve: EmptyMessage): void => {
      this.httpClient
        .post(`${environment.consumerApiPath}/user/featureFlags`, Object.values(FeatureFlagsEnum))
        .subscribe(
          (response: FeatureFlagsEnum[]) => {
            if (response instanceof Array) {
              this.enabledFeatureFlags = response;
            } else {
              this.logger.error("Error parsing feature flags response since it's not an array");
            }
            resolve();
          },
          (error: HttpErrorResponse) => {
            this.logger.error('Error getting user feature flags: ', error);
            resolve();
          }
        );
    });
  }
}
