import { Injectable } from '@angular/core';

import { AddressModel } from '../../services/address/model/address.model';
import { ApplicationFilterStage } from '../../services/dashboard/dashboard.service';
import { TableItemModel } from '../../models/table-item.model';
import { DecisionType } from '../base-opportunity.model';
import { DashboardModel } from '../model/dashboard.model';
import { NameModel } from './model/applicant-name.model';
import { ApplicationModel, NonPaginatedApplicationsResponseModel } from './model/application.model';
import { toDateOrNull } from '../../utils/date.util';

/**
 * Class to map the common consumer response.
 */
@Injectable({
  providedIn: 'root'
})
export class ConsumerResponseService {
  mapNonPaginatedApplications(
    response: NonPaginatedApplicationsResponseModel,
    stage: ApplicationFilterStage
  ): DashboardModel {
    const dashboard: DashboardModel = {
      summary: response?.summary,
      records: []
    };
    if (stage === 'closed') {
      dashboard.records = this.mapApplications(response.closedApplications);
    } else if (stage === 'funded') {
      dashboard.records = this.mapApplications(response.fundedApplications);
    } else {
      dashboard.records = this.mapApplications(response.liveApplications);
    }
    return dashboard;
  }

  mapApplications(applications: ApplicationModel[]): TableItemModel[] {
    return (
      applications?.map(
        (opportunity: ApplicationModel): TableItemModel => {
          if (!opportunity) {
            return null;
          }

          return {
            id: opportunity.id ?? null,
            reference: opportunity.reference ?? null,
            applicant1Name: this.mapContactName(opportunity.applicant1),
            applicant2Name: this.mapContactName(opportunity.applicant2),
            stage: opportunity.stage ?? null,
            subStage: opportunity.subStage ?? null,
            decision: this.mapDecision(opportunity),
            requestedAmount: opportunity.requestedAmount ?? null,
            created: opportunity.created ?? null,
            brokerName: this.mapContactName(opportunity.brokerSubmitter),
            selinaContact: this.mapContactName(opportunity.selinaContact),
            lastModified: opportunity.lastModified ?? null,
            securityAddress: this.mapAddress(opportunity.propertyAddress),
            creditCheckExpiry: toDateOrNull(opportunity.creditCheckExpiry),
            dashboardStage: opportunity.dashboardStage
          };
        }
      ) ?? []
    );
  }

  // noinspection JSMethodCanBeStatic
  private mapDecision(opportunity: ApplicationModel): DecisionType {
    switch (opportunity.decision) {
      case 'ACCEPT':
      case 'DECLINE':
      case 'ERROR':
        return opportunity.decision;
      default:
        return null;
    }
  }

  // noinspection JSMethodCanBeStatic
  private mapContactName(name: NameModel): string {
    if (!name?.firstName?.length) {
      return null;
    }

    return `${name.firstName} ${name.surname ?? ''}`.trim();
  }

  // noinspection JSMethodCanBeStatic
  private mapAddress(propertyAddress: AddressModel): string {
    if (!propertyAddress) {
      return '';
    }
    let address: string = propertyAddress.addressLine1;
    if (propertyAddress.addressLine2) {
      address = `${address}, ${propertyAddress.addressLine2}`;
    }
    return `${address}, ${propertyAddress.city}, ${propertyAddress.postcode}`;
  }
}
