export enum BrokerFeesControlNamesEnum {
  COMMISSION_FEE = 'commissionFee',
  ADD_COMMISSION_FEE_TO_LOAN = 'addCommissionFeeToLoan',
  ARRANGEMENT_FEE = 'arrangementFee',
  ADD_ARRANGEMENT_FEE_TO_LOAN = 'addArrangementFeeToLoan',
  VALUATION_FEE = 'valuationFee',
  ADD_VALUATION_FEE_TO_LOAN = 'addValuationFeeToLoan',
  THIRD_PARTY_FEE = 'thirdPartyFee',
  ADD_THIRD_PARTY_FEE_TO_LOAN = 'addThirdPartyFeeToLoan',
  ADD_PRODUCT_FEE_TO_LOAN = 'addProductFeeToLoan',

  INTERMEDIARY_SECTION = 'intermediary',
  INTERMEDIARY_ADVICE_FEE = 'adviceFee',
  INTERMEDIARY_ADD_ADVICE_FEE_TO_LOAN = 'addAdviceFeeToLoan',

  ADD_ARRANGEMENT_FEE_SELINA = 'addArrangementFeeSelina',
  ADD_ARRANGEMENT_FEE_SELINA_TO_LOAN = 'addArrangementFeeSelinaToLoan'
}
