import { Injectable } from '@angular/core';

import { ApplicantControlNamesEnum } from '../../../consumer/dip/form/applicant/enum/applicant-control-names.enum';
import { asSortable, asSortableCcj } from '../../../consumer/dip/form/credit-commitments/status-utils';
import { FacilityDetailsControlNamesEnum } from '../../../consumer/dip/form/facility-details/enum/facility-details-control-names.enum';
import { PropertyDetailsControlNamesEnum } from '../../../consumer/dip/form/property-details/enum/property-details-control-names.enum';
import { AddressModel } from '../../services/address/model/address.model';
import { AddressControlNamesEnum } from '../../components/consumer/address/enum/address-control-names.enum';
import { asDate, toDateOrNull, toDateString } from '../../utils/date.util';
import { EmploymentStatusEnum } from './enum/employment-status.enum';
import { IncomeSourceTypeEnum } from './enum/income-source-type.enum';
import { CreditCommitmentsMapper } from './mappers/credit-commitments.mapper';
import { toNumber, trimValue } from './mappers/mapping-utils';
import { ApplicantModel } from './model/applicant.model';
import { BrokerFeesDetailsModel } from './model/broker-fees-details.model';
import {
  ApplicantCreditScoreModel,
  BaseCreditCommitmentsDetailsModel,
  CcjModel,
  CreditCommitmentsDetailsModel,
  MortgageOrSecuredCreditCommitmentModel,
  UnsecuredCreditCommitmentModel
} from './model/credit-commitments-details.model';
import { DecisionInPrincipleDetailsModel, DecisionInPrincipleModel } from './model/decision-in-principle-details.model';
import { EmploymentModel } from './model/employment.model';
import { ExpenditureDetailsModel } from './model/expenditure-details.model';
import { FacilityAllocationModel } from './model/facility-allocation.model';
import { FacilityDetailsModel } from './model/facility-details.model';
import { IncomeSourceModel } from './model/income-source.model';
import { IncomeModel } from './model/income.model';
import { PreviousAddressModel } from './model/previous-address.model';
import { PreviousNameModel } from './model/previous-name.model';
import { ProductOfferCalculationDetailsModel } from './model/product-offer-calculation.model';
import { PropertyDetailsModel } from './model/property-details.model';
import { ApplicantViewModel } from './model/view-model/applicant.view-model';
import {
  ApplicantScoreViewModel,
  BaseCreditCommitmentsViewModel,
  CcjViewModel,
  CreditCommitmentsViewModel,
  SecuredCreditCommitmentViewModel,
  UnsecuredCreditCommitmentViewModel
} from './model/view-model/credit-commitments-details.view-model';
import { DependentsCountType, HouseholdExpenditureViewModel } from './model/view-model/expenditure-details.view-model';
import { IncomeSourceFormGroupViewModel } from './model/view-model/income-source-form-group.view-model';
import { IncomeSourceViewModel } from './model/view-model/income-source.view-model';
import { PropertyDetailsViewModel } from './model/view-model/property-details.view-model';
import { isUndefined } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ConsumerDipResponseService {
  /**
   * Maps a DIP response into a DIP view models.
   */
  mapDip(response: DecisionInPrincipleModel): DecisionInPrincipleDetailsModel {
    const dip: DecisionInPrincipleDetailsModel = {
      reasonOrDecisionDescription: response.reasonOrDecisionDescription,
      stage: response.stage,
      subStage: response.subStage,
      decision: response.decision,
      dipCreditCommitments: this.mapCreditCommitmentModel(
        response.dipCreditCommitments,
        response.applicant1,
        response.applicant2,
        response.loanInformation?.numberOfApplicants ?? 0
      ),
      id: response.id,
      applicant1: { ...response.applicant1 },
      applicant2: { ...response.applicant2 },
      brokerFees: { ...response.brokerFees },
      created: response.created,
      expenditures: this.mapExpenditureModel(response.expenditures),
      loanInformation: { ...response.loanInformation },
      propertyDetails: { ...response.propertyDetails },
      reference: response.reference,
      selectedOffer: { ...response.selectedOffer }
    };
    if (dip.selectedOffer && !dip.selectedOffer.status) {
      dip.selectedOffer.status = 'Accept';
    }
    return dip;
  }

  /**
   * Maps the facility details response.
   */
  mapFacilityDetails(facilityDetails: FacilityDetailsModel): FacilityDetailsModel {
    return {
      clientMeetsEligibilityCriteria: true,
      numberOfApplicants: toNumber(facilityDetails[FacilityDetailsControlNamesEnum.NUMBER_OF_APPLICANTS]),
      requestedLoanAmount: toNumber(facilityDetails[FacilityDetailsControlNamesEnum.LOAN_AMOUNT_REQUESTED]),
      requestedLoanTerm: toNumber(facilityDetails[FacilityDetailsControlNamesEnum.LOAN_TERM_REQUESTED]),
      facilities: facilityDetails[FacilityDetailsControlNamesEnum.FACILITIES_ARRAY]
        .filter(
          (facility: FacilityAllocationModel) =>
            (facility[FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_AMOUNT] as any)?.length > 0 ||
            facility[FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_PURPOSE]?.length > 0
        )
        .map((facility: FacilityAllocationModel) => {
          return {
            allocationAmount: toNumber(facility[FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_AMOUNT]),
            allocationPurpose: facility[FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_PURPOSE]
          };
        }),
      isUserTheAdvisingBroker: facilityDetails.isUserTheAdvisingBroker,
      intermediary: !facilityDetails.isUserTheAdvisingBroker
        ? {
            ...facilityDetails.intermediary
          }
        : undefined
    };
  }

  /**
   * Maps the property details response.
   */
  mapPropertyDetails(propertyDetails: PropertyDetailsViewModel): PropertyDetailsModel {
    return {
      isSameAsResidentialAddress: propertyDetails.isSameAsResidentialAddress,
      address: ConsumerDipResponseService.getPropertyDetailsAddress(propertyDetails),
      estimatedValue: toNumber(propertyDetails[PropertyDetailsControlNamesEnum.ESTIMATED_VALUE]),
      whenHasLastPurchased: toNumber(propertyDetails[PropertyDetailsControlNamesEnum.PURCHASE_YEAR]),
      purchaseValue: toNumber(propertyDetails[PropertyDetailsControlNamesEnum.PURCHASE_PRICE]),
      propertyType: propertyDetails[PropertyDetailsControlNamesEnum.PROPERTY_TYPE],
      numberOfBedrooms: toNumber(propertyDetails[PropertyDetailsControlNamesEnum.NUMBER_OF_BEDROOMS])
    };
  }

  /**
   * Maps the broker fees details response.
   */
  mapBrokerFeesDetails(
    brokerFeesDetails: BrokerFeesDetailsModel
  ): Omit<BrokerFeesDetailsModel, 'addArrangementFeeSelina'> {
    const feeDetails: Omit<BrokerFeesDetailsModel, 'addArrangementFeeSelina'> = {
      commissionFee: toNumber(brokerFeesDetails.commissionFee),
      addCommissionFeeToLoan: brokerFeesDetails.addCommissionFeeToLoan,
      arrangementFee: toNumber(brokerFeesDetails.arrangementFee),
      addArrangementFeeToLoan: brokerFeesDetails.addArrangementFeeToLoan,
      valuationFee: toNumber(brokerFeesDetails.valuationFee),
      addValuationFeeToLoan: brokerFeesDetails.addValuationFeeToLoan,
      thirdPartyFee: toNumber(brokerFeesDetails.thirdPartyFee),
      addThirdPartyFeeToLoan: brokerFeesDetails.addThirdPartyFeeToLoan,
      addProductFeeToLoan: brokerFeesDetails.addProductFeeToLoan,
      addArrangementFeeSelinaToLoan: brokerFeesDetails.addArrangementFeeSelinaToLoan
    };
    if (brokerFeesDetails.intermediary) {
      feeDetails.intermediary = {
        adviceFee: toNumber(brokerFeesDetails.intermediary.adviceFee),
        addAdviceFeeToLoan: brokerFeesDetails.intermediary.addAdviceFeeToLoan
      };
    }
    return feeDetails;
  }

  /**
   * Maps the brokerFees and facilityDetails forms data to capture the values used to re-calculate the offer.
   */
  mapProductSelectionCalculationDetails(
    facilityDetails: FacilityDetailsModel,
    addProductFees: boolean,
    propertyValue?: number
  ): ProductOfferCalculationDetailsModel {
    return {
      ...(facilityDetails && {
        loanValues: {
          requestedLoanAmount: toNumber(facilityDetails.requestedLoanAmount),
          requestedLoanTerm: toNumber(facilityDetails.requestedLoanTerm)
        }
      }),
      ...(!isUndefined(addProductFees) && { addProductFeesToFacility: addProductFees }),
      ...(propertyValue && { propertyDetails: { estimatedValue: propertyValue } })
    };
  }

  /**
   * Maps the debt and expenditure details response.
   */
  mapCreditCommitmentDetails(data: BaseCreditCommitmentsViewModel): BaseCreditCommitmentsDetailsModel {
    if (!data) {
      return null;
    }

    return {
      securedCreditCommitments: data.securedCreditCommitments?.map((secured: SecuredCreditCommitmentViewModel) => {
        return CreditCommitmentsMapper.toSecuredCreditCommitmentDetailsModel(secured);
      }),
      unsecuredCreditCommitments: data.unsecuredCreditCommitments?.map(
        (unsecured: UnsecuredCreditCommitmentViewModel) => {
          return CreditCommitmentsMapper.toUnsecuredCreditCommitmentsDetailsModel(unsecured);
        }
      ),
      ccjs: data.ccjs?.map((ccj: CcjViewModel) => {
        return CreditCommitmentsMapper.toCcjDetailsModel(ccj);
      })
    };
  }

  mergeCreditCommitments(
    creditCommitmentsDetails: CreditCommitmentsViewModel,
    currentCreditCommitmentsData: CreditCommitmentsViewModel
  ): BaseCreditCommitmentsViewModel {
    if (!creditCommitmentsDetails || !currentCreditCommitmentsData) {
      return null;
    }

    return {
      securedCreditCommitments: creditCommitmentsDetails.securedCreditCommitments?.map(
        (secured: SecuredCreditCommitmentViewModel, index: number) => {
          return CreditCommitmentsMapper.mergeSecuredCreditCommitmentViewModel(
            currentCreditCommitmentsData,
            index,
            secured
          );
        }
      ),
      unsecuredCreditCommitments: creditCommitmentsDetails.unsecuredCreditCommitments?.map(
        (unsecured: UnsecuredCreditCommitmentViewModel, index: number) => {
          return CreditCommitmentsMapper.mergeUnsecuredCreditCommitmentViewModel(
            currentCreditCommitmentsData,
            index,
            unsecured
          );
        }
      ),
      ccjs: creditCommitmentsDetails.ccjs?.map((ccj: CcjViewModel, index: number) => {
        return CreditCommitmentsMapper.mergeCcjViewModel(currentCreditCommitmentsData, index, ccj);
      })
    };
  }

  /**
   * Maps the debt and expenditure details response.
   */
  mapCreditCommitmentModel(
    data: CreditCommitmentsDetailsModel,
    applicant1: ApplicantModel,
    applicant2: ApplicantModel,
    numberOfApplicants: number
  ): CreditCommitmentsViewModel {
    if (!data) {
      return null;
    }

    const creditCommitments: CreditCommitmentsViewModel = {
      creditCommitmentsReady: data.creditCommitmentsReady,
      creditCommitmentsError: data.creditCommitmentsError,
      applicantsScore: [],
      securedCreditCommitments:
        data.securedCreditCommitments
          ?.map((secured: MortgageOrSecuredCreditCommitmentModel) => {
            return CreditCommitmentsMapper.toSecuredCreditCommitmentsViewModel(secured);
          })
          ?.sort((a: SecuredCreditCommitmentViewModel, b: SecuredCreditCommitmentViewModel) => {
            return (
              a.source.localeCompare(b.source) ||
              asSortable(a.status).localeCompare(asSortable(b.status)) ||
              asDate(b.startDate).getTime() - asDate(a.startDate).getTime()
            );
          }) ?? [],
      unsecuredCreditCommitments:
        data.unsecuredCreditCommitments
          ?.map((unsecured: UnsecuredCreditCommitmentModel) => {
            return CreditCommitmentsMapper.toUnsecuredCreditCommitmentsViewModel(unsecured);
          })
          ?.sort((a: UnsecuredCreditCommitmentViewModel, b: UnsecuredCreditCommitmentViewModel) => {
            return (
              a.source.localeCompare(b.source) ||
              asSortable(a.status).localeCompare(asSortable(b.status)) ||
              asDate(b.startDate).getTime() - asDate(a.startDate).getTime()
            );
          }) ?? [],
      ccjs:
        data.ccjs
          ?.map((ccj: CcjModel) => {
            return CreditCommitmentsMapper.toCcjViewModel(ccj);
          })
          ?.sort((a: CcjViewModel, b: CcjViewModel) => {
            return (
              a.source.localeCompare(b.source) ||
              asSortableCcj(a.status).localeCompare(asSortableCcj(b.status)) ||
              asDate(b.registrationDate).getTime() - asDate(a.registrationDate).getTime()
            );
          }) ?? [],
      creditCheckExpiry: toDateOrNull(data.creditCheckExpiry)
    };
    const scores: ApplicantScoreViewModel[] =
      data.applicantsScore?.map((score: ApplicantCreditScoreModel) => {
        return CreditCommitmentsMapper.toApplicantScoreViewModel(score, applicant1, applicant2);
      }) ?? [];
    CreditCommitmentsMapper.checkForMissingApplicantCreditFile(scores, applicant1, numberOfApplicants, applicant2);
    creditCommitments.applicantsScore = scores;
    return creditCommitments;
  }

  /**
   * Maps the debt and expenditure details response.
   */
  mapExpenditureDetails(data: HouseholdExpenditureViewModel): ExpenditureDetailsModel {
    return this.sumExpenditures({
      numberOfChildDependants: ConsumerDipResponseService.fromDependentsCount(
        data.childDependantsCount,
        data.numberOfChildDependants
      ),
      numberOfAdultDependants: ConsumerDipResponseService.fromDependentsCount(
        data.adultDependantsCount,
        data.numberOfAdultDependants
      ),
      foodDrinkAndOtherHousekeeping: toNumber(data.foodDrinkAndOtherHousekeepingCosts),
      clothingAndFootwear: toNumber(data.clothingAndFootwear),
      tvPhoneAndInternet: toNumber(data.tvPhoneAndInternet),
      utilities: toNumber(data.utilities),
      furnishingAndPropertyMaintenance: toNumber(data.furnishingAndPropertyMaintenance),
      insurance: toNumber(data.insurance),
      councilTax: toNumber(data.councilTax),
      transport: toNumber(data.transport),
      recreation: toNumber(data.recreation),
      groundRentAndServiceCharge: toNumber(data.groundRentAndServiceCharge),
      schoolNurseryAndChildcareFees: toNumber(data.schoolNurseryAndChildcareFees),
      alimonyAndChildMaintenance: toNumber(data.alimonyAndChildMaintenance),
      other: toNumber(data.otherMonthlyExpenditure),
      totalMonthlyExpenditure: 0
    });
  }

  /**
   * Maps the debt and expenditure details response.
   */
  mapExpenditureModel(data: ExpenditureDetailsModel): HouseholdExpenditureViewModel {
    if (!data) {
      return {} as HouseholdExpenditureViewModel;
    }
    return {
      childDependantsCount: this.toDependentsCount(data.numberOfChildDependants),
      numberOfChildDependants: this.toDependentsNumber(data.numberOfChildDependants),
      adultDependantsCount: this.toDependentsCount(data.numberOfAdultDependants),
      numberOfAdultDependants: this.toDependentsNumber(data.numberOfAdultDependants),
      foodDrinkAndOtherHousekeepingCosts: toNumber(data.foodDrinkAndOtherHousekeeping),
      clothingAndFootwear: toNumber(data.clothingAndFootwear),
      tvPhoneAndInternet: toNumber(data.tvPhoneAndInternet),
      utilities: toNumber(data.utilities),
      furnishingAndPropertyMaintenance: toNumber(data.furnishingAndPropertyMaintenance),
      insurance: toNumber(data.insurance),
      councilTax: toNumber(data.councilTax),
      transport: toNumber(data.transport),
      recreation: toNumber(data.recreation),
      groundRentAndServiceCharge: toNumber(data.groundRentAndServiceCharge),
      schoolNurseryAndChildcareFees: toNumber(data.schoolNurseryAndChildcareFees),
      alimonyAndChildMaintenance: toNumber(data.alimonyAndChildMaintenance),
      otherMonthlyExpenditure: toNumber(data.other)
    };
  }

  mapApplicant(applicant: ApplicantViewModel): ApplicantModel {
    return {
      title: applicant[ApplicantControlNamesEnum.TITLE],
      firstName: trimValue(applicant[ApplicantControlNamesEnum.FIRST_NAME]),
      middleName: trimValue(applicant[ApplicantControlNamesEnum.MIDDLE_NAME]),
      surname: trimValue(applicant[ApplicantControlNamesEnum.SURNAME]),
      applicantUsedAnotherName: applicant[ApplicantControlNamesEnum.USED_ANOTHER_NAME],
      previousName: applicant[ApplicantControlNamesEnum.PREVIOUS_NAME_ARRAY].map((name: PreviousNameModel) => {
        return {
          title: name[ApplicantControlNamesEnum.PREVIOUS_TITLE],
          firstName: trimValue(name[ApplicantControlNamesEnum.PREVIOUS_FIRST_NAME]),
          middleName: trimValue(name[ApplicantControlNamesEnum.PREVIOUS_MIDDLE_NAME]),
          surname: trimValue(name[ApplicantControlNamesEnum.PREVIOUS_SURNAME])
        };
      }),
      currentAddress: ConsumerDipResponseService.getApplicant2Address(applicant),
      livedInCurrentAddressFor3Years: applicant[ApplicantControlNamesEnum.LIVED_AT_ADDRESS_3_YEARS],
      applicant2LivesWithApplicant1: applicant[ApplicantControlNamesEnum.APPLICANT_2_LIVES_WITH_APPLICANT_1],
      applicant2LivesWithApplicant1For3Years:
        applicant[ApplicantControlNamesEnum.APPLICANT_2_LIVES_WITH_APPLICANT_1_FOR_3_YEARS],
      currentAddressMovedInDate: toDateString(applicant[ApplicantControlNamesEnum.CURRENT_ADDRESS_MOVED_IN_DATE]),
      previousAddresses: this.getApplicant2PreviousAddresses(applicant),
      dateOfBirth: toDateString(applicant[ApplicantControlNamesEnum.DATE_OF_BIRTH]),
      estimatedRetirementAge: toNumber(applicant[ApplicantControlNamesEnum.RETIREMENT_AGE]),
      maritalStatus: applicant[ApplicantControlNamesEnum.MARITAL_STATUS],
      nationality: applicant[ApplicantControlNamesEnum.COUNTRY_OF_CITIZENSHIP],
      employment: ConsumerDipResponseService.mapApplicantEmployment(applicant),
      income: this.mapApplicantIncome(applicant)
    };
  }

  private static fromDependentsCount(type: DependentsCountType, count: number): number {
    if (type !== '4+') {
      return toNumber(type);
    }
    return toNumber(count);
  }

  // noinspection JSMethodCanBeStatic
  private toDependentsCount(count: number): DependentsCountType {
    if (count === null || count === undefined) {
      return null;
    }
    if (count < 4) {
      return `${count}` as DependentsCountType;
    }
    return '4+';
  }

  // noinspection JSMethodCanBeStatic
  private toDependentsNumber(count: number): number {
    if (!count || count < 4) {
      return null;
    }
    return count;
  }

  private static getPropertyDetailsAddress(propertyDetails: PropertyDetailsViewModel): AddressModel {
    if (propertyDetails.isSameAsResidentialAddress) {
      return null;
    }
    return ConsumerDipResponseService.mapAddress(propertyDetails[PropertyDetailsControlNamesEnum.ADDRESS_FORM_GROUP]);
  }

  private static getApplicant2Address(applicant: ApplicantViewModel): AddressModel {
    if (applicant.applicant2LivesWithApplicant1) {
      return null;
    }
    return ConsumerDipResponseService.mapAddress(applicant[ApplicantControlNamesEnum.ADDRESS_FORM_GROUP]);
  }

  private getApplicant2PreviousAddresses(applicant: ApplicantViewModel): PreviousAddressModel[] {
    if (!applicant.applicant2LivesWithApplicant1 || !applicant.applicant2LivesWithApplicant1For3Years) {
      const previousAddresses: PreviousAddressModel[] = applicant[ApplicantControlNamesEnum.PREVIOUS_ADDRESS_ARRAY];
      return this.mapPreviousAddresses(previousAddresses);
    } else {
      return [];
    }
  }

  private mapPreviousAddresses(previousAddresses: PreviousAddressModel[]): PreviousAddressModel[] {
    return previousAddresses.map((address: PreviousAddressModel) => {
      return {
        ...ConsumerDipResponseService.mapAddress(address),
        from: toDateString(new Date(address[ApplicantControlNamesEnum.DATE_APPLICANT_MOVED_IN])),
        to: toDateString(new Date(address[ApplicantControlNamesEnum.DATE_APPLICANT_MOVED_OUT]))
      };
    });
  }

  private static mapApplicantEmployment(applicant: ApplicantViewModel): EmploymentModel {
    const employment: EmploymentModel = {
      employmentStatus: applicant[ApplicantControlNamesEnum.EMPLOYMENT_STATUS]
    };

    const formGroup: any = applicant[ApplicantControlNamesEnum.EMPLOYMENT_FORM_GROUP];

    switch (applicant[ApplicantControlNamesEnum.EMPLOYMENT_STATUS]) {
      case EmploymentStatusEnum.EMPLOYED:
        employment.employed = {
          employmentType: formGroup[ApplicantControlNamesEnum.EMPLOYMENT_TYPE],
          contractStartDate: toDateString(formGroup[ApplicantControlNamesEnum.CONTRACT_START_DATE]),
          isInProbationPeriod: formGroup[ApplicantControlNamesEnum.IS_IN_PROBATION_PERIOD]
        };
        break;
      case EmploymentStatusEnum.SELF_EMPLOYED_LIMITED_COMPANY:
      case EmploymentStatusEnum.SELF_EMPLOYED_SOLE_TRADER_PARTNERSHIP:
        employment.selfEmployed = {
          fiscalYear: formGroup[ApplicantControlNamesEnum.FISCAL_YEAR_REPORTED],
          ownershipShare: toNumber(formGroup[ApplicantControlNamesEnum.OWNERSHIP_SHARE]),
          timeInSelfEmployment: formGroup[ApplicantControlNamesEnum.TIME_IN_SELF_EMPLOYMENT]
        };
        break;
      case EmploymentStatusEnum.SELF_EMPLOYED_CONTRACTOR:
        employment.contractor = {
          contractStartDate: toDateString(formGroup[ApplicantControlNamesEnum.CONTRACT_START_DATE]),
          contractEndDate: toDateString(formGroup[ApplicantControlNamesEnum.CONTRACT_END_DATE]),
          isFirstTimeContractor: formGroup[ApplicantControlNamesEnum.IS_FIRST_TIME_CONTRACTOR]
        };
        break;
      case EmploymentStatusEnum.RETIRED:
      case EmploymentStatusEnum.NOT_IN_PAID_EMPLOYMENT:
        break;
    }

    return employment;
  }

  private mapApplicantIncome(applicant: ApplicantViewModel): IncomeModel {
    const incomeSources: IncomeSourceModel[] = [];
    const formGroupValue: IncomeSourceFormGroupViewModel = applicant.incomeSourceFormGroup;

    const salaryInput: number = +formGroupValue.salaryInput;
    if (!isNaN(salaryInput) && salaryInput > 0) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.GROSS_SALARY,
        amount: toNumber(formGroupValue.salaryInput)
      });
    }

    if (!isNaN(+formGroupValue.bonusInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.BONUS,
        amount: toNumber(formGroupValue.bonusInput)
      });
    }

    if (!isNaN(+formGroupValue.commissionInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.COMMISSION,
        amount: toNumber(formGroupValue.commissionInput)
      });
    }

    if (!isNaN(+formGroupValue.overtimeInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.OVERTIME,
        amount: toNumber(formGroupValue.overtimeInput)
      });
    }

    if (!isNaN(+formGroupValue.directorSalaryInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.DIRECTOR_GROSS_SALARY,
        amount: toNumber(formGroupValue.directorSalaryInput)
      });
    }

    if (!isNaN(+formGroupValue.dividendsInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.DIVIDENDS,
        amount: toNumber(formGroupValue.dividendsInput)
      });
    }

    if (!isNaN(+formGroupValue.netProfitInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.NET_PROFIT,
        amount: toNumber(formGroupValue.netProfitInput)
      });
    }

    if (!isNaN(+formGroupValue.drawingsInput)) {
      incomeSources.push({
        type: IncomeSourceTypeEnum.DRAWINGS,
        amount: toNumber(formGroupValue.drawingsInput)
      });
    }

    formGroupValue.retiredIncomeSourceArray?.forEach((incomeSource: IncomeSourceViewModel) => {
      if (incomeSource.incomeType) {
        incomeSources.push({
          type: incomeSource.incomeType,
          amount: toNumber(incomeSource.incomeAmount)
        });
      }
    });

    formGroupValue.extraIncomeSourceArray?.forEach((incomeSource: IncomeSourceViewModel) => {
      if (incomeSource.incomeType) {
        incomeSources.push({
          type: incomeSource.incomeType,
          amount: toNumber(incomeSource.incomeAmount)
        });
      }
    });

    const income: IncomeModel = {
      doesNotHaveAnyIncome: formGroupValue.doesNotHaveAnyIncome ?? false,
      expectsFutureIncomeDecrease: applicant[ApplicantControlNamesEnum.ANY_FUTURE_DECREASE] ?? false,
      futureIncomeDecreaseReason: applicant[ApplicantControlNamesEnum.REASON_FOR_FUTURE_DECREASE],
      income: incomeSources
    };

    if (!isNaN(+formGroupValue.contractDayRateInput) && !isNaN(+formGroupValue.countDaysWorkedWeeklyInput)) {
      income.contractorIncome = {
        dailyRate: toNumber(formGroupValue.contractDayRateInput),
        daysWorkedWeekly: toNumber(formGroupValue.countDaysWorkedWeeklyInput)
      };
    }

    return income;
  }

  private static mapAddress(address: AddressModel): AddressModel {
    if (!address) {
      return null;
    }
    return {
      addressLine1: trimValue(address[AddressControlNamesEnum.ADDRESS_LINE_1]),
      addressLine2: trimValue(address[AddressControlNamesEnum.ADDRESS_LINE_2]),
      city: trimValue(address[AddressControlNamesEnum.CITY]),
      postcode: trimValue(address[AddressControlNamesEnum.POSTCODE]),
      subBuildingName: address[AddressControlNamesEnum.SUB_BUILDING_NAME],
      buildingName: address[AddressControlNamesEnum.BUILDING_NAME],
      buildingNumber: address[AddressControlNamesEnum.BUILDING_NUMBER],
      udprn: address[AddressControlNamesEnum.UDPRN],
      poBox: address[AddressControlNamesEnum.PO_BOX],
      county: address[AddressControlNamesEnum.COUNTY],
      country: address[AddressControlNamesEnum.COUNTRY],
      uprn: address[AddressControlNamesEnum.UPRN]
    };
  }

  private sumExpenditures(data: ExpenditureDetailsModel): ExpenditureDetailsModel {
    if (!data) {
      return data;
    }
    const expenditures: ExpenditureDetailsModel = {
      ...data,
      numberOfAdultDependants: 0,
      numberOfChildDependants: 0
    } as ExpenditureDetailsModel;
    data.totalMonthlyExpenditure = Object.keys(expenditures)
      .map((key: string) => ConsumerDipResponseService.checkNumber(expenditures[key]))
      .reduce((current: number, next: number) => current + next, 0);
    return data;
  }

  private static checkNumber(expenditure: any): number {
    return expenditure && typeof expenditure === 'number' ? expenditure : 0;
  }
}
