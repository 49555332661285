import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { ParseError, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js/max';
import { differenceInMonths, isBefore } from 'date-fns';

import { ApplicantControlNamesEnum } from '../../consumer/dip/form/applicant/enum/applicant-control-names.enum';
import { CreditCommitmentsControlNamesEnum } from '../../consumer/dip/form/credit-commitments/enum/credit-commitments-control-names.enum';
import { FacilityDetailsControlNamesEnum } from '../../consumer/dip/form/facility-details/enum/facility-details-control-names.enum';
import { PropertyCharacteristicsFormControlNamesEnum } from '../../consumer/full-app/details/property/characteristics/enum/property-characteristics-form-control-names.enum';
import { ConsentFormControlNamesEnum } from '../../consumer/full-app/details/consent/enum/consent-form-control-names.enum';
import { DisbursementOptionsEnum } from '../../consumer/full-app/details/consent/enum/disbursement-options.enum';
import { FullApplicationDetailsConsentCompletionAmountModel } from '../consumer/details/model/full-application-details-consent-completion-amount.model';

/**
 * Validates if the year is acceptable.
 */
export function yearDifference(maxDifference: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value?.length) {
      const currentDate: Date = new Date();
      const maxYear: number = currentDate.getFullYear();
      const minYear: number = maxYear - maxDifference;

      if (+control.value > maxYear || +control.value < minYear) {
        return { invalidYear: true };
      }
    }

    return null;
  };
}

/**
 * Validates if the sum of the facilities loan amount is equal to the amount requested.
 */
export const loanAmountSumValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const totalValueControl: UntypedFormControl = formGroup.get(
    FacilityDetailsControlNamesEnum.LOAN_AMOUNT_REQUESTED
  ) as UntypedFormControl;
  const facilitiesArray: UntypedFormArray = formGroup.get(
    FacilityDetailsControlNamesEnum.FACILITIES_ARRAY
  ) as UntypedFormArray;
  const controlsWithValue: UntypedFormControl[] =
    facilitiesArray?.length > 0
      ? facilitiesArray.controls
          .map(
            (group: UntypedFormGroup) =>
              group?.get(FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_AMOUNT) as UntypedFormControl
          )
          .filter((control: UntypedFormControl) => control?.value?.length > 0)
      : [];

  if (totalValueControl?.value?.length > 0) {
    const sum: number = controlsWithValue.reduce(
      (accumulator: number, control: UntypedFormControl) => accumulator + +control.value,
      0
    );

    if (controlsWithValue.length && sum !== +totalValueControl.value) {
      totalValueControl.setErrors({
        ...totalValueControl.errors,
        sumAmount: true
      });
      totalValueControl.markAsTouched();

      let biggerThanSum = false;

      // Has single value bigger than the sum
      controlsWithValue.forEach((control: UntypedFormControl) => {
        if (+control.value > +totalValueControl.value) {
          control.setErrors({
            ...control.errors,
            sumAmount: true
          });
          control.markAsTouched();
          biggerThanSum = true;
        } else {
          clearError(control, 'sumAmount');
        }
      });

      // Show error on the last loan amount requested input
      if (!biggerThanSum && sum > +totalValueControl.value) {
        const lastControl: UntypedFormControl = controlsWithValue[controlsWithValue.length - 1];
        lastControl.setErrors({
          ...lastControl.errors,
          sumAmount: true
        });
        lastControl.markAsTouched();
      }

      return null;
    }
  }

  clearError(totalValueControl, 'sumAmount');
  controlsWithValue.forEach((control: UntypedFormControl) => {
    clearError(control, 'sumAmount');
  });
  return null;
};

/**
 * Validates if both fields are null or are filled.
 */
export const facilityRequiredValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const purposeControl: UntypedFormControl = formGroup.get(
    FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_PURPOSE
  ) as UntypedFormControl;
  const amountControl: UntypedFormControl = formGroup.get(
    FacilityDetailsControlNamesEnum.FACILITY_ALLOCATION_AMOUNT
  ) as UntypedFormControl;

  if (purposeControl?.value?.length && !amountControl?.value?.length) {
    amountControl.setErrors({
      ...amountControl.errors,
      empty: true
    });
    amountControl.markAsTouched();
  } else {
    clearError(amountControl, 'empty');
  }

  if (amountControl?.value?.length && !purposeControl?.value?.length) {
    purposeControl.setErrors({
      ...purposeControl.errors,
      empty: true
    });
    purposeControl.markAsTouched();
  } else {
    clearError(purposeControl, 'empty');
  }

  return null;
};

/**
 * Validates if both fields are null or are filled.
 */
export const numberOfFloorsValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const numberOfFloorsControl: UntypedFormControl = formGroup.get(
    PropertyCharacteristicsFormControlNamesEnum.NUMBER_OF_FLOORS_IN_THE_BUILDING
  ) as UntypedFormControl;
  const floorNumberControl: UntypedFormControl = formGroup.get(
    PropertyCharacteristicsFormControlNamesEnum.FLAT_FLOOR_NUMBER
  ) as UntypedFormControl;

  if (numberOfFloorsControl.valid && +floorNumberControl?.value > +numberOfFloorsControl?.value) {
    floorNumberControl.setErrors({
      ...floorNumberControl.errors,
      invalidFloor: true
    });
    floorNumberControl.markAsTouched();
  } else {
    clearError(floorNumberControl, 'invalidFloor');
  }

  return null;
};

/**
 * Validates if age is between min and max.
 */
export const validateDateRange: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const inControl: UntypedFormControl = formGroup?.controls[
    ApplicantControlNamesEnum.DATE_APPLICANT_MOVED_IN
  ] as UntypedFormControl;
  const outControl: UntypedFormControl = formGroup?.controls[
    ApplicantControlNamesEnum.DATE_APPLICANT_MOVED_OUT
  ] as UntypedFormControl;
  if (!inControl || !outControl) {
    return null;
  }
  const inDate: Date = inControl.value;
  const outDate: Date = outControl.value;
  if (differenceInMonths(inDate, outDate) > 0) {
    outControl.setErrors({
      ...outControl.errors,
      invalidRange: true
    });
  }

  return null;
};

/**
 * Validates if the date is less than 3 years.
 */
export const validateMinimumDate: ValidatorFn = (formDateControl: UntypedFormControl): ValidationErrors | null => {
  const currentTime: Date = new Date();
  const yearsAgo: Date = new Date(currentTime.getFullYear() - 3, currentTime.getMonth());
  if (isBefore(formDateControl.value as Date, yearsAgo)) {
    setTimeout(() => {
      formDateControl.setErrors({
        ...formDateControl.errors,
        invalidRange: true
      });
      return;
    });
  }

  return null;
};

/**
 * Validates the email address.
 */
export function emailValidator(): ValidatorFn {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control?.value && !control.value.match(emailRegex)) {
      return { email: true };
    }

    return null;
  };
}

/**
 * Validates mobile phone.
 */
export function mobilePhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control?.value?.length) {
      try {
        const phoneNumber: PhoneNumber = parsePhoneNumber(control.value, 'GB');

        if (!phoneNumber.isValid()) {
          return { invalidPhone: true };
        }
      } catch (error) {
        if (error instanceof ParseError) {
          // Not a phone number, non-existent country, etc.
          return { invalidPhone: true };
        }
        throw error;
      }
    }

    return null;
  };
}

export function completionAmountValidator(data: FullApplicationDetailsConsentCompletionAmountModel): ValidatorFn {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const optionValue = formGroup.get(ConsentFormControlNamesEnum.COMPLETION_DETAILS_DISBURSEMENT_OPTION)
      .value as DisbursementOptionsEnum;
    const amountControl = formGroup.get(
      ConsentFormControlNamesEnum.COMPLETION_DETAILS_DISBURSEMENT_AMOUNT
    ) as UntypedFormControl;

    if (optionValue === DisbursementOptionsEnum.FULL || optionValue === DisbursementOptionsEnum.UNKNOWN) {
      amountControl.clearValidators();
    }

    if (optionValue === DisbursementOptionsEnum.PARTIAL) {
      amountControl.setValidators([
        Validators.required,
        Validators.min(data.minDisbursementAmount),
        Validators.max(data.maxDisbursementAmount)
      ]);
    }

    return null;
  };
}

const valueAsNumber: (value: any) => number = (value: any): number => {
  if (value === null || value === undefined) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  const converted: number = +value;
  return isNaN(converted) ? 0 : converted;
};

const valueAsDate: (value: any) => Date = (value: any): Date => {
  if (value === null || value === undefined) {
    return null;
  }
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'number') {
    const converted: number = +value;
    return isNaN(converted) ? null : new Date(converted);
  }
  if (typeof value === 'number') {
    const converted: number = +value;
    return isNaN(converted) ? null : new Date(converted);
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return null;
};

const validateFieldMaxValue: (validatingControl: UntypedFormControl, referenceControl: UntypedFormControl) => null = (
  validatingControl: UntypedFormControl,
  referenceControl: UntypedFormControl
): null => {
  if (validatingControl) {
    clearError(validatingControl, 'max');
  }
  const validating: number = valueAsNumber(validatingControl?.value);
  const reference: number = valueAsNumber(referenceControl?.value);
  if (validating > reference) {
    validatingControl.setErrors({
      ...validatingControl.errors,
      max: true
    });
    validatingControl.markAsTouched();
  }

  return null;
};

const validateValueMaxValue: (validatingControl: UntypedFormControl, referenceValue: number) => null = (
  validatingControl: UntypedFormControl,
  outstandingBalance: number
): null => {
  if (validatingControl) {
    clearError(validatingControl, 'max');
  }
  const validating: number = valueAsNumber(validatingControl?.value);
  const reference: number = valueAsNumber(outstandingBalance);
  if (validating > reference) {
    validatingControl.setErrors({
      ...validatingControl.errors,
      max: true
    });
    validatingControl.markAsTouched();
  }

  return null;
};

/**
 * Validates if the interest only of part and part mortgage value is less than the outstanding balance of the prior
 * charge.
 */
export const interestOnlyOfPartAndPartValidator: ValidatorFn = (
  formGroup: UntypedFormGroup
): ValidationErrors | null => {
  const outstandingBalanceControl: UntypedFormControl = formGroup.get(
    CreditCommitmentsControlNamesEnum.OUTSTANDING_BALANCE
  ) as UntypedFormControl;
  const interestOnlyBalanceControl: UntypedFormControl = formGroup.get(
    CreditCommitmentsControlNamesEnum.INTEREST_ONLY_BALANCE
  ) as UntypedFormControl;

  return validateFieldMaxValue(interestOnlyBalanceControl, outstandingBalanceControl);
};

/**
 * Validates if the interest only of part and part mortgage value is less than the outstanding balance of the credit
 * commitment.
 */
export const interestOnlyOfPartAndPartValueValidator: (outstandingBalance: number) => ValidatorFn = (
  outstandingBalance: number
): ValidatorFn => {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const interestOnlyBalanceControl: UntypedFormControl = formGroup.get(
      CreditCommitmentsControlNamesEnum.INTEREST_ONLY_BALANCE
    ) as UntypedFormControl;

    return validateValueMaxValue(interestOnlyBalanceControl, outstandingBalance);
  };
};

/**
 * Validates if the settlement date value is after the start date of the credit commitment.
 */
export const settlementDateValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const startDateControl: UntypedFormControl = formGroup.get(
    CreditCommitmentsControlNamesEnum.START_DATE
  ) as UntypedFormControl;
  const settlementDateControl: UntypedFormControl = formGroup.get(
    CreditCommitmentsControlNamesEnum.SETTLEMENT_DATE
  ) as UntypedFormControl;

  if (settlementDateControl) {
    clearError(settlementDateControl, 'min');
  }
  const startDate: Date = valueAsDate(startDateControl?.value);
  const settlementDate: Date = valueAsDate(settlementDateControl?.value);
  if (startDate === null || settlementDate === null) {
    return null;
  }
  if (startDate.getTime() >= settlementDate.getTime()) {
    settlementDateControl.setErrors({
      ...settlementDateControl.errors,
      invalid: true
    });
    settlementDateControl.markAsTouched();
  }

  return null;
};

/**
 * Validates if the amount to consolidate value is less than the outstanding balance of the credit commitment.
 */
export const amountToConsolidateValueValidator: (outstandingBalance: number) => ValidatorFn = (
  outstandingBalance: number
): ValidatorFn => {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const amountToConsolidateControl: UntypedFormControl = formGroup.get(
      CreditCommitmentsControlNamesEnum.AMOUNT_TO_CONSOLIDATE
    ) as UntypedFormControl;

    return validateValueMaxValue(amountToConsolidateControl, outstandingBalance);
  };
};

/**
 * Validator function to validate if two password fields do not match.
 * @return the validator function.
 */
export const passwordNotMatchValidator: (passwords: [string, string]) => ValidatorFn = (
  passwords: [string, string]
): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (!(control instanceof UntypedFormGroup)) {
    return null;
  }
  const firstControl: AbstractControl = control.controls[passwords[0]];
  const secondControl: AbstractControl = control.controls[passwords[1]];

  if (!firstControl?.value || typeof firstControl.value !== 'string') {
    return null;
  }
  if (!secondControl?.value || typeof secondControl.value !== 'string') {
    return null;
  }

  let errors: any = secondControl.errors;
  if (firstControl.value !== secondControl.value) {
    if (errors) {
      errors = {
        ...errors,
        match: true
      };
    } else {
      errors = { match: true };
    }
  } else {
    if (errors) {
      delete errors.match;
      if (Object.keys(errors).length === 0) {
        errors = null;
      }
    }
  }
  secondControl.setErrors(errors);
  return null;
};

/**
 * Validates password complexity.
 */
export const passwordComplexityValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control?.value || typeof control.value !== 'string') {
    return null;
  }

  const password: string = control.value;

  const hasLetter: boolean = /[a-zA-Z]/g.test(password);
  const hasNumber: boolean = /\d/g.test(password);
  const hasLength: boolean = password.length < 8;
  const isInvalid: boolean = hasLength || !(hasLetter && hasNumber);
  if (isInvalid) {
    return {
      complexity: true
    };
  }

  return null;
};

/**
 * Removes whitespace from both sides of a string.
 */
export function trimValue(formControl: AbstractControl): void {
  formControl.setValue(formControl.value?.trim() ?? null);
}

function clearError(formControl: UntypedFormControl, errorName: string): void {
  if (!formControl) {
    return;
  }
  if (formControl.hasError(errorName)) {
    if (Object.keys(formControl.errors).length === 1) {
      formControl.setErrors(null);
    } else {
      formControl.setErrors({
        ...formControl.errors,
        [errorName]: undefined
      });
    }
  }
}
