import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../../../shared/services/auth/auth.service';
import { RegisterModel } from '../../../shared/models/auth/register.model';
import { LocalizableComponent } from '../../../shared/localization/localizable.component';
import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import {
  mobilePhoneValidator,
  passwordComplexityValidator,
  passwordNotMatchValidator,
  trimValue
} from '../../../shared/utils/input-validator';
import { trimPhoneNumber } from '../../../shared/utils/phone-number.util';
import { LoggerService } from '../../../shared/services/logger/logger.service';

@Component({
  selector: 'broker-register-step2',
  templateUrl: './register-step2.component.html'
})
export class RegisterStep2Component extends LocalizableComponent implements OnInit {
  @Input()
  fcaNumber: string;

  registerForm: UntypedFormGroup;

  disabled = false;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthService,
    private readonly mixpanelService: MixpanelService,
    private readonly logger: LoggerService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  get firstNameControl(): UntypedFormControl {
    return this.registerForm.get('firstName') as UntypedFormControl;
  }

  get lastNameControl(): UntypedFormControl {
    return this.registerForm.get('lastName') as UntypedFormControl;
  }

  get phoneNumberControl(): UntypedFormControl {
    return this.registerForm.get('phoneNumber') as UntypedFormControl;
  }

  get emailControl(): UntypedFormControl {
    return this.registerForm.get('email') as UntypedFormControl;
  }

  get passwordControl(): UntypedFormControl {
    return this.registerForm.get('password') as UntypedFormControl;
  }

  get confirmPasswordControl(): UntypedFormControl {
    return this.registerForm.get('confirmPassword') as UntypedFormControl;
  }

  get acceptedTermsAndConditionsControl(): UntypedFormControl {
    return this.registerForm.get('acceptedTermsAndConditions') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * Trims the form control value.
   */
  trimValue(formControl: UntypedFormControl): void {
    trimValue(formControl);
  }

  /**
   * Register a new user.
   */
  register(): void {
    this.disabled = true;
    this.registerForm.setErrors(null);

    const value: RegisterModel = {
      ...this.registerForm.value,
      fcaNumber: this.fcaNumber
    };
    value.phoneNumber = trimPhoneNumber(value.phoneNumber);

    this.authService
      .register(value)
      .then((result: boolean) => {
        this.disabled = false;
        this.authService.fcaNumber = null;
        this.authService.registeredUser = btoa(JSON.stringify(value));
        if (result) {
          this.mixpanelService.trackSignUpEvent(value);
          this.router
            .navigate(['auth/confirm-registration'])
            .catch((error) => this.logger.error('Error navigating to Auth Confirm-Registration', error));
        } else {
          this.emailControl.setErrors({
            alreadyExists: true
          });
        }
      })
      .catch(() => {
        this.registerForm.setErrors({
          generic: true
        });
      });
  }

  private buildForm(): void {
    this.registerForm = this.formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.maxLength(255)]],
        lastName: ['', [Validators.required, Validators.maxLength(255)]],
        phoneNumber: ['', [Validators.required, mobilePhoneValidator()]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.maxLength(255), passwordComplexityValidator]],
        confirmPassword: ['', [Validators.required, Validators.maxLength(255)]],
        acceptedTermsAndConditions: [false]
      },
      {
        validators: [passwordNotMatchValidator(['password', 'confirmPassword'])]
      }
    );
  }
}
