<div class='login-wrapper d-flex justify-content-center'>
  <div class='w-490 d-flex flex-column consumer-flow pt-5'>
    <div class='text-center mt-5 pt-5'>
      <a [routerLink]="['']">
        <img src='/assets/images/simple-logo.svg' alt='Selina Logo' width='300' />
      </a>
      <h1 class='mt-5'>{{ 'authentication.recover.title' | translate: lang }}</h1>
      <p>{{ 'authentication.recover.sub-title' | translate: lang }}</p>
    </div>
    <div>
      <div
        class='warning mt-4'
        [innerHTML]="'authentication.recover.errors.generic' | translate: lang"
        *ngIf='recoverForm.errors?.generic'
      ></div>
    </div>
    <div class='flex-grow-1'>
      <form
        [formGroup]='recoverForm'
        (ngSubmit)='recover()'
        novalidate
        [ngClass]="recoverForm.errors?.invalid ? 'mt-4' : 'mt-5'"
      >
        <div class='mb-3'>
          <broker-label
            [for]="'email'"
            [label]="'authentication.login.form.email.label' | translate:lang">
          </broker-label>
          <mat-form-field appearance='standard'>
            <input
              type='email'
              matInput
              id='email'
              [formControl]='emailControl'
              [placeholder]="'authentication.login.form.email.placeholder' | translate: lang"
              (blur)='trimValue(emailControl)'
            />
          </mat-form-field>
          <mat-error *ngIf='emailControl.errors?.email'>
            {{ 'authentication.login.form.email.errors.email' | translate: lang }}
          </mat-error>
        </div>
        <broker-button [id]="'reset'" [height]="'large'" [width]="'full-width'"
                       [disabled]='!recoverForm.valid || disabled'>{{ 'authentication.recover.submit' | translate: lang }}</broker-button>

        <a [routerLink]="['/auth/login']" class='btn btn-link mt-2'>
          {{ 'authentication.recover.login.return' | translate: lang }}
        </a>
      </form>
    </div>
    <div class='text-center'>
      <p class='font-small'>{{ 'authentication.footer-info' | translate: lang }}</p>
      <p class='font-small'>{{ 'authentication.copyright' | translate: lang:{ currentYear: currentYear } }}</p>
    </div>
  </div>
</div>
