import { Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { RegisterModel } from '../../models/auth/register.model';

declare let window: any;

/**
 * Service to manage the Intercom actions.
 */
@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private isIntercomRunning = false;

  constructor(private readonly authService: AuthService) {}

  /**
   * Boots the Intercom session (we just want to show intercom messenger after login).
   */
  boot(): void {
    if (!this.isIntercomRunning && window?.Intercom) {
      this.authService.getCurrentUserData().then((userData: RegisterModel) => {
        window.Intercom('boot', {
          app_id: environment.intercom.appId,
          name: `${userData.firstName} ${userData.lastName}`,
          email: userData.email,
          'Type of user': 'Broker'
        });
        this.isIntercomRunning = true;
      });
    }
  }

  /**
   * Shutdown the intercom session.
   */
  shutdown(): void {
    if (this.isIntercomRunning) {
      window.Intercom('shutdown');
      this.isIntercomRunning = false;
    }
  }

  /**
   * Opens the intercom messenger.
   */
  openMessenger(): void {
    if (this.isIntercomRunning) {
      window.Intercom('show');
    }
  }
}
