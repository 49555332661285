import { FullApplicationDetailsApplicantNameModel } from '../consumer/details/model/applicant/full-application-details-applicant-name.model';

/**
 * Gets the constructed applicant full name.
 */
export function getApplicantFullName(name: FullApplicationDetailsApplicantNameModel): string {
  if (!name) {
    return '';
  }
  if (name?.surname) {
    return `${name.firstName} ${name.surname}`;
  }
  return name.firstName;
}
