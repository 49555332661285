import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { LoggerLevel } from './logger-level';

/**
 * Logger service.
 */
@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private loggerLevel: LoggerLevel = environment.logLevel;

  private readonly colors: any = {
    trace: '#636c72',
    debug: '#5bc0de',
    info: '#0275d8',
    warn: '#f0ad4e',
    error: '#d9534f'
  };

  /**
   * Gets the logger level.
   * @return the logger level.
   */
  get level(): LoggerLevel {
    return this.loggerLevel;
  }

  /**
   * Sets the logger level.
   * @param loggerLevel the logger level to set.
   */
  set level(loggerLevel: LoggerLevel) {
    if (loggerLevel >= LoggerLevel.OFF && loggerLevel <= LoggerLevel.LOG) {
      this.loggerLevel = loggerLevel;
    } else {
      this.loggerLevel = LoggerLevel.INFO;
    }
  }

  /**
   * Logs a standard log messages. This is the same level as debug.
   *
   * @param message the messages to log the the console.
   * @param object an optional object to dump to the console.
   */
  log(message: string, object: any = null): void {
    if (this.loggerLevel >= LoggerLevel.LOG) {
      if (object === null) {
        console.log(message);
      } else {
        console.log(message, object);
      }
    }
  }

  /**
   * Logs a trace level log messages.
   *
   * @param message the messages to log the the console.
   * @param object an optional object to dump to the console.
   */
  trace(message: string, object: any = null): void {
    if (this.loggerLevel >= LoggerLevel.TRACE) {
      if (object === null) {
        console.trace(`%c${message}`, LoggerService.color(this.colors.trace));
      } else {
        console.trace(`%c${message}`, LoggerService.color(this.colors.trace), object);
      }
    }
  }

  /**
   * Logs a debug level log messages.
   *
   * @param message the messages to log the the console.
   * @param object an optional object to dump to the console.
   */
  debug(message: string, object: any = null): void {
    if (this.loggerLevel >= LoggerLevel.DEBUG) {
      if (object === null) {
        console.debug(`%c${message}`, LoggerService.color(this.colors.debug));
      } else {
        console.debug(`%c${message}`, LoggerService.color(this.colors.debug), object);
      }
    }
  }

  /**
   * Logs an info level log messages.
   *
   * @param message the messages to log the the console.
   * @param object an optional object to dump to the console.
   */
  info(message: string, object: any = null): void {
    if (this.loggerLevel >= LoggerLevel.INFO) {
      if (object === null) {
        console.info(`%c${message}`, LoggerService.color(this.colors.info));
      } else {
        console.info(`%c${message}`, LoggerService.color(this.colors.info), object);
      }
    }
  }

  /**
   * Logs a warning level log messages.
   *
   * @param message the messages to log the the console.
   * @param object an optional object to dump to the console.
   */
  warn(message: string, object: any = null): void {
    if (this.loggerLevel >= LoggerLevel.WARN) {
      if (object === null) {
        console.warn(`%c${message}`, LoggerService.color(this.colors.warn));
      } else {
        console.warn(`%c${message}`, LoggerService.color(this.colors.warn), object);
      }
    }
  }

  /**
   * Logs an error level log messages.
   *
   * @param message the messages to log the the console.
   * @param object an optional object to dump to the console.
   */
  error(message: string, object: any = null): void {
    if (this.loggerLevel >= LoggerLevel.ERROR) {
      if (object === null) {
        console.error(`%c${message}`, LoggerService.color(this.colors.error));
      } else {
        console.error(`%c${message}`, LoggerService.color(this.colors.error), object);
      }
    }
  }

  private static color(id: string): string {
    return `color: ${id}`;
  }
}
