import { Component, Inject, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HttpErrorResponse } from '@angular/common/http';

import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthService } from '../shared/services/auth/auth.service';
import { ConsumerApiDipService } from '../shared/consumer/dip/consumer-api-dip.service';
import {
  PaginationSummaryModel,
  DashboardModel,
  emptyPaginationSummaryModel,
  PaginationMetadataModel
} from '../shared/consumer/model/dashboard.model';
import { ConsumerStorageDipService } from '../shared/consumer/storage/consumer-storage-dip.service';
import { ConsumerStorageFullAppService } from '../shared/consumer/storage/consumer-storage-full-app.service';
import { StorageUserOpportunityModel } from '../shared/consumer/storage/model/storage-user-opportunity.model';
import { CompanyModel } from '../shared/models/dashboard/company.model';
import {
  ApplicationFilterStage,
  ApplicationsFilter,
  ApplicationsPageSortSearchFilter,
  DashboardService
} from '../shared/services/dashboard/dashboard.service';
import { IntercomService } from '../shared/services/intercom/intercom.service';
import { LocalizableComponent } from '../shared/localization/localizable.component';
import { LoggerService } from '../shared/services/logger/logger.service';
import { MixpanelService } from '../shared/services/mixpanel/mixpanel.service';
import { newSelectItem, SelectItemModel } from '../shared/models/select-item.model';
import { TableItemModel } from '../shared/models/table-item.model';
import { PageLinkItemModel } from '../shared/models/page-link-item.model';
import { NavigatorService } from '../shared/services/navigator/navigator.service';
import { StorageKeyEnum } from '../shared/services/storage/enum/storage-key.enum';
import { StorageService } from '../shared/services/storage/storage.service';
import { FeatureFlagsService } from '../shared/services/feature-flags/feature-flags.service';
import { WhatsNewService } from '../shared/services/whats-new/whats-new.service';

export enum DashboardTabs {
  LIVE = 'live',
  CLOSED = 'closed',
  FUNDED = 'funded'
}

/**
 * Dashboard component.
 */
@Component({
  selector: 'broker-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends LocalizableComponent implements OnInit {
  get selectedCompanyId(): string {
    const data: string = this.storage.getValue(StorageKeyEnum.DASHBOARD_SELECTED_COMPANY);
    if (data) {
      return data;
    }
    return null;
  }

  set selectedCompanyId(value: string) {
    this.storage.saveValue(StorageKeyEnum.DASHBOARD_SELECTED_COMPANY, value);
  }

  get canSubmitConsumerFlow(): boolean {
    return this.authService.canSubmitConsumerFlow;
  }

  get canRequestAdminAccess(): boolean {
    return this.authService.canRequestAdminAccess;
  }

  get canRequestAnyAccountAccess(): boolean {
    return this.authService.canRequestAnyAccountAccess;
  }

  get isAdminViewEnabled(): boolean {
    return this.authService.isAdminViewEnabled;
  }

  set isAdminViewEnabled(value: boolean) {
    this.authService.isAdminViewEnabled = value;
  }

  get isAnyAccountViewEnabled(): boolean {
    return this.authService.isAnyAccountViewEnabled;
  }

  set isAnyAccountViewEnabled(value: boolean) {
    this.authService.isAnyAccountViewEnabled = value;
  }

  get isUserActive(): boolean {
    return this.authService.isUserActive;
  }

  isLoadingComponentData = true;
  isLoadingApplications = false;
  isLoadingCompanies = false;
  pages: PageLinkItemModel = {
    dashboard: true,
    documents: false
  };

  hasError = false;
  liveOpportunities: TableItemModel[] = null;
  closedOpportunities: TableItemModel[] = null;
  fundedOpportunities: TableItemModel[] = null;
  companyOptions: SelectItemModel[] = [];
  caseTabIndex = 0;

  readonly dashboardTabs: typeof DashboardTabs = DashboardTabs;
  protected readonly Object = Object;
  private summary: PaginationSummaryModel = emptyPaginationSummaryModel();
  private metadata: PaginationMetadataModel;

  constructor(
    private readonly navigator: NavigatorService,
    private readonly authService: AuthService,
    private readonly dashboardService: DashboardService,
    private readonly logger: LoggerService,
    private readonly dipService: ConsumerApiDipService,
    private readonly consumerStorageDipService: ConsumerStorageDipService,
    private readonly consumerStorageFullAppService: ConsumerStorageFullAppService,
    private readonly mixpanelService: MixpanelService,
    private readonly intercom: IntercomService,
    private readonly storage: StorageService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly whatsNew: WhatsNewService,
    @Inject(L10N_LOCALE) locale: L10nLocale
  ) {
    super(locale);
  }

  ngOnInit(): void {
    this.intercom.boot();
    this.dipService.skipOfferReload = false;
    this.consumerStorageDipService.offersData = null;
    this.consumerStorageDipService.dipData = null;
    this.consumerStorageDipService.creditCommitmentsData = null;
    this.consumerStorageFullAppService.fullApp = null;
    this.caseTabIndex = 0;
    this.fetchDashboardData();
  }

  caseTabChanged(index: number): void {
    this.caseTabIndex = index;
    this.fetchApplications();
  }

  openDIPForm(): void {
    this.mixpanelService.trackStartConsumerEvent();
    this.navigator.navigateToDipForm().catch(() => this.logger.error('Error navigating to DIP form route.'));
  }

  handleAdminToggleChange(event: MatSlideToggleChange): void {
    this.isAdminViewEnabled = event.checked;

    this.doApplicationsRequest();
  }

  handleAnyAccountToggleChange(event: MatSlideToggleChange): void {
    this.isAnyAccountViewEnabled = event.checked;

    if (!this.isAnyAccountViewEnabled) {
      this.selectedCompanyId = null;
      this.doApplicationsRequest();
    } else {
      this.clearAllApplications();
      this.fetchAllCompanies();
    }
  }

  handleCompanySelectChange(): void {
    this.doApplicationsRequest();
  }

  getTabInformation(tab: string) {
    switch (tab) {
      case DashboardTabs.LIVE:
        return {
          records: this.liveOpportunities,
          count: this.summary.totalLiveApplications ?? 0,
          metadata: this.metadata
        };
      case DashboardTabs.CLOSED:
        return {
          records: this.closedOpportunities,
          count: this.summary.totalClosedApplications ?? 0,
          metadata: this.metadata
        };
      case DashboardTabs.FUNDED:
        return {
          records: this.fundedOpportunities,
          count: this.summary.totalFundedApplications ?? 0,
          metadata: this.metadata
        };
    }
  }

  handleFetchApplications(filter: ApplicationsPageSortSearchFilter) {
    this.fetchApplications(this.buildFilters(filter));
  }

  private doApplicationsRequest(): void {
    this.clearAllApplications();
    this.fetchApplications();
  }

  private clearAllApplications(): void {
    this.liveOpportunities = null;
    this.closedOpportunities = null;
    this.fundedOpportunities = null;
    this.summary = emptyPaginationSummaryModel();
    this.metadata = null;
  }

  private fetchApplications(filters: ApplicationsPageSortSearchFilter = undefined, isInitialFetch = false): void {
    if (!this.isUserActive || (this.isAnyAccountViewEnabled && !this.selectedCompanyId)) {
      return;
    }
    this.isLoadingApplications = true;
    this.hasError = false;

    this.dashboardService
      .fetchApplications(this.buildFilters(filters))
      .then((data: DashboardModel) => {
        this.mapApplicationsByStage(data.records);
        this.summary = data.summary;
        this.metadata = data.metadata;
        this.isLoadingApplications = false;
        if (isInitialFetch) {
          this.consumerStorageDipService.userOpportunities = DashboardComponent.mappedUserOpportunities(
            this.liveOpportunities
          );
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.isLoadingApplications = false;
        this.hasError = true;
        this.logger.error('Error fetching applications', error);
      })
      .finally(() => {
        if (isInitialFetch) {
          this.isLoadingComponentData = false;
        }
      });
  }

  private fetchDashboardData(): void {
    if (!this.isUserActive || (this.isAnyAccountViewEnabled && !this.selectedCompanyId)) {
      this.isLoadingComponentData = false;
      return;
    }

    this.featureFlags
      .checkFeatureFlags()
      .then()
      .finally(() => {
        this.whatsNew.showWhatsNewInformation();
        this.fetchApplications(undefined, true);
      });

    if (this.isAnyAccountViewEnabled) {
      this.fetchAllCompanies();
    }
  }

  private fetchAllCompanies(): void {
    if (!this.canRequestAnyAccountAccess) {
      return;
    }

    this.isLoadingCompanies = true;
    this.dashboardService.fetchCompanies().then((companies: CompanyModel[]) => {
      this.companyOptions =
        companies
          ?.sort((a: CompanyModel, b: CompanyModel) => a.name.localeCompare(b.name))
          ?.map((c: CompanyModel) => {
            return newSelectItem(c.name, c.id);
          }) ?? [];
      this.isLoadingCompanies = false;
    });
  }

  private buildFilters(filter?: ApplicationsPageSortSearchFilter): ApplicationsFilter {
    return {
      stage: this.getStage(),
      allApplications: this.isAdminViewEnabled,
      brokerAccountId: this.isAnyAccountViewEnabled ? this.selectedCompanyId : null,
      pageSize: filter?.pageSize,
      pageNumber: filter?.pageNumber,
      sortBy: filter?.sortBy,
      searchTerm: filter?.searchTerm
    };
  }

  private static mappedUserOpportunities(opportunities: TableItemModel[]): StorageUserOpportunityModel[] {
    return opportunities.map<StorageUserOpportunityModel>((opportunity: TableItemModel) => {
      return {
        id: opportunity.id,
        stage: opportunity.stage,
        reference: opportunity.reference
      };
    });
  }

  private mapApplicationsByStage(records: TableItemModel[]) {
    if (this.getStage() === 'live') {
      this.liveOpportunities = records;
    }
    if (this.getStage() === 'closed') {
      this.closedOpportunities = records;
    }
    if (this.getStage() === 'funded') {
      this.fundedOpportunities = records;
    }
  }

  private getStage(): ApplicationFilterStage {
    if (this.caseTabIndex === 0) return 'live';
    if (this.caseTabIndex === 1) return 'closed';
    if (this.caseTabIndex === 2) return 'funded';
  }
}
