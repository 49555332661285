import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { isArray, isString } from 'lodash-es';

import { ConfirmDialogModel } from './model/confirm-dialog.model';

@Component({
  selector: 'broker-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  /**
   * Emits an event when the navigation bar back button click event occurs.
   */
  @Output() navigationBarBackButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits an event when the navigation bar next button click event occurs.
   */
  @Output() navigationBarNextButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   * The next request pending state.
   */
  nextRequestPending = false;

  /**
   * The next request error state.
   */
  nextRequestError = false;

  /**
   * The button disable state.
   */
  isButtonDisabled = false;

  get message(): string {
    return isString(this.data.infoPanelMessage) ? this.data.infoPanelMessage : null;
  }

  get messageArray(): string[] {
    return isArray(this.data.infoPanelMessage) ? this.data.infoPanelMessage : null;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {}

  handleNavigationBarBackButtonClickEvent(): void {
    this.navigationBarBackButtonClickEvent.emit();
  }

  handleNavigationBarNextButtonClickEvent(): void {
    this.navigationBarNextButtonClickEvent.emit();
  }
}
