<div class="with-padding-and-color">
  <span [innerHTML]="'documents.description' | translate: lang"></span>
  <ul class="document-list mt-3 mb-0" *ngIf="canSubmitConsumerFlow">
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('accountantCertificate')">
        {{ 'useful-links.accountant-certificate' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('apiDocumentation')">
        {{ 'useful-links.api-documentation' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('consumerDutyAssessment')">
        {{ 'useful-links.consumer-duty-assessment' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('debtConsolidationForm')">
        {{ 'useful-links.debt-consolidation-form' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('deedOfConsentJointApplication')">
        {{ 'useful-links.deed-of-consent-joint' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('deedOfConsentSingleApplication')">
        {{ 'useful-links.deed-of-consent-single' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('directDebitMandate')">
        {{ 'useful-links.direct-debit-mandate' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('packagingGuide')">
        {{ 'useful-links.packaging-guide' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('privacyPolicy')">
        {{ 'useful-links.privacy-policy' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('productGuide')">
        {{ 'useful-links.product-guide' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('submissionChecklist')">
        {{ 'useful-links.submission-checklist' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('tariffOfCharges')">
        {{ 'useful-links.tariff-of-charges' | translate: lang }}
      </a>
    </li>
    <li>
      <a [target]="'blank'" [href]="getConsumerUrl('termsAndConditions')">
        {{ 'useful-links.terms-and-conditions' | translate: lang }}
      </a>
    </li>
  </ul>
</div>
