export class Rotate {
  set shouldRotate(value: boolean) {
    this._shouldRotate = value;
  }

  get rotate(): string {
    return this._shouldRotate ? 'rotateX(-180deg)' : '';
  }

  private _shouldRotate = false;

  constructor() {
    this.shouldRotate = false;
  }
}
